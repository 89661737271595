import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import spinner from "images/spinner.svg"
import PropTypes from "prop-types"

import useWorkCheck from "./useWorkCheck"

import { DangerAlert } from "~/design/alerts"
import Show from "~/design/Show"

function BigSpinner({ message, workCheckUrl }) {
  const {
    data: { working },
    error,
  } = useWorkCheck(workCheckUrl)

  if (error) {
    return (
      <DangerAlert icon>
        <p className="font-weight-bold mb-3">Error</p>
        <p>{error.message}</p>
      </DangerAlert>
    )
  }

  if (!working) {
    window.location.reload(true)
    message = "Done! Reloading..."
  }

  return (
    <div className="d-flex align-items-center gap-3 p-5 border rounded">
      <Show when={working}>
        <img src={spinner} width={50} height={50} alt="Processing..." />
      </Show>
      <span style={{ fontSize: "1.5rem" }}>{message}</span>
    </div>
  )
}

BigSpinner.propTypes = {
  message: PropTypes.string.isRequired,
  workCheckUrl: PropTypes.string.isRequired,
}

const queryClient = new QueryClient()

function Provider(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <BigSpinner {...props} />
    </QueryClientProvider>
  )
}

export { BigSpinner }
export default Provider
