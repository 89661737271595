import PropTypes from "prop-types"

const diagnosis = PropTypes.shape({
  id: PropTypes.number.isRequired,
  isPrimary: PropTypes.bool,
  description: PropTypes.string,
  diagnosisValueId: PropTypes.number.isRequired,
  diagnosisValueOther: PropTypes.string,
})

export default diagnosis
