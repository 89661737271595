import { useState } from "react"
import { Collapse } from "react-bootstrap"
import PropTypes from "prop-types"

import { LinkButton } from "~/design/buttons"
import { CollapseIcon, ExpandIcon } from "~/design/icons"

function RiskFactorsAccordion({ title, riskFactorDescriptions }) {
  const [isCollapsed, setIsCollapsed] = useState(true)

  function toggleCollapsed() {
    setIsCollapsed((isCollapsed) => !isCollapsed)
  }

  const ArrowIcon = isCollapsed ? CollapseIcon : ExpandIcon

  return (
    <>
      <LinkButton onClick={toggleCollapsed} size="sm">
        <span>{title}</span>
        <ArrowIcon />
      </LinkButton>
      <Collapse in={!isCollapsed}>
        <div>
          <ul className="pt-3">
            {riskFactorDescriptions.map((description) => (
              <li key={description}>{description}</li>
            ))}
          </ul>
        </div>
      </Collapse>
    </>
  )
}

RiskFactorsAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  riskFactorDescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RiskFactorsAccordion
