import PropTypes from "prop-types"

import { redirectTo } from "~/browserHelpers"
import { care_manager_dashboard_path } from "~/routes"
import types from "~/types"
import AutoSubmitForm from "~/views/shared/autoSubmitForm/AutoSubmitForm"

function DashboardSelector({ currentCareManagerId, careManagers }) {
  function handleSubmit({ careManagerId }) {
    redirectTo(care_manager_dashboard_path({ care_manager: { id: careManagerId } }))
  }

  return (
    <AutoSubmitForm initialValues={{ careManagerId: currentCareManagerId }} onSubmit={handleSubmit}>
      <AutoSubmitForm.SelectBox label="Select Care Manager:" name="careManagerId" options={careManagers} />
    </AutoSubmitForm>
  )
}

DashboardSelector.propTypes = {
  currentCareManagerId: PropTypes.number,
  careManagers: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default DashboardSelector
