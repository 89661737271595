import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import { redirectTo } from "~/browserHelpers"
import { dashboards_care_management_agency_admin_path } from "~/routes"
import types from "~/types"
import AutoSubmitForm from "~/views/shared/autoSubmitForm/AutoSubmitForm"

function DashboardSelector({
  currentCareManagementAgencyId,
  currentHealthHomeId,
  careManagementAgencies,
  healthHomes,
}) {
  function handleSubmit({ careManagementAgencyId, healthHomeId }) {
    redirectTo(
      dashboards_care_management_agency_admin_path({
        filter: { care_management_agency_id: careManagementAgencyId, health_home_id: healthHomeId },
      })
    )
  }

  return (
    <AutoSubmitForm
      initialValues={{ careManagementAgencyId: currentCareManagementAgencyId, healthHomeId: currentHealthHomeId }}
      onSubmit={handleSubmit}
    >
      <Row>
        <Col md={6}>
          <AutoSubmitForm.SelectBox
            label="Select CMA:"
            name="careManagementAgencyId"
            options={careManagementAgencies}
          />
        </Col>
        <Col md={6}>
          <AutoSubmitForm.SelectBox
            label="Select Health Home:"
            name="healthHomeId"
            options={[{ value: null, label: "All" }, ...healthHomes]}
          />
        </Col>
      </Row>
    </AutoSubmitForm>
  )
}

DashboardSelector.propTypes = {
  currentCareManagementAgencyId: PropTypes.number,
  currentHealthHomeId: PropTypes.number,
  careManagementAgencies: PropTypes.arrayOf(types.selectOption).isRequired,
  healthHomes: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default DashboardSelector
