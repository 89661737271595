import createCachedSelector from "re-reselect"

const getGoalUiState = ({ ui: { goals } }, { goalId }) => goals[goalId]

export const getIsTasksListOpen = createCachedSelector(
  getGoalUiState,
  (goal) => goal && goal.isTasksListOpen
)((state, { goalId }) => goalId)

export const getIsNewTaskFormOpen = createCachedSelector(
  getGoalUiState,
  (goal) => goal && goal.isNewTaskFormOpen
)((state, { goalId }) => goalId)
