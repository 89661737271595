import Form from "~/design/forms"

function EncounterYesNoField({ label, name, errorKey }) {
  return (
    <Form.Group name={name} errorKey={errorKey}>
      <Form.Label>{label}</Form.Label>
      <Form.SelectBox
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
        isClearable
      />
    </Form.Group>
  )
}

export default EncounterYesNoField
