const uploadModal = {
  initialize() {
    $(document).on("click", "[data-trigger-upload-modal]", this.triggerUploadModal.bind(this))
  },

  pollForCompletionCookie() {
    const cleanup = () => {
      document.cookie = "download_finished=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
      window.clearInterval(window.HH.pollTimer)
    }

    window.HH.pollTimer = window.setInterval(() => {
      if (document.cookie.search(/download_finished=true/) > -1) {
        this.hideModal()
        cleanup()
      }
    }, 1000)
  },

  triggerUploadModal() {
    this.showModal()
    this.pollForCompletionCookie()
  },

  showModal: () => {
    $("#upload-modal").modal({ backdrop: "static", keyboard: false })
  },

  hideModal: () => {
    $("#upload-modal").modal("hide")
  },
}

export default uploadModal
