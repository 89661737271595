import { Button } from "react-bootstrap"
import PropTypes from "prop-types"

/**
 * A `<DangerButton />` is most often used to indicate destructive actions such as
 * deleting an object.
 */
function DangerButton({ children, disabled, onClick, size }) {
  return (
    <Button disabled={disabled} onClick={onClick} size={size} type="button" variant="danger">
      {children}
    </Button>
  )
}

DangerButton.propTypes = {
  /**
   * The content of the button.
   */
  children: PropTypes.node.isRequired,
  /**
   * A disabled button receives no pointer events.
   */
  disabled: PropTypes.bool,
  /**
   * The action to take when the button is clicked.
   */
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["sm", "lg"]),
}

export default DangerButton
