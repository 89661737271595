import { Form as BootstrapForm } from "react-bootstrap"
import classNames from "classnames"
import PropTypes from "prop-types"

import { DEFAULT_LABEL_WIDTH } from "./constants"
import useLayout from "./useLayoutContext"
import useNameContext from "./useNameContext"

/**
 * A `<Label />` is attached to a single form control and should
 * describe the purpose of the field e.g. "First Name", "Age", etc.
 */
function Label({ children, hidden, width }) {
  const { id } = useNameContext()
  const { vertical } = useLayout()
  const layoutProps = vertical ? null : { column: true, sm: width }

  const hiddenClass = ["sr-only", hidden].filter((e) => typeof e === "string").join("-")

  return (
    <BootstrapForm.Label
      {...layoutProps}
      className={classNames({ [hiddenClass]: hidden, "text-sm-right col-form-label pr-0": !vertical })}
      htmlFor={id}
      id={`${id}Label`}
      style={{ overflowWrap: "anywhere" }}
    >
      {children}
    </BootstrapForm.Label>
  )
}

Label.propTypes = {
  /**
   * The text to display in the label.
   */
  children: PropTypes.node.isRequired,
  /**
   * Whether to display the label. Use `true` to hide the label always,
   * or use a breakpoint to specify that labels should be hidden above a
   * particular screen size.
   *
   * All form controls need an associated label for accessibility, so this
   * option allows you to include a label that is not visually displayed but
   * is still findable by screen readers.
   */
  hidden: PropTypes.oneOf([false, true, "sm", "md", "lg", "xl"]),
  /**
   * The number of columns the control should span.
   */
  width: PropTypes.number,
}

Label.defaultProps = {
  hidden: false,
  width: DEFAULT_LABEL_WIDTH,
}

export default Label
