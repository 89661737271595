import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_childrens_hcbs_workflow_linked_documents_path } from "~/routes"

const childrensHCBSWorkflowLinkedDocuments = {
  post: function (childrensHCBSWorkflowId, workflowDocument) {
    return submitAsyncRequest({
      url: api_v1_childrens_hcbs_workflow_linked_documents_path(childrensHCBSWorkflowId),
      type: "POST",
      data: { workflowDocument },
    })
  },
}

export default childrensHCBSWorkflowLinkedDocuments
