import { Form as BootstrapForm } from "react-bootstrap"
import { Formik } from "formik"
import PropTypes from "prop-types"

import SelectBox from "./SelectBox"

function AutoSubmitForm({ children, initialValues, onSubmit }) {
  return (
    <Formik validateOnBlur={false} validateOnChange={false} onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, handleReset }) => (
        <BootstrapForm className="bg-white" onReset={handleReset} onSubmit={handleSubmit}>
          {children}
        </BootstrapForm>
      )}
    </Formik>
  )
}

AutoSubmitForm.propTypes = {
  children: PropTypes.node.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

AutoSubmitForm.SelectBox = SelectBox

export default AutoSubmitForm
