import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import ParticipatingPartnersFields from "./components/ParticipatingPartnersFields"
import Section from "./components/Section"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { chart_documents_path, edit_user_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function New({ currentUserId, doh5055Form }) {
  const { chartId, healthHomeName, healthHomePhoneNumber, rhioName, rhioPhoneNumber, signedOn } = doh5055Form

  async function handleSubmit(values, { setErrors, setStatus }) {
    try {
      await api.doh5055.post(chartId, values)
      redirectTo(chart_documents_path(chartId))
      setStatus("success")
    } catch ({ errors }) {
      if (errors.base) {
        errors.base = errors.base.map((error) => {
          if (error === "Consented by care manager can't be blank") {
            return (
              <li>
                Your profile must be linked to a care manager in order to complete this form. Use the{" "}
                <strong>Care Manager Link</strong> field in your{" "}
                <a href={edit_user_path(currentUserId)} target="_blank" rel="noopener noreferrer">
                  profile page <i className="fa fa-external-link" />
                </a>{" "}
                to link a care manager to your profile and try again.
              </li>
            )
          }
          return error
        })
      }
      setErrors(errors)
      window.scrollTo(0, 0)
    }
  }

  return (
    <Form
      initialValues={{
        ...doh5055Form,
        // Signature date fields are read-only, but they need to be referenced via separate form keys to generate unique DOM IDs
        participatingPartnersPatientSignatureDate: signedOn,
        participatingPartnersCareManagerSignatureDate: signedOn,
      }}
      onSubmit={handleSubmit}
      vertical
    >
      <Section>
        <Row>
          <Col md={6}>
            <Form.Group name="healthHomeName">
              <Form.Label>Health Home Name</Form.Label>
              <Form.TextField disabled />
            </Form.Group>
          </Col>
        </Row>
        <p>
          By signing this form, you agree to be in the <strong className="bg-light">{healthHomeName}</strong> Health
          Home. To be in a Health Home, health care providers and other people involved in your care need to be able to
          talk to each other about your care and share your health information with each other to give you better care.
          While being in a Health Home will help make sure you get the care you need, you will still be able to get
          health care and health insurance even if you do not sign this form or do not want to be in the Health Home.
        </p>
        <div>
          The Health Home may get your health information, including your health records, from partners listed at the
          end of this form and/or from others through a computer system run by the{" "}
          <strong className="bg-light">{rhioName}</strong> RHIO, a Regional Health Information Organization (RHIO)
          and/or a computer system called PSYCKES run by the New York State Office of Mental Health, and/or a computer
          system called TABS/CHOICES. A RHIO uses a computer system to collect and store your health information,
          including medical records, from your doctors and health care providers who are part of the RHIO. The RHIO can
          only share your health information with the people who you say can see or get your health information. PSYCKES
          is a computer system to collect and store your health treatment from your doctors and health care providers
          who are part of the Medicaid program. TABS/CHOICES is a computer system run by the New York State Office for
          People With Developmental Disabilities, that collects and stores information about your developmental
          disabilities.
        </div>
        <br />
        <p>
          If you agree and sign this form, the Health Home and the partners listed on this form are allowed to get, see,
          read and copy, and share with each other, ALL of your health information (including all of your health
          information the Health Home obtains from the RHIO and/or from PSYCKES and/or from TABS/CHOICES) that they need
          to give you care, manage your care or study your care to make health care better for patients. The health
          information they may get, see, read, copy and share may be from before and after the date you sign this form.
          Your health records may have information about illnesses or injuries you had or may have had before; test
          results, like X-rays or blood tests; and the medicines you are now taking or have taken before. Your health
          records may also have information on:
        </p>
        <ol>
          <li>Alcohol or drug use programs which you are in now or were in before as a patient;</li>
          <li>Family planning services like birth control and abortion;</li>
          <li>Inherited diseases;</li>
          <li>HIV/AIDS;</li>
          <li>Mental health conditions;</li>
          <li>Developmental disability diagnosis and services; and/or</li>
          <li>Sexually-transmitted diseases (diseases you can get from having sex).</li>
        </ol>
        <p>
          Your health information is private and cannot be given to other people without your permission under New York
          State and U.S. laws and rules. The partners that can get and see your health information must obey all these
          laws. They cannot give your information to other people unless you agree or the law says they can give the
          information to other people. This is true if your health information is on a computer system or on paper. Some
          laws cover care for HIV/AIDS, mental health records, and drug and alcohol use. The partners that use your
          health information and the Health Home must obey these laws and rules.
        </p>
      </Section>
      <Section title="Details About Patient Information and the Consent Process">
        <ol>
          <li>
            <p className="font-weight-bold">How will partners use my information?</p>
            <p>If you agree, partners will use your health information to:</p>
            <ul className="mb-3">
              <li>Give you health care and manage your care;</li>
              <li>Check if you have health insurance and what it pays for; and</li>
              <li>Study and make health care for patients better.</li>
            </ul>
            <p>
              The choice you make does NOT let health insurers see your information to decide whether to give you health
              insurance or pay your bills. You can make that choice in a separate form that health insurers must use.
            </p>
          </li>
          <li>
            <p className="font-weight-bold">Where does my health information come from?</p>
            <p>
              Your health information comes from places and people that gave you health care or health insurance in the
              past. These may include hospitals, doctors, drugstores, laboratories, developmental disability providers,
              health plans (insurance companies), the Medicaid program, and other groups that share health information.
              You can get a list of all the places and people by calling{" "}
              <strong className="bg-light">{rhioName}</strong> at{" "}
              <strong className="bg-light">{rhioPhoneNumber}</strong> or talking to your care manager.
            </p>
          </li>
          <li>
            <p className="font-weight-bold">What laws and rules cover how my health information can be shared?</p>
            <p>
              These laws and regulations include New York Mental Hygiene Law Section 33.13, New York Public Health Law
              Article 27-F, and federal confidentiality rules, including 42 CFR Part 2 and 45 CFR Parts 160 and 164
              (which are the rules referred to as “HIPAA”).
            </p>
          </li>
          <li>
            <p className="font-weight-bold">If I agree, who can get and see my information?</p>
            <p>
              The only people who can see your health information are those who you agree can get and see it, like
              doctors and other people who work for a Health Home partner and who are involved in your health care;
              health care providers who are working for a Health Home partner who is giving you care; and people who
              work for a Health Home partner who is giving you care to help them check your health insurance or to study
              and make health care better for all patients. When you get care from a person who is not your usual doctor
              or provider, like a new drugstore, new hospital, or other provider, some information, like what your
              health plan pays for or the name of your Health Home provider, may be given to them or seen by them.
            </p>
          </li>
          <li>
            <p className="font-weight-bold">
              What if a person uses my information and I didn't agree to let them use it?
            </p>
            <p>If this happens, you can:</p>
            <ul className="mb-3">
              <li> call the Medicaid Helpline at 1-800-541-2831, or</li>
              <li>
                contact the US Department of Health and Human Services, Office for Civil Rights at 1-800-368-1019, or
                submit a written complaint at:{" "}
                <a href="https://www.hhs.gov/hipaa/filing-a-complaint/index.html">
                  https://www.hhs.gov/hipaa/filing-a-complaint/index.html
                </a>
              </li>
            </ul>
            <p>You may also want to:</p>
            <ul className="mb-3">
              <li>call one of the providers you have said can see your records,</li>
              <li>
                call your care manager or health home: <strong className="bg-light">{healthHomeName}</strong> at{" "}
                <strong className="bg-light">{healthHomePhoneNumber}</strong>, or{" "}
              </li>
              <li>call your Managed Care Plan if you belong to a Managed Care Plan</li>
            </ul>
          </li>
          <li>
            <p className="font-weight-bold">How long does my consent last?</p>
            <p>
              Your consent will last until the day you take back your consent, or if you leave the Health Home program,
              or if the Health Home stops working.
            </p>
          </li>
          <li>
            <p className="font-weight-bold">What if I change my mind later and want to take back my consent?</p>
            <p>
              You can take back your consent at any time by signing a Withdrawal of Consent Form (DOH-5058) and giving
              it to one of the Health Home partners. If you agree to share your information, all Health Home partners
              listed at the end of this form will be able to get your health information. If you do not wish the Health
              Home partners listed on this form to get your health information, you need to take away your consent from
              the Health Home program. You can get this form by calling{" "}
              <strong className="bg-light">{healthHomePhoneNumber}</strong>. Your care manager will help you fill out
              this form if you want. Note: Even if you later decide to take back your consent, providers who already
              have your information do not have to give your information back to you or take it out of their records.
            </p>
          </li>
          <li>
            <p className="font-weight-bold">How do I get a copy of this form?</p>
            <p>You can have a copy of this form after you sign it.</p>
          </li>
        </ol>
      </Section>
      <Section>
        <p className="font-weight-bold">Please read all the information on this form before you sign it.</p>
        <Form.Group name="consented">
          <Form.CheckBox
            label={
              <div>
                <b>
                  I AGREE to be in the <strong className="bg-light">{healthHomeName}</strong> Health Home and agree that
                  the Health Home can get ALL of my health information from the partners listed at the end of this form
                  and from others through the{" "}
                </b>
                <strong className="bg-light">{rhioName}</strong> RHIO and/or through PSYCKES and/or through TABS/CHOICES
                to give me care or manage my care, to check if I am in a health plan and what it covers, and to study
                and make the care of all patients better. I also AGREE that the Health Home and the partners listed at
                the end of this form may share my health information with each other. I understand this Consent Form
                takes the place of other Health Home Patient Information Sharing Consent Forms I may have signed before
                to share my health information. I can change my mind and take back my consent at any time by signing a
                Withdrawal of Consent Form (DOH-5058) and giving it to one of the Health Home partners.
              </div>
            }
          />
        </Form.Group>
        <Row>
          <Col md={6}>
            <Form.Group name="patientName">
              <Form.Label>Print Name of Patient</Form.Label>
              <Form.TextField disabled />
            </Form.Group>
            <Form.Group name="patientDateOfBirth">
              <Form.Label>Patient Date of Birth</Form.Label>
              <Form.TextField disabled />
            </Form.Group>
            <Form.Group name="signature">
              <Form.Label>Signature of Patient or Patient's Legal Representative</Form.Label>
              <Form.SignatureField />
            </Form.Group>
            <Form.Group name="signedOn">
              <Form.Label>Date</Form.Label>
              <Form.TextField disabled />
            </Form.Group>
            <Form.Group name="legalRepresentativeName">
              <Form.Label>Print Name of Legal Representative (If Applicable)</Form.Label>
              <Form.TextField />
            </Form.Group>
            <Form.Group name="legalRepresentativeRelationshipToPatient">
              <Form.Label>Relationship of Legal Representative to Patient (If Applicable)</Form.Label>
              <Form.TextField />
            </Form.Group>
          </Col>
        </Row>
      </Section>
      <Section title={`${healthHomeName} Participating Partners`}>
        <ParticipatingPartnersFields />
      </Section>
      <Section border={false}>
        <Row>
          <Col md={6}>
            <Form.Group name="participatingPartnersPatientSignature">
              <Form.Label>Patient (or Legal Representative) Signature for Participating Partners</Form.Label>
              <Form.SignatureField />
            </Form.Group>
            <Form.Group name="participatingPartnersPatientSignatureDate">
              <Form.Label>Signed On</Form.Label>
              <Form.TextField disabled />
            </Form.Group>
            <Form.Group name="participatingPartnersCareManagerSignature">
              <Form.Label>Care Manager Signature for Participating Partners</Form.Label>
              <Form.SignatureField />
            </Form.Group>
            <Form.Group name="participatingPartnersCareManagerSignatureDate">
              <Form.Label>Signed On</Form.Label>
              <Form.TextField disabled />
            </Form.Group>
          </Col>
        </Row>
      </Section>
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_documents_path(chartId))} />
            <Form.SubmitButton label="Submit" />
          </>
        }
      />
    </Form>
  )
}

New.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  doh5055Form: types.doh5055Form.isRequired,
}

export default New
