import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import BarriersCard from "./components/BarriersCard"
import DiagnosesSection from "./components/DiagnosesSection"
import MedicationsSection from "./components/MedicationsSection"
import RiskFactorsCard from "./components/RiskFactorsCard"
import StrengthsCard from "./components/StrengthsCard"

import { ToastContainer } from "~/toast"
import types from "~/types"

function Show({
  barriers,
  chart,
  diagnoses,
  diagnosisValues,
  medications,
  riskFactors,
  riskFactorCategories,
  strengths,
}) {
  return (
    <>
      <DiagnosesSection chart={chart} diagnoses={diagnoses} diagnosisValues={diagnosisValues} />
      <MedicationsSection chart={chart} medications={medications} />
      <Row>
        <Col xl={4} className="mb-4">
          <StrengthsCard chart={chart} strengths={strengths} />
          <p className="mt-1">*Required by MAPP as part of the sync process</p>
        </Col>
        <Col xl={4} className="mb-4">
          <BarriersCard chart={chart} barriers={barriers} />
          <p className="mt-1">*Required by MAPP as part of the sync process</p>
        </Col>
        <Col xl={4} className="mb-4">
          <RiskFactorsCard chart={chart} riskFactors={riskFactors} categories={riskFactorCategories} />
          <p className="mt-1">*Reported to MAPP as part of the sync process</p>
        </Col>
      </Row>
      <ToastContainer />
    </>
  )
}

Show.propTypes = {
  barriers: PropTypes.objectOf(types.barrier).isRequired,
  chart: types.chart.isRequired,
  diagnoses: PropTypes.objectOf(types.diagnosis).isRequired,
  diagnosisValues: PropTypes.arrayOf(types.selectOption).isRequired,
  medications: PropTypes.objectOf(types.medication).isRequired,
  riskFactors: PropTypes.objectOf(types.riskFactor).isRequired,
  riskFactorCategories: PropTypes.arrayOf(types.selectOption).isRequired,
  strengths: PropTypes.objectOf(types.strength).isRequired,
}

export default Show
