import PropTypes from "prop-types"

import RiskFactorsFormFields from "./RiskFactorsFormFields"

import { WarningAlert } from "~/design/alerts"
import Form from "~/design/forms"
import Modal from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function RiskFactorsForm({ riskFactors, categories, chart, show, onClose, onUpdate }) {
  async function onSubmit(values, { setErrors, setStatus }) {
    try {
      const updatedRiskFactors = await api.bulkRiskFactorsUpdates.post(chart.id, values.riskFactors)
      onUpdate(updatedRiskFactors)
      setStatus("success")
      toast.success("Risk factors successfully updated.")
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Modal show={show} onClose={onClose} title="Edit Risk Factors" size="lg">
      <Form.Provider initialValues={{ riskFactors }} onSubmit={onSubmit} formId="risk-factors-modal-form">
        <Modal.Body>
          <WarningAlert>
            When saved, this will be associated with {chart.healthHomeName} Health Home and{" "}
            {chart.careManagementAgencyName} CMA.
          </WarningAlert>
          <Form.Body vertical>
            <RiskFactorsFormFields categories={categories} />
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Save" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

RiskFactorsForm.propTypes = {
  riskFactors: PropTypes.objectOf(types.riskFactor).isRequired,
  categories: PropTypes.arrayOf(types.selectOption).isRequired,
  chart: types.chart,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default RiskFactorsForm
