import { connect } from "react-redux"

import CarePlanFormBody from "./CarePlanFormBody"
import { closeNewTaskForm } from "./carePlanGoalUIActions"
import { carePlanTaskCreated, carePlanTaskFlashKey } from "./carePlanTaskActions"
import TaskFormFields from "./TaskFormFields"

import Form from "~/design/forms"
import { flashMessage } from "~/flashMessages/flashMessageActions"
import api from "~/services/api"
import { toast } from "~/toast"

function NewTaskForm({
  goalId,
  closeNewTaskFormConnect,
  carePlanTaskCreatedConnect,
  flashMessageConnect,
  carePlanTaskStatuses,
  gapsForNewTaskLinkages,
  taskResponsibilities,
}) {
  const defaultTask = {
    description: "",
    carePlanTaskStatusId: 2, // CarePlanTaskStatus::ACTIVE.id
    notes: "",
    taskResponsibilityId: "",
    targetCompletionDate: "",
    completionDate: "",
    taskResponsibilityOther: "",
    mcoGapInCareIds: [],
  }

  async function handleSubmit(values, { setErrors, setStatus }) {
    try {
      const createdTask = await api.carePlans.tasks.post(goalId, values)
      setStatus("success")
      carePlanTaskCreatedConnect(createdTask, goalId)
      flashMessageConnect(carePlanTaskFlashKey(createdTask))
      toast.success("Task successfully created.")
      closeNewTaskFormConnect(goalId)
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  const gapOptions = Object.values(gapsForNewTaskLinkages).map((gap) => ({
    label: gap.description,
    value: gap.id,
  }))

  return (
    <Form initialValues={defaultTask} onSubmit={handleSubmit} vertical>
      <CarePlanFormBody title="Add Task" dataTestId="task-form">
        <TaskFormFields
          carePlanTaskStatuses={carePlanTaskStatuses}
          gapOptions={gapOptions}
          taskResponsibilities={taskResponsibilities}
        />
        <Form.ActionBar
          right={
            <>
              <Form.CancelButton onClick={() => closeNewTaskFormConnect(goalId)} />
              <Form.SubmitButton label="Create" />
            </>
          }
        />
      </CarePlanFormBody>
    </Form>
  )
}

export default connect(
  ({ globals, entities }) => ({
    carePlanTaskStatuses: globals.carePlanTaskStatuses,
    taskResponsibilities: globals.taskResponsibilities,
    gapsForNewTaskLinkages: entities.gapsForNewTaskLinkages,
  }),
  {
    closeNewTaskFormConnect: closeNewTaskForm,
    carePlanTaskCreatedConnect: carePlanTaskCreated,
    flashMessageConnect: flashMessage,
  }
)(NewTaskForm)
