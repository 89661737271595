import types from "~/types"

function CareTeamProviderDetails({ provider }) {
  return (
    <>
      <div className="font-weight-bold my-2">{provider.name}</div>
      <div>Specialty: {provider.specialty}</div>
      <div>{provider.facility}</div>
      <div>{provider.phone}</div>
      <div>{provider.address}</div>
    </>
  )
}

CareTeamProviderDetails.propTypes = {
  provider: types.careTeamProvider.isRequired,
}

export default CareTeamProviderDetails
