import { carePlanEntitiesDeleted } from "~/components/carePlans/carePlanActions"
import api from "~/services/api"

export function carePlanTaskFlashKey(task) {
  return `CARE_PLAN_TASK_${task.id}_FLASH_KEY`
}

export const CARE_PLAN_TASK_CREATED = "CARE_PLAN_TASK_CREATED"
export function carePlanTaskCreated(task, goalId) {
  return {
    type: CARE_PLAN_TASK_CREATED,
    goalId,
    task,
  }
}

export const CARE_PLAN_TASK_UPDATED = "CARE_PLAN_TASK_UPDATED"
export function carePlanTaskUpdated(task) {
  return {
    type: CARE_PLAN_TASK_UPDATED,
    task,
  }
}

export function deleteCarePlanTask(taskId) {
  return async (dispatch) => {
    await api.carePlans.tasks.delete(taskId)

    dispatch(carePlanEntitiesDeleted([], [], [taskId]))
  }
}
