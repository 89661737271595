import spinner from "images/spinner.svg"

function ChartLoadingSpinner() {
  return (
    <div className="d-flex justify-content-center align-items-center gap-3 border p-5">
      <img src={spinner} width={50} height={50} alt="Loading..." />
      <div style={{ fontSize: "1.5rem" }}>Loading...</div>
    </div>
  )
}

export default ChartLoadingSpinner
