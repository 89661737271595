import _ from "lodash"
import { createSelector } from "reselect"

import { ActiveOnlyFilterState } from "~/components/carePlans/carePlanActiveFilterActions"
import { getNeeds } from "~/components/carePlans/carePlanNeedsSelectors"
import { getActiveFilterState, getFilteredNeedCategoryId } from "~/components/carePlans/carePlanUISelectors"
import { getNeedCategories } from "~/components/carePlans/globalsSelectors"

export const getCarePlan = ({ entities: { carePlan } }) => carePlan

const filterByActiveStatus = (activeFilterState) => (need) =>
  activeFilterState === ActiveOnlyFilterState.ALL || need.carePlanNeedStatusId === 1 // CarePlanNeedStatus::ACTIVE.id

const filterbyCategoryId = (needCategoryId) => (need) =>
  needCategoryId === null || needCategoryId === need.carePlanNeedCategoryId

export const getFilteredCarePlanNeedIds = createSelector(
  [getCarePlan, getNeeds, getFilteredNeedCategoryId, getActiveFilterState],
  ({ needIds }, needs, filteredNeedCategoryId, activeFilterState) => {
    const categoryIdPredicate = filterbyCategoryId(filteredNeedCategoryId)
    const activeStatusPredicate = filterByActiveStatus(activeFilterState)
    return needIds.filter((needId) => {
      const need = needs[needId]
      return categoryIdPredicate(need) && activeStatusPredicate(need)
    })
  }
)

export const getAvailableNeedCategories = createSelector([getNeedCategories, getNeeds], (needCategories, needs) => {
  const availableNeedCategories = _.values(needs).map((need) => need.carePlanNeedCategoryId)
  return needCategories.filter((needCategory) => availableNeedCategories.includes(needCategory.value))
})
