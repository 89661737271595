import Icon from "./Icon"

/**
 * Renders an icon of an up arrow, indicating a collapsed section.
 */
function CollapseIcon() {
  return <Icon name="chevron-up" />
}

export default CollapseIcon
