import PropTypes from "prop-types"

/**
 * An `<ActionBar />` is used to house the form action buttons (e.g. Delete, Cancel, Submit).
 *
 * The action bar has two sections: a left and a right. To remain consistent, most forms
 * should include a delete button in the left section (if applicable), with the other actions
 * living in the right section.
 */
function ActionBar({ left, right }) {
  return (
    <div className="d-flex align-items-center justify-content-end gap-2 border-top p-3">
      {left}
      <div className="flex-fill" />
      {right}
    </div>
  )
}

ActionBar.propTypes = {
  /**
   * The content of the left section of the action bar.
   */
  left: PropTypes.node,
  /**
   * The content of the right section of the action bar.
   */
  right: PropTypes.node,
}

export default ActionBar
