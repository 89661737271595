import { useFormikContext } from "formik"

import LinkedGapInCareBanner from "./LinkedGapInCareBanner"

function LinkedGapInCareFromFormBanner() {
  const {
    values: { mcoGapInCareIds },
  } = useFormikContext()

  return <LinkedGapInCareBanner mcoGapInCareIds={mcoGapInCareIds} />
}

export default LinkedGapInCareFromFormBanner
