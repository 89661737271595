import { createContext, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import QueryString from "qs"

const QueryParamsContext = createContext()

function stringify(params) {
  return QueryString.stringify(params, { arrayFormat: "brackets" })
}

function decoder(str, defaultDecoder, charset, type) {
  if (type === "value" && /^\d+$/.test(str)) {
    return parseInt(str, 10)
  }

  return defaultDecoder(str, defaultDecoder, charset)
}

function parse(queryString) {
  return QueryString.parse(queryString, { decoder })
}

function useQueryParams() {
  const queryParamsContext = useContext(QueryParamsContext)
  if (!queryParamsContext) {
    throw Error("useQueryParams can only be called within a <QueryParamsProvider />.")
  }
  return queryParamsContext
}

function QueryParamsProvider({ children }) {
  const [queryParams, setQueryParams] = useState(() => parse(window.location.search.slice(1)))

  useEffect(() => {
    function changeListener() {
      setQueryParams(parse(window.location.search.slice(1)))
    }

    window.addEventListener("popstate", changeListener)

    return () => window.removeEventListener("popstate", changeListener)
  }, [])

  function updateQueryParams(queryParams) {
    const queryString = stringify(queryParams)
    const newUrl = window.location.origin + window.location.pathname + "?" + queryString
    window.history.pushState(queryParams, null, newUrl)
    setQueryParams(parse(queryString))
  }

  return <QueryParamsContext.Provider value={[queryParams, updateQueryParams]}>{children}</QueryParamsContext.Provider>
}

QueryParamsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { QueryParamsProvider }
export default useQueryParams
