import { useState } from "react"
import PropTypes from "prop-types"

import SideBarList from "../SideBarList"
import SideBarToggle from "../SideBarToggle"

import DiagnosesForm from "./DiagnosesForm"

import { EditIcon, MedkitIcon } from "~/design/icons"
import { useModal } from "~/design/Modal"
import types from "~/types"

function DiagnosisSection({ chart, diagnoses: initialDiagnoses, diagnosisValues }) {
  const [show, { close, open }] = useModal()
  const [diagnoses, setDiagnoses] = useState(initialDiagnoses)

  const displayDiagnoses = Object.keys(diagnoses)
    .map((diagnosisId) => diagnoses[diagnosisId])
    .sort((diagnosisA, diagnosisB) => diagnosisB.isPrimary - diagnosisA.isPrimary)

  return (
    <SideBarToggle
      title="Diagnoses"
      HeaderIcon={MedkitIcon}
      ActionIcon={EditIcon}
      actionLabel="Edit diagnoses"
      onActionIconClick={open}
    >
      <SideBarList bulleted emptyListMessage="This patient does not have any diagnoses.">
        {displayDiagnoses.map((diagnosis) => (
          <li className="p-1" key={diagnosis.id}>
            <span>
              {diagnosis.description}
              <i className="text-muted">{diagnosis.isPrimary && "  Primary"}</i>
            </span>
          </li>
        ))}
      </SideBarList>
      <DiagnosesForm
        show={show}
        onClose={close}
        diagnoses={diagnoses}
        chart={chart}
        diagnosisValues={diagnosisValues}
        onUpdate={setDiagnoses}
      />
      <p className="font-weight-light mt-1">*Required by MAPP as part of the sync process</p>
    </SideBarToggle>
  )
}

DiagnosisSection.propTypes = {
  chart: types.chart.isRequired,
  diagnoses: PropTypes.objectOf(types.diagnosis).isRequired,
  diagnosisValues: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default DiagnosisSection
