import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button, Col, Collapse, FormCheck, Row } from "react-bootstrap"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table"
import classNames from "classnames"
import pluralize from "pluralize"
import PropTypes from "prop-types"

import DividerList from "~/components/DividerList"
import EncountersLink from "~/components/EncountersLink"
import Pagination from "~/components/pagination"
import TablePageInfo from "~/components/TablePageInfo"
import formattedDate from "~/dateHelpers"
import { LinkButton, SecondaryButton } from "~/design/buttons"
import { CollapseIcon, ExpandIcon } from "~/design/icons"
import { clinical_event_path } from "~/routes"
import api from "~/services/api"
import { ToastContainer } from "~/toast"
import ClinicalAlertDetailsList from "~/transitionsOfCare/ClinicalAlertDetailsList"
import ClinicalEventMergeModal from "~/transitionsOfCare/ClinicalEventMergeModal"
import ClinicalEventStatusDropdown from "~/transitionsOfCare/ClinicalEventStatusDropdown"
import Section from "~/views/shared/patientDetails/Section"

const columnHelper = createColumnHelper()

// This hook handles pagination and selection logic to set the page index and selection state of the table
// after merging a clinical event and receiving a new list of merged clinical events from the server.
// It uses a side-effect to run rather than being handled in the same event that produces the merged event
// because it requires the table state to reflect the new set of clinical events after merging, which happens
// only after a re-render following the call to `setClinicalEvents`.
const useHighlightMergedClinicalEvent = (table) => {
  const mergedClinicalEventRef = useRef(null)
  const mergedClinicalEvent = mergedClinicalEventRef.current

  const setMergedClinicalEvent = useCallback((mergedClinicalEvent) => {
    mergedClinicalEventRef.current = mergedClinicalEvent
  }, [])

  useEffect(() => {
    // after merging, `mergedClinicalEvent` will be a clinical event object
    if (mergedClinicalEvent) {
      const rowsBeforePagination = table.getPrePaginationRowModel().rows
      const rowIndex = rowsBeforePagination.findIndex((row) => row.original.id === mergedClinicalEvent.id)

      // go to the page of the merged clinical event
      const { pageSize } = table.getState().pagination
      const pageIndex = Math.floor(rowIndex / pageSize)
      table.setPageIndex(pageIndex)

      // select the merged clinical event
      table.setRowSelection({ [rowIndex]: true })

      // collapse all rows except the merged clinical event
      table.setExpanded({ [rowIndex]: true })

      // reset the hook conditional triggers
      mergedClinicalEventRef.current = null
    }
  })

  return setMergedClinicalEvent
}

const ClinicalEventsIndexEntryPoint = ({ clinicalEvents: initialClinicalEvents, clinicalEventStatuses, chartId }) => {
  const [showModal, setModalVisibility] = useState(false)
  const openModal = () => setModalVisibility(true)
  const closeModal = () => setModalVisibility(false)

  const [clinicalEvents, setClinicalEvents] = useState(initialClinicalEvents)

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => (
          <Row className="align-items-center">
            <Col md="6">
              <Button
                type="button"
                variant="link"
                className="icon-button"
                onClick={() => row.toggleExpanded()}
                aria-controls={`target-id-${row.original.id}`}
                aria-expanded={row.getIsExpanded()}
              >
                {row.getIsExpanded() ? (
                  <>
                    <ExpandIcon />
                    <span className="sr-only">Collapse row {row.original.id}</span>
                  </>
                ) : (
                  <>
                    <CollapseIcon />
                    <span className="sr-only">Expand row {row.original.id}</span>
                  </>
                )}
              </Button>
            </Col>
            <Col md="6">
              <FormCheck
                custom
                id={`clinical-event-select-${row.original.id}`}
                checked={row.getIsSelected()}
                onChange={row.getToggleSelectedHandler()}
                label={<span className="sr-only">Select row {row.original.id}</span>}
              />
            </Col>
          </Row>
        ),
        colWidth: 2,
      }),
      columnHelper.accessor("initialAlertReceivedAt", {
        header: "Created At",
        cell: ({ getValue }) => <div className="card-table-row-text">{formattedDate(getValue())}</div>,
        colWidth: 3,
      }),
      columnHelper.accessor("latestAlertReceivedAt", {
        header: "Last Notified Date",
        cell: ({ getValue }) => <div className="card-table-row-text">{formattedDate(getValue())}</div>,
        colWidth: 4,
      }),
      columnHelper.accessor("updatedAt", {
        header: "Last Updated",
        cell: ({ getValue }) => <div className="card-table-row-text">{formattedDate(getValue())}</div>,
        colWidth: 3,
      }),
      columnHelper.accessor("clinicalEventStatusId", {
        header: "Status",
        cell: ({ row, table }) => {
          const handleUpdate = (clinicalEvent) => {
            table.options.meta.updateClinicalEventStatus(clinicalEvent)
          }

          return (
            <ClinicalEventStatusDropdown
              clinicalEvent={row.original}
              clinicalEventStatuses={clinicalEventStatuses}
              onClinicalEventUpdated={handleUpdate}
            />
          )
        },
        colWidth: 4,
      }),
      columnHelper.accessor("clinicalAlertIds", {
        header: "# of Alerts",
        cell: ({ getValue }) => (
          <div className="card-table-row-text">{pluralize("alert", getValue().length, true)}</div>
        ),
        colWidth: 3,
      }),
      columnHelper.accessor("encounterIds", {
        header: "",
        cell: ({ row }) => (
          <EncountersLink
            filter={{ clinical_events: { id: [row.original.id] } }}
            numEncounters={row.original.encounterIds.length}
            chartId={row.original.chartId}
          />
        ),
        colWidth: 4,
      }),
      columnHelper.accessor("id", {
        header: "",
        cell: ({ getValue }) => (
          <a className="pull-right" href={clinical_event_path(getValue())}>
            Details
          </a>
        ),
        colWidth: 1,
      }),
    ],
    [clinicalEventStatuses]
  )

  const table = useReactTable({
    data: clinicalEvents,
    columns,
    initialState: {
      pagination: { pageSize: 10 },
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // By default, pagination is reset when `data` changes. On this screen, we need to manually set the page index
    // when clinical events are merged (which results in `data` changing), so we don't use the auto-reset.
    autoResetPageIndex: false,
    meta: {
      updateClinicalEventStatus: (updatedClinicalEvent) => {
        setClinicalEvents((currentClinicalEvents) =>
          currentClinicalEvents.map((currentClinicalEvent) => {
            if (currentClinicalEvent.id === updatedClinicalEvent.id) {
              return updatedClinicalEvent
            }
            return currentClinicalEvent
          })
        )
      },
    },
  })

  const highlightMergedClinicalEvent = useHighlightMergedClinicalEvent(table)

  const handleModalSubmitSuccess = async (mergedClinicalEvent) => {
    const clinicalEvents = await api.transitionsOfCare.clinicalEvents.get(chartId)
    highlightMergedClinicalEvent(mergedClinicalEvent)
    setClinicalEvents(clinicalEvents)
  }

  const {
    pagination: { pageIndex, pageSize },
  } = table.getState()

  return (
    <Section
      title="Clinical Events"
      actions={
        <SecondaryButton onClick={openModal} disabled={table.getSelectedRowModel().rows.length < 2}>
          Merge Clinical Events
        </SecondaryButton>
      }
    >
      <Row className="align-items-center my-4">
        <Col md="8">
          <div className="d-flex gap-2">
            <span>
              <DividerList>
                <LinkButton onClick={() => table.toggleAllRowsExpanded(true)} disabled={table.getIsAllRowsExpanded()}>
                  Show all
                </LinkButton>
                <LinkButton onClick={() => table.toggleAllRowsExpanded(false)} disabled={!table.getIsAllRowsExpanded()}>
                  Collapse all
                </LinkButton>
              </DividerList>
            </span>
          </div>
        </Col>
        <Col md="4">
          <TablePageInfo pageIndex={pageIndex} pageSize={pageSize} total={table.getFilteredRowModel().rows.length} />
        </Col>
      </Row>
      <div className="card-table">
        <div className="card-table-heading">
          {table.getHeaderGroups().map((headerGroup) => {
            const leftHeaders = headerGroup.headers.slice(0, 4)
            const rightHeaders = headerGroup.headers.slice(4)
            return (
              <Row key={headerGroup.id}>
                {[leftHeaders, rightHeaders].map((headers, index) => (
                  <Col key={index} md={6}>
                    <Row>
                      {headers.map((header) => (
                        <Col key={header.id} md={header.column.columnDef.colWidth} className="heading">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Col>
                      ))}
                    </Row>
                  </Col>
                ))}
              </Row>
            )
          })}
        </div>
        {table.getRowModel().rows.map((row) => {
          const leftCells = row.getVisibleCells().slice(0, 4)
          const rightCells = row.getVisibleCells().slice(4)

          return (
            <div
              key={row.id}
              className={classNames("card-table-row", { selected: row.getIsSelected() })}
              data-testid={`clinical-event-${row.original.id}-row`}
            >
              <Row className="align-items-center">
                {[leftCells, rightCells].map((cells, index) => (
                  <Col key={index} md={6}>
                    <Row className="align-items-center">
                      {cells.map((cell) => (
                        <Col key={cell.id} md={cell.column.columnDef.colWidth}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Col>
                      ))}
                    </Row>
                  </Col>
                ))}
              </Row>
              <Collapse in={row.getIsExpanded()}>
                <div id={`target-id-${row.original.id}`}>
                  <div className="clinical-alert-list">
                    <hr />
                    <ClinicalAlertDetailsList clinicalAlerts={row.original.clinicalAlerts} />
                  </div>
                </div>
              </Collapse>
            </div>
          )
        })}
        <Pagination
          gotoPage={(index) => table.setPageIndex(index)}
          pageCount={table.getPageCount()}
          pageIndex={pageIndex}
        />
      </div>
      <ClinicalEventMergeModal
        selectedClinicalEvents={table.getSelectedRowModel().rows.map((row) => row.original)}
        clinicalEventStatuses={clinicalEventStatuses}
        show={showModal}
        onClose={closeModal}
        onSubmitSuccess={handleModalSubmitSuccess}
      />
      <ToastContainer />
    </Section>
  )
}

ClinicalEventsIndexEntryPoint.propTypes = {
  clinicalEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      clinicalEventStatusId: PropTypes.number.isRequired,
      chartId: PropTypes.number.isRequired,
      clinicalAlertIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      encounterIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      clinicalEventStatus: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        actionNeeded: PropTypes.bool,
      }).isRequired,
      initialAlertReceivedAt: PropTypes.string.isRequired,
      latestAlertReceivedAt: PropTypes.string.isRequired,
      clinicalAlerts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          occurredAt: PropTypes.string.isRequired,
          receivedAt: PropTypes.string.isRequired,
          clinicalAlertVisitTypeId: PropTypes.number.isRequired,
          facility: PropTypes.string.isRequired,
          clinicalAlertVisitTypes: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            color: PropTypes.string,
          }),
        })
      ),
    }).isRequired
  ),
  clinicalEventStatuses: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  chartId: PropTypes.number.isRequired,
}

export default ClinicalEventsIndexEntryPoint
