import { useCallback, useMemo } from "react"
import { Form as ReactBootstrapForm, Table as ReactBootstrapTable } from "react-bootstrap"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useField } from "formik"
import _ from "lodash"
import PropTypes from "prop-types"

import formattedDate from "~/dateHelpers"
import { PrimaryAlert } from "~/design/alerts"
import ExternalLink from "~/design/ExternalLink"
import Show from "~/design/Show"
import types from "~/types"

const columnHelper = createColumnHelper()

function RecordTable({ alert, caption, recordType, chartedDataCopyRecords = [] }) {
  const columns = useMemo(
    () => [
      columnHelper.accessor("copiedFromDescription", {
        header: "Description",
      }),
      columnHelper.accessor("copiedFromCreatedAt", {
        header: "Created At",
        cell: ({ getValue }) => formattedDate(getValue()),
        meta: { width: "100px" },
      }),
      columnHelper.accessor("copiedFromUpdatedAt", {
        header: "Updated At",
        cell: ({ getValue }) => formattedDate(getValue()),
        meta: { width: "100px" },
      }),
      columnHelper.display({
        header: "",
        id: "view",
        cell: ({ row }) => <ExternalLink href={row.original.copiedFromPath} text="View" />,
        meta: { width: "80px" },
      }),
    ],
    []
  )

  const [selectedRecordsField, selectedRecordsMeta, selectedRecordsHelpers] = useField({
    name: `selectedRecords.${recordType}`,
  })

  const rowSelection = useMemo(() => {
    return selectedRecordsField.value || {}
  }, [selectedRecordsField.value])

  const onRowSelectionChange = useCallback(
    (getSelectedRow) => {
      selectedRecordsHelpers.setValue(getSelectedRow(rowSelection))
    },
    [selectedRecordsHelpers, rowSelection]
  )

  const table = useReactTable({
    data: chartedDataCopyRecords,
    columns,
    onRowSelectionChange,
    state: {
      rowSelection,
    },
    initialState: {
      sorting: [{ id: "copiedFromCreatedAt", desc: true }],
    },
    getRowId: (row) => row.copiedFromId,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const hasAnyErrors = Boolean(selectedRecordsMeta.error)

  const { rows } = table.getRowModel()
  const { headers } = table.getHeaderGroups()[0]

  if (rows.length === 0) {
    return (
      <div>
        <h3 className="text-dark py-2" style={{ fontSize: "1rem" }}>
          {caption}
        </h3>
        <p className="font-italic text-muted">No records found for the selected chart.</p>
      </div>
    )
  }

  return (
    <>
      <ReactBootstrapTable size="sm" bordered striped>
        <caption className="caption-top text-dark py-2" style={{ fontSize: "1rem" }}>
          {caption}
        </caption>
        <thead>
          <tr>
            <th className="border-bottom-0" style={{ width: hasAnyErrors ? "200px" : "40px" }}>
              <ReactBootstrapForm.Check
                checked={table.getIsAllRowsSelected()}
                custom
                id={`${_.kebabCase(caption.toLowerCase())}-select-all`}
                label={
                  <>
                    <span className="sr-only">Select all {caption}</span>&nbsp;
                  </>
                }
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            </th>
            {headers.map((header) => (
              <th
                key={header.id}
                className="border-bottom-0 font-weight-normal text-muted"
                style={{ width: header.column.columnDef.meta?.width }}
              >
                {header.column.columnDef.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            const error = selectedRecordsMeta.error?.[row.id]
            const hasError = Boolean(error)
            const fieldId = `${_.kebabCase(caption)}-${row.id}`
            const errorMessageId = `${fieldId}-error`

            return (
              <tr key={row.id} className="font-weight-normal">
                <td>
                  <ReactBootstrapForm.Check custom id={fieldId}>
                    <ReactBootstrapForm.Check.Input
                      aria-errormessage={hasError ? errorMessageId : undefined}
                      checked={row.getIsSelected()}
                      name={fieldId}
                      isInvalid={hasError}
                      onChange={row.getToggleSelectedHandler()}
                      className="d-inline"
                    />
                    <ReactBootstrapForm.Check.Label className="d-inline">
                      <span className="sr-only">
                        Select {caption} {row.id}
                      </span>
                      &nbsp;
                    </ReactBootstrapForm.Check.Label>
                    <ReactBootstrapForm.Control.Feedback
                      className={hasError ? "d-inline" : undefined}
                      id={errorMessageId}
                      role="alert"
                      type="invalid"
                    >
                      {error}
                    </ReactBootstrapForm.Control.Feedback>
                  </ReactBootstrapForm.Check>
                </td>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </ReactBootstrapTable>
      <Show when={Boolean(alert)}>
        <PrimaryAlert>
          <strong>Note:</strong> {alert}
        </PrimaryAlert>
      </Show>
    </>
  )
}

RecordTable.propTypes = {
  alert: PropTypes.string,
  caption: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired,
  chartedDataCopyRecords: PropTypes.arrayOf(types.chartedDataCopyRecord),
}

export default RecordTable
