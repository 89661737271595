import { someDeep } from "deepdash-es/standalone"
import _ from "lodash"
import { serialize } from "object-to-formdata"

export const submitAsyncRequest = ({
  url,
  type,
  data,
  method,
  keyTransformOverrides,
  // Set jsonData = true when sending an array of objects in the request data.
  // Without this, AJAX converts the array of objects to an object with
  // the array elements as values and the array index as keys.
  jsonData = false,
  ...otherOptions
}) =>
  new Promise((resolve, reject) => {
    let requestData = toSnakeCase(data, keyTransformOverrides)

    let fileOptions = {}
    const hasFileData = someDeep(data, (datum) => datum instanceof File, { leavesOnly: true })
    if (hasFileData) {
      // File data must be serialized as FormData
      requestData = serialize(requestData)
      fileOptions = { processData: false, contentType: false }
    }

    // AJAX removes keys with empty array values from request data.
    // As a workaround, send [""] instead. Rails interprets [""] as an empty array.
    return $.ajax({
      url,
      type,
      method,
      data: jsonData ? JSON.stringify(requestData) : requestData,
      success: (response) => resolve(toCamelCase(response, keyTransformOverrides)),
      error: ({ responseJSON = {}, statusText, status }) => {
        // TODO: we should return a structure like this in the response body from the server
        // for all API requests that error, but for now, we'll return this default error
        // if the response body is empty
        const defaultErrors = {
          message: "Something went wrong.",
          errors: { base: [statusText] },
        }
        const errors = _.isEmpty(responseJSON) ? defaultErrors : responseJSON
        reject({ ...errors, status })
      },
      ...(jsonData ? { contentType: "application/json", dataType: "json" } : {}),
      ...fileOptions,
      ...otherOptions,
    })
  })

export const shuttleValue = (callback) => (value) => {
  callback(value)
  return value
}

export function toSnakeCase(object, keyTransformOverrides) {
  return transformKeys(object, transform(_.snakeCase, keyTransformOverrides))
}

export function toCamelCase(object, keyTransformOverrides) {
  return transformKeys(object, transform(_.camelCase, keyTransformOverrides))
}

function transformKeys(object, transform) {
  if (typeof object === "object") {
    if (object === null || object === undefined || object instanceof File) {
      return object
    }

    if (Array.isArray(object)) {
      return _.map(object, (value) => transformKeys(value, transform))
    }

    const result = {}
    Object.keys(object).forEach((key) => {
      result[transform(key)] = transformKeys(object[key], transform)
    })
    return result
  }

  return object
}

const transform =
  (defaultTransform, keyTransformOverrides = {}) =>
  (key) =>
    keyTransformOverrides[key] ? keyTransformOverrides[key] : defaultTransform(key)
