import PropTypes from "prop-types"

import TeamFormFields from "./components/TeamFormFields"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { supervisory_team_path, supervisory_teams_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

const New = ({ team, careManagementAgencies }) => {
  const handleSubmit = async (formValues, { setErrors, setStatus }) => {
    try {
      const response = await api.supervisoryTeams.teams.post(formValues)
      redirectTo(supervisory_team_path(response.id))
      setStatus("success")
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={team} onSubmit={handleSubmit}>
      <TeamFormFields careManagementAgencies={careManagementAgencies} />
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(supervisory_teams_path())} />
            <Form.SubmitButton label="Create Team" submittingLabel="Saving..." />
          </>
        }
      />
    </Form>
  )
}

New.propTypes = {
  team: types.supervisoryTeam,
  careManagementAgencies: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default New
