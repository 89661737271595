import { useField } from "formik"
import PropTypes from "prop-types"

import ChartSearch from "./ChartSearch"

import { SecondaryButton } from "~/design/buttons"
import Form from "~/design/forms"

function ChartField({ name }) {
  const [{ value: chart }, , helpers] = useField({ name })

  if (chart) {
    return (
      <div className="d-flex justify-content-between align-items-start">
        <dl className="flex-grow-1">
          <div className="mb-1">
            {/* &#8203; renders a zero-width space, which helps separate CIN from the label when users double-click to select */}
            <dt className="w-25 mr-1">CIN&#8203;</dt>
            <dd>{chart.patientMedicaidMemberId}</dd>
          </div>
          <div className="mb-1">
            <dt className="w-25 mr-1">Patient&#8203;</dt>
            <dd>{chart.patientName}</dd>
          </div>
          <div className="mb-1">
            <dt className="w-25 mr-1">Health Home&#8203;</dt>
            <dd>{chart.healthHomeName}</dd>
          </div>
          <div className="mb-1">
            <dt className="w-25 mr-1">CMA&#8203;</dt>
            <dd>{chart.careManagementAgencyName}</dd>
          </div>
        </dl>
        <SecondaryButton onClick={() => helpers.setValue(null)}>Change Chart</SecondaryButton>
      </div>
    )
  } else {
    return (
      <Form.Group name={name} clearOnHide={false}>
        <Form.Label>Search by CIN or name</Form.Label>
        <ChartSearch onChange={(newChart) => helpers.setValue(newChart)} />
      </Form.Group>
    )
  }
}

ChartField.propTypes = {
  name: PropTypes.string.isRequired,
}

export default ChartField
