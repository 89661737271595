import { connect } from "react-redux"

import { getIsNewGoalFormOpen } from "~/components/carePlans/carePlanNeedsUISelectors"
import { openGoalsList, openNewGoalForm } from "~/components/carePlans/carePlanNeedUIActions"
import { getReadOnly } from "~/components/carePlans/globalsSelectors"

const NeedAddButton = ({ isNewGoalFormOpen, needId, openNewGoalFormConnect, openGoalsListConnect, readOnly }) => {
  const onClickAddGoal = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!isNewGoalFormOpen) {
      openNewGoalFormConnect(needId)
      openGoalsListConnect(needId)
    }
  }

  return (
    !readOnly && (
      <button type="button" className="btn btn-link" onClick={onClickAddGoal} disabled={isNewGoalFormOpen}>
        Add Goal
      </button>
    )
  )
}

export default connect(
  (state, props) => ({
    isNewGoalFormOpen: getIsNewGoalFormOpen(state, props),
    readOnly: getReadOnly(state),
  }),
  {
    openNewGoalFormConnect: openNewGoalForm,
    openGoalsListConnect: openGoalsList,
  }
)(NeedAddButton)
