import { parse } from "date-fns"

/* Takes an object with payment claim data grouped by date and returns an array for the chart report:
transformChartData({
  20240101: {
    claimSummaries: {
      outstanding: { sumCharges: '100.0' },
      paid: { sumCharges: '200.0', sumPayments: '50.5' }
    }
  },
  20240201: { ... }
}) =>
[
  { month: Jan 2024, Outstanding: 100.0, Paid: 50.5, Adjusted: 149.5 },
  { month: Feb 2024, ... }
]
*/
function transformChartData(data) {
  return Object.entries(data)
    .map(([month, { claimSummaries }]) => {
      const result = { month: parse(month, "yyyyMMdd", new Date()) }

      for (const [claimStatus, claimSummary] of Object.entries(claimSummaries)) {
        const sumPayments = parseFloat(claimSummary.sumPayments) || 0
        const sumCharges = parseFloat(claimSummary.sumCharges) || 0

        // capitalize first letter of claimStatus for label
        const label = claimStatus[0].toUpperCase() + claimStatus.slice(1)

        // ClaimStatus::PAID.name
        if (label === "Paid") {
          result[label] = sumPayments
          result.Adjusted = sumCharges - sumPayments
        } else {
          result[label] = sumCharges
        }
      }

      return result
    })
    .sort((a, b) => a.month - b.month)
}

export { transformChartData }
