import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_chart_health_home_consents_path, api_v1_health_home_consent_path } from "~/routes"

const healthHomeConsents = {
  post: function (chartId, healthHomeConsent) {
    return submitAsyncRequest({
      url: api_v1_chart_health_home_consents_path(chartId),
      type: "post",
      data: { healthHomeConsent },
    })
  },
  patch: function (healthHomeConsentId, healthHomeConsent) {
    return submitAsyncRequest({
      url: api_v1_health_home_consent_path(healthHomeConsentId),
      type: "patch",
      data: { healthHomeConsent },
    })
  },
}

export default healthHomeConsents
