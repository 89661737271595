import PropTypes from "prop-types"

const customAssessmentSchemaSection = PropTypes.shape({
  id: PropTypes.number.isRequired,
  sectionName: PropTypes.string.isRequired,
  description: PropTypes.string,
  questionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
})

export default customAssessmentSchemaSection
