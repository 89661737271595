import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import ActiveReferralNote from "./ActiveReferralNote"
import CareTeamProviderFormFields from "./CareTeamProviderFormFields"

import Form from "~/design/forms"
import Modal from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function AddCareTeamMembershipForm({
  chart,
  providerCredentials,
  providerTitles,
  providerTypes,
  show,
  onAdd,
  onClose,
}) {
  const initialValues = {
    active: true,
    startDate: "",
    endDate: "",
    providerId: null,
    providerTypeId: null,
    provider: {},
    chartId: chart.id,
    followupNeeded: false,
  }
  async function onSubmit(formData, { setErrors, setStatus }) {
    try {
      const membership = await api.careTeamMemberships.post(chart.id, formData)
      onAdd(membership)
      toast.success("Care team member added.")
      setStatus("success")
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      title="Add Care Team Member"
      size="xl"
      dataTestId="add-care-team-membership-modal"
    >
      <Form.Provider formId="add-care-team-membership-modal-form" initialValues={initialValues} onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Body vertical>
            <Row>
              <Col md={6}>
                <CareTeamProviderFormFields providerCredentials={providerCredentials} providerTitles={providerTitles} />
                <ActiveReferralNote />
              </Col>
              <Col md={6}>
                <Form.Group name="providerTypeId">
                  <Form.Label>Role</Form.Label>
                  <Form.SelectBox options={providerTypes} />
                </Form.Group>
                <Form.Group name="startDate">
                  <Form.Label>Start</Form.Label>
                  <Form.TextField />
                </Form.Group>
                <Form.Group name="endDate">
                  <Form.Label>End</Form.Label>
                  <Form.TextField />
                </Form.Group>
                <Form.Group name="active">
                  <Form.CheckBox label="Active" />
                </Form.Group>
                <Form.Group name="followupNeeded">
                  <Form.CheckBox label="Followup Needed" />
                </Form.Group>
              </Col>
            </Row>
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Save" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

AddCareTeamMembershipForm.propTypes = {
  chart: types.chart.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  providerCredentials: PropTypes.arrayOf(types.selectOption).isRequired,
  providerTitles: PropTypes.arrayOf(types.selectOption).isRequired,
  providerTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  show: PropTypes.bool.isRequired,
}

export default AddCareTeamMembershipForm
