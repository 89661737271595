import { Col, Row } from "react-bootstrap"
import { FieldArray, useFormikContext } from "formik"

import { PrimaryButton, SecondaryButton } from "~/design/buttons"
import Form from "~/design/forms"
import Show from "~/design/Show"

function ParticipatingPartnersFields() {
  const {
    values: { doh5055FormParticipatingPartnersAttributes },
  } = useFormikContext()

  const newParticipatingPartnerAttributes = {
    name: "",
    consented: false,
    isCareManagementAgency: false,
  }

  return (
    <FieldArray name="doh5055FormParticipatingPartnersAttributes">
      {({ push, remove }) => (
        <>
          {doh5055FormParticipatingPartnersAttributes.map((ppAttr, index) => (
            <Row className="align-items-end" key={index}>
              <Col md={6} xs={9}>
                <Form.Group
                  clearOnHide={false}
                  name={`doh5055FormParticipatingPartnersAttributes[${index}].name`}
                  errorKey={`doh5055FormParticipatingPartners[${index}].name`}
                >
                  <Form.Label>Name of Participating Partner</Form.Label>
                  <Form.TextField disabled={ppAttr.isCareManagementAgency} />
                </Form.Group>
              </Col>
              <Show when={!ppAttr.isCareManagementAgency}>
                <Col md={6} xs={3} className="mb-3">
                  <SecondaryButton onClick={() => remove(index)}>Remove</SecondaryButton>
                </Col>
              </Show>
            </Row>
          ))}
          <PrimaryButton onClick={() => push(newParticipatingPartnerAttributes)}>
            Add Participating Partner +
          </PrimaryButton>
        </>
      )}
    </FieldArray>
  )
}

export default ParticipatingPartnersFields
