import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_care_management_agency_quality_dashboard_path } from "~/routes"

const careManagementAgencyQualities = {
  get: function (
    careManagementAgencyId,
    { category, healthHomeIds, supervisorIds, supervisoryTeamIds, adultChildServicesCodeId }
  ) {
    return submitAsyncRequest({
      url: api_v1_care_management_agency_quality_dashboard_path(careManagementAgencyId),
      type: "get",
      data: { category, healthHomeIds, supervisorIds, supervisoryTeamIds, adultChildServicesCodeId },
    }).then((res) => res.map(columnValues))
  },
}

function columnValues(row) {
  return {
    entity: row.entity,
    enrolled: row.enrolledCount,
    pastGracePeriod: row.pastGracePeriodCount,
    missing: row.missingCount,
    expired: row.expiredCount,
    valid: row.compliantCount,
    completionPercentage: row.completionPercentage,
  }
}

export default careManagementAgencyQualities
