import _ from "lodash"

import { CARE_PLAN_ENTITIES_DELETED } from "~/components/carePlans/carePlanActions"
import { CARE_PLAN_NEED_CREATED } from "~/components/carePlans/carePlanNeedActions"
import { SET_GOAL_FORM_STATE, SET_GOALS_LIST_OPEN_STATE } from "~/components/carePlans/carePlanNeedUIActions"
import { COLLAPSE_ALL, EXPAND_ALL, EXPAND_GOALS } from "~/components/carePlans/carePlanUIActions"
import { deleteSiblingNodes } from "~/components/carePlans/entityTreeTransformer"

export default function carePlanNeedsUiReducer(state = {}, action) {
  switch (action.type) {
    case CARE_PLAN_NEED_CREATED:
      return {
        ...state,
        [action.need.id]: {
          isGoalsListOpen: true,
          isNewGoalFormOpen: false,
        },
      }
    case SET_GOAL_FORM_STATE: {
      const need = state[action.id]

      return {
        ...state,
        [action.id]: { ...need, isNewGoalFormOpen: action.isOpen },
      }
    }
    case SET_GOALS_LIST_OPEN_STATE: {
      const need = state[action.id]

      return {
        ...state,
        [action.id]: {
          ...need,
          isGoalsListOpen: action.isOpen,
          isNewGoalFormOpen: action.isOpen && need.isNewGoalFormOpen,
        },
      }
    }
    case EXPAND_GOALS:
    case EXPAND_ALL:
      return _.mapValues(state, (need) => ({
        ...need,
        isGoalsListOpen: true,
      }))
    case COLLAPSE_ALL:
      return _.mapValues(state, (need) => ({
        ...need,
        isGoalsListOpen: false,
        isNewGoalFormOpen: false,
      }))
    case CARE_PLAN_ENTITIES_DELETED:
      return deleteSiblingNodes(state, action.needIds)
    default:
      return state
  }
}
