import { ActiveOnlyFilterState, SET_ACTIVE_FILTER_STATE } from "~/components/carePlans/carePlanActiveFilterActions"

export default function carePlanUiReducer(state = ActiveOnlyFilterState.ACTIVE_ONLY, action) {
  switch (action.type) {
    case SET_ACTIVE_FILTER_STATE:
      return action.activeFilterState
    default:
      return state
  }
}
