import _ from "lodash"
import createCachedSelector from "re-reselect"

import { ActiveOnlyFilterState } from "~/components/carePlans/carePlanActiveFilterActions"
import { getGoals } from "~/components/carePlans/carePlanGoalsSelectors"
import { getActiveFilterState } from "~/components/carePlans/carePlanUISelectors"
import { getCarePlanGoalStatuses } from "~/components/carePlans/globalsSelectors"

export const getNeeds = ({ entities: { needs } }) => needs
export const getNeed = ({ entities: { needs } }, { needId }) => needs[needId]

const filterByActiveStatus = (activeFilterState, carePlanGoalStatuses) => (goal) => {
  const goalStatusId = goal.carePlanGoalStatusId
  return (
    activeFilterState === ActiveOnlyFilterState.ALL ||
    _.find(carePlanGoalStatuses, ({ value, isActive }) => value === goalStatusId && isActive)
  )
}

export const getGoalIds = createCachedSelector([getNeed], ({ goalIds }) => goalIds)((_state, { needId }) => needId)

export const getFilteredGoalIds = createCachedSelector(
  [getNeed, getGoals, getActiveFilterState, getCarePlanGoalStatuses],
  ({ goalIds }, goals, activeFilterState, carePlanGoalStatuses) => {
    const activeStatusPredicate = filterByActiveStatus(activeFilterState, carePlanGoalStatuses)
    return goalIds.filter((goalId) => {
      const goal = goals[goalId]
      return activeStatusPredicate(goal)
    })
  }
)((_state, { needId }) => needId)
