import { connect } from "react-redux"

import CarePlanFormBody from "./CarePlanFormBody"
import { carePlanTaskFlashKey, carePlanTaskUpdated, deleteCarePlanTask } from "./carePlanTaskActions"
import TaskFormFields from "./TaskFormFields"

import { DangerButton } from "~/design/buttons"
import Form from "~/design/forms"
import useConfirmationModal from "~/design/modals/useConfirmationModal"
import { flashMessage } from "~/flashMessages/flashMessageActions"
import api from "~/services/api"
import { toast } from "~/toast"

function EditTaskForm({
  task,
  onCancelClick,
  carePlanTaskUpdatedConnect,
  deleteCarePlanTaskConnect,
  flashMessageConnect,
  carePlanTaskStatuses,
  gapsForNewTaskLinkages,
  taskResponsibilities,
}) {
  const confirm = useConfirmationModal()

  const defaultTask = {
    description: "",
    carePlanTaskStatusId: "",
    notes: "",
    taskResponsibilityId: "",
    targetCompletionDate: "",
    completionDate: "",
    taskResponsibilityOther: "",
    mcoGapInCareIds: [],
  }
  const initialValues = Object.assign(defaultTask, task)

  async function handleSubmit(values, { setErrors, setStatus }) {
    try {
      const updatedTask = await api.carePlans.tasks.patch(task.id, values)
      setStatus("success")
      carePlanTaskUpdatedConnect(updatedTask)
      flashMessageConnect(carePlanTaskFlashKey(updatedTask))
      toast.success("Task successfully updated.")
      onCancelClick()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  async function handleDelete() {
    const confirmed = await confirm.danger("This task will be permanently deleted.", {
      title: "Delete Task",
      confirmButtonLabel: "Delete",
    })

    if (confirmed) {
      try {
        await deleteCarePlanTaskConnect(task.id)
        toast.success("Task successfully deleted.")
      } catch (error) {
        toast.error(
          <>
            Task could not be deleted.
            <br />
            Please refresh the page.
          </>
        )
      }
    }
  }

  const gapOptions = Object.values(gapsForNewTaskLinkages).map((gap) => ({
    label: gap.description,
    value: gap.id,
  }))

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} vertical>
      <CarePlanFormBody title="Edit Task" dataTestId="task-form">
        <TaskFormFields
          carePlanTaskStatuses={carePlanTaskStatuses}
          gapOptions={gapOptions}
          taskResponsibilities={taskResponsibilities}
        />
        <Form.ActionBar
          left={<DangerButton onClick={handleDelete}>Delete</DangerButton>}
          right={
            <>
              <Form.CancelButton onClick={onCancelClick} />
              <Form.SubmitButton label="Update" />
            </>
          }
        />
      </CarePlanFormBody>
    </Form>
  )
}

export default connect(
  ({ globals, entities }) => ({
    carePlanTaskStatuses: globals.carePlanTaskStatuses,
    taskResponsibilities: globals.taskResponsibilities,
    gapsForNewTaskLinkages: entities.gapsForNewTaskLinkages,
  }),
  {
    carePlanTaskUpdatedConnect: carePlanTaskUpdated,
    deleteCarePlanTaskConnect: deleteCarePlanTask,
    flashMessageConnect: flashMessage,
  }
)(EditTaskForm)
