import api from "~/services/api"
import SessionKeeper from "~/sessions/sessionKeeper"

function autoLogoutIfNecessary(attempts = 0, autoLogoutCallback = redirectToLogin) {
  return api.sessions
    .test()
    .then(({ checkAgainInSecs }) => {
      setTimeout(() => {
        autoLogoutIfNecessary(null, autoLogoutCallback)
      }, checkAgainInSecs * 1000)
    })
    .catch((err) => {
      if (err.status === 401 || attempts >= 5) {
        autoLogoutCallback()
      } else {
        setTimeout(() => {
          autoLogoutIfNecessary(attempts + 1, autoLogoutCallback)
        }, 5000)
      }
    })
}

function redirectToLogin() {
  const returnTo = encodeURIComponent(`${window.location.pathname}${window.location.search}`)
  window.location = `/sessions/delete?return_to=${returnTo}`
}

function redirectToDesiredDestination() {
  const defaultPath = "/"
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search)
    if (params.has("return_to")) {
      window.location = params.get("return_to")
    } else {
      window.location = defaultPath
    }
  } else {
    window.location = defaultPath
  }
}

const sessionManager = {
  SESSION_MANAGEMENT_KEY: "signedIn",

  signIn(recreatableSessionsEnabled, initialTimeout) {
    this.autoLogoutCallback = recreatableSessionsEnabled ? HH.LoginModal.showModal : redirectToLogin
    this.initialTimeout = initialTimeout

    this.publishSignedIn()
    this.listenForLoggedOut()
    this.listenForUserActivity()
    this.startSessionTimer()
  },

  signOut() {
    this.publishSignedOut()
    this.listenForLoggedIn()
  },

  startSessionTimer() {
    setTimeout(() => {
      autoLogoutIfNecessary(0, this.autoLogoutCallback)
    }, this.initialTimeout)
  },

  listenForUserActivity() {
    new SessionKeeper().startTracking(60000)
  },

  listenForLoggedIn() {
    window.onstorage = (e) => {
      if (e.key === this.SESSION_MANAGEMENT_KEY && e.newValue.startsWith("true")) {
        redirectToDesiredDestination()
      }
    }
  },

  listenForLoggedOut() {
    window.onstorage = (e) => {
      if (e.key === this.SESSION_MANAGEMENT_KEY && e.newValue.startsWith("false")) {
        this.autoLogoutCallback()
      }
    }
  },

  publishSignedOut() {
    localStorage.setItem(this.SESSION_MANAGEMENT_KEY, `false-${new Date().getTime()}`)
  },

  publishSignedIn() {
    localStorage.setItem(this.SESSION_MANAGEMENT_KEY, `true-${new Date().getTime()}`)
  },
}

export default {
  sessionManager,
  autoLogoutIfNecessary,
}
