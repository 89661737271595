import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_chart_documents_path, api_v1_document_path } from "~/routes"

const documents = {
  post: function (chartId, document) {
    return submitAsyncRequest({
      url: api_v1_chart_documents_path(chartId),
      type: "post",
      data: { document },
    })
  },
  patch: function (documentId, document) {
    return submitAsyncRequest({
      url: api_v1_document_path(documentId),
      type: "patch",
      data: { document },
    })
  },
}

export default documents
