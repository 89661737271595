import PropTypes from "prop-types"

const user = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  roleId: PropTypes.number.isRequired,
})

export default user
