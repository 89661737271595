import PropTypes from "prop-types"

const healthHome = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  servesChildren: PropTypes.bool.isRequired,
  servesAdults: PropTypes.bool.isRequired,
})

export default healthHome
