import PropTypes from "prop-types"

import useDisableContext from "./useDisableContext"

import SecondaryButton from "~/design/buttons/SecondaryButton"

/**
 * A `<CancelButton />` is used within a `<Form />` to exit the form without making changes.
 *
 * This is most often used to redirect the user back to the "show" screen for the object
 * being edited, or the "list" screen when creating a new instance.
 */
function CancelButton({ disabled: disabledByProps, onClick }) {
  const disabledByContext = useDisableContext()
  const disabled = disabledByProps || disabledByContext

  return (
    <SecondaryButton disabled={disabled} onClick={onClick}>
      Cancel
    </SecondaryButton>
  )
}

CancelButton.propTypes = {
  /**
   * A disabled button receives no pointer events.
   */
  disabled: PropTypes.bool,
  /**
   * The function to call when the button is clicked.
   */
  onClick: PropTypes.func.isRequired,
}

CancelButton.defaultProps = {
  disabled: false,
}

export default CancelButton
