import careManagerColumn from "~/big3Dashboard/CareManagementAgencyQualityDashboard/careManagerColumn"
import Filters from "~/big3Dashboard/CareManagementAgencyQualityDashboard/Filters"
import Header from "~/big3Dashboard/components/Header"
import Tabs from "~/big3Dashboard/components/Tabs"
import {
  getCareManagementAgencyQualityDashboardExportRoute,
  getSelectedEntity,
} from "~/big3Dashboard/selectors/globalsSelector"
import { api_v1_care_management_agency_care_management_agency_quality_dashboard_exports_path } from "~/routes"

function CareManagementAgencyQualityDashboard({
  activeTab,
  tabs,
  status,
  report,
  healthHomeIds,
  careManagementAgencyId,
  date,
  adultChildServicesCodeId,
}) {
  const TableComponent = activeTab.tableComponent

  return (
    <div>
      <Header
        entity="Care Management Agency"
        entityNameSelector={getSelectedEntity}
        exportRouteFunction={api_v1_care_management_agency_care_management_agency_quality_dashboard_exports_path}
        exportRouteSelector={getCareManagementAgencyQualityDashboardExportRoute}
      />
      <Filters />
      <Tabs tabs={tabs} />
      <TableComponent
        status={status}
        report={report}
        healthHomeIds={healthHomeIds}
        careManagementAgencyId={careManagementAgencyId}
        date={date}
        entityColumn={careManagerColumn}
        adultChildServicesCodeId={adultChildServicesCodeId}
      />
    </div>
  )
}

export default CareManagementAgencyQualityDashboard
