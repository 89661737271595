import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import _ from "lodash"

import { setFilterParams } from "~/big3Dashboard/actions/paramsActions"
import { QUALITY_REPORT_LOADING } from "~/big3Dashboard/actions/qualityReportStatusActions"
import {
  getAdultChildServicesCodeId,
  getEntityId,
  getFilteredReportRowEntityIds,
  getHealthHomeIds,
  getSupervisorIds,
  getSupervisoryTeamIds,
} from "~/big3Dashboard/selectors/filtersSelector"
import {
  getAdultChildServicesCodes,
  getEntities,
  getHealthHomes,
  getSupervisors,
  getSupervisoryTeams,
} from "~/big3Dashboard/selectors/globalsSelector"
import { getReportRowEntities, getStatus } from "~/big3Dashboard/selectors/qualityReportStatusSelector"
import Form from "~/design/forms"
import IndexFilter from "~/design/IndexFilter"

function Filters({
  allCareManagers,
  initialCareManagerIds,
  careManagementAgencies,
  initialCareManagementAgencyId,
  healthHomes,
  initialHealthHomeIds,
  supervisoryTeams,
  initialSupervisoryTeamIds,
  supervisors,
  initialSupervisorIds,
  setFilterParamsConnect,
  status,
  initialAdultChildServicesCodeId,
  adultChildServicesCodes,
}) {
  const isLoading = status === QUALITY_REPORT_LOADING

  function handleSubmit(values, { setSubmitting }) {
    setFilterParamsConnect(values)
    setSubmitting(false)
  }

  return (
    <Form
      initialValues={{
        reportRowEntityIds: initialCareManagerIds,
        entityId: initialCareManagementAgencyId,
        healthHomeIds: initialHealthHomeIds,
        supervisoryTeamIds: initialSupervisoryTeamIds,
        supervisorIds: initialSupervisorIds,
        adultChildServicesCodeId: initialAdultChildServicesCodeId,
      }}
      onSubmit={handleSubmit}
    >
      <IndexFilter>
        <Row>
          <Col lg={4}>
            <Form.Group name="healthHomeIds" compact>
              <Form.Label width={5}>Health Home</Form.Label>
              <Form.MultiSelectBox
                width={7}
                placeholder=""
                options={_.sortBy(healthHomes, "name").map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
              />
            </Form.Group>
            <Form.Group name="entityId" compact>
              <Form.Label width={5}>CMA</Form.Label>
              <Form.SelectBox
                width={7}
                placeholder=""
                options={_.sortBy(careManagementAgencies, "name").map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group name="supervisoryTeamIds" compact>
              <Form.Label width={5}>Supervisory Team</Form.Label>
              <Form.MultiSelectBox
                width={7}
                placeholder=""
                options={_.sortBy(supervisoryTeams, "name").map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
              />
            </Form.Group>
            <Form.Group name="supervisorIds" compact>
              <Form.Label width={5}>Supervisor</Form.Label>
              <Form.MultiSelectBox
                width={7}
                placeholder=""
                options={_.sortBy(supervisors, "name").map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group name="reportRowEntityIds" compact>
              <Form.Label width={5}>Care Manager</Form.Label>
              <Form.MultiSelectBox
                width={7}
                placeholder=""
                options={_.sortBy(allCareManagers, "name").map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
              />
            </Form.Group>
            <Form.Group name="adultChildServicesCodeId" compact>
              <Form.Label width={5}>Adult/Child</Form.Label>
              <Form.SelectBox
                width={7}
                placeholder=""
                isClearable={true}
                options={_.sortBy(adultChildServicesCodes, "label")}
              />
            </Form.Group>
          </Col>
        </Row>
        <IndexFilter.ActionBar>
          <Form.SubmitButton label="Filter" disabled={isLoading} />
        </IndexFilter.ActionBar>
      </IndexFilter>
    </Form>
  )
}

export default connect(
  (state) => ({
    allCareManagers: getReportRowEntities(state),
    initialCareManagerIds: getFilteredReportRowEntityIds(state),
    careManagementAgencies: getEntities(state),
    initialCareManagementAgencyId: getEntityId(state),
    healthHomes: getHealthHomes(state),
    initialHealthHomeIds: getHealthHomeIds(state),
    supervisoryTeams: getSupervisoryTeams(state),
    initialSupervisoryTeamIds: getSupervisoryTeamIds(state),
    supervisors: getSupervisors(state),
    initialSupervisorIds: getSupervisorIds(state),
    status: getStatus(state),
    adultChildServicesCodes: getAdultChildServicesCodes(state),
    initialAdultChildServicesCodeId: getAdultChildServicesCodeId(state),
  }),
  {
    setFilterParamsConnect: setFilterParams,
  }
)(Filters)
