import PropTypes from "prop-types"

import DiagnosesFormFields from "./DiagnosesFormFields"

import Form from "~/design/forms"
import Modal from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function DiagnosesForm({ diagnoses, diagnosisValues, chart, show, onClose, onUpdate }) {
  async function onSubmit(values, { setErrors, setStatus }) {
    try {
      const updatedDiagnoses = await api.bulkDiagnosesUpdates.post(chart.id, values.diagnoses)
      onUpdate(updatedDiagnoses)
      setStatus("success")
      toast.success("Diagnoses successfully updated.")
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Modal show={show} onClose={onClose} title="Edit Diagnoses" size="lg">
      <Form.Provider initialValues={{ diagnoses }} onSubmit={onSubmit} formId="diagnoses-modal-form">
        <Modal.Body>
          <Form.Body vertical>
            <DiagnosesFormFields diagnosisValues={diagnosisValues} />
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Save" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

DiagnosesForm.propTypes = {
  diagnoses: PropTypes.objectOf(types.diagnosis).isRequired,
  diagnosisValues: PropTypes.arrayOf(types.selectOption).isRequired,
  chart: types.chart.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default DiagnosesForm
