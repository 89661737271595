import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_chart_doh5055_forms_path } from "~/routes"

const doh5055 = {
  post: function (chartId, form) {
    return submitAsyncRequest({
      url: api_v1_chart_doh5055_forms_path(chartId),
      type: "post",
      data: { doh5055_form: form },
      keyTransformOverrides: {
        doh5055_form: "doh5055_form",
        doh5055FormParticipatingPartnersAttributes: "doh5055_form_participating_partners_attributes",
      },
    })
  },
}

export default doh5055
