import { Spinner } from "react-bootstrap"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import useDisableContext from "./useDisableContext"
import useFormIdContext from "./useFormIdContext"

import PrimaryButton from "~/design/buttons/PrimaryButton"

/**
 * A `<SubmitButton />` is used within a `<Form />` and submits the form when clicked.
 *
 * The button will automatically update when its parent form is submitting to show a
 * spinner and an alternate label, if provided.
 */
function SubmitButton({ disabled: disabledByProps, formId: formIdFromProps, label, submittingLabel }) {
  const formIdContext = useFormIdContext()
  const disabledByContext = useDisableContext()
  const { isSubmitting, status } = useFormikContext()

  // Buttons are disabled after successful form submission since we typically redirect to a new screen
  // after submitting a form, and don't want the user to try to re-submit the form in the middle of a redirect
  const disabled = disabledByProps || disabledByContext || isSubmitting || status === "success"
  const formId = formIdFromProps || formIdContext

  return (
    <PrimaryButton disabled={disabled} formId={formId} type="submit">
      {isSubmitting ? (
        <>
          <Spinner as="span" size="sm" animation="border" role="status" />
          &nbsp;{submittingLabel}
        </>
      ) : (
        label
      )}
    </PrimaryButton>
  )
}

SubmitButton.propTypes = {
  /**
   * A disabled button receives no pointer events.
   */
  disabled: PropTypes.bool,
  /**
   * The ID of the form this button should submit.
   *
   * This is necessary when your submit button isn't included inside of
   * its related `<Form />` e.g. in a modal where action buttons are
   * located in the modal footer rather than the body.
   */
  formId: PropTypes.string,
  /**
   * The text to render on the button when the form is not submitting.
   */
  label: PropTypes.string,
  /**
   * The text to render on the button while the form is submitting.
   * A spinner will be rendered as well to offer visual feedback to
   * the user that the page hasn't locked up.
   */
  submittingLabel: PropTypes.string,
}

SubmitButton.defaultProps = {
  disabled: false,
  label: "Submit",
  submittingLabel: "Submitting...",
}

export default SubmitButton
