import PropTypes from "prop-types"

import FormFields from "./FormFields"

import { redirectTo } from "~/browserHelpers"
import { LineDivider } from "~/components/Divider"
import Form from "~/design/forms"
import { chart_segments_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"
import ChartIndicator from "~/views/shared/ChartIndicator"

function NewForm({
  adultChildServicesCodes,
  appropriatenessCriteriaCodes,
  chart,
  endDateReasonCategories,
  endDateReasonCodes,
  healthHome,
  outreachEnrollmentCodes,
  pendReasons,
  referralCodes,
  segment,
}) {
  async function handleSubmit(values, { setErrors, setStatus }) {
    const { ...segment } = values

    const segmentParams = {
      ...segment,
    }

    try {
      await api.segments.post(chart.id, segmentParams)
      setStatus("success")
      redirectTo(chart_segments_path(chart.id))
    } catch ({ errors }) {
      setErrors(errors)
      window.scrollTo(0, 0)
    }
  }

  return (
    <Form initialValues={segment} onSubmit={handleSubmit}>
      <FormFields
        segment={segment}
        healthHome={healthHome}
        endDateReasonCodes={endDateReasonCodes}
        endDateReasonCategories={endDateReasonCategories}
        pendReasons={pendReasons}
        referralCodes={referralCodes}
        outreachEnrollmentCodes={outreachEnrollmentCodes}
        adultChildServicesCodes={adultChildServicesCodes}
        appropriatenessCriteriaCodes={appropriatenessCriteriaCodes}
      />

      <LineDivider />
      <ChartIndicator chart={chart} />

      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_segments_path(chart.id))} />
            <Form.SubmitButton label="Create Segment" />
          </>
        }
      />
    </Form>
  )
}

NewForm.propTypes = {
  adultChildServicesCodes: PropTypes.arrayOf(types.selectOption).isRequired,
  appropriatenessCriteriaCodes: PropTypes.arrayOf(types.appropriatenessCriteriaCode).isRequired,
  endDateReasonCategories: PropTypes.arrayOf(types.selectOption).isRequired,
  endDateReasonCodes: PropTypes.arrayOf(types.endDateReasonCode).isRequired,
  healthHome: types.healthHome.isRequired,
  outreachEnrollmentCodes: PropTypes.arrayOf(types.selectOption).isRequired,
  chart: types.chart.isRequired,
  pendReasons: PropTypes.arrayOf(types.selectOption).isRequired,
  referralCodes: PropTypes.arrayOf(types.selectOption).isRequired,
  segment: types.segment.isRequired,
}

export default NewForm
