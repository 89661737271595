import { Col as BootstrapCol, Form as BootstrapForm } from "react-bootstrap"
import AsyncSelect from "react-select/async"
import debounce from "debounce-promise"
import { useField } from "formik"

import LinkButton from "~/design/buttons/LinkButton"
import FieldErrors from "~/design/forms/FieldErrors"
import HelpText from "~/design/forms/HelpText"
import { classNames } from "~/design/forms/selectBoxConfiguration"
import useErrorContext from "~/design/forms/useErrorContext"
import useNameContext from "~/design/forms/useNameContext"
import api from "~/services/api"
import { toast } from "~/toast"

function NewMedicaidMemberId() {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name })
  const { hasError } = useErrorContext()

  const errorMessageId = `${id}Errors`

  function fetchResults(searchString) {
    if (!searchString) return
    return api.patientMedicaidMemberIdMatches
      .get(searchString)
      .then((resp) => {
        return resp.map((member) => ({
          label: `${member.medicaidMemberId} - ${member.name}`,
          value: member.medicaidMemberId,
        }))
      })
      .catch((e) =>
        toast.error(`There was an error finding results for "${searchString}". Please check the CIN and try again.`)
      )
  }

  return (
    <BootstrapCol sm={6}>
      {field.value ? (
        <>
          <div className="d-flex align-items-center justify-content-between">
            {field.value}
            <LinkButton onClick={() => helpers.setValue("")}>Remove</LinkButton>
          </div>
          <BootstrapForm.Text id="medicaidIdSearchBoxHelpText" muted>
            Click "Remove" to select a new CIN.
          </BootstrapForm.Text>
        </>
      ) : (
        <>
          <AsyncSelect
            aria-describedby={"medicaidIdSearchBoxHelpText"}
            aria-errormessage={errorMessageId}
            aria-invalid={hasError}
            aria-label="medicaid id search box"
            aria-labelledby={`${id}Label`}
            cacheOptions
            className="react-select"
            classNamePrefix="react-select"
            classNames={classNames}
            defaultOptions
            id={`${id}SelectBox`}
            inputId={id}
            loadOptions={debounce(fetchResults, 400)}
            name={name}
            noOptionsMessage={() => "No member with matching CIN found"}
            onChange={(evt) => helpers.setValue(evt.value)}
            placeholder="Start typing a member CIN"
            value={field.value}
          />
          <HelpText id="medicaidIdSearchBoxHelpText">
            Enter the value of the member's new medicaid member id. The new member must exist in FCM before this segment
            can be ended with the new CIN. If the member CIN cannot be found here, try creating the member first.
          </HelpText>
        </>
      )}
      <FieldErrors id={errorMessageId} />
    </BootstrapCol>
  )
}

export default NewMedicaidMemberId
