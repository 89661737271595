import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import LinkedGapsInCareField from "./LinkedGapsInCareField"

import Form from "~/design/forms"
import types from "~/types"

function TaskFormFields({ carePlanTaskStatuses, gapOptions, taskResponsibilities }) {
  return (
    <>
      <Row>
        <Col md={8}>
          <Form.Group name="description">
            <Form.Label>Task</Form.Label>
            <Form.TextArea placeholder="e.g. Care Manager will assist client in getting a glucose monitor" />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group name="carePlanTaskStatusId" errorKey="status">
            <Form.Label>Status</Form.Label>
            <Form.SelectBox options={carePlanTaskStatuses} isClearable placeholder="Select a status" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Form.Group name="notes">
            <Form.Label>Task Note</Form.Label>
            <Form.TextArea />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group name="taskResponsibilityId" errorKey="need">
            <Form.Label>Responsibility</Form.Label>
            <Form.SelectBox options={taskResponsibilities} isClearable placeholder="Select a responsibility" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group name="targetCompletionDate">
            <Form.Label>Target Completion Date</Form.Label>
            <Form.DatePicker />
          </Form.Group>
        </Col>
        <Col md={4}>
          {/* CarePlanTaskStatus::COMPLETED */}
          <Form.Show when={({ values: { carePlanTaskStatusId } }) => carePlanTaskStatusId === 3}>
            <Form.Group name="completionDate">
              <Form.Label>Completion Date</Form.Label>
              <Form.DatePicker />
            </Form.Group>
          </Form.Show>
        </Col>
        <Col md={4}>
          {/* CarePlanTaskResponsibility::OTHER */}
          <Form.Show when={({ values: { taskResponsibilityId } }) => taskResponsibilityId === 6}>
            <Form.Group name="taskResponsibilityOther">
              <Form.Label>Other</Form.Label>
              <Form.TextField />
            </Form.Group>
          </Form.Show>
        </Col>
      </Row>
      <LinkedGapsInCareField gapOptions={gapOptions} />
    </>
  )
}

TaskFormFields.propTypes = {
  carePlanTaskStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  gapOptions: PropTypes.arrayOf(types.selectOption).isRequired,
  taskResponsibilities: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default TaskFormFields
