import PropTypes from "prop-types"

import PopoverButton from "~/components/PopoverButton"
import Form from "~/design/forms"
import { globalsStateWrapper } from "~/redux"
import types from "~/types"

function EncounterMeetingTypeField({ meetingTypes, enabled, errorKey, name }) {
  if (enabled) {
    return (
      <Form.Group name={name} errorKey={errorKey}>
        <Form.Label>
          Was this a care conference or interdisciplinary team meeting?{" "}
          <PopoverButton
            title="Was this a care conference or interdisciplinary team meeting?"
            label="?"
            placement="left"
          >
            <div>
              An Interdisciplinary Team Meeting are for Children members where both the child and/or caregiver are
              present along with care team member(s) to review and update the member's plan of care, CANS, or as
              requested by member/caregiver and/or interdisciplinary team provider.
            </div>
          </PopoverButton>
        </Form.Label>
        <Form.SelectBox options={meetingTypes} isClearable />
      </Form.Group>
    )
  }

  return null
}

EncounterMeetingTypeField.propTypes = {
  meetingTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  enabled: PropTypes.bool.isRequired,
  errorKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default globalsStateWrapper(({ meetingTypes }) => ({ meetingTypes }))(EncounterMeetingTypeField)
