export function getMcoGapsInCare({ entities: { mcoGapsInCare } }) {
  return mcoGapsInCare
}

export function getSelectedMcoGapsInCare({ entities: { mcoGapsInCare } }, { mcoGapInCareIds }) {
  return mcoGapInCareIds.map((mcoGapInCareId) => mcoGapsInCare[mcoGapInCareId])
}

export function getMcoGapInCare({ entities: { mcoGapsInCare } }, { mcoGapInCareId }) {
  return mcoGapsInCare[mcoGapInCareId]
}
