import { Form as BootstrapForm } from "react-bootstrap"
import { Formik, useFormikContext } from "formik"
import PropTypes from "prop-types"

import ActionBar from "./ActionBar"
import Autosave from "./Autosave"
import CancelButton from "./CancelButton"
import CheckBox from "./CheckBox"
import DatePicker from "./DatePicker"
import Fieldset from "./Fieldset"
import FileField from "./FileField"
import FormErrors from "./FormErrors"
import Group from "./Group"
import Label from "./Label"
import MarkForDelete from "./MarkForDelete"
import MultiSelectBox from "./MultiSelectBox"
import Radio from "./Radio"
import RadioGroup from "./RadioGroup"
import SelectBox from "./SelectBox"
import Show from "./Show"
import SignatureField from "./SignatureField"
import SubmitButton from "./SubmitButton"
import TextArea from "./TextArea"
import TextField from "./TextField"
import useFormIdContext, { FormIdProvider } from "./useFormIdContext"
import { LayoutProvider } from "./useLayoutContext"

/**
 * A `<Form />` is the top-level component for building React forms.
 *
 * The Form type is a compound component, meaning you can access all of the
 * control types, buttons, etc. using dot notation e.g. `<Form.Label />`.
 */
function Form({ backgroundColor, children, formId, initialValues, onSubmit, vertical }) {
  return (
    <FormProvider formId={formId} initialValues={initialValues} onSubmit={onSubmit}>
      <FormBody backgroundColor={backgroundColor} vertical={vertical}>
        {children}
      </FormBody>
    </FormProvider>
  )
}

Form.propTypes = {
  /**
   * An optional background color for the form. Defaults to 'white'.
   */
  backgroundColor: PropTypes.oneOf(["light", "white"]),
  /**
   * The contents of the form.
   *
   * This should be constrained to components scoped to the Form
   * component (i.e. any of the "subcomponents": Form.Group, etc.),
   * though there may be exceptions for "display only" elements where
   * it fits the design system guidelines.
   */
  children: PropTypes.node.isRequired,
  /**
   * An optional DOM ID for the `<form>` element.
   */
  formId: PropTypes.string,
  /**
   * The initial values for fields in the form.
   *
   * For "create"-style forms, these will usually be blank or null
   * values. For "edit"-style forms, these are usually populated
   * using the current values from the model. In both cases, every
   * field in the form should be covered by an initial value to
   * prevent errors moving from an uncontrolled to a controlled
   * input.
   *
   * An exception is any field not transmitted to the server, such
   * as fields that exist to conditionally show other fields.
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to call when the form is submitted.
   *
   * Note that you do not have to handle the submitting state or
   * calling event.preventDefault as this is handled by the
   * Form component for you.
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Setting vertical to true positions field labels above their
   * respective fields.
   */
  vertical: PropTypes.bool,
}

Form.defaultProps = {
  backgroundColor: "white",
  vertical: false,
}

/**
 * `<FormProvider />` is useful when you need to render components requiring form context
 * outside of the form itself, for example, rendering a modal's submit button in the modal footer.
 *
 * Whenever the form's submit button is rendered outside the form element, be sure to use the `formId`
 * prop to associate the submit button back with the form.
 *
 * ```jsx
 * <Form.Provider formId="my-form">
 *   <Modal.Body>
 *     <Form.Body>
 *       // form fields here
 *     </Form.Body>
 *   </ Modal.Body>
 *   <Modal.Footer>
 *     <Form.SubmitButton />
 *   </ Modal.Footer>
 * </ Form.Provider>
 * ```
 */
function FormProvider({ children, formId, initialValues, onSubmit }) {
  return (
    <Formik validateOnBlur={false} validateOnChange={false} onSubmit={onSubmit} initialValues={initialValues}>
      <FormIdProvider id={formId}>{children}</FormIdProvider>
    </Formik>
  )
}

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  formId: Form.propTypes.formId,
  initialValues: Form.propTypes.initialValues,
  onSubmit: Form.propTypes.onSubmit,
}

function FormBody({ backgroundColor, children, vertical }) {
  const { handleReset, handleSubmit } = useFormikContext()
  const id = useFormIdContext()

  return (
    <BootstrapForm className={`bg-${backgroundColor}`} id={id} onReset={handleReset} onSubmit={handleSubmit}>
      <FormErrors />
      <LayoutProvider vertical={vertical}>{children}</LayoutProvider>
    </BootstrapForm>
  )
}

FormBody.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: Form.propTypes.backgroundColor,
  vertical: Form.propTypes.vertical,
}

FormBody.defaultProps = {
  backgroundColor: Form.defaultProps.backgroundColor,
  vertical: Form.defaultProps.vertical,
}

Form.ActionBar = ActionBar
Form.Autosave = Autosave
Form.Body = FormBody
Form.CancelButton = CancelButton
Form.CheckBox = CheckBox
Form.DatePicker = DatePicker
Form.Fieldset = Fieldset
Form.FileField = FileField
Form.Group = Group
Form.Label = Label
Form.MarkForDelete = MarkForDelete
Form.MultiSelectBox = MultiSelectBox
Form.Provider = FormProvider
Form.Radio = Radio
Form.RadioGroup = RadioGroup
Form.SelectBox = SelectBox
Form.Show = Show
Form.SignatureField = SignatureField
Form.SubmitButton = SubmitButton
Form.TextArea = TextArea
Form.TextField = TextField

export default Form
