import { Provider } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"

import makeCarePlanGoalsReducer from "~/components/carePlans/carePlanGoalsReducer"
import carePlanTasksReducer from "~/components/carePlans/carePlanTasksReducer"
import mcoGapsInCareReducer from "~/components/carePlans/mcoGapsInCareReducer"
import EncounterForm from "~/components/encounters/EncounterForm"
import flashMessageReducer from "~/flashMessages/flashMessageReducer"

const EncounterFormEntryPoint = ({ entities, globals, ...otherProps }) => {
  const store = configureStore({
    reducer: {
      entities: combineReducers({
        tasks: carePlanTasksReducer,
        goals: makeCarePlanGoalsReducer({ taskIdAdder: (taskIds, newTaskId) => [newTaskId, ...taskIds] }),
        mcoGapsInCare: mcoGapsInCareReducer,
        gapsForNewTaskLinkages: (state = {}) => state,
      }),
      flashMessages: flashMessageReducer,
      globals: (state = {}) => state,
    },
    preloadedState: {
      entities,
      globals,
    },
  })

  return (
    <Provider store={store}>
      <EncounterForm {...otherProps} />
    </Provider>
  )
}

export default EncounterFormEntryPoint
