import { connect } from "react-redux"

import { getFilteredCarePlanNeedIds } from "~/components/carePlans/carePlanSelectors"
import { getIsNewNeedFormOpen } from "~/components/carePlans/carePlanUISelectors"
import NeedContainer from "~/components/carePlans/NeedContainer"
import NewNeedForm from "~/components/carePlans/NewNeedForm"
import Jumbotron from "~/design/Jumbotron"

function NeedsList({ needIds, isNewNeedFormOpen }) {
  return (
    <div className="needs-list">
      {isNewNeedFormOpen && <NewNeedForm />}
      {needIds.length > 0 ? (
        needIds.map((needId) => <NeedContainer key={needId} needId={needId} />)
      ) : (
        <Jumbotron message="There are no care plan needs currently visible." />
      )}
    </div>
  )
}

export default connect((state) => ({
  needIds: getFilteredCarePlanNeedIds(state),
  isNewNeedFormOpen: getIsNewNeedFormOpen(state),
}))(NeedsList)
