import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import PropTypes from "prop-types"

import EditForm from "./components/EditForm"

import { ToastContainer } from "~/toast"
import types from "~/types"
import WorkflowDocumentsTable from "~/views/shared/childrensHCBS/WorkflowDocumentsTable"

const queryClient = new QueryClient()

function Edit({
  chart,
  childrensHCBSDisenrollmentReasons,
  childrensHCBSDisenrollmentStatuses,
  childrensHCBSWorkflow,
  childrensHCBSWorkflowStatuses,
  childrensHCBSWorkflowStepId,
  childrensHCBSWorkflowDocuments,
  documentTypes,
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <WorkflowDocumentsTable
        chart={chart}
        childrensHCBSWorkflowId={childrensHCBSWorkflow.id}
        childrensHCBSWorkflowStepId={childrensHCBSWorkflowStepId}
        childrensHCBSWorkflowDocuments={childrensHCBSWorkflowDocuments}
        documentTypes={documentTypes}
      />
      <EditForm
        childrensHCBSDisenrollmentReasons={childrensHCBSDisenrollmentReasons}
        childrensHCBSDisenrollmentStatuses={childrensHCBSDisenrollmentStatuses}
        childrensHCBSWorkflow={childrensHCBSWorkflow}
        childrensHCBSWorkflowStatuses={childrensHCBSWorkflowStatuses}
      />
      <ToastContainer />
    </QueryClientProvider>
  )
}

Edit.propTypes = {
  chart: types.chart.isRequired,
  childrensHCBSDisenrollmentReasons: PropTypes.arrayOf(types.selectOption).isRequired,
  childrensHCBSDisenrollmentStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  childrensHCBSWorkflow: types.childrensHCBSWorkflow.isRequired,
  childrensHCBSWorkflowStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  childrensHCBSWorkflowStepId: PropTypes.number.isRequired,
  childrensHCBSWorkflowDocuments: PropTypes.arrayOf(types.childrensHCBSWorkflowDocument).isRequired,
  documentTypes: PropTypes.arrayOf(types.documentType).isRequired,
}

export default Edit
