export const FLASH_MESSAGE_SECONDS = 5

export function flashMessage(key) {
  return (dispatch) => {
    dispatch(flashMessageStart(key))
    setTimeout(() => dispatch(flashMessageEnd(key)), FLASH_MESSAGE_SECONDS * 1000)
  }
}

export const FLASH_MESSAGE_START = "FLASH_MESSAGE_START"
function flashMessageStart(key) {
  return {
    type: FLASH_MESSAGE_START,
    key,
  }
}

export const FLASH_MESSAGE_END = "FLASH_MESSAGE_END"
function flashMessageEnd(key) {
  return {
    type: FLASH_MESSAGE_END,
    key,
  }
}
