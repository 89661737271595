import { createFilter } from "react-select"
import { isBefore, isValid, parseISO } from "date-fns"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import Form from "~/design/forms"
import types from "~/types"

// Patient::Segment::INITIAL_APPROPRIATENESS_MAPP_START_DATE
const INITIAL_APPROPRIATENESS_MAPP_START_DATE = new Date(2023, 11, 1) // December 1, 2023 (months are 0-indexed)

function InitialAppropriateness({ appropriatenessCriteriaCodes }) {
  const {
    values: { adultChildServicesCodeId, appropriatenessCriteriaCodeId, appropriatenessComments, startDate },
  } = useFormikContext()

  const selectedAppropriatenessCriteriaCode = appropriatenessCriteriaCodes.find(
    ({ id }) => id === appropriatenessCriteriaCodeId
  )

  const appropriatenessCriteriaCodeOptions = appropriatenessCriteriaCodes
    .filter((appropriatenessCode) => {
      if (
        adultChildServicesCodeId &&
        !appropriatenessCode.adultChildServicesCodes.some(({ id }) => id === adultChildServicesCodeId)
      ) {
        return false
      }

      // Additional filter by date
      if (startDate) {
        const parsedStartDate = parseISO(startDate)

        return (
          (!appropriatenessCode.enabledOn || parsedStartDate >= parseISO(appropriatenessCode.enabledOn)) &&
          (!appropriatenessCode.disabledOn || parsedStartDate < parseISO(appropriatenessCode.disabledOn))
        )
      }

      return true
    })
    .sort((codeA, codeB) => codeA.code - codeB.code)
    .map((code) => ({ value: code.id, label: code.name }))

  function isEnrolledSegmentOnOrAfterInitialAppropriatenessStartDate({
    values: { outreachEnrollmentCodeId, startDate, pended },
  }) {
    // Patient::OutreachEnrollmentCode::ENROLLED.id
    if (outreachEnrollmentCodeId !== 2 || pended) return false // Only show for enrolled not-pended segments

    const parsedStartDate = parseISO(startDate)

    return isValid(parsedStartDate) && !isBefore(parsedStartDate, INITIAL_APPROPRIATENESS_MAPP_START_DATE)
  }

  // Only use the option label for the search filter logic, instead of label and value
  const filterOption = createFilter({
    stringify: (option) => option.label,
  })

  return (
    <Form.Show when={isEnrolledSegmentOnOrAfterInitialAppropriatenessStartDate}>
      <Form.Fieldset title="Initial Appropriateness">
        <Form.Group name="appropriatenessCriteriaCodeId" errorKey="appropriatenessCriteriaCode">
          <Form.Label>Appropriateness Criteria</Form.Label>
          <Form.SelectBox isClearable filterOption={filterOption} options={appropriatenessCriteriaCodeOptions} />
        </Form.Group>
        <Form.Show
          when={() =>
            Boolean(selectedAppropriatenessCriteriaCode?.commentsRequired) || Boolean(appropriatenessComments)
          }
        >
          <Form.Group name="appropriatenessComments">
            <Form.Label>Comments</Form.Label>
            <Form.TextArea
              placeholder="Please enter specifications required for this selection."
              helpText={selectedAppropriatenessCriteriaCode?.commentsDescription}
            />
          </Form.Group>
        </Form.Show>
      </Form.Fieldset>
    </Form.Show>
  )
}

InitialAppropriateness.propTypes = {
  appropriatenessCriteriaCodes: PropTypes.arrayOf(types.appropriatenessCriteriaCode).isRequired,
}

export default InitialAppropriateness
