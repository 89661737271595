import { connect } from "react-redux"

import { getReadOnly } from "~/components/carePlans/globalsSelectors"
import IconButton from "~/design/buttons/IconButton"
import { EditIcon } from "~/design/icons"
import Show from "~/design/Show"

const EditButton = ({ onClick, readOnly, label }) => {
  return (
    <Show when={!readOnly}>
      <IconButton Icon={EditIcon} label={label} onClick={onClick} />
    </Show>
  )
}

export default connect((state) => ({ readOnly: getReadOnly(state) }))(EditButton)
