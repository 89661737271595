import { connect } from "react-redux"

import { getFilteredGoalIds } from "~/components/carePlans/carePlanNeedsSelectors"
import { getIsGoalsListOpen, getIsNewGoalFormOpen } from "~/components/carePlans/carePlanNeedsUISelectors"
import GoalContainer from "~/components/carePlans/GoalContainer"
import NewGoalForm from "~/components/carePlans/NewGoalForm"

function GoalsList({ goalIds, needId, isNewGoalFormOpen, isGoalsListOpen }) {
  return (
    <div className="goals-list">
      {isNewGoalFormOpen && <NewGoalForm needId={needId} />}
      {isGoalsListOpen && goalIds.map((goalId) => <GoalContainer key={goalId} goalId={goalId} />)}
    </div>
  )
}

export default connect((state, props) => ({
  isNewGoalFormOpen: getIsNewGoalFormOpen(state, props),
  isGoalsListOpen: getIsGoalsListOpen(state, props),
  goalIds: getFilteredGoalIds(state, props),
}))(GoalsList)
