import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_patient_medicaid_member_id_matches_path } from "~/routes"

const patientMedicaidMemberIdMatches = {
  get: function (searchString) {
    return submitAsyncRequest({
      url: api_v1_patient_medicaid_member_id_matches_path(),
      type: "get",
      data: { q: searchString },
    })
  },
}

export default patientMedicaidMemberIdMatches
