import { createSelector } from "reselect"

import {
  getAdultChildServicesCodeId,
  getDate,
  getEntityId,
  getHealthHomeIds,
  getSupervisorIds,
  getSupervisoryTeamIds,
} from "~/big3Dashboard/selectors/filtersSelector"

export const getEntities = ({ globals: { entities } }) => entities
export const getSelectedEntity = createSelector([getEntityId, getEntities], (entityId, entities) =>
  entities.find(({ id }) => id === entityId)
)

export const getHealthHomeQualityDashboardExportRoute = (state, routeFunction) => {
  const healthHomeId = getEntityId(state)
  const date = getDate(state)
  return routeFunction(healthHomeId, { filter: { date } })
}

export const getCareManagementAgencyQualityDashboardExportRoute = (state, routeFunction) => {
  const cmaId = getEntityId(state)
  const params = {
    health_home_ids: getHealthHomeIds(state),
    supervisor_ids: getSupervisorIds(state),
    supervisory_team_ids: getSupervisoryTeamIds(state),
    adult_child_services_code_id: getAdultChildServicesCodeId(state),
  }
  return routeFunction(cmaId, { filter: params })
}

export const getHealthHomes = ({ globals: { healthHomes } }) => healthHomes
export const getDefaultDate = ({ globals: { date } }) => date

export const getSupervisoryTeams = ({ globals: { supervisoryTeams } }) => supervisoryTeams
export const getSupervisors = ({ globals: { supervisors } }) => supervisors
export const getAdultChildServicesCodes = ({ globals: { adultChildServicesCodes } }) => adultChildServicesCodes
