import PropTypes from "prop-types"

const chartDataCopyRecord = PropTypes.shape({
  copiedFromId: PropTypes.number.isRequired,
  copiedFromType: PropTypes.string.isRequired,
  copiedFromModelName: PropTypes.string.isRequired,
  copiedFromDescription: PropTypes.string.isRequired,
  copiedFromPath: PropTypes.string.isRequired,
  copiedFromCreatedAt: PropTypes.string.isRequired,
  copiedFromUpdatedAt: PropTypes.string.isRequired,
})

export default chartDataCopyRecord
