import PropTypes from "prop-types"

const pdfAssessment = PropTypes.shape({
  id: PropTypes.number,
  chartId: PropTypes.number,
  completionDate: PropTypes.string,
  patientAssessmentOccasionId: PropTypes.number,
  uploadedFile: PropTypes.shape({ url: PropTypes.string }),
})

export default pdfAssessment
