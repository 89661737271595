import { connect } from "react-redux"
import { useFormikContext } from "formik"
import pluralize from "pluralize"

import { getCarePlanGoals } from "~/components/carePlans/carePlanGoalsSelectors"
import CarePlanGoals from "~/components/encounters/CarePlanGoals"
import CarePlanMessageWrapper from "~/components/encounters/CarePlanMessageWrapper"
import { care_plan_path } from "~/routes"

const CarePlanDetails = ({ carePlan, goals }) => {
  const {
    values: { carePlanTaskIds, chartId },
  } = useFormikContext()
  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        {carePlan && (
          <a href={care_plan_path(carePlan.id)} target="_blank">
            View Care Plan&nbsp;
            <i className="fa fa-external-link" />
          </a>
        )}
      </div>
      <div className="d-flex justify-content-between mb-2">
        <small>Select all tasks mentioned in this Encounter:</small>
        <strong>{pluralize("task", carePlanTaskIds.length, true)} selected</strong>
      </div>
      <CarePlanMessageWrapper carePlan={carePlan} chartId={chartId}>
        <CarePlanGoals goals={goals} editable={true} />
      </CarePlanMessageWrapper>
    </>
  )
}

const mapStateToProps = (state, { carePlan }) => ({
  goals: getCarePlanGoals(state, { carePlan }),
})

export default connect(mapStateToProps)(CarePlanDetails)
