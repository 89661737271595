import { Form as BootstrapForm, Row as BootstrapRow } from "react-bootstrap"
import PropTypes from "prop-types"

import { DEFAULT_CONTROL_WIDTH, DEFAULT_LABEL_WIDTH } from "./constants"
import { ErrorProvider } from "./useErrorContext"
import useLayout, { LayoutWrapper } from "./useLayoutContext"
import { NameProvider } from "./useNameContext"

function RadioGroup({ compact, name, errorKey, children, width, offset }) {
  const { vertical } = useLayout()
  return (
    <NameProvider name={name}>
      <ErrorProvider errorKey={errorKey} name={name}>
        <BootstrapForm.Group className={compact ? "mb-1" : undefined} as={vertical ? undefined : BootstrapRow}>
          <LayoutWrapper sm={{ span: width, offset }}>{children}</LayoutWrapper>
        </BootstrapForm.Group>
      </ErrorProvider>
    </NameProvider>
  )
}

RadioGroup.propTypes = {
  /**
   * The name of the field as assigned by the parent form.
   */
  name: PropTypes.string.isRequired,
  /**
   * The content of the radio group. This should be one or more
   * <Radio /> components. The radio group is responsible for
   * propagating the field name to all of the options.
   */
  children: PropTypes.node.isRequired,
  /**
   * Reduce the spacing between form fields.
   */
  compact: PropTypes.bool,
  /**
   * A custom value to use when looking for errors returned by the server.
   *
   * This is useful for scenarios such as presence validations on an
   * association rather than a foreign key. This can cause the errors to
   * be returned under the association name rather than the field name
   * e.g. endDateReasonCode rather than endDateReasonCodeId.
   */
  errorKey: PropTypes.string,
  /**
   * The number of columns to the control so that it aligns correctly in horizontal forms.
   */
  offset: PropTypes.number,
  /**
   * The number of columns the control should span.
   */
  width: PropTypes.number,
}

RadioGroup.defaultProps = {
  compact: false,
  width: DEFAULT_CONTROL_WIDTH,
  offset: DEFAULT_LABEL_WIDTH,
}

export default RadioGroup
