import { Button, Spinner } from "react-bootstrap"
import PropTypes from "prop-types"

const COLORS = {
  danger: "text-danger",
}

/**
 * A `<LinkButton />` is used for scenarios where you need a semantic button
 * (any non-navigation event) but would like it to be styled like an HTML link.
 *
 * If the `onClick` action changes the user's history (a navigation event), you
 * should use one of the more semantically appropriate components.
 */
function LinkButton({ children, color, disabled, isLoading, loadingText = "Loading...", onClick, size }) {
  return (
    <Button
      disabled={disabled || isLoading}
      onClick={onClick}
      size={size}
      type="button"
      variant="link"
      className={COLORS[color]}
    >
      {isLoading ? (
        <span className="d-flex align-items-center gap-1">
          <Spinner as="span" size="sm" animation="border" role="status" />
          {loadingText}
        </span>
      ) : (
        children
      )}
    </Button>
  )
}

LinkButton.propTypes = {
  /**
   * The content of the button.
   */
  children: PropTypes.node.isRequired,
  /**
   * Pass a color to override the default link color.
   */
  color: PropTypes.oneOf(Object.keys(COLORS)),
  /**
   * A disabled button receives no pointer events.
   */
  disabled: PropTypes.bool,
  /**
   * Adds a spinner and disables the button to indicate a loading state.
   */
  isLoading: PropTypes.bool,
  /**
   * When `isLoading` is true, display this text with the spinner.
   */
  loadingText: PropTypes.string,
  /**
   * The action to take when the button is clicked.
   */
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["sm", "lg"]),
}

export default LinkButton
