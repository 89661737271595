import PropTypes from "prop-types"

import IconButton from "~/design/buttons/IconButton"
import { ChildIcon, ExclamationTriangleIcon, ExpandIcon, ShieldIcon } from "~/design/icons"

function PreviewSocialBackgroundWidget() {
  return (
    <div className="border bg-light mb-3 p-3">
      <EmptySideBarToggle title="Strengths" HeaderIcon={ChildIcon} />
      <EmptySideBarToggle title="Barriers" HeaderIcon={ShieldIcon} />
      <EmptySideBarToggle title="Risk Factors" HeaderIcon={ExclamationTriangleIcon} />
    </div>
  )
}

function EmptySideBarToggle({ HeaderIcon, title }) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <h3 className="font-weight-bold mb-3">
        {HeaderIcon ? <HeaderIcon /> : null} {title}
      </h3>
      <span className="mb-3">
        <IconButton Icon={ExpandIcon} disabled={true} />
      </span>
    </div>
  )
}

EmptySideBarToggle.propTypes = {
  HeaderIcon: PropTypes.elementType,
  title: PropTypes.string.isRequired,
}

export default PreviewSocialBackgroundWidget
