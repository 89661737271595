import PropTypes from "prop-types"

import MedicationsFormFields from "./MedicationsFormFields"

import Form from "~/design/forms"
import Modal from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function MedicationsForm({ chart, medications, onClose, onUpdate, show }) {
  async function onSubmit(values, { setErrors, setStatus }) {
    try {
      const updatedMedications = await api.bulkMedicationsUpdates.post(chart.id, values.medications)
      onUpdate(updatedMedications)
      setStatus("success")
      toast.success("Medications successfully updated.")
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Modal show={show} onClose={onClose} title="Edit Medication" size="xl">
      <Form.Provider initialValues={{ medications }} onSubmit={onSubmit} formId="medications-modal-form">
        <Modal.Body>
          <Form.Body vertical>
            <MedicationsFormFields />
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Save" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

MedicationsForm.propTypes = {
  medications: PropTypes.objectOf(types.medication).isRequired,
  chart: types.chart.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default MedicationsForm
