import CareManagementAgencyQualityContainer from "~/big3Dashboard/CareManagementAgencyQualityContainer"
import activeTabReducer from "~/big3Dashboard/reducers/activeTabReducer"
import paramsReducer from "~/big3Dashboard/reducers/paramsReducer"
import qualityReportEntitiesReducer from "~/big3Dashboard/reducers/qualityReportEntitiesReducer"
import qualityReportStatusReducer from "~/big3Dashboard/reducers/qualityReportStatusReducer"
import { wrapper } from "~/redux"

const mapPropsToPreloadedState = ({
  globals: { careManagementAgencies, healthHomes, supervisoryTeams, supervisors, date, adultChildServicesCodes },
  careManagementAgencyId,
  activeTabId,
  healthHomeIds,
  supervisoryTeamIds,
  supervisorIds,
  adultChildServicesCodeId,
}) => ({
  params: {
    reportRowEntityIds: [],
    entityId: careManagementAgencyId,
    healthHomeIds: healthHomeIds.map((id) => parseInt(id, 10)),
    supervisoryTeamIds: supervisoryTeamIds.map((id) => parseInt(id, 10)),
    supervisorIds: supervisorIds.map((id) => parseInt(id, 10)),
    adultChildServicesCodeId: parseInt(adultChildServicesCodeId, 10) || null,
  },
  globals: {
    entities: careManagementAgencies,
    healthHomes,
    supervisoryTeams,
    supervisors,
    date,
    adultChildServicesCodes,
  },
  activeTabId: parseInt(activeTabId, 10),
})

export default wrapper(
  CareManagementAgencyQualityContainer,
  {
    status: qualityReportStatusReducer,
    entities: qualityReportEntitiesReducer,
    params: paramsReducer,
    activeTabId: activeTabReducer,
  },
  mapPropsToPreloadedState
)
