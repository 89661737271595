import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { chart_documents_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"
import ChartIndicator from "~/views/shared/ChartIndicator"

function New({ chart, healthHomeOptOut }) {
  async function handleSubmit(values, { setStatus, setErrors }) {
    try {
      await api.healthHomeOptOuts.post(healthHomeOptOut.chartId, values)
      setStatus("success")
      redirectTo(chart_documents_path(healthHomeOptOut.chartId))
    } catch ({ errors }) {
      setErrors(errors)
    }
  }
  return (
    <Form initialValues={healthHomeOptOut} onSubmit={handleSubmit}>
      <Form.Group name="uploadedFile">
        <Form.Label>Document</Form.Label>
        <Form.FileField />
      </Form.Group>
      <Form.Group name="dateOfOptOut">
        <Form.Label>Date of Opt-out</Form.Label>
        <Form.DatePicker />
      </Form.Group>

      <ChartIndicator chart={chart} />

      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_documents_path(healthHomeOptOut.chartId))} />
            <Form.SubmitButton label="Create Health Home Opt-out" />
          </>
        }
      />
    </Form>
  )
}

New.propTypes = {
  chart: types.chart.isRequired,
  healthHomeOptOut: types.healthHomeOptOut.isRequired,
}

export default New
