import { useEffect } from "react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import PopoverButton from "~/components/PopoverButton"
import Form from "~/design/forms"
import types from "~/types"

function DocumentFields({ documentTypes, documentCategories }) {
  const {
    values: { categoryId },
    setFieldValue,
  } = useFormikContext()

  const visibleDocumentTypes = documentTypes
    .filter((documentType) => documentType.documentCategoryId === categoryId)
    .map((documentType) => ({
      label: documentType.name,
      value: documentType.id,
    }))

  useEffect(() => {
    if (!categoryId) {
      setFieldValue("documentTypeId", undefined)
    }
  }, [categoryId, setFieldValue])

  function otherDocumentSelection({ values: { documentTypeId } }) {
    const selectedType = documentTypes.find((documentType) => documentType.id === documentTypeId)

    return Boolean(selectedType?.allowsUserDocumentName)
  }

  return (
    <>
      <Form.Group name="categoryId">
        <Form.Label>Category</Form.Label>
        <Form.SelectBox options={documentCategories} isClearable />
      </Form.Group>
      <Form.Group name="documentTypeId">
        <Form.Label>Type</Form.Label>
        <Form.SelectBox options={visibleDocumentTypes} isClearable />
      </Form.Group>
      <Form.Show when={otherDocumentSelection}>
        <Form.Group name="otherDocumentTypeName">
          <Form.Label>Other</Form.Label>
          <Form.TextField />
        </Form.Group>
      </Form.Show>
      <Form.Group name="description">
        <Form.Label>Description</Form.Label>
        <Form.TextField />
      </Form.Group>
      <Form.Group name="date">
        <Form.Label>
          Document Date&nbsp;
          <PopoverButton title="Document Date" label="?">
            If this document does not have a “Signed On” date, please enter today’s date for this field.
          </PopoverButton>
        </Form.Label>
        <Form.DatePicker />
      </Form.Group>
    </>
  )
}

DocumentFields.propTypes = {
  documentTypes: PropTypes.arrayOf(types.documentType).isRequired,
  documentCategories: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default DocumentFields
