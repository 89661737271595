import pluralize from "pluralize"
import PropTypes from "prop-types"
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from "recharts"

import useEndDateReasonCodeSummaryReport from "../hooks/useEndDateReasonCodeSummaryReport"

import Jumbotron from "~/design/Jumbotron"
import { formatCompactNumber, formatNumberWithCommas, formatPercentage } from "~/numberHelpers"
import ChartContainer from "~/views/shared/graphicalCharts/ChartContainer"
import ChartLoadingSpinner from "~/views/shared/graphicalCharts/ChartLoadingSpinner"

function Report() {
  const { data, isLoading } = useEndDateReasonCodeSummaryReport()

  if (isLoading) {
    return <ChartLoadingSpinner />
  }

  if (data.length === 0) {
    return <Jumbotron message="No segments found" />
  }

  const totalCount = data.reduce((total, { count }) => total + count, 0)

  return (
    <ChartContainer>
      <BarChart data={data}>
        <XAxis
          dataKey="code"
          interval={0}
          label={{ value: "End date reason code", offset: -2, position: "insideBottom" }}
        />
        <YAxis tickFormatter={formatCompactNumber} />
        <Tooltip content={<CustomTooltip totalCount={totalCount} />} />
        <Bar dataKey="count" fill="var(--primary)">
          {data.map(({ code }) => (
            <Cell key={code} aria-label={`Segments for end date reason code ${code}`} />
          ))}
        </Bar>
      </BarChart>
    </ChartContainer>
  )
}

function CustomTooltip({ active, payload, label, totalCount }) {
  if (active && payload && payload.length) {
    const count = payload[0].value
    const description = payload[0].payload.description

    return (
      <div className="bg-white border p-2 text-center">
        <div style={{ fontSize: "1rem" }}>
          {label} - {description}
        </div>
        <div>
          {formatNumberWithCommas(count)} {pluralize("segment", count)}
        </div>
        <div>of {formatNumberWithCommas(totalCount)} total</div>
        <div>({formatPercentage(count / totalCount, { maximumFractionDigits: 2 })})</div>
      </div>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      payload: PropTypes.shape({
        description: PropTypes.string,
      }),
    })
  ).isRequired,
  label: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
}

export default Report
