import { connect } from "react-redux"

import { getNeed } from "~/components/carePlans/carePlanNeedsSelectors"
import { getCarePlanNeedStatuses } from "~/components/carePlans/globalsSelectors"
import GoalsList from "~/components/carePlans/GoalsList"
import NeedCard from "~/components/carePlans/NeedCard"
import { selectedOption } from "~/reactSelectHelpers"

const NeedContainer = ({ need, carePlanNeedStatuses }) => {
  const { carePlanNeedStatusId } = need
  const status = selectedOption(carePlanNeedStatuses, carePlanNeedStatusId)

  return (
    <div className="need-container">
      <NeedCard need={need} status={status} />
      <GoalsList needId={need.id} />
    </div>
  )
}

export default connect((state, props) => ({
  need: getNeed(state, props),
  carePlanNeedStatuses: getCarePlanNeedStatuses(state),
}))(NeedContainer)
