import { YES_NO_OPTIONS } from "../constants"

import QuestionCard from "./QuestionCard"
import Section from "./Section"

import Form from "~/design/forms"

function HealthHomePlusSection() {
  return (
    <Section id="ces-tool-section-health-home-plus-eligibility" title="Health Home Plus Eligibility">
      <p className="mb-3">
        Members who are eligible for any HH+ program are exempt from the DOH CES Tool requirements. All Health Home
        members have their HH+ Eligibility documented monthly on their Billing Support Questionnaire.
      </p>
      <p className="mb-3">
        If you are not sure if this member is eligible for a HH+ program, you may consult these state policies:{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.health.ny.gov/health_care/medicaid/program/medicaid_health_homes/special_populations/docs/hh_plus_high_need_smi_guidance.pdf"
        >
          Health Home Plus Program Guidance for High-Need Individuals with Serious Mental Illness
        </a>{" "}
        and{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.health.ny.gov/health_care/medicaid/program/medicaid_health_homes/special_populations/docs/health_home_plus_for_hiv.pdf"
        >
          Health Home Plus Program Guidance for Individuals with HIV
        </a>
        .
      </p>
      <QuestionCard questionNumber={1}>
        <Form.Group name="memberIsEligibleForHhPlus">
          <Form.Label>Is the member eligible for HH+ SMI, HH+ HIV, AOT, or AH+?</Form.Label>
          <Form.SelectBox isClearable options={YES_NO_OPTIONS} />
        </Form.Group>
      </QuestionCard>
    </Section>
  )
}

export default HealthHomePlusSection
