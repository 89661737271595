import { createColumnHelper } from "@tanstack/react-table"

import { care_manager_dashboard_path } from "~/routes"

const columnHelper = createColumnHelper()

const careManagerColumn = columnHelper.accessor(({ entity }) => entity.name.toLowerCase(), {
  header: "Care Manager",
  id: "entity",
  cell: ({ row }) => {
    const { entity } = row.original
    const header = entity.id ? (
      <a href={care_manager_dashboard_path({ care_manager: { id: entity.id } })}>{entity.name}</a>
    ) : (
      <p>{entity.name}</p>
    )

    return header
  },
})

export default careManagerColumn
