import { Jumbotron as ReactBootstrapJumbotron } from "react-bootstrap"
import PropTypes from "prop-types"

import Show from "./Show"

function Jumbotron({ message, children }) {
  return (
    <ReactBootstrapJumbotron>
      <div style={{ fontSize: "1.25rem" }}>{message}</div>
      <Show when={Boolean(children)}>
        <div className="mt-3">{children}</div>
      </Show>
    </ReactBootstrapJumbotron>
  )
}

Jumbotron.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Jumbotron
