import { useMemo } from "react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import ShowComponent from "~/design/Show"

/**
 * `<Show />` is used to conditionally show a component or set of
 * components based on the value of a supplied predicate.
 *
 * Unlike the basic `<Show />` component, a `<Form.Show />` instance has
 * access to a subset of the form context, which is passed to the predicate
 * you provide. This allows you to base your condition on the state of the
 * form without additional hooks or component layers.
 */
function Show({ when, children }) {
  const { initialValues, values } = useFormikContext()
  const pred = useMemo(() => when({ initialValues, values }), [when, initialValues, values])
  return <ShowComponent when={pred}>{children}</ShowComponent>
}

Show.propTypes = {
  /**
   * A predicate that accepts (a limited) Formik context and returns
   * true if the content should be shown or false otherwise.
   *
   * Currently, only the form values are passed to the predicate
   * function.
   */
  when: PropTypes.func.isRequired,
  /**
   * The content to conditionally display.
   */
  children: PropTypes.node,
}

export default Show
