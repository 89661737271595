import { Col, Row } from "react-bootstrap"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import ColorOption from "./ColorOption"

import Form from "~/design/forms"
import { DEFAULT_CONTROL_WIDTH, DEFAULT_LABEL_WIDTH } from "~/design/forms/constants"
import Show from "~/design/Show"
import types from "~/types"

function FormFields({ colorOptions }) {
  const {
    values: { abbreviation, color, textColor },
  } = useFormikContext()

  return (
    <>
      <Row className="mb-3">
        <Col sm={{ offset: DEFAULT_LABEL_WIDTH, span: DEFAULT_CONTROL_WIDTH }}>
          <span className="py-2">
            <strong>Preview: </strong>
            <Show when={abbreviation?.length > 0}>
              <span className="badge" style={{ color: textColor, backgroundColor: color }}>
                {abbreviation}
              </span>
            </Show>
          </span>
        </Col>
      </Row>
      <Form.Group name="name">
        <Form.Label>Name</Form.Label>
        <Form.TextField />
      </Form.Group>
      <Form.Group name="abbreviation">
        <Form.Label>Abbreviation</Form.Label>
        <Form.TextField />
      </Form.Group>
      <Form.Group name="description">
        <Form.Label>Description</Form.Label>
        <Form.TextArea />
      </Form.Group>
      <Form.Group name="textColor">
        <Form.Label>Text color</Form.Label>
        <Form.SelectBox options={colorOptions} formatOptionLabel={(option) => <ColorOption option={option} />} />
      </Form.Group>
      <Form.Group name="color">
        <Form.Label>Color</Form.Label>
        <Form.SelectBox options={colorOptions} formatOptionLabel={(option) => <ColorOption option={option} />} />
      </Form.Group>
    </>
  )
}

FormFields.propTypes = {
  colorOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default FormFields
