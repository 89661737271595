import { Col, Row } from "react-bootstrap"
import _ from "lodash"
import pluralize from "pluralize"
import PropTypes from "prop-types"

import Badge from "~/components/badge"
import formattedDate from "~/dateHelpers"
import Jumbotron from "~/design/Jumbotron"
import SelectableBox from "~/views/shared/SelectableBox"

const ClinicalEventDetails = ({ clinicalEventIds, clinicalEvents, chartId }) => {
  if (_.isEmpty(clinicalEvents)) {
    return (
      <Jumbotron message="This patient does not have any clinical events with this encounter's Health Home and CMA." />
    )
  }

  if (clinicalEvents.some((clinicalEvent) => clinicalEvent.chartId !== chartId)) {
    return (
      <Jumbotron message="Clinical events can only be linked to encounters associated with the same Health Home and CMA." />
    )
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <small>Select all clinical events mentioned in this Encounter:</small>
        <strong>{pluralize("clinical event", clinicalEventIds.length, true)} selected</strong>
      </div>
      <div className="bg-white border rounded p-3 overflow-auto" style={{ maxHeight: "600px" }}>
        {clinicalEvents.map((clinicalEvent) => (
          <div key={clinicalEvent.id} data-testid={`clinical-event-${clinicalEvent.id}`}>
            <SelectableBox name="clinicalEventIds" value={clinicalEvent.id}>
              <Row noGutters className="py-1">
                <Col sm={3}>
                  <strong>Clinical Event</strong>
                </Col>
                <Col sm={3}>{pluralize("alert", clinicalEvent.clinicalAlerts.length, true)}</Col>
                <Col sm={6}>
                  <div className="d-sm-flex justify-content-end">
                    <div>
                      <span className="text-muted">Created On </span>{" "}
                      {formattedDate(clinicalEvent.initialAlertReceivedAt)}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row noGutters className="pt-2">
                <Col>
                  <ul>
                    {clinicalEvent.clinicalAlerts.map((clinicalAlert) => (
                      <li key={clinicalAlert.id} className="mb-1">
                        {formattedDate(clinicalAlert.occurredAt)}
                        {" - "}
                        <Badge variant={clinicalAlert.visitType.color}>{clinicalAlert.visitType.name}</Badge>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </SelectableBox>
          </div>
        ))}
      </div>
    </>
  )
}

ClinicalEventDetails.propTypes = {
  clinicalEventIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  chartId: PropTypes.number.isRequired,
  clinicalEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      chartId: PropTypes.number.isRequired,
      initialAlertReceivedAt: PropTypes.string.isRequired,
      clinicalAlerts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          occurredAt: PropTypes.string.isRequired,
          visitType: PropTypes.shape({
            color: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        })
      ),
    })
  ),
}

export default ClinicalEventDetails
