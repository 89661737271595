import { Col, Row } from "react-bootstrap"
import classNames from "classnames"
import { useFormikContext } from "formik"
import _ from "lodash"
import PropTypes from "prop-types"
import uniqid from "uniqid"

import DividerList from "~/components/DividerList"
import { LinkButton } from "~/design/buttons"
import IconButton from "~/design/buttons/IconButton"
import Form from "~/design/forms"
import CloseIcon from "~/design/icons/CloseIcon"
import Show from "~/design/Show"
import types from "~/types"

function RiskFactorsFormFields({ categories }) {
  const {
    values: { riskFactors },
    setFieldValue,
  } = useFormikContext()

  function removeUnpersistedRiskFactor(id) {
    setFieldValue("riskFactors", _.omit(riskFactors, id))
  }

  function addRiskFactor() {
    const newRiskFactorKey = _.camelCase(uniqid())
    setFieldValue("riskFactors", {
      ...riskFactors,
      [newRiskFactorKey]: {
        categoryId: null,
        description: "",
        active: false,
      },
    })
  }

  const [persistedRiskFactorIds, unpersistedRiskFactorIds] = _.partition(
    Object.keys(riskFactors),
    (id) => riskFactors[id].id
  )

  return (
    <>
      <Show when={persistedRiskFactorIds.length > 0}>
        <DividerList Divider={() => <hr className="mt-0 mb-3" />}>
          {persistedRiskFactorIds.map((id) => {
            const disabled = riskFactors[id].wasDeleted
            return (
              <div
                data-testid={`risk-factors-form-fields-${_.kebabCase(id)}`}
                key={id}
                className={classNames({ "bg-light": disabled })}
              >
                <div className="d-none d-md-block mb-3">Risk Factor</div>
                <Row>
                  <Col md={4}>
                    <Form.Group name={`riskFactors.${id}.categoryId`}>
                      <Form.Label>
                        Category
                        <span className="text-danger">
                          &nbsp;*<span className="sr-only">Required</span>
                        </span>
                      </Form.Label>
                      <Form.SelectBox disabled={disabled} options={categories} placeholder="Select a risk category" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={8}>
                    <Form.Group name={`riskFactors.${id}.description`}>
                      <Form.Label hidden="md">Risk Factor</Form.Label>
                      <Form.TextArea disabled={disabled} placeholder="Enter risk factor description" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group name={`riskFactors.${id}.active`}>
                      <Form.CheckBox disabled={disabled} label="Active" />
                    </Form.Group>
                  </Col>
                  <Col md={1}>
                    <Form.Group name={`riskFactors.${id}.wasDeleted`}>
                      <Form.MarkForDelete />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            )
          })}
        </DividerList>
      </Show>
      <h2 className="my-3">Add Risk Factors</h2>
      <Show when={unpersistedRiskFactorIds.length > 0}>
        <div className="d-none d-md-block mb-3">Risk Factor</div>
        <DividerList Divider={() => <hr className="mt-0 mb-3" />}>
          {unpersistedRiskFactorIds.map((id) => (
            <div data-testid={`risk-factors-form-fields-${_.kebabCase(id)}`} key={id}>
              <Row>
                <Col md={4}>
                  <Form.Group clearOnHide={false} name={`riskFactors.${id}.categoryId`}>
                    <Form.Label>
                      Category
                      <span className="text-danger">
                        &nbsp;*<span className="sr-only">Required</span>
                      </span>
                    </Form.Label>
                    <Form.SelectBox options={categories} placeholder="Select a risk category" />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={8}>
                  <Form.Group clearOnHide={false} name={`riskFactors.${id}.description`}>
                    <Form.Label hidden="md">Risk Factor</Form.Label>
                    <Form.TextArea placeholder="Enter risk factor description" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group clearOnHide={false} name={`riskFactors.${id}.active`}>
                    <Form.CheckBox label="Active" />
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <div className="mb-3">
                    <IconButton
                      Icon={CloseIcon}
                      label="Remove risk factor row"
                      onClick={() => removeUnpersistedRiskFactor(id)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </DividerList>
      </Show>
      <div className="mt-3">
        <LinkButton onClick={addRiskFactor}>Add {riskFactors.length > 0 ? "another " : ""}risk factor</LinkButton>
      </div>
    </>
  )
}

RiskFactorsFormFields.propTypes = {
  categories: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default RiskFactorsFormFields
