import ReactDOM from "react-dom"

import DividerList from "~/components/DividerList"
import { LinkButton } from "~/design/buttons"

function PatientEncountersActionBar({ collapseAll, expandAll, areAllCollapsed, areAllExpanded }) {
  const performActionOnClick = (action) => (e) => {
    e.preventDefault()
    action()
  }

  const node = document.getElementById("patient-encounters-action-bar-collapse-selector")

  return ReactDOM.createPortal(
    <DividerList>
      <span>
        <i className="fa fa-expand p-1 text-dark" />
        <LinkButton onClick={performActionOnClick(expandAll)} disabled={areAllExpanded}>
          Show all
        </LinkButton>
      </span>
      <LinkButton onClick={performActionOnClick(collapseAll)} disabled={areAllCollapsed}>
        Collapse all
      </LinkButton>
    </DividerList>,
    node
  )
}

export default PatientEncountersActionBar
