import _ from "lodash"
import { combineReducers } from "redux"

import carePlanActiveFilterReducer from "~/components/carePlans/carePlanActiveFilterReducer"
import CarePlanBody from "~/components/carePlans/CarePlanBody"
import makeCarePlanGoalsReducer from "~/components/carePlans/carePlanGoalsReducer"
import carePlanGoalsUiReducer from "~/components/carePlans/carePlanGoalsUiReducer"
import carePlanNeedCategoryFilterReducer from "~/components/carePlans/carePlanNeedCategoryFilterReducer"
import carePlanNeedsReducer from "~/components/carePlans/carePlanNeedsReducer"
import carePlanNeedsUiReducer from "~/components/carePlans/carePlanNeedsUiReducer"
import carePlanNewNeedFormReducer from "~/components/carePlans/carePlanNewNeedFormReducer"
import carePlanReducer from "~/components/carePlans/carePlanReducer"
import carePlanTasksReducer from "~/components/carePlans/carePlanTasksReducer"
import mcoGapsInCareReducer from "~/components/carePlans/mcoGapsInCareReducer"
import flashMessageReducer from "~/flashMessages/flashMessageReducer"
import { wrapper } from "~/redux"

const mapPropsToPreloadedState = (props) => ({
  ui: {
    needs: _.mapValues(props.entities.needs, () => ({
      isGoalsListOpen: true,
      isNewGoalFormOpen: false,
    })),
    goals: _.mapValues(props.entities.goals, () => ({
      isTasksListOpen: true,
      isNewTaskFormOpen: false,
    })),
  },
})

export default wrapper(
  CarePlanBody,
  {
    entities: combineReducers({
      carePlan: carePlanReducer,
      needs: carePlanNeedsReducer,
      goals: makeCarePlanGoalsReducer({}),
      tasks: carePlanTasksReducer,
      mcoGapsInCare: mcoGapsInCareReducer,
      gapsForNewTaskLinkages: (state = {}) => state,
    }),
    flashMessages: flashMessageReducer,
    ui: combineReducers({
      activeFilterState: carePlanActiveFilterReducer,
      filteredNeedCategoryId: carePlanNeedCategoryFilterReducer,
      isNewNeedFormOpen: carePlanNewNeedFormReducer,
      needs: carePlanNeedsUiReducer,
      goals: carePlanGoalsUiReducer,
    }),
  },
  mapPropsToPreloadedState
)
