import { useState } from "react"

import CarePlanTask from "~/components/encounters/CarePlanTask"
import NewTaskForm from "~/components/linkedCarePlanTasks/NewTaskForm"
import { SecondaryButton } from "~/design/buttons"
import Show from "~/design/Show"

function CarePlanGoal({ goal, carePlan, editable }) {
  const [showModal, setShowModal] = useState(false)
  const goalCategoryName = goal.categoryName ? goal.categoryName : "UNCATEGORIZED"

  return (
    <div data-testid={`goal-${goal.id}`}>
      <div className="text-muted my-2">{`${goalCategoryName} GOAL`.toUpperCase()}</div>
      <div className="d-sm-flex">
        <div className="flex-grow-1">{goal.description}</div>
        <Show when={editable}>
          <SecondaryButton onClick={() => setShowModal(true)} size="sm">
            Add Task
          </SecondaryButton>
        </Show>
      </div>
      <div className="text-muted my-2">ACTIVE TASKS</div>
      {goal.taskIds.map((taskId) => (
        <CarePlanTask taskId={taskId} goal={goal} editable={editable} key={taskId} />
      ))}
      <NewTaskForm
        show={showModal}
        onClose={() => setShowModal(false)}
        defaultSelectedGoal={goal}
        carePlan={carePlan}
      />
    </div>
  )
}

export default CarePlanGoal
