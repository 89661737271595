import { Badge as ReactBootstrap4Badge } from "react-bootstrap"
import classnames from "classnames"
import PropTypes from "prop-types"

const REACT_BOOTSTRAP_4_VARIANTS = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]

// Custom variants are defined in app/assets/stylesheets/application/badge.scss
// following the format `badge-${variant}`
const CUSTOM_VARIANTS = [
  "white",
  "light-gray",
  "light-orange",
  "lighter-orange",
  "light-cyan",
  "lighter-cyan",
  "light-blue",
  "lighter-blue",
  "light-purple",
  "lighter-purple",
  "light-pink",
  "lighter-pink",
]

const Badge = ({ variant = "white", bold = false, pill = false, className, children, ...otherProps }) => {
  const customVariantClass = CUSTOM_VARIANTS.includes(variant) ? `badge-${variant}` : null

  return (
    <ReactBootstrap4Badge
      className={classnames(customVariantClass, className, { "font-weight-normal": !bold })}
      variant={!customVariantClass ? variant : undefined}
      pill={pill}
      {...otherProps}
    >
      {children}
    </ReactBootstrap4Badge>
  )
}

Badge.propTypes = {
  /**
   * Use a Bootstrap variant of 'warning', 'success', 'secondary' or 'danger' for most badges.
   * Use a custom variant like 'light-cyan' or 'lighter-purple' for clinical alert badges.
   */
  variant: PropTypes.oneOf(REACT_BOOTSTRAP_4_VARIANTS.concat(CUSTOM_VARIANTS)),
  /**
   * Badges have normal-weight font by default. Bold should only be used for smaller badges,
   * where the badge only displays a few characters of text, like a help popover badge.
   */
  bold: PropTypes.bool,
  /**
   * Gives badges very round edges. Used only for MCO gap in care badge.
   */
  pill: PropTypes.bool,
  /**
   * Used to add additional styling (i.e. Bootstrap spacing utility classes).
   */
  className: PropTypes.string,
  /**
   * The contents of the badge. This should either be text or an icon + text.
   */
  children: PropTypes.node.isRequired,
}

export default Badge
