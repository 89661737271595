import { connect } from "react-redux"
import pluralize from "pluralize"
import PropTypes from "prop-types"

import { sectionDestroyed, sectionSelectors, sectionUpdated } from "../redux/sectionsSlice"

import Card from "./Card"
import SchemaSectionFormFields from "./SchemaSectionFormFields"

import { LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function EditSchemaSectionForm({ section = {}, sectionDestroyedConnect, sectionUpdatedConnect, sectionIndex }) {
  const defaultSection = {
    description: section.description || "",
    sectionName: section.sectionName || "",
  }

  async function handleSubmit(sectionFormData, { setErrors }) {
    try {
      const updatedSection = await api.customAssessments.schemaSections.patch(section.id, sectionFormData)
      sectionUpdatedConnect(updatedSection)
      toast.success("Section updated successfully.")
    } catch ({ errors }) {
      toast.error("Section could not be updated.")
      setErrors(errors)
    }
  }

  async function handleDelete() {
    try {
      await api.customAssessments.schemaSections.delete(section.id)
      toast.success("Section deleted successfully.")
      sectionDestroyedConnect(section.id)
    } catch ({ errors }) {
      toast.error("Section could not be deleted.")
    }
  }

  return (
    <Form.Provider initialValues={defaultSection} onSubmit={handleSubmit} formId={`schema-section-card-${section.id}`}>
      <Card dataTestid={`schema-section-card-${section.id}`}>
        <Card.Header title={`Section ${sectionIndex + 1}`}>
          <LinkButton color="danger" onClick={handleDelete}>
            Delete
          </LinkButton>
        </Card.Header>
        <Card.Body>
          <Form.Body vertical>
            <SchemaSectionFormFields sectionIndex={sectionIndex} />
          </Form.Body>
        </Card.Body>
        <Card.Footer
          left={<strong>{pluralize("question", section.questionIds.length, true)}</strong>}
          right={<Form.SubmitButton label="Update" />}
        />
      </Card>
    </Form.Provider>
  )
}

EditSchemaSectionForm.propTypes = {
  section: types.customAssessmentSchemaSection,
  sectionDestroyedConnect: PropTypes.func.isRequired,
  sectionUpdatedConnect: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
}

export default connect((state, { sectionId }) => ({ section: sectionSelectors.selectById(state, sectionId) }), {
  sectionDestroyedConnect: sectionDestroyed,
  sectionUpdatedConnect: sectionUpdated,
})(EditSchemaSectionForm)
