import PropTypes from "prop-types"

/**
 * Use the `<IndexFilter />` component to render a set of form fields in a compact group
 * with an action bar. This component is typically used for sets of fields that filter
 * data on global index and report screens.
 */
function IndexFilter({ children }) {
  return (
    <div className="index-filter">
      <div className="index-filter-label">Filter Criteria</div>
      {children}
    </div>
  )
}

IndexFilter.propTypes = {
  children: PropTypes.node.isRequired,
}

function ActionBar({ children }) {
  return <div className="index-filter-action-bar">{children}</div>
}

ActionBar.propTypes = {
  children: PropTypes.node.isRequired,
}

IndexFilter.ActionBar = ActionBar

export default IndexFilter
