import _ from "lodash"

import { redirectTo } from "~/browserHelpers"
import { SecondaryButton } from "~/design/buttons"
import Form from "~/design/forms"
import { edit_health_home_path } from "~/routes"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function Edit({ healthHome, uniteUsAgency }) {
  const allCmas = _.sortBy(healthHome.careManagementAgencies, "name").map((option) => ({
    value: option.id,
    label: option.name,
  }))

  const selectedCmas = uniteUsAgency.uniteUsEnabledCareManagementAgencies.map(
    (uucma) => uucma.care_management_agency_id
  )

  const submitButtonLabel = uniteUsAgency.active ? "Save Unite Us Settings" : "Save and Activate Unite Us"
  async function onSubmit({ agencyId, selectedCmas }, { setErrors, setStatus, setTouched }) {
    try {
      await api.uniteUsConfigurationUpdate.put(healthHome, true, agencyId, selectedCmas)
      redirectTo(edit_health_home_path(healthHome))
      setStatus("success")
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  async function onDeactivate() {
    try {
      await api.uniteUsConfigurationUpdate.put(healthHome, false)
      redirectTo(edit_health_home_path(healthHome))
    } catch {
      toast.error("Failed to save Unite Us settings.")
    }
  }

  return (
    <Form
      formId="uniteUs"
      initialValues={{ agencyId: uniteUsAgency.agencyId, selectedCmas: selectedCmas }}
      onSubmit={onSubmit}
    >
      <Form.Group name="agencyId">
        <Form.Label>Unite Us Agency ID</Form.Label>
        <Form.TextField
          component="input"
          type="text"
          className="form-control"
          helpText="Your agency's ID as provided by Unite Us"
          disabled={uniteUsAgency.active === true}
        />
      </Form.Group>
      <Form.Group name="selectedCmas">
        <Form.Label>Enabled Care Management Agencies</Form.Label>
        <Form.MultiSelectBox options={allCmas} placeholder="Select Care Management Agencies" />
      </Form.Group>
      <Form.ActionBar
        right={
          <>
            {uniteUsAgency.active ? (
              <SecondaryButton onClick={onDeactivate}>Deactivate Unite Us</SecondaryButton>
            ) : null}
            <Form.SubmitButton label={submitButtonLabel} />
          </>
        }
      />
    </Form>
  )
}

Edit.propTypes = {
  healthHome: types.healthHome.isRequired,
  uniteUsAgency: types.uniteUsAgency.isRequired,
}
export default Edit
