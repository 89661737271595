import { Form as BootstrapForm } from "react-bootstrap"
import Select from "react-select"
import { useField, useFormikContext } from "formik"
import PropTypes from "prop-types"

import { classNames } from "~/design/forms/selectBoxConfiguration"
import types from "~/types"

function SelectBox({ label, name, options }) {
  const [field, , helpers] = useField({ name })
  const { isSubmitting, submitForm } = useFormikContext()

  const id = name.replace(/\W/g, "_")
  const value = field.value === undefined ? null : options?.find((opt) => opt.value === field.value)

  function handleChange(evt) {
    helpers.setValue(evt?.value === undefined ? null : evt.value)
    submitForm()
  }

  return (
    <BootstrapForm.Group className="mb-0">
      <BootstrapForm.Label htmlFor={id}>{label}</BootstrapForm.Label>
      <Select
        {...field}
        aria-label={`${name} search box`}
        aria-labelledby={`${id}Label`}
        className="react-select"
        classNamePrefix="react-select"
        classNames={classNames}
        isDisabled={isSubmitting}
        id={`${id}SelectBox`}
        inputId={id}
        name={name}
        onChange={handleChange}
        options={options}
        value={value}
      />
    </BootstrapForm.Group>
  )
}

SelectBox.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default SelectBox
