import { Form as BootstrapForm } from "react-bootstrap"
import { useField } from "formik"
import PropTypes from "prop-types"

import FieldErrors from "./FieldErrors"
import HelpText from "./HelpText"
import useErrorContext from "./useErrorContext"
import useNameContext from "./useNameContext"

function Radio({ value, label, helpText, validate }) {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name, validate })
  const { hasError } = useErrorContext()

  const valueId = id + value
  const helpTextId = `${valueId}HelpText`
  const errorMessageId = `${valueId}Errors`

  return (
    <div className="mb-1">
      <BootstrapForm.Check custom type="radio" id={valueId} style={{ zIndex: "auto" }}>
        <BootstrapForm.Check.Input
          name={name}
          value={value}
          checked={field.value === value}
          aria-errormessage={errorMessageId}
          aria-invalid={hasError}
          isInvalid={hasError}
          onChange={(e) => helpers.setValue(e.target.value)}
          type="radio"
        />
        <BootstrapForm.Check.Label>{label}</BootstrapForm.Check.Label>
      </BootstrapForm.Check>
      <HelpText id={helpTextId}>{helpText}</HelpText>
      <FieldErrors id={errorMessageId} />
    </div>
  )
}

Radio.propTypes = {
  /**
   * Text intended to guide the user toward the correct input.
   */
  helpText: PropTypes.string,
  /**
   * The visible label for the radio.
   */
  label: PropTypes.string.isRequired,
  /**
   * An optional function to run client-side validations:
   * https://formik.org/docs/api/field#validate
   */
  validate: PropTypes.func,
  /**
   * The underlying value of the option to be sent to the server
   * when the form is submitted.
   */
  value: PropTypes.string.isRequired,
}

export default Radio
