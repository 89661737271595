import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import { DisableProvider } from "./useDisableContext"

/**
 * A `<Fieldset />` represents a logical grouping of related controls e.g.
 * demographic data.
 *
 * Using a `<Fieldset />` groups fields visually, but also creates a "disable context".
 * This makes it much easier to disable an entire group of fields based on a shared
 * condition
 * (this mirrors the HTML behavior: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset#attributes)
 * .
 *
 * Fieldsets are automatically disabled when the parent form is submitting to prevent
 * issues where a user modifies the value of a field during an in-flight request, making
 * any resultant error messages potentially irrelevant.
 */
function Fieldset({ title, disabled, children }) {
  const { isSubmitting } = useFormikContext()

  return (
    <fieldset disabled={disabled || isSubmitting}>
      <legend>{title}</legend>
      <DisableProvider disabled={disabled || isSubmitting}>{children}</DisableProvider>
    </fieldset>
  )
}

Fieldset.propTypes = {
  /**
   * The content of the fieldset. This is usually two or more fields linked by a common theme.
   */
  children: PropTypes.node.isRequired,
  /**
   * The visible title of the fieldset. This should describe the group of fields contained within.
   */
  title: PropTypes.string.isRequired,
  /**
   * Disables the fieldset and all child controls. This can be set manually, but the fieldset is
   * also disabled automatically when the parent form is submitting.
   */
  disabled: PropTypes.bool,
}

Fieldset.defaultProps = {
  disabled: false,
}

export default Fieldset
