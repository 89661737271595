import pluralize from "pluralize"
import PropTypes from "prop-types"
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from "recharts"

import useSegmentLengthsReport from "../hooks/useSegmentLengthsReport"

import Jumbotron from "~/design/Jumbotron"
import { formatCompactNumber, formatNumberWithCommas, formatPercentage } from "~/numberHelpers"
import ChartContainer from "~/views/shared/graphicalCharts/ChartContainer"
import ChartLoadingSpinner from "~/views/shared/graphicalCharts/ChartLoadingSpinner"
import ChartTooltip from "~/views/shared/graphicalCharts/ChartTooltip"

function Report() {
  const { data, isLoading } = useSegmentLengthsReport()

  if (isLoading) {
    return <ChartLoadingSpinner />
  }

  if (data.length === 0) {
    return <Jumbotron message="No segments found" />
  }

  const totalCount = data.reduce((total, { count }) => total + count, 0)

  return (
    <ChartContainer>
      <BarChart data={data}>
        <XAxis dataKey="length" label={{ value: "Segment length", offset: -2, position: "insideBottom" }} />
        <YAxis tickFormatter={formatCompactNumber} />
        <Tooltip content={<CustomTooltip totalCount={totalCount} />} />
        <Bar dataKey="count" fill="var(--primary)">
          {data.map(({ length }, index) => (
            <Cell key={index} aria-label={`Segments open for ${pluralize("month", length, true)}`} />
          ))}
        </Bar>
      </BarChart>
    </ChartContainer>
  )
}

function CustomTooltip({ active, payload, label, totalCount }) {
  if (active && payload && payload.length) {
    const count = payload[0].value
    return (
      <ChartTooltip title={`Segments open for ${pluralize("month", label, true)}`}>
        <div>
          {formatNumberWithCommas(count)} {pluralize("segment", count)}
        </div>
        <div>of {formatNumberWithCommas(totalCount)} total</div>
        <div>({formatPercentage(count / totalCount, { maximumFractionDigits: 2 })})</div>
      </ChartTooltip>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    })
  ),
  label: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
}

export default Report
