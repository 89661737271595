import { useEffect, useRef } from "react"
import { Form as BootstrapForm, Row as BootstrapRow } from "react-bootstrap"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import { ErrorProvider } from "./useErrorContext"
import useLayout from "./useLayoutContext"
import { NameProvider } from "./useNameContext"

/**
 * A `<Group />` represents a single label/control pair.
 */
function Group({ compact, errorKey, name, clearOnHide, clearedValue, children }) {
  const { setFieldValue } = useFormikContext()
  const { vertical } = useLayout()

  // Ensure clearedValue has a stable identity so that it isn't required as a dependency
  // of the useEffect hook. Otherwise, values like [] would trigger an infinite render loop.
  const clearedValueRef = useRef(clearedValue)

  useEffect(() => {
    const clearedValue = clearedValueRef.current

    return function () {
      if (clearOnHide) {
        setFieldValue(name, clearedValue)
      }
    }
  }, [clearOnHide, name, setFieldValue])

  return (
    <NameProvider name={name}>
      <ErrorProvider errorKey={errorKey} name={name}>
        <BootstrapForm.Group as={vertical ? undefined : BootstrapRow} className={compact ? "mb-1" : undefined}>
          {children}
        </BootstrapForm.Group>
      </ErrorProvider>
    </NameProvider>
  )
}

Group.propTypes = {
  /**
   * The name of the field as assigned by the parent Formik form.
   */
  name: PropTypes.string.isRequired,
  /**
   * When set to true, the value of the field will be cleared when
   * the field is unmounted. This is usually what you want, otherwise
   * a value could be transmitted to the server that is no longer
   * relevant, based on its rendering condition.
   *
   * If you want to retain the value of the field after an unmount,
   * manually set this value to false in your form.
   */
  clearOnHide: PropTypes.bool,
  /**
   * The value to assign to the form field when cleared. This should typically
   * be an "empty" value, like null, "", or [].
   */
  clearedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /**
   * The content of the form group. This should almost always be a
   * combination of a label and its associated control. The form
   * group is responsible for propagating the field name to its
   * child controls.
   */
  children: PropTypes.node.isRequired,
  /**
   * A custom value to use when looking for errors returned by the server.
   *
   * This is useful for scenarios such as presence validations on an
   * association rather than a foreign key. This can cause the errors to
   * be returned under the association name rather than the field name
   * e.g. endDateReasonCode rather than endDateReasonCodeId.
   */
  errorKey: PropTypes.string,
  /**
   * Reduce the spacing between form fields.
   */
  compact: PropTypes.bool,
}

Group.defaultProps = {
  clearOnHide: true,
  clearedValue: null,
  compact: false,
}

export default Group
