import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import { filterAsOfDate } from "../helpers/filterAsOfDate"
import useCesToolSchema from "../hooks/useCesToolSchema"

import QuestionCard from "./QuestionCard"
import RiskFactorsAccordion from "./RiskFactorsAccordion"
import Section from "./Section"

import Form from "~/design/forms"
import Show from "~/design/Show"
import types from "~/types"

function AdditionalRiskFactorsSection({
  cesToolQuestionResponseOptions,
  generalRiskFactors,
  skillsBasedRiskFactors,
  stabilityRiskFactors,
}) {
  const { isFieldAnswerable } = useCesToolSchema()
  const {
    values: { startDate },
  } = useFormikContext()

  const skillsBasedRiskFactorOptions = filterAsOfDate(skillsBasedRiskFactors, startDate).map(({ id, description }) => ({
    value: id,
    label: description,
  }))
  const generalRiskFactorOptions = filterAsOfDate(generalRiskFactors, startDate).map(({ id, description }) => ({
    value: id,
    label: description,
  }))
  const stabilityRiskFactorOptions = filterAsOfDate(stabilityRiskFactors, startDate).map(({ id, description }) => ({
    value: id,
    label: description,
  }))

  return (
    <Show when={isFieldAnswerable("memberIsExperiencingGeneralRiskFactorsId")}>
      <Section id="ces-tool-section-additional-risk-factors" title="Additional Risk Factors">
        <p className="mb-3">
          <strong>
            If the answers to General Risk Factors, Stability Risk Factors, and Skills-Based Risk Factors are all "No",
            this ends the screening and generates a RECOMMEND DISENROLLMENT result.
          </strong>
        </p>
        <p className="mb-3">
          <strong>Any "Yes" in this section skips straight to Member Engagement.</strong>
        </p>
        <p className="mb-3">
          <strong>Any "Unclear" answer ends the screening and generates a MORE INFORMATION NEEDED result.</strong>
        </p>
        <QuestionCard questionNumber={1}>
          <Form.Group name="memberIsExperiencingGeneralRiskFactorsId">
            <Form.Label>Is the member experiencing any of the general risk factors from the list below?</Form.Label>
            <Form.SelectBox isClearable options={cesToolQuestionResponseOptions} />
          </Form.Group>
          <RiskFactorsAccordion
            title="Click here to view a list of general risk factors"
            riskFactorDescriptions={generalRiskFactorOptions.map(({ label }) => label)}
          />
        </QuestionCard>
        <Show when={isFieldAnswerable("generalRiskFactorIds")}>
          <QuestionCard questionNumber={2}>
            <Form.Group clearedValue={[]} name="generalRiskFactorIds">
              <Form.Label>Which general risk factor(s)?</Form.Label>
              <Form.MultiSelectBox options={generalRiskFactorOptions} />
            </Form.Group>
          </QuestionCard>
        </Show>
        <Show when={isFieldAnswerable("memberIsExperiencingStabilityRiskFactorsId")}>
          <QuestionCard questionNumber={3}>
            <Form.Group name="memberIsExperiencingStabilityRiskFactorsId">
              <Form.Label>Is the member experiencing any stability risk factors from the list below?</Form.Label>
              <Form.SelectBox isClearable options={cesToolQuestionResponseOptions} />
            </Form.Group>
            <RiskFactorsAccordion
              title="Click here to view a list of stability risk factors"
              riskFactorDescriptions={stabilityRiskFactorOptions.map(({ label }) => label)}
            />
          </QuestionCard>
        </Show>
        <Show when={isFieldAnswerable("stabilityRiskFactorIds")}>
          <QuestionCard questionNumber={4}>
            <Form.Group clearedValue={[]} name="stabilityRiskFactorIds">
              <Form.Label>Which type(s) of stability risk factors apply to this member?</Form.Label>
              <Form.MultiSelectBox options={stabilityRiskFactorOptions} />
            </Form.Group>
          </QuestionCard>
        </Show>
        <Show when={isFieldAnswerable("memberIsExperiencingSkillsBasedRiskFactorsId")}>
          <QuestionCard questionNumber={5}>
            <Form.Group name="memberIsExperiencingSkillsBasedRiskFactorsId">
              <Form.Label>Is the member experiencing any skills-based risk factors from the list below?</Form.Label>
              <Form.SelectBox
                options={cesToolQuestionResponseOptions}
                isClearable
                helpText="These questions should be answered as if the member did not have HHCM assistance. Some people may
              have assistance from someone else in their life such as family, home aides, or other types of case managers,
              which we are referring to collectively as 'a caregiver'."
              />
            </Form.Group>
            <RiskFactorsAccordion
              title="Click here to view a list of skills-based risk factors"
              riskFactorDescriptions={skillsBasedRiskFactorOptions.map(({ label }) => label)}
            />
          </QuestionCard>
        </Show>
        <Show when={isFieldAnswerable("skillsBasedRiskFactorIds")}>
          <QuestionCard questionNumber={6}>
            <Form.Group clearedValue={[]} name="skillsBasedRiskFactorIds">
              <Form.Label>Which skill(s) are they struggling with?</Form.Label>
              <Form.MultiSelectBox options={skillsBasedRiskFactorOptions} />
            </Form.Group>
          </QuestionCard>
        </Show>
      </Section>
    </Show>
  )
}

AdditionalRiskFactorsSection.propTypes = {
  cesToolQuestionResponseOptions: PropTypes.arrayOf(types.selectOption).isRequired,
  generalRiskFactors: PropTypes.arrayOf(types.cesRiskFactor).isRequired,
  skillsBasedRiskFactors: PropTypes.arrayOf(types.cesRiskFactor).isRequired,
  stabilityRiskFactors: PropTypes.arrayOf(types.cesRiskFactor).isRequired,
}

export default AdditionalRiskFactorsSection
