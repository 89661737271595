import { useState } from "react"
import { FormControl, Table } from "react-bootstrap"
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import PropTypes from "prop-types"

import { IconButton } from "~/design/buttons"
import { SearchIcon } from "~/design/icons"
import SortIcon from "~/design/icons/SortIcon"
import Show from "~/design/Show"

/**
 * The `<DataTable />` component returns a table with sorting and filtering using `@tanstack/react-table`.
 */
function DataTable({ table }) {
  const { rows } = table.getRowModel()
  const { headers } = table.getHeaderGroups()[0]

  return (
    <Table size="sm">
      <thead>
        <tr>
          {headers.map((header) => (
            <HeaderCell key={header.id} column={header.column} />
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.length === 0 ? (
          <tr>
            <td colSpan={headers.length}>No data available in table</td>
          </tr>
        ) : (
          rows.map((row) => (
            <tr key={row.id} className="font-weight-normal">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </Table>
  )
}

DataTable.propTypes = {
  /**
   * The `table` prop is a table object returned by the `useDataTable` hook.
   *
   * When creating column definitions for the table, take note of the following:
   *
   * - `header` must be a string so that accessible labels can be generated for sorting/filtering elements
   * - use `enableColumnFilter: false` to disable filtering for a column
   * - use `enableSorting: false` to disable sorting for a column
   */
  table: PropTypes.shape({
    getHeaderGroups: PropTypes.func.isRequired,
    getRowModel: PropTypes.func.isRequired,
  }),
}

function HeaderCell({ column }) {
  const [isFilterVisible, setIsFilterVisible] = useState(false)

  const direction = column.getIsSorted()

  let sortLabel
  if (direction) {
    sortLabel = `Sorted by ${column.columnDef.header} ${direction}ending`
  } else {
    sortLabel = `Sort by ${column.columnDef.header} ascending`
  }

  return (
    <th className="border-0 font-weight-normal text-muted">
      <div className="d-flex gap-2 align-items-end">
        {column.columnDef.header}
        <Show when={column.getCanFilter()}>
          <IconButton
            Icon={SearchIcon}
            label={`Show search for ${column.columnDef.header}`}
            onClick={() => setIsFilterVisible(!isFilterVisible)}
          />
          <FormControl
            size="sm"
            onChange={(e) => column.setFilterValue(e.target.value)}
            style={{ visibility: isFilterVisible ? "initial" : "hidden" }}
            aria-label={`Search ${column.columnDef.header}`}
          />
        </Show>
        <Show when={column.getCanSort()}>
          <div className="flex-grow-1">
            <IconButton
              Icon={() => <SortIcon sortDirection={direction} />}
              onClick={column.getToggleSortingHandler()}
              label={sortLabel}
            />
          </div>
        </Show>
      </div>
    </th>
  )
}

HeaderCell.propTypes = {
  column: PropTypes.shape({
    columnDef: PropTypes.shape({ header: PropTypes.string.isRequired }).isRequired,
    getCanFilter: PropTypes.func.isRequired,
    getCanSort: PropTypes.func.isRequired,
    getIsSorted: PropTypes.func.isRequired,
    getToggleSortingHandler: PropTypes.func.isRequired,
    setFilterValue: PropTypes.func.isRequired,
  }).isRequired,
}

function useDataTable({ data, columns }) {
  return useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
}

export { useDataTable }
export default DataTable
