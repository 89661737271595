import { connect } from "react-redux"

import { getGoalWithOptions } from "~/components/carePlans/carePlanGoalsSelectors"
import GoalCard from "~/components/carePlans/GoalCard"
import TasksList from "~/components/carePlans/TasksList"

const GoalContainer = ({ goal }) => (
  <div className="goal-container">
    <GoalCard goal={goal} />
    <TasksList goalId={goal.id} />
  </div>
)

export default connect((state, props) => ({
  goal: getGoalWithOptions(state, props),
}))(GoalContainer)
