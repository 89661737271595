import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import LinkedGapInCareField from "~/components/linkedCarePlanTasks/LinkedGapInCareField"
import Form from "~/design/forms"
import types from "~/types"

function TaskFormFields({
  carePlanTaskStatuses,
  disabled,
  gapInCareOptions,
  linkedGapInCareFieldInitialVisibility,
  taskResponsibilities,
}) {
  return (
    <>
      <Form.Group name="description">
        <Form.Label>Task</Form.Label>
        <Form.TextField
          placeholder="e.g. Care Manager will assist client in getting a glucose monitor"
          disabled={disabled}
        />
      </Form.Group>

      <Row>
        <Col md={6}>
          <Form.Group name="taskResponsibilityId" errorKey="responsibility">
            <Form.Label>Responsibility</Form.Label>
            <Form.SelectBox options={taskResponsibilities} isClearable disabled={disabled} />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group name="carePlanTaskStatusId" errorKey="status">
            <Form.Label>Status</Form.Label>
            <Form.SelectBox options={carePlanTaskStatuses} isClearable disabled={disabled} />
          </Form.Group>
        </Col>
      </Row>
      {/* CarePlanTaskResponsibility::OTHER.id */}
      <Form.Show when={({ values }) => values.taskResponsibilityId === 6}>
        <Row>
          <Col md={6}>
            <Form.Group name="taskResponsibilityOther">
              <Form.Label>Other</Form.Label>
              <Form.TextField disabled={disabled} />
            </Form.Group>
          </Col>
        </Row>
      </Form.Show>

      <Row>
        <Col md={6}>
          <Form.Group name="targetCompletionDate">
            <Form.Label>Target Date</Form.Label>
            <Form.DatePicker disabled={disabled} />
          </Form.Group>
        </Col>

        {/* CarePlanTaskStatus::COMPLETED.id */}
        <Form.Show when={({ values }) => values.carePlanTaskStatusId === 3}>
          <Col md={6}>
            <Form.Group name="completionDate">
              <Form.Label>Completion Date</Form.Label>
              <Form.DatePicker disabled={disabled} />
            </Form.Group>
          </Col>
        </Form.Show>
      </Row>

      <Form.Group name="notes">
        <Form.Label>Note</Form.Label>
        <Form.TextField disabled={disabled} />
      </Form.Group>

      <LinkedGapInCareField
        gapInCareOptions={gapInCareOptions}
        disabled={disabled}
        initialVisibility={linkedGapInCareFieldInitialVisibility}
      />
    </>
  )
}

TaskFormFields.propTypes = {
  carePlanTaskStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  disabled: PropTypes.bool,
  gapInCareOptions: PropTypes.arrayOf(types.selectOption).isRequired,
  linkedGapInCareFieldInitialVisibility: PropTypes.bool.isRequired,
  taskResponsibilities: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default TaskFormFields
