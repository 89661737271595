import { useCallback, useMemo } from "react"
import { Form as BootstrapForm } from "react-bootstrap"
import { useField } from "formik"
import _ from "lodash"
import PropTypes from "prop-types"

import { DEFAULT_CONTROL_WIDTH } from "./constants"
import FieldErrors from "./FieldErrors"
import HelpText from "./HelpText"
import useDisableContext from "./useDisableContext"
import useErrorContext from "./useErrorContext"
import { LayoutWrapper } from "./useLayoutContext"
import useNameContext from "./useNameContext"

/**
 * A `<TextField />` represents a single line text input control.
 */
function TextField({ debounce, disabled: disabledByProps, helpText, placeholder, validate, width }) {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name, validate })
  const { hasError } = useErrorContext()
  const disabledByContext = useDisableContext()

  const helpTextId = `${id}HelpText`
  const errorMessageId = `${id}Errors`
  const disabled = disabledByContext || disabledByProps

  const onChange = useCallback((value) => helpers.setValue(value), [helpers])
  const debouncedOnChange = useMemo(() => _.debounce(onChange, 400), [onChange])

  const fieldValue = field.value == null ? "" : field.value
  const defaultValue = debounce ? fieldValue : undefined
  const value = debounce ? undefined : fieldValue

  return (
    <LayoutWrapper sm={width}>
      <BootstrapForm.Control
        aria-describedby={helpText ? helpTextId : null}
        aria-errormessage={errorMessageId}
        aria-invalid={hasError}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        isInvalid={hasError}
        name={name}
        onBlur={field.onBlur}
        onChange={(event) => (debounce ? debouncedOnChange(event.target.value) : onChange(event.target.value))}
        placeholder={placeholder}
        type="text"
        value={value}
      />
      <HelpText id={helpTextId}>{helpText}</HelpText>
      <FieldErrors id={errorMessageId} />
    </LayoutWrapper>
  )
}

TextField.propTypes = {
  /**
   * Setting debounce to true will debounce change event for this field
   * so that the form's state is only updated 200ms after the last input
   * is received. Debounced fields are _uncontrolled_. Using this approach
   * helps prevent unnecessary re-renders, since controlled fields
   * can make inputs laggy, especially on large or complex forms where
   * many components are subscribed to changes to the form's state.
   */
  debounce: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
   * Text intended to guide the user toward the correct input.
   */
  helpText: PropTypes.string,
  /**
   * Representative text to display in the input before user input.
   */
  placeholder: PropTypes.string,
  /**
   * An optional function to run client-side validations:
   * https://formik.org/docs/api/field#validate
   */
  validate: PropTypes.func,
  /**
   * The number of columns the control should span.
   */
  width: PropTypes.number,
}

TextField.defaultProps = {
  disabled: false,
  width: DEFAULT_CONTROL_WIDTH,
}

export default TextField
