import Icon from "./Icon"

/**
 * Renders an icon of a minus sign, indicating an action such as removing an item from a collection.
 */
function MinusIcon() {
  return <Icon name="minus" />
}

export default MinusIcon
