import EncounterDetailsFields from "~/components/encounters/EncounterDetailsFields"
import Form from "~/design/forms"
import { globalsStateWrapper } from "~/redux"

function OfficeWorkEncounterDetailsFields({ officeWorkTypes }) {
  return (
    <EncounterDetailsFields
      attributesName="officeWorkEncounterDetailsAttributes"
      defaultValues={{
        officeWorkTypeId: "",
      }}
    >
      <Form.Group name="officeWorkTypeId">
        <Form.Label>What kind of office work did you do?</Form.Label>
        <Form.SelectBox options={officeWorkTypes} isClearable />
      </Form.Group>
    </EncounterDetailsFields>
  )
}

export default globalsStateWrapper(({ officeWorkTypes }) => ({ officeWorkTypes }))(OfficeWorkEncounterDetailsFields)
