import { Fragment } from "react"
import _ from "lodash"

import { LineDivider } from "~/components/Divider"
import CarePlanTaskDetails from "~/components/encounters/CarePlanTaskDetails"
import Jumbotron from "~/design/Jumbotron"

const CarePlanTasks = ({ tasks }) => {
  if (_.isEmpty(tasks)) {
    return <Jumbotron message="There are no tasks linked to this encounter." />
  }

  return (
    <div className="bg-white border rounded p-3 overflow-auto" style={{ maxHeight: "469px" }}>
      {tasks.map((task, index) => (
        <Fragment key={task.id}>
          <CarePlanTaskDetails task={task} />
          {index < tasks.length - 1 ? <LineDivider /> : null}
        </Fragment>
      ))}
    </div>
  )
}

export default CarePlanTasks
