import { useEffect } from "react"

import { SIDEBAR_TOP_OFFSET } from "../constants"
import useCesToolSchema from "../hooks/useCesToolSchema"

import ListGroup from "~/components/listGroup"

function SideBar() {
  const { isFieldAnswerable } = useCesToolSchema()

  useEffect(() => {
    // The offset controls where the scrollspy thinks the boundary for the section begins
    // relative to the top of the page.
    $("body").scrollspy({ offset: SIDEBAR_TOP_OFFSET + 5, target: "#response-sidebar" })
  }, [])

  return (
    <div id="response-sidebar" style={{ position: "sticky", top: `${SIDEBAR_TOP_OFFSET}px` }}>
      <h3 className="mb-2">Sections</h3>
      <ListGroup size="sm">
        <ListGroup.Item action href="#ces-tool-section-instructions">
          Instructions
        </ListGroup.Item>
        <ListGroup.Item action href="#ces-tool-section-health-home-plus-eligibility">
          Health Home Plus Eligibility
        </ListGroup.Item>
        <ListGroup.Item
          action
          disabled={!isFieldAnswerable("memberHasQualifyingDiagnosesId")}
          href="#ces-tool-section-basic-eligibility-pre-screen"
        >
          Basic Eligibility Pre-Screen
        </ListGroup.Item>
        <ListGroup.Item
          action
          disabled={!isFieldAnswerable("memberIsExperiencingSignificantRiskFactors")}
          href="#ces-tool-section-significant-risk-factors"
        >
          Significant Risk Factors
        </ListGroup.Item>
        <ListGroup.Item
          action
          disabled={!isFieldAnswerable("memberIsExperiencingGeneralRiskFactorsId")}
          href="#ces-tool-section-additional-risk-factors"
        >
          Additional Risk Factors
        </ListGroup.Item>
        <ListGroup.Item
          action
          disabled={!isFieldAnswerable("memberHasNonMaintenanceGoalsLeftOnPocId")}
          href="#ces-tool-section-member-engagement"
        >
          Member Engagement
        </ListGroup.Item>
        <ListGroup.Item action href="#ces-tool-section-results">
          Results
        </ListGroup.Item>
        <ListGroup.Item action href="#ces-tool-section-completion">
          Complete
        </ListGroup.Item>
      </ListGroup>
    </div>
  )
}

export default SideBar
