import NewForm from "./components/NewForm"

import { ConfirmationModalProvider } from "~/design/modals/useConfirmationModal"
import { ToastContainer } from "~/toast"

function New(pageProps) {
  return (
    <ConfirmationModalProvider>
      <NewForm {...pageProps} />
      <ToastContainer />
    </ConfirmationModalProvider>
  )
}

export default New
