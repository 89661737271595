import PropTypes from "prop-types"

import { useAddChildrensHCBSWorkflowDocument } from "./useChildrensHCBSWorkflowDocuments"

import PopoverButton from "~/components/PopoverButton"
import { WarningAlert } from "~/design/alerts"
import Form from "~/design/forms"
import Modal from "~/design/Modal"
import { toast } from "~/toast"
import types from "~/types"

function AddWorkflowDocumentModal({
  chart,
  childrensHCBSWorkflowId,
  childrensHCBSWorkflowStepId,
  show,
  onClose,
  documentTypes,
}) {
  const addWorkflowDocumentMutation = useAddChildrensHCBSWorkflowDocument(childrensHCBSWorkflowId)

  async function handleSubmit(values, { setErrors }) {
    try {
      const workflowDocumentParams = {
        childrensHCBSWorkflowStepId,
        documentAttributes: values,
      }
      await addWorkflowDocumentMutation.mutateAsync(workflowDocumentParams)
      toast.success("Workflow document added successfully")
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  function otherDocumentSelection({ values: { documentTypeId } }) {
    const selectedType = documentTypes.find((documentType) => documentType.id === documentTypeId)

    return Boolean(selectedType?.allowsUserDocumentName)
  }

  return (
    <Modal show={show} onClose={onClose} title="Add Document">
      <Form.Provider
        initialValues={{ uploadedFile: "", documentTypeId: "", otherDocumentTypeName: "", description: "", date: "" }}
        onSubmit={handleSubmit}
        formId="add-workflow-document-form"
      >
        <Modal.Body>
          <WarningAlert>
            When saved, this will be associated with {chart.healthHomeName} Health Home and{" "}
            {chart.careManagementAgencyName} CMA.
          </WarningAlert>
          <Form.Body vertical>
            <Form.Group name="uploadedFile" errorKey="document.uploadedFile">
              <Form.Label>Uploaded File</Form.Label>
              <Form.FileField />
            </Form.Group>
            <Form.Group name="documentTypeId" errorKey="document.documentTypeId">
              <Form.Label>Type</Form.Label>
              <Form.SelectBox
                options={documentTypes.map((type) => {
                  return { label: type.name, value: type.id }
                })}
                isClearable
              />
            </Form.Group>
            <Form.Show when={otherDocumentSelection}>
              <Form.Group name="otherDocumentTypeName" errorKey="document.otherDocumentTypeName">
                <Form.Label>Other</Form.Label>
                <Form.TextField />
              </Form.Group>
            </Form.Show>
            <Form.Group name="description" errorKey="document.description">
              <Form.Label>Description</Form.Label>
              <Form.TextField />
            </Form.Group>
            <Form.Group name="date" errorKey="document.date">
              <Form.Label>
                Document Date&nbsp;
                <PopoverButton title="Document Date" label="?">
                  If this document does not have a “Signed On” date, please enter today’s date for this field.
                </PopoverButton>
              </Form.Label>
              <Form.DatePicker />
            </Form.Group>
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Upload Document" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

AddWorkflowDocumentModal.propTypes = {
  chart: types.chart.isRequired,
  childrensHCBSWorkflowId: PropTypes.number.isRequired,
  childrensHCBSWorkflowStepId: PropTypes.number.isRequired,
  documentTypes: PropTypes.arrayOf(types.documentType).isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default AddWorkflowDocumentModal
