import Icon from "./Icon"

/**
 * Renders an icon of minus sign inside of a circle.
 */
function ErrorIcon() {
  return <Icon name="minus-circle" />
}

export default ErrorIcon
