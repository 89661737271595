import { cloneElement } from "react"
import PropTypes from "prop-types"

import Jumbotron from "~/design/Jumbotron"
import types from "~/types"

const CarePlanMessageWrapper = ({ carePlan, chartId, children }) => {
  let message
  if (!carePlan) {
    message = "This patient does not have an active care plan with this encounter's Health Home and CMA."
  } else if (carePlan.chartId !== chartId) {
    message = "Care Plan Tasks can only be linked to encounters associated with this encounter's Health Home and CMA."
  }

  if (message) {
    return <Jumbotron message={message} />
  }

  return cloneElement(children, { carePlan })
}

CarePlanMessageWrapper.propTypes = {
  carePlan: types.carePlan,
  chartId: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default CarePlanMessageWrapper
