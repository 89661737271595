import { Button } from "react-bootstrap"
import PropTypes from "prop-types"

/**
 * A `<PrimaryButton />` is used to indicate the "primary" action for a page
 * or page element (e.g. submitting a form).
 *
 * In general, there should only be a single primary action for any distinct
 * page element. For additional actions, prefer other more appropriate
 * components such as `<SecondaryButton />`.
 */
function PrimaryButton({ children, disabled, formId, onClick, type, size }) {
  return (
    <Button
      disabled={disabled}
      form={type === "submit" ? formId : null}
      onClick={onClick}
      type={type}
      size={size}
      variant="primary"
    >
      {children}
    </Button>
  )
}

PrimaryButton.propTypes = {
  /**
   * The content of the button.
   */
  children: PropTypes.node.isRequired,
  /**
   * A disabled button receives no pointer events.
   */
  disabled: PropTypes.bool,
  /**
   * The ID of a form this button should submit.
   *
   * This prop is ignored when type is not "submit".
   */
  formId: PropTypes.string,
  /**
   * The action to take when the button is clicked.
   *
   * This should be supplied for any non-submit buttons. If
   * you have a button that is conditionally inactive, favor
   * the disabled attribute rather than passing a null
   * onClick handler.
   */
  onClick: PropTypes.func,
  /**
   * The HTML type of the button.
   *
   * By default, this is "button" for buttons that take some
   * action other than submitting a form. If you are looking
   * for a "submit" button, you should probably be looking
   * at the SubmitButton component in the forms package.
   */
  type: PropTypes.oneOf(["button", "submit"]),
  size: PropTypes.oneOf(["sm", "lg"]),
}

PrimaryButton.defaultProps = {
  type: "button",
}

export default PrimaryButton
