import { connect } from "react-redux"

import LinkedCarePlanGoalField from "./LinkedCarePlanGoalField"
import LinkedGapInCareFromFormBanner from "./LinkedGapInCareFromFormBanner"
import TaskFormFields from "./TaskFormFields"

import { getCarePlanGoals } from "~/components/carePlans/carePlanGoalsSelectors"
import { carePlanTaskCreated, carePlanTaskFlashKey } from "~/components/carePlans/carePlanTaskActions"
import { fetchMcoGapsInCare } from "~/components/carePlans/mcoGapInCareActions"
import Form from "~/design/forms"
import Modal from "~/design/Modal"
import { flashMessage } from "~/flashMessages/flashMessageActions"
import { care_plan_path } from "~/routes"
import api from "~/services/api"

const NewTaskForm = ({
  defaultSelectedGoal,
  defaultSelectedGapInCare,
  goals,
  carePlan,
  linkedGapInCareFieldInitialVisibility,
  carePlanTaskCreatedConnect,
  fetchMcoGapsInCareConnect,
  flashMessageConnect,
  show,
  onClose,
  taskResponsibilities,
  carePlanTaskStatuses,
  gapsForNewTaskLinkages,
}) => {
  const disabled = !goals.length
  const defaultTask = {
    description: "",
    taskResponsibilityId: "",
    taskResponsibilityOther: "",
    carePlanTaskStatusId: "",
    targetCompletionDate: "",
    completionDate: "",
    notes: "",
    goalId: defaultSelectedGoal && defaultSelectedGoal.id,
    mcoGapInCareIds: defaultSelectedGapInCare ? [defaultSelectedGapInCare.id] : [],
  }

  async function onSubmit({ goalId, ...otherValues }, { setErrors, setStatus }) {
    try {
      const task = await api.carePlans.tasks.post(goalId, otherValues)
      carePlanTaskCreatedConnect(task, goalId)

      if (otherValues.mcoGapInCareIds) {
        await fetchMcoGapsInCareConnect(otherValues.mcoGapInCareIds)
      }

      setStatus("success")
      flashMessageConnect(carePlanTaskFlashKey(task))
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  const gapInCareOptions = Object.values(gapsForNewTaskLinkages).map((gap) => ({
    label: gap.description,
    value: gap.id,
  }))

  const goalOptions = goals.map((goal) => {
    const categoryName = goal.categoryName || "Uncategorized"
    return { label: `${categoryName}: ${goal.description}`, value: goal.id }
  })

  return (
    <Modal show={show} onClose={onClose} title="Add Task" size="lg" dataTestId="new-task-modal">
      <Form.Provider formId="new-task-modal-form" initialValues={defaultTask} onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Body vertical>
            <LinkedCarePlanGoalField goalOptions={goalOptions} />
            <LinkedGapInCareFromFormBanner />
            <p className="text-muted">
              To create a new goal, go to the patient's&nbsp;
              <a href={care_plan_path(carePlan.id)} target="_blank" rel="noopener noreferrer">
                care plan&nbsp;
                <i className="fa fa-external-link" />
              </a>
            </p>
            <TaskFormFields
              gapInCareOptions={gapInCareOptions}
              taskResponsibilities={taskResponsibilities}
              carePlanTaskStatuses={carePlanTaskStatuses}
              linkedGapInCareFieldInitialVisibility={linkedGapInCareFieldInitialVisibility}
              disabled={disabled}
            />
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Create" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

NewTaskForm.defaultProps = {
  defaultSelectedGoal: null,
  defaultSelectedGapInCare: null,
  linkedGapInCareFieldInitialVisibility: false,
}

export default connect(
  (state, props) => ({
    goals: getCarePlanGoals(state, props),
    gapsForNewTaskLinkages: state.entities.gapsForNewTaskLinkages,
    taskResponsibilities: state.globals.taskResponsibilities,
    carePlanTaskStatuses: state.globals.carePlanTaskStatuses,
  }),
  {
    carePlanTaskCreatedConnect: carePlanTaskCreated,
    fetchMcoGapsInCareConnect: fetchMcoGapsInCare,
    flashMessageConnect: flashMessage,
  }
)(NewTaskForm)
