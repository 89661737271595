import api from "~/services/api"

export function fetchMcoGapsInCare(ids) {
  return (dispatch) => api.mcoGapsInCare.get(ids).then((mcoGapsInCare) => dispatch(mcoGapsInCareFetched(mcoGapsInCare)))
}

export const MCO_GAPS_IN_CARE_FETCHED = "MCO_GAPS_IN_CARE_FETCHED"
export function mcoGapsInCareFetched(mcoGapsInCare) {
  return {
    type: MCO_GAPS_IN_CARE_FETCHED,
    mcoGapsInCare,
  }
}

export const MCO_GAP_IN_CARE_UPDATED = "MCO_GAP_IN_CARE_UPDATED"
export function mcoGapInCareUpdated(mcoGapInCare) {
  return {
    type: MCO_GAP_IN_CARE_UPDATED,
    mcoGapInCare,
  }
}
