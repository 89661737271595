import SideBarToggle from "../SideBarToggle"

import { UserIcon } from "~/design/icons"
import Show from "~/design/Show"
import types from "~/types"

function CareManagerSection({ careManager }) {
  return (
    <SideBarToggle title="Care Manager" HeaderIcon={UserIcon}>
      {careManager ? (
        <div>
          <h4 className="my-2 font-weight-bold">{careManager.name}</h4>
          <Show when={Boolean(careManager.workPhone)}>
            <p className="m-0 small">Office Phone: {careManager.workPhone}</p>
          </Show>
          <Show when={Boolean(careManager.cellPhone)}>
            <p className="m-0 small">Cell Phone: {careManager.cellPhone}</p>
          </Show>
        </div>
      ) : (
        <p className="font-weight-light">This patient does not have a care manager.</p>
      )}
    </SideBarToggle>
  )
}

CareManagerSection.propTypes = {
  careManager: types.careManager,
}

export default CareManagerSection
