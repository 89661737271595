import PropTypes from "prop-types"

function CompleteAssessmentSection({ children }) {
  return (
    <div id="completion-section">
      <h2 className="mb-2">Complete Assessment</h2>
      {children}
    </div>
  )
}

CompleteAssessmentSection.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CompleteAssessmentSection
