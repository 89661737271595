import { Dropdown, DropdownButton } from "react-bootstrap"
import ReactDOM from "react-dom"
import { connect } from "react-redux"
import _ from "lodash"

import { ActiveOnlyFilterState } from "~/components/carePlans/carePlanActiveFilterActions"
import { getActiveFilterState, getFilteredNeedCategoryId } from "~/components/carePlans/carePlanUISelectors"
import { accessible_external_care_plan_path } from "~/routes"

function removeFalsyValues(obj) {
  return _.pickBy(obj, Boolean)
}

function AccessibleExternalCarePlanActionMenuPortal({ carePlan, activeFilterState, filteredNeedCategoryId }) {
  const node = document.getElementById("care-plan-print-buttons-portal-selector")

  const filterParams = removeFalsyValues({
    active_only: activeFilterState === ActiveOnlyFilterState.ACTIVE_ONLY,
    care_plan_need_category_id: filteredNeedCategoryId,
  })

  return ReactDOM.createPortal(
    <DropdownButton variant="outline-primary" title="Actions" menuAlign="right">
      <Dropdown.Item
        as="button"
        data-target="_blank"
        data-trigger-download-modal-sync={accessible_external_care_plan_path(carePlan.id, {
          format: "pdf",
          ...filterParams,
        })}
      >
        View PDF
      </Dropdown.Item>
    </DropdownButton>,
    node
  )
}

export default connect((state) => ({
  activeFilterState: getActiveFilterState(state),
  carePlan: state.entities.carePlan,
  filteredNeedCategoryId: getFilteredNeedCategoryId(state),
}))(AccessibleExternalCarePlanActionMenuPortal)
