import { Button } from "react-bootstrap"
import PropTypes from "prop-types"

function PrimaryButtonLink({ children, href, size }) {
  return (
    <Button as="a" href={href} size={size} variant="primary">
      {children}
    </Button>
  )
}

PrimaryButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["sm", "lg"]),
}

export default PrimaryButtonLink
