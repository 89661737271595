import { useState } from "react"
import { Table } from "react-bootstrap"
import PropTypes from "prop-types"

import { SecondaryButton } from "~/design/buttons"
import Jumbotron from "~/design/Jumbotron"
import Show from "~/design/Show"
import types from "~/types"
import DiagnosesForm from "~/views/shared/diagnoses/DiagnosesForm"
import Section from "~/views/shared/patientDetails/Section"

function DiagnosesSection({ chart, diagnoses: initialDiagnoses, diagnosisValues }) {
  const [diagnoses, setDiagnoses] = useState(initialDiagnoses)
  const [showModal, setShowModal] = useState(false)

  const displayDiagnoses = Object.keys(diagnoses)
    .map((diagnosisId) => diagnoses[diagnosisId])
    .sort((diagnosisA, diagnosisB) => diagnosisB.isPrimary - diagnosisA.isPrimary)

  return (
    <Section
      title="Diagnoses"
      actions={<SecondaryButton onClick={() => setShowModal(true)}>Edit Diagnoses</SecondaryButton>}
    >
      <div className="text-right">*Required by MAPP as part of the sync process</div>
      {displayDiagnoses.length > 0 ? (
        <Table striped bordered size="sm" className="table-light-header">
          <thead>
            <tr>
              <th>Diagnosis</th>
              <th>Primary?</th>
            </tr>
          </thead>
          <tbody>
            {displayDiagnoses.map((diagnosis) => (
              <tr key={diagnosis.id}>
                <td>{diagnosis.description}</td>
                <td>
                  <Show when={Boolean(diagnosis.isPrimary)}>
                    &#10003;
                    <span className="sr-only">true</span>
                  </Show>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Jumbotron message="This patient does not have any diagnoses for the selected agencies." />
      )}
      <DiagnosesForm
        show={showModal}
        onClose={() => setShowModal(false)}
        diagnoses={diagnoses}
        chart={chart}
        diagnosisValues={diagnosisValues}
        onUpdate={setDiagnoses}
      />
    </Section>
  )
}

DiagnosesSection.propTypes = {
  chart: types.chart.isRequired,
  diagnoses: PropTypes.objectOf(types.diagnosis).isRequired,
  diagnosisValues: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default DiagnosesSection
