import { useFormikContext } from "formik"

import DangerAlert from "~/design/alerts/DangerAlert"
import Show from "~/design/Show"

function FormErrors() {
  const { errors } = useFormikContext()
  const { base, ...otherErrors } = errors

  if (isEmpty(errors)) {
    return null
  }

  return (
    <DangerAlert>
      <div>
        <strong>Something is wrong.</strong>
      </div>
      <ul>
        {base?.map((err, idx) => (
          <li key={idx}>{err}</li>
        ))}
        <Show when={!isEmpty(otherErrors)}>
          <li>Correct the errors below.</li>
        </Show>
      </ul>
    </DangerAlert>
  )
}

function isEmpty(o) {
  return Object.keys(o).length === 0
}

export default FormErrors
