export const SET_TASK_FORM_STATE = "SET_TASK_FORM_STATE"
export function openNewTaskForm(id) {
  return { type: SET_TASK_FORM_STATE, id, isOpen: true }
}

export function closeNewTaskForm(id) {
  return { type: SET_TASK_FORM_STATE, id, isOpen: false }
}

export const SET_TASKS_LIST_OPEN_STATE = "SET_TASKS_LIST_OPEN_STATE"
export function openTasksList(id) {
  return { type: SET_TASKS_LIST_OPEN_STATE, id, isOpen: true }
}

export function closeTasksList(id) {
  return { type: SET_TASKS_LIST_OPEN_STATE, id, isOpen: false }
}
