import Icon from "./Icon"

/**
 * Renders an icon of a closed padlock.
 */
function LockIcon() {
  return <Icon name="lock" />
}

export default LockIcon
