import { FieldArray, useFormikContext } from "formik"
import PropTypes from "prop-types"

import { IconButton, LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import { CloseIcon } from "~/design/icons"

function SchemaQuestionCardOptionsSection({ questionIndex }) {
  const { values: question, setFieldValue } = useFormikContext()

  return (
    <FieldArray
      name="optionsAttributes"
      render={({ push, remove }) => (
        <>
          <div className="mb-3">Answer Options</div>
          <ol>
            {question.optionsAttributes.map((option, optionIndex) => {
              if (option._destroy) {
                return null
              }

              function removeQuestion() {
                if (option.id) {
                  setFieldValue(`optionsAttributes[${optionIndex}]._destroy`, true)
                } else {
                  remove(optionIndex)
                }
              }

              return (
                <li key={optionIndex}>
                  <div className="d-flex align-items-center gap-2">
                    <div className="w-50">
                      <Form.Group
                        clearOnHide={false}
                        name={`optionsAttributes[${optionIndex}].optionText`}
                        errorKey={`options[${optionIndex}].optionText`}
                      >
                        <Form.Label hidden>
                          Question {questionIndex + 1} Option {optionIndex + 1}
                        </Form.Label>
                        <Form.TextField placeholder="Option Text" />
                      </Form.Group>
                    </div>
                    <div className="mb-3">
                      <IconButton
                        Icon={CloseIcon}
                        label={`Remove Question ${questionIndex + 1} Option ${optionIndex + 1}`}
                        onClick={removeQuestion}
                      />
                    </div>
                  </div>
                </li>
              )
            })}
            <li>
              <LinkButton onClick={() => push({ optionText: "" })}>Add Option</LinkButton>
            </li>
          </ol>
        </>
      )}
    />
  )
}

SchemaQuestionCardOptionsSection.propTypes = {
  questionIndex: PropTypes.number.isRequired,
}

export default SchemaQuestionCardOptionsSection
