import { Slide, toast as toastifyToast, ToastContainer as ToastifyToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"

function ToastContainer() {
  return <ToastifyToastContainer position="top-right" transition={Slide} hideProgressBar icon={false} theme="colored" />
}

const toast = {
  error(msg) {
    toastifyToast.error(msg)
  },
  success(msg) {
    toastifyToast.success(msg)
  },
}

export { toast, ToastContainer }
