import PropTypes from "prop-types"

const patientFlag = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  abbreviation: PropTypes.string,
  description: PropTypes.string,
  textColor: PropTypes.string,
  color: PropTypes.string,
})

export default patientFlag
