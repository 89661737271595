import { useEffect } from "react"
import { connect } from "react-redux"

import { getHealthHomeQualityStatusData } from "~/big3Dashboard/actions/qualityReportStatusActions"
import HealthHomeQualityDashboard from "~/big3Dashboard/HealthHomeQualityDashboard"
import tabs from "~/big3Dashboard/HealthHomeQualityDashboard/tabs"
import { createNewHealthHomeBig3Url } from "~/big3Dashboard/helpers/createNewCareManagementAgencyBig3Url"
import { getActiveTabId } from "~/big3Dashboard/selectors/activeTabSelector"
import { getDate, getEntityId } from "~/big3Dashboard/selectors/filtersSelector"
import { getReport, getStatus } from "~/big3Dashboard/selectors/qualityReportStatusSelector"
import { updatePath } from "~/browserHelpers"

function HealthHomeQualityContainer({
  activeTabId,
  date,
  getHealthHomeQualityStatusDataConnect,
  healthHomeId,
  report,
  status,
}) {
  const activeTab = tabs.find(({ id }) => id === activeTabId)

  useEffect(() => {
    getHealthHomeQualityStatusDataConnect(healthHomeId, activeTabId, date)
    updatePath(createNewHealthHomeBig3Url({ healthHomeId, activeTabId, date }))
  }, [activeTabId, date, healthHomeId])

  return (
    <HealthHomeQualityDashboard
      activeTab={activeTab}
      tabs={tabs}
      status={status}
      report={report}
      healthHomeId={healthHomeId}
      date={date}
    />
  )
}

export default connect(
  (state) => ({
    activeTabId: getActiveTabId(state),
    date: getDate(state),
    healthHomeId: getEntityId(state),
    report: getReport(state),
    status: getStatus(state),
  }),
  {
    getHealthHomeQualityStatusDataConnect: getHealthHomeQualityStatusData,
  }
)(HealthHomeQualityContainer)
