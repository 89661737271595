import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import PropTypes from "prop-types"

import Filter from "./components/Filter"
import Report from "./components/Report"

import { QueryParamsProvider } from "~/hooks/useQueryParams"
import types from "~/types"

const client = new QueryClient()

function Show({ careManagementAgencies, healthHomes, openClosedStatuses, outreachEnrollmentCodes }) {
  return (
    <QueryClientProvider client={client}>
      <QueryParamsProvider>
        <Filter
          careManagementAgencies={careManagementAgencies}
          healthHomes={healthHomes}
          openClosedStatuses={openClosedStatuses}
          outreachEnrollmentCodes={outreachEnrollmentCodes}
        />
        <Report />
      </QueryParamsProvider>
    </QueryClientProvider>
  )
}

Show.propTypes = {
  careManagementAgencies: PropTypes.arrayOf(types.selectOption).isRequired,
  healthHomes: PropTypes.arrayOf(types.selectOption).isRequired,
  openClosedStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  outreachEnrollmentCodes: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default Show
