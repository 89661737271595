import { useEffect } from "react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import Form from "~/design/forms/Form"
import types from "~/types"

function EndDateReasonCodes({ endDateReasonCodes }) {
  const filteredCodes = useFilteredEndDateReasonCodes(endDateReasonCodes)
  const endDateReasonCodeOptions = filteredCodes.map((code) => ({ value: code.id, label: code.name }))

  return (
    <Form.Group errorKey="endDateReasonCode" name="endDateReasonCodeId">
      <Form.Label>End Date Reason Code</Form.Label>
      <Form.SelectBox options={endDateReasonCodeOptions} isClearable />
    </Form.Group>
  )
}

EndDateReasonCodes.propTypes = {
  endDateReasonCodes: PropTypes.arrayOf(types.selectOption).isRequired,
}

// Filter the list of end date reason codes based on the category chosen and
// the outreach/enrollment code.
function useFilteredEndDateReasonCodes(allCodes) {
  const { values, setFieldValue } = useFormikContext()
  const { endDateReasonCodeId, endDateReasonCategoryId, outreachEnrollmentCodeId } = values

  function byCategory(code) {
    // If no category is set, we don't perform any filtering (the "All" view).
    if (endDateReasonCategoryId === null) {
      return true
    }
    return code.categoryCode === Number(endDateReasonCategoryId)
  }

  function byOutreachEnrollmentCode(code) {
    const { validSegmentOutreachEnrollmentCodes } = code

    const filtering = outreachEnrollmentCodeId !== ""
    if (!filtering) {
      return true
    }

    const hasRestrictions = Boolean(validSegmentOutreachEnrollmentCodes)
    if (!hasRestrictions) {
      return true
    }

    return validSegmentOutreachEnrollmentCodes.some(function (c) {
      return c.attributes.id === Number(outreachEnrollmentCodeId)
    })
  }

  const filteredCodes = allCodes.filter(byCategory).filter(byOutreachEnrollmentCode)

  // If set of filtered codes does not contain the selected endDateReasonCodeId, set it to null
  useEffect(() => {
    if (endDateReasonCodeId && !filteredCodes.find(({ id }) => id === endDateReasonCodeId)) {
      setFieldValue("endDateReasonCodeId", null)
    }
  }, [filteredCodes, endDateReasonCodeId, setFieldValue])

  return filteredCodes
}

export default EndDateReasonCodes
export { useFilteredEndDateReasonCodes }
