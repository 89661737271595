import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"

import { getCarePlanNeedStatuses, getNeedCategories } from "./globalsSelectors"

import Form from "~/design/forms"

function NeedFormFields({ needCategories, carePlanNeedStatuses }) {
  return (
    <>
      <Row>
        <Col md={8}>
          <Form.Group name="description">
            <Form.Label>Need</Form.Label>
            <Form.TextArea placeholder="e.g. Client's blood sugar levels are not controlled" />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group name="carePlanNeedStatusId" errorKey="status">
            <Form.Label>Status</Form.Label>
            <Form.SelectBox options={carePlanNeedStatuses} isClearable placeholder="Select a status" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Form.Group name="notes">
            <Form.Label>Need Note</Form.Label>
            <Form.TextArea />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group name="carePlanNeedCategoryId" errorKey="need">
            <Form.Label>Category</Form.Label>
            <Form.SelectBox options={needCategories} isClearable placeholder="Select a category" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group name="startDate">
            <Form.Label>Start Date</Form.Label>
            <Form.DatePicker />
          </Form.Group>
        </Col>
        <Col md={4}>
          {/* CarePlanNeedStatus::RESOLVED */}
          <Form.Show when={({ values: { carePlanNeedStatusId } }) => carePlanNeedStatusId === 2}>
            <Form.Group name="resolutionDate">
              <Form.Label>Resolved on</Form.Label>
              <Form.DatePicker />
            </Form.Group>
          </Form.Show>
        </Col>
      </Row>
    </>
  )
}

export default connect((state) => ({
  needCategories: getNeedCategories(state),
  carePlanNeedStatuses: getCarePlanNeedStatuses(state),
}))(NeedFormFields)
