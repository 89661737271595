import PropTypes from "prop-types"

import useQueryParams from "~/hooks/useQueryParams"
import types from "~/types"
import SharedReport from "~/views/shared/reports/segmentSummaryLineChart/Report"

function Report({ outreachEnrollmentCodes }) {
  const [queryParams] = useQueryParams()

  return (
    <SharedReport
      careManagementAgencyId={queryParams?.filter?.care_management_agency_id}
      healthHomeId={queryParams?.filter?.health_home_id}
      outreachEnrollmentCodes={outreachEnrollmentCodes}
    />
  )
}

Report.propTypes = {
  outreachEnrollmentCodes: PropTypes.arrayOf(types.outreachEnrollmentCode).isRequired,
}

export default Report
