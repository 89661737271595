import { getRecordsAttributes } from "../helpers"

import ChartSelection from "./ChartSelection"
import FormActions from "./FormActions"
import RecordSelection from "./RecordSelection"

import Form from "~/design/forms"
import { charted_data_copy_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function NewForm({ copiedFromChart, copiedToChart }) {
  const initialValues = {
    copiedFromChart,
    copiedToChart,
    selectedRecords: {},
  }

  async function handleSubmit(values, { setStatus, setErrors }) {
    try {
      const chartDataCopy = await api.chartedDataCopies.post(values.copiedFromChart?.id, {
        copiedToChartId: values.copiedToChart?.id,
        recordsAttributes: getRecordsAttributes(values.selectedRecords),
      })
      setStatus("success")
      window.location.assign(charted_data_copy_path(chartDataCopy.id))
    } catch ({ errors }) {
      setErrors(errors)
      window.scrollTo(0, 0)
    }
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} vertical>
      <ChartSelection />
      <RecordSelection />
      <FormActions />
    </Form>
  )
}

NewForm.propTypes = {
  copiedFromChart: types.chart,
  copiedToChart: types.chart,
}

export default NewForm
