import { Button } from "react-bootstrap"
import PropTypes from "prop-types"

/**
 * A `<SecondaryButton />` is used for actions which are not the primary action
 * for a page or page element, but are common enough to warrant an individual
 * button.
 *
 * If you need more than two actions for any single element, consider using
 * alternate components for the rest. For instance, if you have three actions
 * it may be appropriate to use a `<LinkButton />` for the third option. If you
 * have many actions, then a dropdown menu may be best.
 */
function SecondaryButton({ children, disabled, size, onClick }) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      type="button"
      // In our design system, semantically "secondary" buttons use the
      // "outline-primary" Bootstrap variant.
      variant="outline-primary"
      size={size}
    >
      {children}
    </Button>
  )
}

SecondaryButton.propTypes = {
  /**
   * The content of the button.
   */
  children: PropTypes.node.isRequired,
  /**
   * A disabled button receives no pointer events.
   */
  disabled: PropTypes.bool,
  /**
   * The action to take when the button is clicked.
   */
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["sm", "lg"]),
}

export default SecondaryButton
