import { useState } from "react"
import { connect } from "react-redux"

import { sectionSelectors } from "../redux/sectionsSlice"

import EditSchemaQuestionForm from "./EditSchemaQuestionForm"
import NewSchemaQuestionForm from "./NewSchemaQuestionForm"

import { LinkButton } from "~/design/buttons"
import Show from "~/design/Show"
import types from "~/types"

function SchemaQuestions({ section }) {
  const [isNewQuestionOpen, setIsNewQuestionOpen] = useState(false)

  return (
    <div className="ml-4 mb-3">
      {section.questionIds.map((questionId, questionIndex) => (
        <EditSchemaQuestionForm key={questionId} questionId={questionId} questionIndex={questionIndex} />
      ))}
      <Show when={isNewQuestionOpen}>
        <NewSchemaQuestionForm
          questionIndex={section.questionIds.length}
          onFormClose={() => setIsNewQuestionOpen(false)}
          sectionId={section.id}
        />
      </Show>
      <LinkButton disabled={isNewQuestionOpen} onClick={() => setIsNewQuestionOpen(true)}>
        Add Question
      </LinkButton>
    </div>
  )
}

SchemaQuestions.propTypes = {
  section: types.customAssessmentSchemaSection,
}

export default connect((state, { sectionId }) => ({ section: sectionSelectors.selectById(state, sectionId) }))(
  SchemaQuestions
)
