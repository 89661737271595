import { connect } from "react-redux"

import LinkActiveTasksCarePlanHeader from "./LinkActiveTasksCarePlanHeader"
import LinkedGapInCareBanner from "./LinkedGapInCareBanner"

import { getCarePlanGoalsHavingTasks } from "~/components/carePlans/carePlanGoalsSelectors"
import { mcoGapInCareUpdated } from "~/components/carePlans/mcoGapInCareActions"
import CarePlanGoals from "~/components/encounters/CarePlanGoals"
import Form from "~/design/forms"
import Modal from "~/design/Modal"
import { care_plan_path } from "~/routes"
import api from "~/services/api"

const LinkActiveTasksForm = ({ carePlan, mcoGapInCare, goals, mcoGapInCareUpdatedConnect, show, onClose }) => {
  const disabled = goals.length === 0

  const onSubmit = async ({ carePlanTaskIds }, { setErrors, setStatus }) => {
    try {
      const updatedMcoGapInCare = await api.mcoGapsInCare.patch(mcoGapInCare.id, { carePlanTaskIds })
      mcoGapInCareUpdatedConnect(updatedMcoGapInCare)
      setStatus("success")
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Modal show={show} onClose={onClose} title="Link Gap to Active Care Plan Tasks" size="lg">
      <Form.Provider
        formId="link-active-tasks-form-modal"
        initialValues={{ carePlanTaskIds: mcoGapInCare.carePlanTaskIds }}
        onSubmit={onSubmit}
      >
        <Modal.Body>
          <Form.Body vertical>
            <p>
              If you need to <strong>create a new goal </strong>
              or <strong>edit tasks</strong>, go to the patient's
              <a href={care_plan_path(carePlan.id)} target="_blank" rel="noopener noreferrer">
                &nbsp;care plan&nbsp;
                <i className="fa fa-external-link" />
              </a>
            </p>
            <LinkedGapInCareBanner mcoGapInCareIds={[mcoGapInCare.id]} />
            <LinkActiveTasksCarePlanHeader />
            <CarePlanGoals carePlan={carePlan} goals={goals} />
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Save" disabled={disabled} />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

export default connect(
  (state, { carePlan }) => ({
    goals: getCarePlanGoalsHavingTasks(state, { carePlan }),
  }),
  {
    mcoGapInCareUpdatedConnect: mcoGapInCareUpdated,
  }
)(LinkActiveTasksForm)
