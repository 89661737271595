import PropTypes from "prop-types"

import useCesToolSchema from "../hooks/useCesToolSchema"

import QuestionCard from "./QuestionCard"
import Section from "./Section"

import Form from "~/design/forms"
import Show from "~/design/Show"
import types from "~/types"

function EligibilityPrescreenSection({ cesToolQuestionResponseOptions }) {
  const { isFieldAnswerable } = useCesToolSchema()

  return (
    <Show when={isFieldAnswerable("memberHasQualifyingDiagnosesId")}>
      <Section id="ces-tool-section-basic-eligibility-pre-screen" title="Basic Eligibility Pre-Screen">
        <p className="mb-3">
          <strong>Any "No" answer ends the screening and generates a RECOMMEND DISENROLLMENT result.</strong>
          <br />
          <strong>Any "Unclear" answer ends the screening and generates a MORE INFORMATION NEEDED result.</strong>
        </p>
        <p className="mb-3">Reference links:</p>
        <ul className="mb-3">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.health.ny.gov/health_care/medicaid/program/medicaid_health_homes/docs/health_home_chronic_conditions.pdf"
            >
              DOH Qualifying Diagnoses (Chronic Conditions) List for Both Adult and Children's CMAs
            </a>{" "}
            (list starts on page 4)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.health.ny.gov/health_care/medicaid/program/medicaid_health_homes/docs/restriction_exception_codes.pdf"
            >
              Medicaid Restriction/Exemption Code
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.health.ny.gov/health_care/medicaid/program/medicaid_health_homes/docs/hh_coverage_codes.pdf"
            >
              Medicaid Coverage Codes
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.health.ny.gov/health_care/medicaid/program/medicaid_health_homes/docs/desk_aid_fida_pace_and_fida_idd_enrollment.pdf"
            >
              PACE/FIDA Programs
            </a>
          </li>
        </ul>
        <QuestionCard questionNumber={1}>
          <Form.Group name="memberHasQualifyingDiagnosesId">
            <Form.Label>
              Does the member have qualifying diagnoses for Health Home verified in the member record?
            </Form.Label>
            <Form.SelectBox isClearable options={cesToolQuestionResponseOptions} />
          </Form.Group>
        </QuestionCard>
        <Show when={isFieldAnswerable("memberHasActiveMedicaidId")}>
          <QuestionCard questionNumber={2}>
            <Form.Group name="memberHasActiveMedicaidId">
              <Form.Label>Does the member still have Active Medicaid, with no disqualifying R/E codes?</Form.Label>
              <Form.SelectBox
                isClearable
                options={cesToolQuestionResponseOptions}
                helpText="Remember: Members can only be disenrolled due to inactive Medicaid if they are no longer eligible for Medicaid or if they have been refusing to take the necessary steps to fix the Medicaid case. It is expected that the HHCMs will need to help members reactivate their Medicaid at times."
              />
            </Form.Group>
          </QuestionCard>
        </Show>
      </Section>
    </Show>
  )
}

EligibilityPrescreenSection.propTypes = {
  cesToolQuestionResponseOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default EligibilityPrescreenSection
