import Section from "./Section"

function InstructionsSection() {
  return (
    <Section id="ces-tool-section-instructions" title="Instructions">
      <p className="mb-3">
        The DOH CES Tool is a screening tool, it is not a clinical assessment. It should not be done with the member or
        a care team member, but rather based on what is available within the chart. It is designed to only require the
        minimum information necessary to generate a recommendation. The tool will generate a recommendation of either
        "Continue Services", Disenrollment", or "More Information Needed" based on your responses to each question.
      </p>
      <p className="mb-3">
        For further training on how to use this tool, see our{" "}
        <a
          href="https://www.loom.com/share/58632f5cee5c4eed8f0332bf125202ef?sid=68f9f3ec-5564-43c0-a729-ca00ee4bf38a"
          target="_blank"
          rel="noreferrer"
        >
          FCM CES Tool Training
        </a>
        .
        <br />
        For more information, please see{" "}
        <a
          href="https://www.health.ny.gov/health_care/medicaid/program/medicaid_health_homes/policy/docs/ces_tool_guidance.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Guidance for Use of the Continued Eligibility for Services(CES) Tool
        </a>
        .
      </p>
      <p>
        The DOH CES Tool is due 12 months after enrollment, and every 6 months thereafter. If a member steps down from
        HH+ and is no longer HH+ eligible, their next CES Tool is due 12 months after stepping down to standard Health
        Home Care Management, and every 6 months thereafter.
      </p>
    </Section>
  )
}

export default InstructionsSection
