import { createContext, useContext } from "react"
import PropTypes from "prop-types"

import useFormIdContext from "./useFormIdContext"

const NameContext = createContext()

function useNameContext() {
  const nameContext = useContext(NameContext)
  if (!nameContext) {
    throw Error(
      `useNameContext can only be called within a <NameProvider />. ` +
        `Try rendering your component inside of a <Group /> with an ` +
        `appropriate name. ` +
        `For example:\n\n` +
        `  <Form.Group name={myField}>\n` +
        `    <Form.Label>My Field</Form.Label>\n` +
        `    ...\n` +
        `  </Form.Group>`
    )
  }
  return nameContext
}

function NameProvider({ name, children }) {
  const formId = useFormIdContext()
  const formattedName = name.replace(/\W/g, "_")
  const id = [formId, formattedName].filter(Boolean).join("_")

  return <NameContext.Provider value={{ id, name }}>{children}</NameContext.Provider>
}

NameProvider.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default useNameContext
export { NameProvider }
