import Icon from "./Icon"

/**
 * Renders an X icon, indicating a "close" or "cancel" action.
 */
function CloseIcon() {
  return <Icon name="close" />
}

export default CloseIcon
