import { useState } from "react"
import PropTypes from "prop-types"

import SideBarList from "../SideBarList"
import SideBarToggle from "../SideBarToggle"

import MedicationsForm from "./MedicationsForm"

import { EditIcon, HeartbeatIcon } from "~/design/icons"
import { useModal } from "~/design/Modal"
import types from "~/types"

function MedicationSection({ chart, medications: initialMedications }) {
  const [show, { close, open }] = useModal()
  const [medications, setMedications] = useState(initialMedications)

  const displayMedications = Object.keys(medications)
    .map((id) => medications[id])
    .filter((medication) => medication.active)

  return (
    <SideBarToggle
      title="Medications"
      HeaderIcon={HeartbeatIcon}
      ActionIcon={EditIcon}
      actionLabel="Edit medications"
      onActionIconClick={open}
    >
      <SideBarList emptyListMessage="This patient does not have any active medications.">
        {displayMedications.map((medication) => (
          <li className="py-2" key={medication.id}>
            <p className="m-0">
              <strong>{medication.name}</strong>
            </p>
            <i className="text-muted">{medication.dosage}</i>
            {medication.reason && (
              <p className="m-0">
                <span className="text-muted">Reason</span>&nbsp;
                {medication.reason}
              </p>
            )}
          </li>
        ))}
      </SideBarList>
      <MedicationsForm show={show} onClose={close} medications={medications} chart={chart} onUpdate={setMedications} />
    </SideBarToggle>
  )
}

MedicationSection.propTypes = {
  chart: types.chart.isRequired,
  medications: PropTypes.objectOf(types.medication).isRequired,
}

export default MedicationSection
