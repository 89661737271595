import { connect } from "react-redux"

import ExportCsvButton from "~/big3Dashboard/components/ExportCsvButton"
import PageHeader from "~/design/PageHeader"

const Header = ({ entity, entityName, exportRoute }) => (
  <PageHeader title={`${entity} Quality: ${entityName}`} actions={<ExportCsvButton route={exportRoute} />} />
)

export default connect((state, props) => ({
  entityName: props.entityName || props.entityNameSelector(state).name,
  exportRoute: props.exportRouteSelector(state, props.exportRouteFunction),
}))(Header)
