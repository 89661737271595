import { useFormikContext } from "formik"

import { getRecordsAttributes } from "../helpers"

import Form from "~/design/forms"

function FormActions() {
  const {
    values: { copiedFromChart, selectedRecords },
  } = useFormikContext()

  const recordsAttributes = getRecordsAttributes(selectedRecords)

  const allowSubmit = copiedFromChart && recordsAttributes.length > 0

  return <Form.ActionBar right={<Form.SubmitButton disabled={!allowSubmit} label="Copy Selected Data" />} />
}

export default FormActions
