import { Nav } from "react-bootstrap"
import { connect } from "react-redux"

import { setActiveTab } from "~/big3Dashboard/actions/activeTabActions"
import { QUALITY_REPORT_LOADING } from "~/big3Dashboard/actions/qualityReportStatusActions"
import { getActiveTabId } from "~/big3Dashboard/selectors/activeTabSelector"
import { getStatus } from "~/big3Dashboard/selectors/qualityReportStatusSelector"

export const Tabs = ({ tabs, activeTabId, setActiveTabConnect, status }) => (
  <div className="mb-3">
    <Nav variant="tabs" defaultActiveKey={activeTabId} onSelect={(id) => setActiveTabConnect(parseInt(id, 10))}>
      {tabs.map((tab) => (
        <Nav.Item key={tab.id}>
          <Nav.Link as="button" eventKey={tab.id} disabled={status === QUALITY_REPORT_LOADING}>
            {tab.displayName}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  </div>
)

export default connect(
  (state) => ({
    activeTabId: getActiveTabId(state),
    status: getStatus(state),
  }),
  {
    setActiveTabConnect: setActiveTab,
  }
)(Tabs)
