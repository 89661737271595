import { OverlayTrigger, Popover } from "react-bootstrap"
import PropTypes from "prop-types"

const PopoverIcon = ({ content, badgeStyle, placement, title, trigger }) => {
  const popover = (popoverTitle, popoverContent) => (
    <Popover id="popover-trigger-click-root-close">
      <Popover.Title as="h3">{popoverTitle}</Popover.Title>
      <Popover.Content>{popoverContent}</Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger trigger={trigger} placement={placement} rootClose overlay={popover(title, content)}>
      <a onClick={(e) => e.stopPropagation()} className={`badge ${badgeStyle}`}>
        ?
      </a>
    </OverlayTrigger>
  )
}

PopoverIcon.defaultProps = {
  trigger: "click",
  placement: "right",
  badgeStyle: "badge-secondary",
}

PopoverIcon.propTypes = {
  content: PropTypes.string.isRequired,
  badgeStyle: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.string.isRequired,
  trigger: PropTypes.string,
}

export default PopoverIcon
