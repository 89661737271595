import { createColumnHelper } from "@tanstack/react-table"

import { createNewCareManagementAgencyBig3Url } from "~/big3Dashboard/helpers/createNewCareManagementAgencyBig3Url"

const columnHelper = createColumnHelper()

const careManagementAgencyColumn = ({ healthHomeId, activeTabId }) =>
  columnHelper.accessor(({ entity }) => entity.name.toLowerCase(), {
    header: "Care Management Agency",
    id: "entity",
    cell: ({ row }) => {
      const { entity } = row.original
      const healthHomeIds = [healthHomeId]
      const careManagementAgencyId = entity.id
      return (
        <a href={createNewCareManagementAgencyBig3Url({ careManagementAgencyId, healthHomeIds, activeTabId })}>
          {entity.name}
        </a>
      )
    },
  })

export default careManagementAgencyColumn
