import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_bulk_medications_update_path } from "~/routes"

const bulkMedicationsUpdates = {
  post: function (chartId, bulkMedicationsUpdate) {
    return submitAsyncRequest({
      url: api_v1_bulk_medications_update_path(),
      type: "post",
      data: { bulkMedicationsUpdate, chartId },
    })
  },
}

export default bulkMedicationsUpdates
