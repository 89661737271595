import PropTypes from "prop-types"

import Alert from "./Alert"

import ErrorIcon from "~/design/icons/ErrorIcon"

/**
 * A `<DangerAlert />` is used for critical text e.g. error messages.
 */
function DangerAlert({ children, icon }) {
  return (
    <Alert IconComponent={icon ? ErrorIcon : null} variant="danger">
      {children}
    </Alert>
  )
}

DangerAlert.propTypes = {
  /**
   * The content of the alert.
   */
  children: PropTypes.node.isRequired,
  /**
   * Adds a lock icon to the start of the alert.
   */
  icon: PropTypes.bool,
}

export default DangerAlert
