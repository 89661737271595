import _ from "lodash"

/**
 * Delete child nodes from a normalized tree.
 *
 * Example:
 *   A
 *  / \
 * C   B
 *
 * A: {
 *   children: [C, B]
 * }
 *
 * @function deleteChildNodes(A, "children", [B]);
 *
 * A: {
 *   children: [C]
 * }
 *
 * @param {*} initialState the initial normalized state to be transformed
 * @param {*} childKey a key into initial state where a list of childId's are located
 * @param {*} deletedChildrenIds a list of the deleted child node identifiers
 * @returns an object of the same type as initialState with the @param deleteChildrenIds deleted from
 *          property @param childKey.
 */
const deleteChildNodes = (initialState, childKey, deletedChildrenIds) => ({
  ...initialState,
  [childKey]: initialState[childKey].filter((item) => !deletedChildrenIds.includes(item)),
})

/**
 * Delete nodes nested at the same level in a normalized tree.
 *
 * Example:
 *   A
 *  / \
 * C   B
 *
 * children: {
 *   C: {
 *     ...
 *   },
 *   B: {
 *     ...
 *   }
 * }
 *
 * @function deleteSiblingNodes(children, [B]);
 *
 * children: {
 *   C: {
 *     ...
 *   }
 * }
 *
 * @param {*} initialState the initial normalized state to be transformed
 * @param {*} deletedSiblingNodeIds a list of sibling nodes to be deleted
 * @returns an object of the same type as initialState with all of the @param deletedSiblingNodeIds
 *          removed
 */
const deleteSiblingNodes = (initialState, deletedSiblingNodeIds) =>
  _.omitBy(initialState, (_node, key) => _.find(deletedSiblingNodeIds, (deletedKey) => deletedKey.toString() === key))

/**
 * Deletes the specified sibling *and* child nodes from the normalized tree.
 *
 * Example:
 *   A
 *  / \
 * C   B
 *  \   \
 *   E   D
 *
 * children: {
 *   C: {
 *     grandchildren: [E]
 *   },
 *   B: {
 *     grandchildren: [D]
 *   }
 * }
 *
 * @function deleteChildNodesAndSiblingNodes(children, "grandchildren", [B], [E]);
 *
 * children: {
 *   C: {
 *     grandchildren: []
 *   }
 * }
 *
 * @param {*} initialState the initial normalized state to be transformed
 * @param {*} childKey where the list of children nodes under the nodes of this level
 * @param {*} deletedSiblingNodeIds a list of sibling nodes to be deleted
 * @param {*} deletedChildrenIds a list of the deleted child node identifiers
 * @returns an object of the same type as initialState with all of the @param deletedSiblingNodeIds
 *          from this level and all of the @param deletedChildNodeIds removed from the level @param childKey property
 */
const deleteChildNodesAndSiblingNodes = (state, childKey, deletedSiblingNodeIds, deletedChildNodeIds) => {
  const withoutSiblingNodes = deleteSiblingNodes(state, deletedSiblingNodeIds)
  return _.mapValues(withoutSiblingNodes, (node) => deleteChildNodes(node, childKey, deletedChildNodeIds))
}

export { deleteChildNodes, deleteChildNodesAndSiblingNodes, deleteSiblingNodes }
