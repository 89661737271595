// Pendo analytics initialization
// Pendo is a tool that we use for tracking user actions on FCM
// This initializes pendo on each page of our application

const pendoConfig = {
  initialize(user, role) {
    if (window.pendo) {
      // eslint-disable-next-line no-undef
      pendo.initialize({
        visitor: {
          id: user, // Required if user is logged in
          role: role,
        },
      })
    }
  },
}

export default pendoConfig
