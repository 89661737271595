import classNames from "classnames"
import { useField } from "formik"
import PropTypes from "prop-types"

import { DeleteIcon, UndoIcon } from "../icons"

import { DEFAULT_CONTROL_WIDTH, DEFAULT_LABEL_WIDTH } from "./constants"
import useDisableContext from "./useDisableContext"
import { LayoutWrapper } from "./useLayoutContext"
import useNameContext from "./useNameContext"

function MarkForDelete({ disabled: disabledByProps, offset, width }) {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name, type: "checkbox" })
  const disabledByContext = useDisableContext()

  const disabled = disabledByContext || disabledByProps

  function toggleChecked() {
    helpers.setValue(!field.checked)
  }

  function handleKeyDown(e) {
    // Handle the space key being pressed when the element is focused, so this behaves like a checkbox
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/checkbox_role#description
    if (e.key === " ") {
      e.preventDefault()
      helpers.setValue(!field.checked)
    }
  }

  return (
    <LayoutWrapper sm={{ span: width, offset }}>
      <div
        aria-checked={field.checked}
        aria-disabled={disabled}
        aria-label={"Mark for deletion"}
        className={classNames("mark-for-delete", { "mark-for-delete--disabled": disabled })}
        id={id}
        name={name}
        onClick={toggleChecked}
        onKeyDown={handleKeyDown}
        role="checkbox"
        tabIndex={disabled ? -1 : 0}
      >
        {field.checked ? <UndoIcon /> : <DeleteIcon />}
      </div>
    </LayoutWrapper>
  )
}

MarkForDelete.propTypes = {
  disabled: PropTypes.bool,
  /**
   * The number of columns to the control so that it aligns correctly in horizontal forms.
   */
  offset: PropTypes.number,
  /**
   * The number of columns the control should span.
   */
  width: PropTypes.number,
}

MarkForDelete.defaultProps = {
  disabled: false,
  offset: DEFAULT_LABEL_WIDTH,
  width: DEFAULT_CONTROL_WIDTH,
}

export default MarkForDelete
