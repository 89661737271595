import { connect } from "react-redux"

import { getIsNewTaskFormOpen } from "~/components/carePlans/carePlanGoalsUISelectors"
import { openNewTaskForm, openTasksList } from "~/components/carePlans/carePlanGoalUIActions"
import { getReadOnly } from "~/components/carePlans/globalsSelectors"
import { SecondaryButton } from "~/design/buttons"
import Show from "~/design/Show"

const TaskAddButton = ({ isNewTaskFormOpen, goalId, openNewTaskFormConnect, openTasksListConnect, readOnly }) => {
  const onClickAddTask = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!isNewTaskFormOpen) {
      openNewTaskFormConnect(goalId)
      openTasksListConnect(goalId)
    }
  }

  return (
    <Show when={!readOnly}>
      <SecondaryButton size="sm" onClick={onClickAddTask} disabled={isNewTaskFormOpen}>
        Add Task
      </SecondaryButton>
    </Show>
  )
}

export default connect(
  (state, props) => ({
    isNewTaskFormOpen: getIsNewTaskFormOpen(state, props),
    readOnly: getReadOnly(state),
  }),
  {
    openNewTaskFormConnect: openNewTaskForm,
    openTasksListConnect: openTasksList,
  }
)(TaskAddButton)
