import { useEffect, useRef, useState } from "react"
import SignatureCanvas from "react-signature-canvas"
import classNames from "classnames"
import { useField } from "formik"
import PropTypes from "prop-types"

import IconButton from "../buttons/IconButton"
import { CloseIcon } from "../icons"
import Show from "../Show"

import { DEFAULT_CONTROL_WIDTH } from "./constants"
import FieldErrors from "./FieldErrors"
import HelpText from "./HelpText"
import useDisableContext from "./useDisableContext"
import useErrorContext from "./useErrorContext"
import { LayoutWrapper } from "./useLayoutContext"
import useNameContext from "./useNameContext"

const MAX_CANVAS_WIDTH = 600
const DEFAULT_CANVAS_WIDTH = 600
const DEFAULT_CANVAS_HEIGHT = 200
const CANVAS_ASPECT_RATIO = DEFAULT_CANVAS_WIDTH / DEFAULT_CANVAS_HEIGHT

/**
 * A `<SignatureField />` renders a canvas where users can draw a signature using a pointer
 * device. Signatures are stored as `base64`-encoded data URLs.
 */
function SignatureField({ disabled: disabledByProps, helpText, isClearable, validate, width }) {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name, validate })
  const { hasError } = useErrorContext()
  const disabledByContext = useDisableContext()

  const helpTextId = `${id}HelpText`
  const errorMessageId = `${id}Errors`
  const disabled = disabledByContext || disabledByProps

  const signatureImage = field.value

  const { containerRef, canvasWidth, canvasHeight } = useGetCanvasSize()

  const signatureCanvasRef = useRef(null)

  // Redraw the signature when the canvas resizes (but don't redraw every time the value changes)
  useEffect(() => {
    const signatureCanvas = signatureCanvasRef.current
    if (!signatureCanvas?.isEmpty() && signatureImage !== signatureCanvas.toDataURL()) {
      signatureCanvas.fromDataURL(signatureImage, { width: canvasWidth, height: canvasHeight })
    }
  }, [signatureImage, canvasWidth, canvasHeight])

  function handleClear() {
    signatureCanvasRef.current.clear()
    helpers.setValue(null)
  }

  return (
    <LayoutWrapper sm={width}>
      <div>
        <div
          className={classNames("signature-field", {
            "signature-field--invalid": Boolean(hasError),
            "signature-field--disabled": disabled,
          })}
          ref={containerRef}
        >
          <div
            className="d-inline-flex"
            aria-labelledby={`${name}Label`}
            aria-describedby={hasError ? errorMessageId : helpText ? helpTextId : null}
            role="img"
            id={id}
          >
            <SignatureCanvas
              ref={signatureCanvasRef}
              minWidth={3 * (canvasWidth / DEFAULT_CANVAS_WIDTH)}
              maxWidth={5 * (canvasWidth / DEFAULT_CANVAS_WIDTH)}
              onEnd={() => helpers.setValue(signatureCanvasRef.current.toDataURL())}
              canvasProps={{
                width: canvasWidth,
                height: canvasHeight,
              }}
            />
          </div>
          <div className="position-absolute p-1">
            <Show when={isClearable && !disabled}>
              <IconButton Icon={CloseIcon} onClick={handleClear} label="Clear signature" />
            </Show>
          </div>
        </div>
        <HelpText id={helpTextId}>{helpText}</HelpText>
        <FieldErrors id={errorMessageId} />
      </div>
    </LayoutWrapper>
  )
}

// Used to get the size for the canvas based on width of field container
function useGetCanvasSize() {
  const [{ canvasWidth, canvasHeight }, setCanvasSize] = useState({
    canvasWidth: DEFAULT_CANVAS_WIDTH,
    canvasHeight: DEFAULT_CANVAS_HEIGHT,
  })

  const containerRef = useRef(null)
  const observerRef = useRef(null)

  useEffect(() => {
    const element = containerRef.current?.parentElement
    if (!element || !window.ResizeObserver) return

    if (!observerRef.current) {
      observerRef.current = new ResizeObserver((entries) => {
        // Using requestAnimationFrame prevents "ResizeObserver loop limit exceeded" error
        requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) return

          const canvasWidth = Math.min(entries[0].contentRect.width, MAX_CANVAS_WIDTH)
          const canvasHeight = canvasWidth / CANVAS_ASPECT_RATIO

          setCanvasSize({ canvasWidth, canvasHeight })
        })
      })

      observerRef.current.observe(element)

      return function cleanup() {
        observerRef.current.disconnect()
      }
    }
  }, [])

  return { containerRef, canvasWidth, canvasHeight }
}

SignatureField.propTypes = {
  disabled: PropTypes.bool,
  /**
   * Text intended to guide the user toward the correct input.
   */
  helpText: PropTypes.string,
  /**
   * When true, adds a button to clear the signature.
   */
  isClearable: PropTypes.bool,
  /**
   * An optional function to run client-side validations:
   * https://formik.org/docs/api/field#validate
   */
  validate: PropTypes.func,
  /**
   * The number of columns the control should span.
   */
  width: PropTypes.number,
}

SignatureField.defaultProps = {
  disabled: false,
  isClearable: true,
  width: DEFAULT_CONTROL_WIDTH,
}

export default SignatureField
