import PropTypes from "prop-types"

import { redirectTo } from "~/browserHelpers"
import { supervisory_teams_dashboard_path } from "~/routes"
import types from "~/types"
import AutoSubmitForm from "~/views/shared/autoSubmitForm/AutoSubmitForm"

function DashboardTeamSelector({ currentTeamId, teams }) {
  function handleSubmit({ teamId }) {
    redirectTo(supervisory_teams_dashboard_path(teamId))
  }

  return (
    <AutoSubmitForm initialValues={{ teamId: currentTeamId }} onSubmit={handleSubmit}>
      <AutoSubmitForm.SelectBox label="Select Supervisory Team:" name="teamId" options={teams} />
    </AutoSubmitForm>
  )
}

DashboardTeamSelector.propTypes = {
  currentTeamId: PropTypes.number,
  teams: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default DashboardTeamSelector
