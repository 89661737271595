import { useEffect } from "react"
import { useFormikContext } from "formik"
import _ from "lodash"

import EmailEncounterDetailsFields from "~/components/encounters/EmailEncounterDetailsFields"
import FaxEncounterDetailsFields from "~/components/encounters/FaxEncounterDetailsFields"
import InPersonEncounterDetailsFields from "~/components/encounters/InPersonEncounterDetailsFields"
import MailEncounterDetailsFields from "~/components/encounters/MailEncounterDetailsFields"
import NullEncounterDetailsFields from "~/components/encounters/NullEncounterDetailsFields"
import OfficeWorkEncounterDetailsFields from "~/components/encounters/OfficeWorkEncounterDetailsFields"
import PhoneEncounterDetailsFields from "~/components/encounters/PhoneEncounterDetailsFields"
import TextEncounterDetailsFields from "~/components/encounters/TextEncounterDetailsFields"
import VideoEncounterDetailsFields from "~/components/encounters/VideoEncounterDetailsFields"
import { selectedOption } from "~/reactSelectHelpers"

const ENCOUNTER_MODE_OPTIONS = [
  { value: 1, label: "Mail", component: MailEncounterDetailsFields, attributeName: "mailEncounterDetailsAttributes" },
  { value: 2, label: "Fax", component: FaxEncounterDetailsFields, attributeName: "faxEncounterDetailsAttributes" },
  { value: 3, label: "Text", component: TextEncounterDetailsFields, attributeName: "textEncounterDetailsAttributes" },
  {
    value: 4,
    label: "Email",
    component: EmailEncounterDetailsFields,
    attributeName: "emailEncounterDetailsAttributes",
  },
  {
    value: 5,
    label: "Video",
    component: VideoEncounterDetailsFields,
    attributeName: "videoEncounterDetailsAttributes",
  },
  {
    value: 6,
    label: "In-Person",
    component: InPersonEncounterDetailsFields,
    attributeName: "inPersonEncounterDetailsAttributes",
  },
  {
    value: 7,
    label: "Phone",
    component: PhoneEncounterDetailsFields,
    attributeName: "phoneEncounterDetailsAttributes",
  },
  {
    value: 8,
    label: "Office Work",
    component: OfficeWorkEncounterDetailsFields,
    attributeName: "officeWorkEncounterDetailsAttributes",
  },
]

function encounterModeIdForEncounter(encounter) {
  const option = _.find(ENCOUNTER_MODE_OPTIONS, ({ attributeName }) => !_.isEmpty(encounter[attributeName]))
  return (option && option.value) || null
}

const EncounterModeFields = () => {
  const {
    values: { modeId },
    setFieldValue,
  } = useFormikContext()
  const encounterMode = selectedOption(ENCOUNTER_MODE_OPTIONS, modeId)

  // Clear out unselected mode details attributes
  useEffect(() => {
    let modeOptionsToClear

    if (encounterMode) {
      modeOptionsToClear = ENCOUNTER_MODE_OPTIONS.filter((modeOption) => modeOption !== encounterMode)
      modeOptionsToClear.push({ attributeName: "nullEncounterDetailsAttributes" })
    } else {
      modeOptionsToClear = ENCOUNTER_MODE_OPTIONS
    }

    modeOptionsToClear.forEach((modeOption) => setFieldValue(modeOption.attributeName, {}))
  }, [modeId])

  if (!encounterMode) {
    return <NullEncounterDetailsFields />
  }

  const Component = encounterMode.component
  return <Component />
}

export default EncounterModeFields

export { ENCOUNTER_MODE_OPTIONS, encounterModeIdForEncounter }
