import PropTypes from "prop-types"

import { useIsFeatureEnabled } from "./useFeatureFlagsContext"

import Show from "~/design/Show"

const FeatureFlag = ({ flag, children }) => <Show when={useIsFeatureEnabled(flag)}>{children}</Show>

FeatureFlag.propTypes = {
  flag: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default FeatureFlag
