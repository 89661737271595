import { useFormikContext } from "formik"
import _ from "lodash"

import { WarningAlert } from "~/design/alerts"
import Show from "~/design/Show"

const LegacyEncounterErrorMessage = () => {
  const { values: encounter } = useFormikContext()

  const legacyAttributes = _.assign(
    {},
    encounter.legacyCareConferenceEncounterDetailsAttributes,
    encounter.legacyOtherEncounterDetailsAttributes,
    encounter.legacyUnsuccessfulEnrolledActivityEncounterDetailsAttributes
  )

  return (
    <Show when={_.some(legacyAttributes)}>
      <WarningAlert>
        <p>
          <strong>
            This encounter has the legacy type {legacyAttributes.patientEncounterTypeName}. We can't infer the mode of
            encounters of this type, so in order to edit this encounter, please select a mode and fill in the
            appropriate details.
          </strong>
        </p>
      </WarningAlert>
    </Show>
  )
}

export default LegacyEncounterErrorMessage
