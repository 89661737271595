import { OverlayTrigger, Popover } from "react-bootstrap"

export default function PopoverButton({ title, label, placement = "right", style = {}, variant = "info", children }) {
  const popover = (
    <Popover id="popover-trigger-click-root-close" style={style}>
      <Popover.Title as="h3">{title}</Popover.Title>
      <Popover.Content>{children}</Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger trigger="click" rootClose placement={placement} overlay={popover}>
      <a href="" className={`badge badge-${variant}`} onClick={(e) => e.preventDefault()}>
        {label}
      </a>
    </OverlayTrigger>
  )
}
