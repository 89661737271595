import { OverlayTrigger, Tooltip } from "react-bootstrap"
import PropTypes from "prop-types"

import * as customAssessmentResponseTypes from "../types"

import CompleteAssessmentSection from "./CompleteAssessmentSection"
import ReadOnlyResponseQuestion from "./ReadOnlyResponseQuestion"
import ResponseSection from "./ResponseSection"

import { toAppDefaultDateTime } from "~/dateHelpers"
import { DangerAlert, PrimaryAlert } from "~/design/alerts"
import { DangerButton } from "~/design/buttons"
import Show from "~/design/Show"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function ReadOnlyResponseForm({ questionResponseValues, schemaVersion, response, setResponse, isAdminOrAgencyAdmin }) {
  const completionMessage = (
    <p>
      Marked as done and finalized by <strong>{response.completedByName}</strong> at{" "}
      <strong>{toAppDefaultDateTime(response.completedAt)}</strong>.
    </p>
  )

  async function handleUncompleteClick() {
    if (window.confirm("Are you sure you want to uncomplete and unlock this assessment?")) {
      try {
        const responseValues = await api.customAssessments.responseUncompletes.post(response.id)
        toast.success("Assessment unlocked successfully.")
        setResponse(responseValues)
      } catch {
        toast.error("Assessment could not be unlocked.")
      }
    }
  }

  const tooltip = (
    <Tooltip id={`unlock-${response.id}`}>
      If this is unlocked, edited, and relocked it will change the date of the assessment to the date that the
      assessment is relocked
    </Tooltip>
  )

  return (
    <>
      <PrimaryAlert>{completionMessage}</PrimaryAlert>
      {schemaVersion.sections.map((section) => (
        <ResponseSection key={section.id} section={section}>
          {section.questions.map((question, index) => {
            const dependsOnQuestion = question.dependsOnQuestion
              ? section.questions.find(({ id }) => id === question.dependsOnQuestion.id)
              : null
            return (
              <ReadOnlyResponseQuestion
                key={question.id}
                question={question}
                questionNumber={index + 1}
                dependsOnQuestion={dependsOnQuestion}
                questionResponseValues={questionResponseValues}
              />
            )
          })}
        </ResponseSection>
      ))}
      <CompleteAssessmentSection>
        {completionMessage}
        <Show when={isAdminOrAgencyAdmin}>
          <DangerAlert>
            <div className="d-flex justify-content-between align-items-center gap-3">
              <p>
                ADMIN ONLY: “Uncomplete & Unlock” will un-finalize this assessment, clearing the original date of
                completion, which cannot be undone. Users will be able to make additional edits to this
                assessment.&nbsp;
              </p>
              <OverlayTrigger placement="bottom" overlay={tooltip}>
                <span className={`unlock-${response.id}`}>
                  <DangerButton onClick={handleUncompleteClick}>Uncomplete & Unlock</DangerButton>
                </span>
              </OverlayTrigger>
            </div>
          </DangerAlert>
        </Show>
      </CompleteAssessmentSection>
    </>
  )
}

ReadOnlyResponseForm.propTypes = {
  response: types.customAssessmentResponse.isRequired,
  setResponse: PropTypes.func.isRequired,
  isAdminOrAgencyAdmin: PropTypes.bool.isRequired,
  questionResponseValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired
  ),
  schemaVersion: customAssessmentResponseTypes.schemaVersion,
}

ReadOnlyResponseForm.defaultProps = {
  isAdminOrAgencyAdmin: false,
}

export default ReadOnlyResponseForm
