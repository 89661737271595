import { connect } from "react-redux"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import { questionDestroyed } from "../redux/questionsSlice"

import { LinkButton } from "~/design/buttons"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function DeleteQuestionButton({ question, questionDestroyedConnect }) {
  const { setErrors } = useFormikContext()

  async function handleDelete() {
    try {
      await api.customAssessments.questions.delete(question.id)
      toast.success("Question deleted successfully.")
      questionDestroyedConnect(question)
    } catch ({ errors }) {
      setErrors(errors)
      toast.error("Question could not be deleted.")
    }
  }

  return (
    <LinkButton color="danger" onClick={handleDelete}>
      Delete
    </LinkButton>
  )
}

DeleteQuestionButton.propTypes = {
  question: types.customAssessmentSchemaQuestion,
  questionDestroyedConnect: PropTypes.func.isRequired,
}

export default connect(null, { questionDestroyedConnect: questionDestroyed })(DeleteQuestionButton)
