import { useState } from "react"
import { Button } from "react-bootstrap"
import { useField } from "formik"
import PropTypes from "prop-types"

import Form from "~/design/forms"
import { DEFAULT_CONTROL_WIDTH, DEFAULT_LABEL_WIDTH } from "~/design/forms/constants"
import types from "~/types"

function ChartSelector({ charts, controlWidth, disabled, labelWidth }) {
  const [isHidden, setIsHidden] = useState(true)
  const [field, meta] = useField("chartId")
  const selectedChart = charts.find((chart) => chart.id === field.value)

  if (isHidden && !meta.error) {
    return (
      <div className="text-muted my-3" data-testid="chart-selector-toggle" style={{ fontSize: "13px" }}>
        When saved, this data will be associated with {selectedChart.healthHomeName} Health Home and{" "}
        {selectedChart.careManagementAgencyName} CMA.{" "}
        <Button
          variant="link"
          onClick={() => setIsHidden(false)}
          style={{ fontSize: "inherit", verticalAlign: "baseline" }}
        >
          Edit
        </Button>
      </div>
    )
  }

  const chartOptions = charts.map((chart) => ({
    value: chart.id,
    label: `${chart.healthHomeShortestName} > ${chart.careManagementAgencyShortestName}`,
  }))

  return (
    <Form.Group name="chartId" clearOnHide={false}>
      <Form.Label width={labelWidth}>Agencies (HH {">"} CMA)</Form.Label>
      <Form.SelectBox
        options={chartOptions}
        disabled={disabled}
        width={controlWidth}
        helpText="Only users associated with at least one of the Health Home or CMA will be able to edit this data."
      />
    </Form.Group>
  )
}

ChartSelector.propTypes = {
  /**
   * The charts to display within the select box.
   */
  charts: PropTypes.arrayOf(types.chart).isRequired,
  /**
   * The number of columns the control should span.
   */
  controlWidth: PropTypes.number,
  /**
   * Whether the select box can receive input.
   */
  disabled: PropTypes.bool,
  /**
   * The number of columns the label should span.
   */
  labelWidth: PropTypes.number,
}

ChartSelector.defaultProps = {
  disabled: false,
  labelWidth: DEFAULT_LABEL_WIDTH,
  controlWidth: DEFAULT_CONTROL_WIDTH,
}

export default ChartSelector
