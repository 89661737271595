import _ from "lodash"
import PropTypes from "prop-types"

import DocumentFields from "./components/DocumentFields"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { chart_documents_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"
import ChartIndicator from "~/views/shared/ChartIndicator"

function New({ chart, document, documentTypes, documentCategories }) {
  async function handleSubmit(values, { setStatus, setErrors }) {
    const valuesToSubmit = _.omit(values, "categoryId")
    try {
      await api.documents.post(chart.id, valuesToSubmit)
      setStatus("success")
      redirectTo(chart_documents_path(chart.id))
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={document} onSubmit={handleSubmit}>
      <Form.Group name="uploadedFile">
        <Form.Label>Uploaded File</Form.Label>
        <Form.FileField />
      </Form.Group>
      <DocumentFields documentTypes={documentTypes} documentCategories={documentCategories} />
      <ChartIndicator chart={chart} />
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_documents_path(chart.id))} />
            <Form.SubmitButton label={"Upload Document"} />
          </>
        }
      />
    </Form>
  )
}

New.propTypes = {
  chart: types.chart.isRequired,
  document: types.document.isRequired,
  documentTypes: PropTypes.arrayOf(types.documentType).isRequired,
  documentCategories: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default New
