import PropTypes from "prop-types"

const careTeamProvider = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  titleId: PropTypes.number,
  credentialId: PropTypes.number,
  specialty: PropTypes.string,
  facility: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
})

export default careTeamProvider
