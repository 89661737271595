import PropTypes from "prop-types"

const schemaQuestion = PropTypes.shape({
  id: PropTypes.number.isRequired,
  questionText: PropTypes.string.isRequired,
  questionTypeId: PropTypes.number.isRequired,
  isRequired: PropTypes.bool.isRequired,
  questionResponseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  dependsOnQuestion: PropTypes.shape({
    id: PropTypes.number,
    optionIds: PropTypes.arrayOf(PropTypes.number),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      optionText: PropTypes.string.isRequired,
    })
  ),
})

const schemaSection = PropTypes.shape({
  id: PropTypes.number.isRequired,
  sectionName: PropTypes.string.isRequired,
  description: PropTypes.string,
  questions: PropTypes.arrayOf(schemaQuestion),
})

const schemaVersion = PropTypes.shape({
  id: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(schemaSection),
})

export { schemaQuestion, schemaSection, schemaVersion }
