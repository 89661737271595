import { useState } from "react"
import { Dropdown, Spinner } from "react-bootstrap"
import PropTypes from "prop-types"

import Show from "~/design/Show"
import api from "~/services/api"
import { toast } from "~/toast"

const ClinicalEventStatusDropdown = ({ clinicalEvent, clinicalEventStatuses, onClinicalEventUpdated }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async ({ clinicalEventStatusId }) => {
    try {
      setIsSubmitting(true)
      const response = await api.transitionsOfCare.clinicalEvents.patch(clinicalEvent.id, { clinicalEventStatusId })
      onClinicalEventUpdated(response)
      toast.success("Clinical event status updated successfully")
    } catch {
      toast.error("Clinical event status could not be updated")
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="m-0 d-flex align-items-center">
      <Dropdown>
        <Dropdown.Toggle
          variant={clinicalEvent.clinicalEventStatus.actionNeeded ? "warning" : "outline-primary"}
          size="sm"
          disabled={isSubmitting}
        >
          <Show when={isSubmitting}>
            <Spinner as="span" size="sm" animation="border" role="status" />
            &nbsp;
          </Show>
          {clinicalEvent.clinicalEventStatus.name}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.values(clinicalEventStatuses).map((status) => (
            <Dropdown.Item key={status.id} onClick={() => handleSubmit({ clinicalEventStatusId: status.id })}>
              {status.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

ClinicalEventStatusDropdown.propTypes = {
  clinicalEvent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    clinicalEventStatus: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      actionNeeded: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  clinicalEventStatuses: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClinicalEventUpdated: PropTypes.func.isRequired,
}

export default ClinicalEventStatusDropdown
