import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import Report from "./Report"

const client = new QueryClient()

function ReportProvider(props) {
  return (
    <QueryClientProvider client={client}>
      <Report {...props} />
    </QueryClientProvider>
  )
}

export default ReportProvider
