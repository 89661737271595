import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_bulk_risk_factors_updates_path } from "~/routes"

const bulkRiskFactorsUpdates = {
  post: function (chartId, bulkRiskFactorsUpdate) {
    return submitAsyncRequest({
      url: api_v1_bulk_risk_factors_updates_path(),
      type: "post",
      data: { bulkRiskFactorsUpdate, chartId },
    })
  },
}

export default bulkRiskFactorsUpdates
