import PropTypes from "prop-types"

function Show({ when, children }) {
  if (when === undefined) {
    console.warn(
      "The <Show /> component expects a predicate to be passed in the 'when' prop. " +
        "If you do not pass a predicate, the children will never be shown. Try something like:\n\n" +
        "  <Show when={1 + 1 === 2}>\n" +
        "    Hello\n" +
        "  </Show"
    )
  }
  const shouldShow = Boolean(when)
  if (!shouldShow) return null
  return children
}

Show.propTypes = {
  /**
   * The predicate that determines when to display the child content.
   *
   * If "warn" evaluates to true, the child content is shown. Otherwise,
   * the component returns null.
   */
  when: PropTypes.bool.isRequired,
  /**
   * The content to conditionally display.
   */
  children: PropTypes.node,
}

export default Show
