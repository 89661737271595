import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_mco_gap_in_care_path, api_v1_mco_gaps_in_care_path } from "~/routes"

const mcoGapsInCare = {
  get: function (ids) {
    return submitAsyncRequest({
      url: api_v1_mco_gaps_in_care_path(),
      type: "get",
      data: {
        filter: { id: ids },
      },
    })
  },
  patch: function (mcoGapInCareId, { carePlanTaskIds }) {
    return submitAsyncRequest({
      url: api_v1_mco_gap_in_care_path(mcoGapInCareId),
      type: "patch",
      data: {
        mcoGapInCare: {
          carePlanTaskIds: carePlanTaskIds.length === 0 ? [""] : carePlanTaskIds,
        },
      },
    })
  },
}

export default mcoGapsInCare
