import Form from "~/design/forms"
import { globalsStateWrapper } from "~/redux"

function EncounterTargetsField({ label, name, errorKey, encounterTargets }) {
  return (
    <Form.Group name={name} errorKey={errorKey}>
      <Form.Label>{label}</Form.Label>
      <Form.MultiSelectBox options={encounterTargets} isClearable />
    </Form.Group>
  )
}

export default globalsStateWrapper(({ encounterTargets }) => ({ encounterTargets }))(EncounterTargetsField)
