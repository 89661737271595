import { useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { SIDEBAR_TOP_OFFSET } from "../constants"
import { sectionsReordered, selectSchemaVersion } from "../redux/schemaVersionSlice"
import { sectionSelectors } from "../redux/sectionsSlice"

import ListGroup from "~/components/listGroup"
import { IconButton, LinkButton, PrimaryButton, SecondaryButton } from "~/design/buttons"
import { CollapseIcon, ExpandIcon } from "~/design/icons"
import Show from "~/design/Show"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"
import PreviewMedicalBackgroundWidget from "~/views/customAssessments/PreviewMedicalBackgroundWidget"
import PreviewSocialBackgroundWidget from "~/views/customAssessments/PreviewSocialBackgroundWidget"

function SideBar({
  schemaVersion: { sectionIds, id: schemaVersionId },
  sectionEntities,
  sectionsReorderedConnect,
  medicalBackgroundWidgetEnabled,
  socialBackgroundWidgetEnabled,
}) {
  const [isReorderingSections, setIsReorderingSections] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reorderedSectionIds, setReorderedSectionIds] = useState(sectionIds)

  useEffect(() => {
    // The offset controls where the scrollspy thinks the boundary for the section begins
    // relative to the top of the page.
    $("body").scrollspy({ offset: SIDEBAR_TOP_OFFSET + 5, target: "#schema-version-sidebar" })
  }, [])

  useEffect(() => {
    // Ensure scrollspy still works after sections have been reordered
    if (isReorderingSections === false) {
      $("body").scrollspy("refresh")
    }
  }, [isReorderingSections])

  // Ensure we're using the sectionIds from the Redux store when toggling to show the
  // reordering sections UI
  function showReorderingSections() {
    setIsReorderingSections(true)
    setReorderedSectionIds(sectionIds)
  }

  function moveSectionId(fromIndex, toIndex) {
    const sectionIdsCopy = [...reorderedSectionIds]
    sectionIdsCopy[toIndex] = reorderedSectionIds[fromIndex]
    sectionIdsCopy[fromIndex] = reorderedSectionIds[toIndex]
    setReorderedSectionIds(sectionIdsCopy)
  }

  async function saveReorderedSections() {
    try {
      setIsSubmitting(true)
      await api.customAssessments.schemaVersions.patch(schemaVersionId, {
        sectionIds: reorderedSectionIds,
      })
      sectionsReorderedConnect(reorderedSectionIds)
      toast.success("Sections reordered successfully.")
      setIsReorderingSections(false)
    } catch {
      toast.error("Sections could not be reordered.")
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isReorderingSections) {
    return (
      <div id="schema-version-sidebar" className="mb-3" style={{ top: `${SIDEBAR_TOP_OFFSET}px`, position: "sticky" }}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Reorder</h3>
          <div className="d-flex gap-2 align-items-center">
            <SecondaryButton size="sm" onClick={() => setIsReorderingSections(false)}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="sm" disabled={isSubmitting} onClick={saveReorderedSections}>
              {isSubmitting ? "Loading..." : "Save"}
            </PrimaryButton>
          </div>
        </div>
        <ListGroup size="sm">
          {reorderedSectionIds.map((sectionId, sectionIndex) => (
            <ListGroup.Item key={sectionId}>
              <div className="d-flex justify-content-between gap-2">
                <div>{sectionEntities[sectionId].sectionName}</div>
                <div className="d-flex align-items-center gap-2">
                  <IconButton
                    Icon={CollapseIcon}
                    size="sm"
                    disabled={sectionIndex === 0}
                    label={`Move ${sectionEntities[sectionId].sectionName} section up`}
                    onClick={() => moveSectionId(sectionIndex, sectionIndex - 1)}
                  />
                  <IconButton
                    Icon={ExpandIcon}
                    size="sm"
                    disabled={sectionIndex === sectionIds.length - 1}
                    label={`Move ${sectionEntities[sectionId].sectionName} section down`}
                    onClick={() => moveSectionId(sectionIndex, sectionIndex + 1)}
                  />
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    )
  }

  return (
    <div
      id="schema-version-sidebar"
      className="mb-3"
      style={{ top: `${SIDEBAR_TOP_OFFSET}px`, position: "sticky", maxHeight: "100vh", overflowY: "scroll" }}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Sections</h3>
        <LinkButton size="sm" disabled={isSubmitting} onClick={showReorderingSections}>
          Reorder
        </LinkButton>
      </div>
      <ListGroup size="sm" className="mb-3">
        {sectionIds.map((sectionId) => (
          <ListGroup.Item key={sectionId} action href={`#schema-section-${sectionId}`}>
            {sectionEntities[sectionId].sectionName}
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Show when={socialBackgroundWidgetEnabled}>
        <PreviewSocialBackgroundWidget />
      </Show>
      <Show when={medicalBackgroundWidgetEnabled}>
        <PreviewMedicalBackgroundWidget />
      </Show>
    </div>
  )
}

SideBar.propTypes = {
  schemaVersion: types.customAssessmentSchemaVersion,
  sectionEntities: PropTypes.objectOf(types.customAssessmentSchemaSection).isRequired,
  sectionsReorderedConnect: PropTypes.func.isRequired,
  medicalBackgroundWidgetEnabled: PropTypes.bool.isRequired,
  socialBackgroundWidgetEnabled: PropTypes.bool.isRequired,
}

SideBar.defaultProps = {
  medicalBackgroundWidgetEnabled: false,
}

export default connect(
  (state) => ({
    schemaVersion: selectSchemaVersion(state),
    sectionEntities: sectionSelectors.selectEntities(state),
  }),
  { sectionsReorderedConnect: sectionsReordered }
)(SideBar)
