import _ from "lodash"
import createCachedSelector from "re-reselect"

import { ActiveOnlyFilterState } from "~/components/carePlans/carePlanActiveFilterActions"
import { getTasks } from "~/components/carePlans/carePlanTasksSelectors"
import { getActiveFilterState } from "~/components/carePlans/carePlanUISelectors"
import { getCarePlanGoalStatuses, getCarePlanTaskStatuses } from "~/components/carePlans/globalsSelectors"
import { selectedOption } from "~/reactSelectHelpers"

export const getGoals = ({ entities: { goals } }) => goals
export const getGoal = ({ entities: { goals } }, { goalId }) => goals[goalId]
export const getCarePlanGoals = ({ entities: { goals } }, { carePlan }) => {
  const goalIds = carePlan ? carePlan.goalIds : []
  return goalIds.map((goalId) => goals[goalId])
}
export const getCarePlanGoalsHavingTasks = (state, props) =>
  getCarePlanGoals(state, props).filter((goal) => !_.isEmpty(goal.taskIds))

const goalWithMappedOptions = (goal, carePlanGoalStatuses) => ({
  ...goal,
  status: selectedOption(carePlanGoalStatuses, goal.carePlanGoalStatusId),
})

export const getGoalWithOptions = createCachedSelector(
  [getGoal, getCarePlanGoalStatuses],
  goalWithMappedOptions
)((state, { goalId }) => goalId)

const filterByActiveStatus = (activeFilterState, carePlanTaskStatuses) => (task) => {
  const taskStatusId = task.carePlanTaskStatusId
  return (
    activeFilterState === ActiveOnlyFilterState.ALL ||
    _.find(carePlanTaskStatuses, ({ value, isActive }) => value === taskStatusId && isActive)
  )
}

export const getTaskIds = createCachedSelector([getGoal], ({ taskIds }) => taskIds)((_state, { goalId }) => goalId)

export const getFilteredTaskIds = createCachedSelector(
  [getGoal, getTasks, getActiveFilterState, getCarePlanTaskStatuses],
  ({ taskIds }, tasks, activeFilterState, carePlanTaskStatuses) => {
    const activeStatusPredicate = filterByActiveStatus(activeFilterState, carePlanTaskStatuses)
    return taskIds.filter((taskId) => {
      const task = tasks[taskId]
      return activeStatusPredicate(task)
    })
  }
)((_state, { goalId }) => goalId)
