import { Col, Row } from "react-bootstrap"

import Form from "~/design/forms"
import { globalsStateWrapper } from "~/redux"

function GoalFormFields({ carePlanPriorities, carePlanGoalStatuses }) {
  return (
    <>
      <Row>
        <Col md={8}>
          <Form.Group name="description">
            <Form.Label>Goal</Form.Label>
            <Form.TextArea placeholder="e.g. Client will test and log her blood sugar levels daily for 1 month" />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group name="carePlanGoalStatusId" errorKey="status">
            <Form.Label>Status</Form.Label>
            <Form.SelectBox options={carePlanGoalStatuses} isClearable placeholder="Select a status" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Form.Group name="notes">
            <Form.Label>Goal Note</Form.Label>
            <Form.TextArea />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group name="patientCarePlanPriorityId" errorKey="priority">
            <Form.Label>Priority</Form.Label>
            <Form.SelectBox options={carePlanPriorities} isClearable placeholder="Select a priority" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group name="startDate">
            <Form.Label>Start Date</Form.Label>
            <Form.DatePicker />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group name="targetCompletionDate">
            <Form.Label>Target Completion Date</Form.Label>
            <Form.DatePicker />
          </Form.Group>
        </Col>
        <Col md={4}>
          {/* CarePlanGoalStatus::COMPLETED */}
          <Form.Show when={({ values: { carePlanGoalStatusId } }) => carePlanGoalStatusId === 3}>
            <Form.Group name="completionDate">
              <Form.Label>Completion Date</Form.Label>
              <Form.DatePicker />
            </Form.Group>
          </Form.Show>
        </Col>
      </Row>
    </>
  )
}

export default globalsStateWrapper(({ carePlanPriorities, carePlanGoalStatuses }) => ({
  carePlanPriorities,
  carePlanGoalStatuses,
}))(GoalFormFields)
