import _ from "lodash"
import PropTypes from "prop-types"

import MemberFormFields from "./MemberFormFields"

import Form from "~/design/forms"
import Modal from "~/design/Modal"
import types from "~/types"

function AddMemberModal({ members, onSubmit, onClose, potentialMemberCareManagers, show }) {
  const memberCareManagerIds = members.map(({ careManagerId }) => careManagerId)
  const potentialMemberOptions = Object.values(potentialMemberCareManagers).map(({ id, name }) => ({
    value: id,
    label: name,
  }))
  const currentMemberOptions = members.map(({ careManagerId, name }) => ({
    value: careManagerId,
    label: name,
  }))
  const memberOptions = _.unionBy(currentMemberOptions, potentialMemberOptions, "value")

  return (
    <Modal onClose={onClose} title="Add Care Manager" show={show}>
      <Form.Provider formId="add-member-form" initialValues={{ memberCareManagerIds }} onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Body vertical>
            <MemberFormFields memberOptions={memberOptions} />
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Add to Team" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

AddMemberModal.propTypes = {
  members: PropTypes.arrayOf(types.supervisoryTeamMember).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  potentialMemberCareManagers: PropTypes.objectOf(types.careManager).isRequired,
  show: PropTypes.bool.isRequired,
}

export default AddMemberModal
