import PropTypes from "prop-types"

const TablePageInfo = ({ className, pageIndex, pageSize, total }) => {
  const startDisplaying = total === 0 ? 0 : pageIndex * pageSize + 1
  const pageEnd = startDisplaying + pageSize - 1
  const endDisplaying = pageEnd > total ? total : pageEnd

  return (
    <span className={`table-page-info ${className}`}>
      Displaying <strong>{`${startDisplaying} - ${endDisplaying}`}</strong> of <strong>{total}</strong> in total
    </span>
  )
}

TablePageInfo.defaultProps = {
  className: "float-right",
}

TablePageInfo.propTypes = {
  className: PropTypes.string,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}

export default TablePageInfo
