import { createSelector } from "reselect"

import { QUALITY_REPORT_ERROR, QUALITY_REPORT_LOADING } from "~/big3Dashboard/actions/qualityReportStatusActions"
import { getFilteredReportRowEntityIds } from "~/big3Dashboard/selectors/filtersSelector"

export const getStatus = ({ status }) => status

const getFullReport = ({ entities }) => entities
export const getReport = createSelector(
  [getFullReport, getFilteredReportRowEntityIds],
  (fullReport, filteredReportRowEntityIds) => {
    if (fullReport === QUALITY_REPORT_LOADING || fullReport === QUALITY_REPORT_ERROR) {
      return fullReport
    }

    if (filteredReportRowEntityIds.length === 0) {
      return fullReport
    }

    return fullReport.filter(({ entity: { id } }) => filteredReportRowEntityIds.includes(id))
  }
)

export const getReportRowEntities = createSelector(getFullReport, (fullReport) => {
  switch (fullReport) {
    case QUALITY_REPORT_LOADING:
    case QUALITY_REPORT_ERROR:
      return []
    default:
      return fullReport.map((row) => row.entity)
  }
})
