import types from "~/types"

function ChartIndicator({ chart }) {
  return (
    <div className="text-muted my-3" style={{ fontSize: "13px" }}>
      When saved, this data will be associated with {chart.healthHomeName} Health Home and{" "}
      {chart.careManagementAgencyName} CMA.
    </div>
  )
}

ChartIndicator.propTypes = {
  chart: types.chart.isRequired,
}

export default ChartIndicator
