import { Table } from "react-bootstrap"
import classNames from "classnames"
import PropTypes from "prop-types"

import { LinkButton } from "~/design/buttons"
import Jumbotron from "~/design/Jumbotron"
import useConfirmationModal from "~/design/modals/useConfirmationModal"
import Show from "~/design/Show"
import { provider_path } from "~/routes"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function CareTeamMembershipsTable({ readOnly, careTeamMemberships, onDelete, showEditModal }) {
  const confirm = useConfirmationModal()

  function providerTypeWithOptionalCategory(membership) {
    if (!membership.providerTypeCategory) return membership.providerTypeName

    return `${membership.providerTypeCategory} - ${membership.providerTypeName}`
  }

  function providerNameWithFacility(provider) {
    return [provider.name, provider.facility].filter(Boolean).join(", ")
  }

  function providerContactInfo(provider) {
    return [provider.phone, provider.address].filter(Boolean).join(" / ")
  }

  async function handleDelete(membership) {
    const confirmed = await confirm.danger(
      `Remove this provider from the care team? If the provider was a member of the care team, but is no longer active,
      please don't remove them — mark the provider 'Inactive' instead by clicking 'Edit.' If the provider was
      mistakenly added to the care team, click 'Remove' to remove them.`,
      { confirmButtonLabel: "Remove" }
    )

    if (confirmed) {
      try {
        await api.careTeamMemberships.delete(membership.id)
        toast.success("Care team member successfully removed.")
        onDelete(membership.id)
      } catch {
        toast.error(
          <p>
            Care team member could not be removed.
            <br />
            Please refresh the page.
          </p>
        )
      }
    }
  }

  const sortedCareTeamMemberships = [...careTeamMemberships].sort(
    (membershipA, membershipB) => membershipB.active - membershipA.active
  )

  if (careTeamMemberships.length === 0) {
    return <Jumbotron message="This patient has no care team members for the selected agencies." />
  }

  return (
    <Table striped bordered size="sm" className="table-light-header">
      <thead>
        <tr>
          <th>Provider Type</th>
          <th>Name/Facility</th>
          <th>Specialty</th>
          <th>Contact Info</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Followup Needed?</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sortedCareTeamMemberships.map((membership) => (
          <tr
            key={membership.id}
            className={classNames({ "text-muted": !membership.active })}
            data-testid={`membership-record-row-${membership.id}`}
          >
            <td>
              {providerTypeWithOptionalCategory(membership)}
              {membership.active ? "" : " [INACTIVE]"}
            </td>
            <td>
              <a href={provider_path(membership.providerId)}>{providerNameWithFacility(membership.provider)}</a>
            </td>
            <td>{membership.provider.specialty}</td>
            <td>{providerContactInfo(membership.provider)}</td>
            <td>{membership.startDate}</td>
            <td>{membership.endDate}</td>
            <td>
              <Show when={Boolean(membership.followupNeeded)}>
                &#10003;
                <span className="sr-only">true</span>
              </Show>
            </td>
            <td width={120}>
              <Show when={!readOnly}>
                <LinkButton onClick={() => showEditModal(membership)}>Edit</LinkButton> |{" "}
                <LinkButton color="danger" onClick={() => handleDelete(membership)}>
                  Remove
                </LinkButton>
              </Show>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

CareTeamMembershipsTable.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  careTeamMemberships: PropTypes.arrayOf(types.careTeamMembership).isRequired,
  onDelete: PropTypes.func.isRequired,
  showEditModal: PropTypes.func.isRequired,
}

export default CareTeamMembershipsTable
