import PropTypes from "prop-types"

import careTeamProvider from "./careTeamProvider"

const careTeamMembership = PropTypes.shape({
  id: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  providerId: PropTypes.number.isRequired,
  providerTypeName: PropTypes.string.isRequired,
  providerTypeId: PropTypes.number.isRequired,
  providerTypeCategory: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  provider: careTeamProvider,
  followupNeeded: PropTypes.bool,
})

export default careTeamMembership
