import { Col, Row } from "react-bootstrap"
import classNames from "classnames"
import { useFormikContext } from "formik"
import _ from "lodash"
import uniqid from "uniqid"

import DividerList from "~/components/DividerList"
import { LinkButton } from "~/design/buttons"
import IconButton from "~/design/buttons/IconButton"
import Form from "~/design/forms"
import CloseIcon from "~/design/icons/CloseIcon"
import Show from "~/design/Show"

function BarriersFormFields() {
  const {
    values: { barriers },
    setFieldValue,
  } = useFormikContext()

  function removeUnpersistedBarrier(id) {
    setFieldValue("barriers", _.omit(barriers, id))
  }

  function addBarrier() {
    const newBarrierKey = _.camelCase(uniqid())
    setFieldValue("barriers", {
      ...barriers,
      [newBarrierKey]: {
        description: "",
        active: false,
      },
    })
  }

  const [persistedBarrierIds, unpersistedBarrierIds] = _.partition(Object.keys(barriers), (id) => barriers[id].id)

  return (
    <>
      <Show when={persistedBarrierIds.length > 0}>
        <div className="d-none d-md-block mb-3">Barrier</div>
        <DividerList Divider={() => <hr className="mt-0 mb-3" />}>
          {persistedBarrierIds.map((id) => {
            const disabled = barriers[id].wasDeleted
            return (
              <Row
                className={classNames("align-items-center", { "bg-light": disabled })}
                data-testid={`barriers-form-fields-${_.kebabCase(id)}`}
                key={id}
              >
                <Col md={8}>
                  <Form.Group name={`barriers.${id}.description`}>
                    <Form.Label hidden="md">Barrier</Form.Label>
                    <Form.TextArea disabled={disabled} placeholder="Enter barrier description" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group name={`barriers.${id}.active`}>
                    <Form.CheckBox disabled={disabled} label="Active" />
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group name={`barriers.${id}.wasDeleted`}>
                    <Form.MarkForDelete />
                  </Form.Group>
                </Col>
              </Row>
            )
          })}
        </DividerList>
      </Show>
      <h2 className="my-3">Add Barriers</h2>
      <Show when={unpersistedBarrierIds.length > 0}>
        <div className="d-none d-md-block mb-3">Barrier</div>
        <DividerList Divider={() => <hr className="mt-0 mb-3" />}>
          {unpersistedBarrierIds.map((id) => (
            <Row className="align-items-center" data-testid={`barriers-form-fields-${_.kebabCase(id)}`} key={id}>
              <Col md={8}>
                <Form.Group clearOnHide={false} name={`barriers.${id}.description`}>
                  <Form.Label hidden="md">Barrier</Form.Label>
                  <Form.TextArea placeholder="Enter barrier description" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group clearOnHide={false} name={`barriers.${id}.active`}>
                  <Form.CheckBox label="Active" />
                </Form.Group>
              </Col>
              <Col md={1}>
                <div className="mb-3">
                  <IconButton
                    Icon={CloseIcon}
                    label="Remove barrier row"
                    onClick={() => removeUnpersistedBarrier(id)}
                  />
                </div>
              </Col>
            </Row>
          ))}
        </DividerList>
      </Show>
      <div className="mt-3">
        <LinkButton onClick={addBarrier}>Add {barriers.length > 0 ? "another " : ""}barrier</LinkButton>
      </div>
    </>
  )
}

export default BarriersFormFields
