import api from "~/services/api"

export const QUALITY_REPORT_LOADING = "QUALITY_REPORT_LOADING"
export function qualityReportLoading() {
  return {
    type: QUALITY_REPORT_LOADING,
  }
}

export const QUALITY_REPORT_RECEIVED = "QUALITY_REPORT_RECEIVED"
export function qualityReportReceived(report) {
  return {
    type: QUALITY_REPORT_RECEIVED,
    report,
  }
}

export const QUALITY_REPORT_ERROR = "QUALITY_REPORT_ERROR"
export function qualityReportError() {
  return {
    type: QUALITY_REPORT_ERROR,
  }
}

export function getHealthHomeQualityStatusData(healthHomeId, category, date) {
  return (dispatch) => {
    dispatch(qualityReportLoading())

    api.healthHomeQualities
      .get(healthHomeId, category, date)
      .then((res) => dispatch(qualityReportReceived(res)))
      .catch(() => dispatch(qualityReportError()))
  }
}

export function getCareManagementAgencyStatusData({
  adultChildServicesCodeId,
  careManagementAgencyId,
  category,
  healthHomeIds,
  supervisorIds,
  supervisoryTeamIds,
}) {
  return (dispatch) => {
    dispatch(qualityReportLoading())

    api.careManagementAgencyQualities
      .get(careManagementAgencyId, {
        adultChildServicesCodeId,
        category,
        healthHomeIds,
        supervisorIds,
        supervisoryTeamIds,
      })
      .then((res) => dispatch(qualityReportReceived(res)))
      .catch(() => dispatch(qualityReportError()))
  }
}
