import PropTypes from "prop-types"

const medication = PropTypes.shape({
  id: PropTypes.number.isRequired,
  active: PropTypes.bool,
  name: PropTypes.string,
  dosage: PropTypes.string,
  reason: PropTypes.string,
  startDateDescription: PropTypes.string,
  endDateDescription: PropTypes.string,
})

export default medication
