import { useState } from "react"

const SUPERVISOR_MODAL = "SUPERVISOR_MODAL"
const MEMBER_MODAL = "MEMBER_MODAL"

function useModal() {
  const [modal, setModal] = useState(null)

  return {
    isMemberModalVisible: modal === MEMBER_MODAL,
    isSupervisorModalVisible: modal === SUPERVISOR_MODAL,
    showMemberModal: () => setModal(MEMBER_MODAL),
    showSupervisorModal: () => setModal(SUPERVISOR_MODAL),
    hideModal: () => setModal(null),
  }
}

export default useModal
