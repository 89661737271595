import { useEffect, useRef } from "react"
import { connect } from "react-redux"

import { getCareManagementAgencyStatusData } from "~/big3Dashboard/actions/qualityReportStatusActions"
import CareManagementAgencyQualityDashboard from "~/big3Dashboard/CareManagementAgencyQualityDashboard"
import tabs from "~/big3Dashboard/CareManagementAgencyQualityDashboard/tabs"
import { createNewCareManagementAgencyBig3Url } from "~/big3Dashboard/helpers/createNewCareManagementAgencyBig3Url"
import { getActiveTabId } from "~/big3Dashboard/selectors/activeTabSelector"
import {
  getAdultChildServicesCodeId,
  getEntityId,
  getHealthHomeIds,
  getSupervisorIds,
  getSupervisoryTeamIds,
} from "~/big3Dashboard/selectors/filtersSelector"
import { getDefaultDate } from "~/big3Dashboard/selectors/globalsSelector"
import { getReport, getStatus } from "~/big3Dashboard/selectors/qualityReportStatusSelector"
import updatePath, { redirectTo } from "~/browserHelpers"
import { care_management_agency_quality_dashboard_path } from "~/routes"

function CareManagementAgencyQualityContainer({
  activeTabId,
  getCareManagementAgencyQualityStatusDataConnect,
  careManagementAgencyId,
  healthHomeIds,
  supervisoryTeamIds,
  supervisorIds,
  date,
  report,
  status,
  adultChildServicesCodeId,
}) {
  const activeTab = tabs.find(({ id }) => id === activeTabId)

  const careManagementAgencyIdRef = useRef(careManagementAgencyId)

  useEffect(() => {
    // When the user selects a new CMA, we need to reload the screen in order
    // to populate the Supervisor Teams, Supervisors and Care Managers in the dropdowns
    // since those collections are generated based on the current CMA
    const previousCareManagementAgencyId = careManagementAgencyIdRef.current
    if (previousCareManagementAgencyId !== careManagementAgencyId) {
      redirectTo(care_management_agency_quality_dashboard_path(careManagementAgencyId))
    } else {
      getCareManagementAgencyQualityStatusDataConnect({
        careManagementAgencyId,
        category: activeTabId,
        healthHomeIds,
        supervisorIds,
        supervisoryTeamIds,
        adultChildServicesCodeId,
      })
      updatePath(
        createNewCareManagementAgencyBig3Url({
          careManagementAgencyId,
          activeTabId,
          healthHomeIds,
          supervisorIds,
          supervisoryTeamIds,
          adultChildServicesCodeId,
        })
      )
    }
  }, [activeTabId, careManagementAgencyId, healthHomeIds, supervisorIds, supervisoryTeamIds, adultChildServicesCodeId])

  return (
    <CareManagementAgencyQualityDashboard
      activeTab={activeTab}
      tabs={tabs}
      status={status}
      report={report}
      healthHomeIds={healthHomeIds}
      careManagementAgencyId={careManagementAgencyId}
      date={date}
      adultChildServicesCodeId={adultChildServicesCodeId}
    />
  )
}

export default connect(
  (state) => ({
    activeTabId: getActiveTabId(state),
    report: getReport(state),
    status: getStatus(state),
    careManagementAgencyId: getEntityId(state),
    healthHomeIds: getHealthHomeIds(state),
    supervisoryTeamIds: getSupervisoryTeamIds(state),
    supervisorIds: getSupervisorIds(state),
    date: getDefaultDate(state),
    adultChildServicesCodeId: getAdultChildServicesCodeId(state),
  }),
  {
    getCareManagementAgencyQualityStatusDataConnect: getCareManagementAgencyStatusData,
  }
)(CareManagementAgencyQualityContainer)
