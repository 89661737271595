import { useReducer } from "react"

const defaultModalState = { modal: null, selectedMembership: null }

function modalReducer(state, action) {
  switch (action.type) {
    case "SHOW_ADD_MODAL":
      return { modal: "ADD_MODAL", selectedMembership: null }
    case "SHOW_EDIT_MODAL":
      return { modal: "EDIT_MODAL", selectedMembership: action.payload }
    case "HIDE_MODAL":
      return defaultModalState
    default:
      return state
  }
}

function useCareTeamMembershipModal() {
  const [{ modal, selectedMembership }, dispatch] = useReducer(modalReducer, defaultModalState)

  return {
    isAddModalVisible: modal === "ADD_MODAL",
    isEditModalVisible: modal === "EDIT_MODAL",
    selectedMembership,
    showAddModal: () => dispatch({ type: "SHOW_ADD_MODAL" }),
    showEditModal: (membership) => dispatch({ type: "SHOW_EDIT_MODAL", payload: membership }),
    hideModal: () => dispatch({ type: "HIDE_MODAL" }),
  }
}

export default useCareTeamMembershipModal
