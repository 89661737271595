import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import NewForm from "./components/NewForm"

import { ToastContainer } from "~/toast"

const queryClient = new QueryClient()

function New(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <NewForm {...props} />
      <ToastContainer />
    </QueryClientProvider>
  )
}

New.propTypes = {}

export default New
