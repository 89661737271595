import PropTypes from "prop-types"

/**
 * Component for showing pretty-printed raw data.
 */
function RawJSON({ label = "Raw JSON", data }) {
  if (label === "Raw JSON") {
    console.warn("You should probably provide the 'label' prop to the RawJSON component to keep things clear.")
  }
  return (
    <details>
      <summary style={{ fontWeight: "bold" }}>{label}</summary>
      <pre className="well" style={{ marginTop: "1rem", fontSize: ".75rem" }}>
        {JSON.stringify(data, null, "  ")}
      </pre>
    </details>
  )
}

RawJSON.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
}

export default RawJSON
