import { FILTER_BY_NEED_CATEGORY } from "~/components/carePlans/carePlanNeedCategoryFilterActions"

export default function carePlanNeedCategoryFilterReducer(state = null, action) {
  switch (action.type) {
    case FILTER_BY_NEED_CATEGORY:
      return action.needCategoryId
    default:
      return state
  }
}
