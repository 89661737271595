import formattedDate from "~/dateHelpers"

function DateDetail({ date, className, label }) {
  return (
    <span className={className}>
      <span className="text-muted date-label">{label}</span>&nbsp;
      {formattedDate(date)}
    </span>
  )
}

export default DateDetail
