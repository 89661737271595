import PropTypes from "prop-types"

const cesRiskFactor = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.shape({
    attributes: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  requiresAdditionalSignificantRiskFactorInformation: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  disabledOn: PropTypes.string,
  enabledOn: PropTypes.string,
})

export default cesRiskFactor
