import PropTypes from "prop-types"

import BarriersFormFields from "./BarriersFormFields"

import { WarningAlert } from "~/design/alerts"
import Form from "~/design/forms"
import Modal from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function BarriersForm({ barriers, chart, show, onClose, onUpdate }) {
  async function onSubmit(values, { setErrors, setStatus }) {
    try {
      const updatedBarriers = await api.bulkBarriersUpdates.post(chart.id, values.barriers)
      onUpdate(updatedBarriers)
      setStatus("success")
      toast.success("Barriers successfully updated.")
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Modal show={show} onClose={onClose} title="Edit Barriers" size="lg">
      <Form.Provider initialValues={{ barriers }} onSubmit={onSubmit} formId="barriers-modal-form">
        <Modal.Body>
          <WarningAlert>
            When saved, this will be associated with {chart.healthHomeName} Health Home and{" "}
            {chart.careManagementAgencyName} CMA.
          </WarningAlert>
          <Form.Body vertical>
            <BarriersFormFields />
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Save" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

BarriersForm.propTypes = {
  barriers: PropTypes.objectOf(types.barrier).isRequired,
  chart: types.chart,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default BarriersForm
