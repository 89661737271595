import PropTypes from "prop-types"

import { DangerAlert } from "~/design/alerts"

function QualityDashboardStatusAlert({ error }) {
  if (!error) return null

  return <DangerAlert>Something went wrong.</DangerAlert>
}

QualityDashboardStatusAlert.propTypes = {
  error: PropTypes.bool.isRequired,
}

export default QualityDashboardStatusAlert
