import PropTypes from "prop-types"

import FormFields from "./components/FormFields"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { patient_flag_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function Edit({ colorOptions, patientFlag }) {
  async function handleSubmit(values, { setStatus, setErrors }) {
    try {
      const { id } = await api.patientFlags.patch(patientFlag.id, values)
      setStatus("success")
      redirectTo(patient_flag_path(id))
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={patientFlag} onSubmit={handleSubmit}>
      <FormFields colorOptions={colorOptions} />
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(patient_flag_path(patientFlag.id))} />
            <Form.SubmitButton label="Save" />
          </>
        }
      />
    </Form>
  )
}

Edit.propTypes = {
  patientFlag: types.patientFlag.isRequired,
  colorOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default Edit
