import { createColumnHelper } from "@tanstack/react-table"

import Big3Table from "~/big3Dashboard/components/Big3Table"
import {
  createBig3CareManagementAgencyPatientsDrillthruUrl,
  createBig3HealthHomesPatientsDrillthruUrl,
} from "~/big3Dashboard/helpers/createBig3HealthHomesPatientsDrillthruUrl"

export function CareManagementAgencyCarePlansTable({
  entityColumn,
  status,
  report,
  careManagementAgencyId,
  healthHomeIds,
  date,
  adultChildServicesCodeId,
}) {
  const missingDrillThruCreator = (careManagerId) =>
    createBig3CareManagementAgencyPatientsDrillthruUrl({
      careManagerId,
      careManagementAgencyId,
      healthHomeIds,
      date,
      adultChildServicesCodeId,
      statusValue: "care_plan_missing",
    })

  const expiredDrillThruCreator = (careManagerId) =>
    createBig3CareManagementAgencyPatientsDrillthruUrl({
      careManagerId,
      careManagementAgencyId,
      healthHomeIds,
      date,
      adultChildServicesCodeId,
      statusValue: "care_plan_expired",
    })

  return CarePlansTable({ entityColumn, status, report, missingDrillThruCreator, expiredDrillThruCreator })
}

export function HealthHomeCarePlansTable({ entityColumn, status, report, healthHomeId, date }) {
  const missingDrillThruCreator = (careManagementAgencyId) =>
    createBig3HealthHomesPatientsDrillthruUrl({
      healthHomeId,
      careManagementAgencyId,
      date,
      statusValue: "care_plan_missing",
    })

  const expiredDrillThruCreator = (careManagementAgencyId) =>
    createBig3HealthHomesPatientsDrillthruUrl({
      healthHomeId,
      careManagementAgencyId,
      date,
      statusValue: "care_plan_expired",
    })

  return CarePlansTable({ entityColumn, status, report, missingDrillThruCreator, expiredDrillThruCreator })
}

function CarePlansTable({ entityColumn, status, report, missingDrillThruCreator, expiredDrillThruCreator }) {
  const columnHelper = createColumnHelper()

  const columns = [
    entityColumn,
    columnHelper.accessor("enrolled", {
      header: "Enrolled",
      id: "enrolled",
      popoverContent: "Number of patients that are Enrolled (Not-Pended).",
    }),
    columnHelper.accessor("pastGracePeriod", {
      header: "Enrolled > 60 days",
      id: "enrolled_greater_than_60_days",
      popoverContent: "Number of patients that are Enrolled (Not-Pended) for more than 60 days.",
    }),
    columnHelper.accessor("missing", {
      header: "Missing Signed Care Plans",
      id: "missing",
      popoverContent:
        "Number of patients among Enrolled (Not-Pended) that do not have a signed care plan completed in the past year.",
      cell: ({ row, getValue }) => {
        const { entity } = row.original
        return (
          <a data-testid="missing-care-plans-drillthru" href={missingDrillThruCreator(entity.id)}>
            {getValue()}
          </a>
        )
      },
    }),
    columnHelper.accessor("expired", {
      header: "Expired Signed Care Plans",
      id: "expired",
      popoverContent:
        "Number of patients among Enrolled (Not-Pended) whose most recent signed care plan is over a year old.",
      cell: ({ row, getValue }) => {
        const { entity } = row.original
        return (
          <a data-testid="expired-care-plans-drillthru" href={expiredDrillThruCreator(entity.id)}>
            {getValue()}
          </a>
        )
      },
    }),
    columnHelper.accessor("valid", {
      header: "Valid Signed Care Plans",
      id: "valid",
      popoverContent:
        "Number of patients among Enrolled (Not-Pended) that have a signed care plan completed in the past year.",
    }),
    columnHelper.accessor("completionPercentage", {
      header: "Completion (%)",
      id: "completion_percentage",
      popoverContent:
        "Percentage of patients among Enrolled (Not-Pended) that have a signed care plan completed in the past year.",
    }),
  ]

  return <Big3Table status={status} columns={columns} report={report} />
}
