import { useState } from "react"
import PropTypes from "prop-types"

import { LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import types from "~/types"

function LinkedGapInCareField({ disabled, gapInCareOptions, initialVisibility }) {
  const [isVisible, setIsVisible] = useState(initialVisibility)

  if (!isVisible) {
    return (
      <LinkButton size="sm" onClick={() => setIsVisible(true)}>
        Link to Gaps in Care
      </LinkButton>
    )
  }

  return (
    <>
      <h3 className="mb-2">Linked Gaps in Care</h3>
      {gapInCareOptions.length === 0 ? (
        <p className="text-muted">This patient has no gaps in care.</p>
      ) : (
        <Form.Group name="mcoGapInCareIds">
          <Form.Label>Select from active gaps in care:</Form.Label>
          <Form.MultiSelectBox options={gapInCareOptions} isClearable disabled={disabled} />
        </Form.Group>
      )}
    </>
  )
}

LinkedGapInCareField.propTypes = {
  disabled: PropTypes.bool,
  gapInCareOptions: PropTypes.arrayOf(types.selectOption).isRequired,
  initialVisibility: PropTypes.bool.isRequired,
}

export default LinkedGapInCareField
