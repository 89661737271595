import { Col, Row } from "react-bootstrap"
import classNames from "classnames"
import { useFormikContext } from "formik"
import _ from "lodash"
import uniqid from "uniqid"

import DividerList from "~/components/DividerList"
import { IconButton, LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import { CloseIcon } from "~/design/icons"
import Show from "~/design/Show"

function MedicationsFormFields() {
  const {
    values: { medications },
    setFieldValue,
  } = useFormikContext()

  function removeUnpersistedMedication(id) {
    setFieldValue("medications", _.omit(medications, id))
  }

  function addMedication() {
    const newMedicationKey = _.camelCase(uniqid())
    setFieldValue("medications", {
      ...medications,
      [newMedicationKey]: {
        active: false,
        name: "",
        dosage: "",
        reason: "",
        startDate: "",
        endDate: "",
      },
    })
  }

  const medicationIds = Object.keys(medications)
  const [persistedMedicationIds, unpersistedMedicationIds] = _.partition(medicationIds, (id) => medications[id].id)

  return (
    <>
      <Show when={persistedMedicationIds.length > 0}>
        <Row className="d-none d-lg-flex mb-2">
          <Col lg={2}>Name</Col>
          <Col lg={2}>Dosage</Col>
          <Col lg={2}>Reason</Col>
          <Col lg={2}>Start</Col>
          <Col lg={2}>End</Col>
        </Row>
        <DividerList Divider={() => <hr className="mt-0 mb-3" />}>
          {persistedMedicationIds.map((id) => {
            const disabled = medications[id].wasDeleted
            return (
              <Row
                key={id}
                className={classNames("align-items-center", { "bg-light": disabled })}
                data-testid={`medication-form-fields-${_.kebabCase(id)}`}
              >
                <Col lg={2}>
                  <Form.Group name={`medications.${id}.name`}>
                    <Form.Label hidden="lg">Name</Form.Label>
                    <Form.TextField placeholder="Type medication name" disabled={disabled} />
                  </Form.Group>
                </Col>
                <Col lg={2}>
                  <Form.Group name={`medications.${id}.dosage`}>
                    <Form.Label hidden="lg">Dosage</Form.Label>
                    <Form.TextField placeholder="Ex: 100mg" disabled={disabled} />
                  </Form.Group>
                </Col>
                <Col lg={2}>
                  <Form.Group name={`medications.${id}.reason`}>
                    <Form.Label hidden="lg">Reason</Form.Label>
                    <Form.TextField placeholder="Ex: Diabetes" disabled={disabled} />
                  </Form.Group>
                </Col>
                <Col lg={2}>
                  <Form.Group name={`medications.${id}.startDateDescription`}>
                    <Form.Label hidden="lg">Start</Form.Label>
                    <Form.TextField disabled={disabled} />
                  </Form.Group>
                </Col>
                <Col lg={2}>
                  <Form.Group name={`medications.${id}.endDateDescription`}>
                    <Form.Label hidden="lg">End</Form.Label>
                    <Form.TextField disabled={disabled} />
                  </Form.Group>
                </Col>
                <Col lg={2}>
                  <Row className="align-items-center">
                    <Col lg={8}>
                      <Form.Group name={`medications.${id}.active`}>
                        <Form.CheckBox label="Active" disabled={disabled} />
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group name={`medications.${id}.wasDeleted`}>
                        <Form.MarkForDelete />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          })}
        </DividerList>
      </Show>
      <h2 className="my-3">Add Medication</h2>
      <Show when={unpersistedMedicationIds.length > 0}>
        <Row className="d-none d-lg-flex mb-2">
          <Col lg={2}>Name</Col>
          <Col lg={2}>Dosage</Col>
          <Col lg={2}>Reason</Col>
          <Col lg={2}>Start</Col>
          <Col lg={2}>End</Col>
        </Row>
        <DividerList Divider={() => <hr className="mt-0 mb-3" />}>
          {unpersistedMedicationIds.map((id) => (
            <Row key={id} className="align-items-center" data-testid={`medication-form-fields-${_.kebabCase(id)}`}>
              <Col lg={2}>
                <Form.Group clearOnHide={false} name={`medications.${id}.name`}>
                  <Form.Label hidden="lg">Name</Form.Label>
                  <Form.TextField placeholder="Type medication name" />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group clearOnHide={false} name={`medications.${id}.dosage`}>
                  <Form.Label hidden="lg">Dosage</Form.Label>
                  <Form.TextField placeholder="Ex: 100mg" />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group clearOnHide={false} name={`medications.${id}.reason`}>
                  <Form.Label hidden="lg">Reason</Form.Label>
                  <Form.TextField placeholder="Ex: Diabetes" />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group clearOnHide={false} name={`medications.${id}.startDateDescription`}>
                  <Form.Label hidden="lg">Start</Form.Label>
                  <Form.TextField />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group clearOnHide={false} name={`medications.${id}.endDateDescription`}>
                  <Form.Label hidden="lg">End</Form.Label>
                  <Form.TextField />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Row className="align-items-center">
                  <Col lg={8}>
                    <Form.Group clearOnHide={false} name={`medications.${id}.active`}>
                      <Form.CheckBox label="Active" />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <IconButton
                        Icon={CloseIcon}
                        label="Remove medication row"
                        onClick={() => removeUnpersistedMedication(id)}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </DividerList>
      </Show>
      <div className="mt-3">
        <LinkButton onClick={addMedication}>Add {medicationIds.length > 0 ? "another " : ""}medication</LinkButton>
      </div>
    </>
  )
}

export default MedicationsFormFields
