import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_bulk_diagnoses_update_path } from "~/routes"

const bulkDiagnosesUpdates = {
  post: function (chartId, bulkDiagnosesUpdate) {
    return submitAsyncRequest({
      url: api_v1_bulk_diagnoses_update_path(),
      type: "post",
      data: { bulkDiagnosesUpdate, chartId },
    })
  },
}

export default bulkDiagnosesUpdates
