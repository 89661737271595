import createCachedSelector from "re-reselect"

const getNeedUiState = ({ ui: { needs } }, { needId }) => needs[needId]

export const getIsNewGoalFormOpen = createCachedSelector(
  getNeedUiState,
  (needUi) => needUi && needUi.isNewGoalFormOpen
)((state, { needId }) => needId)

export const getIsGoalsListOpen = createCachedSelector(
  getNeedUiState,
  (needUi) => needUi && needUi.isGoalsListOpen
)((state, { needId }) => needId)
