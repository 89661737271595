import {
  QUALITY_REPORT_ERROR,
  QUALITY_REPORT_LOADING,
  QUALITY_REPORT_RECEIVED,
} from "~/big3Dashboard/actions/qualityReportStatusActions"

const initialState = QUALITY_REPORT_LOADING

function qualityReportStatusReducer(state = initialState, action) {
  switch (action.type) {
    case QUALITY_REPORT_ERROR:
      return QUALITY_REPORT_ERROR
    case QUALITY_REPORT_RECEIVED:
      return QUALITY_REPORT_RECEIVED
    case QUALITY_REPORT_LOADING:
      return QUALITY_REPORT_LOADING
    default:
      return state
  }
}

export default qualityReportStatusReducer
