import { Children, cloneElement, isValidElement, useEffect } from "react"
import { useFormikContext } from "formik"

const EncounterDetailsFields = ({ attributesName, defaultValues, children }) => {
  const {
    values: { [attributesName]: formValues },
    setFieldValue,
  } = useFormikContext()

  useEffect(() => {
    setFieldValue(attributesName, Object.assign(defaultValues, formValues))
  }, [])

  return Children.map(children, (child) => {
    // A child can be null, for example, when conditionally rendered components that evaluate to null
    if (isValidElement(child)) {
      return cloneElement(child, {
        name: `${attributesName}.${child.props.name}`,
        errorKey: `details.${child.props.name}`,
      })
    }

    return null
  })
}

export default EncounterDetailsFields
