import Icon from "./Icon"

/**
 * Renders an icon of a plus sign, indicating an action such as adding an item to a collection.
 */
function AddIcon() {
  return <Icon name="plus-circle" />
}

export default AddIcon
