import { submitAsyncRequest } from "~/asyncHelpers"
import {
  api_v1_care_plan_care_plan_needs_path,
  api_v1_care_plan_care_plan_signatures_path,
  api_v1_care_plan_goal_care_plan_tasks_path,
  api_v1_care_plan_goal_path,
  api_v1_care_plan_need_care_plan_goals_path,
  api_v1_care_plan_need_path,
  api_v1_care_plan_task_path,
  api_v1_chart_care_plans_path,
} from "~/routes"

const carePlans = {
  post: function (chartId, carePlan) {
    return submitAsyncRequest({
      url: api_v1_chart_care_plans_path(chartId),
      type: "post",
      data: { carePlan },
    })
  },
  goals: {
    post: function (needId, values) {
      return submitAsyncRequest({
        url: api_v1_care_plan_need_care_plan_goals_path(needId),
        type: "post",
        data: { goal: values },
      })
    },
    patch: function (goalId, values) {
      return submitAsyncRequest({
        url: api_v1_care_plan_goal_path(goalId),
        type: "patch",
        data: { goal: values },
      })
    },
    delete: function (goalId) {
      return submitAsyncRequest({
        url: api_v1_care_plan_goal_path(goalId),
        type: "delete",
      })
    },
  },
  needs: {
    post: function (carePlanId, need) {
      return submitAsyncRequest({
        url: api_v1_care_plan_care_plan_needs_path(carePlanId),
        type: "post",
        data: { need },
      })
    },
    patch: function (needId, need) {
      return submitAsyncRequest({
        url: api_v1_care_plan_need_path(needId),
        type: "patch",
        data: { need },
      })
    },
    delete: function (needId) {
      return submitAsyncRequest({
        url: api_v1_care_plan_need_path(needId),
        type: "delete",
      })
    },
  },
  tasks: {
    post: function (goalId, values) {
      return submitAsyncRequest({
        url: api_v1_care_plan_goal_care_plan_tasks_path(goalId),
        type: "post",
        data: { task: values },
      })
    },
    patch: function (taskId, values) {
      return submitAsyncRequest({
        url: api_v1_care_plan_task_path(taskId),
        type: "patch",
        data: { task: values },
      })
    },
    delete: function (taskId) {
      return submitAsyncRequest({
        url: api_v1_care_plan_task_path(taskId),
        type: "delete",
      })
    },
  },
  signatures: {
    post: function (patientCarePlanId, signature) {
      return submitAsyncRequest({
        url: api_v1_care_plan_care_plan_signatures_path(patientCarePlanId),
        type: "post",
        data: { carePlanSignature: signature },
      })
    },
  },
}

export default carePlans
