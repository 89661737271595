import Icon from "./Icon"

/**
 * Renders an icon of a bulleted list.
 */
function ListIcon() {
  return <Icon name="list" />
}

export default ListIcon
