import PropTypes from "prop-types"

import * as reportingStatuses from "../constants/reportingStatuses"

import PopoverButton from "~/components/PopoverButton"
import CheckIcon from "~/design/icons/CheckIcon"
import MinusIcon from "~/design/icons/MinusIcon"

function SegmentSyncStatus({ reportingStatus }) {
  const labelText =
    reportingStatus === "not_yet_reported" ? (
      <span>
        <MinusIcon /> Not yet reported
      </span>
    ) : (
      <span>
        <CheckIcon /> Reported
      </span>
    )

  if (reportingStatus === reportingStatuses.NOT_YET_REPORTED) {
    return (
      <PopoverButton label={labelText} variant="info">
        This segment has not yet been reported to MAPP. It will be sent with the next tracking file submission.
      </PopoverButton>
    )
  } else if (reportingStatus === reportingStatuses.REPORTED_SUCCESSFULLY) {
    return (
      <PopoverButton label={labelText} variant="success">
        A matching segment exists in MAPP.
      </PopoverButton>
    )
  }
  if (reportingStatus === reportingStatuses.REPORTED_BUT_NON_PK_FIELD_CHANGE) {
    return (
      <PopoverButton label={labelText} variant="warning">
        This segment exists in MAPP, but one or more of the non-primary key fields has been changed. The next tracking
        file will include a Modify record to reflect this update.
      </PopoverButton>
    )
  } else {
    return
  }
}

SegmentSyncStatus.propTypes = {
  reportingStatus: PropTypes.string.isRequired,
}

export default SegmentSyncStatus
