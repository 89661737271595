function getEnvironment(key) {
  if (window && window.health_homes_env) {
    return window.health_homes_env[key]
  }

  return undefined
}

export const ROLLBAR_CLIENT_ITEM_ACCESS_TOKEN = getEnvironment("ROLLBAR_CLIENT_ITEM_ACCESS_TOKEN")
export const ROLLBAR_ENV = getEnvironment("ROLLBAR_ENV")
export const ENABLE_ROLLBAR_CLIENT_ITEM_REPORTING = getEnvironment("ENABLE_ROLLBAR_CLIENT_ITEM_REPORTING")
export const RAILS_ENV = getEnvironment("RAILS_ENV")
