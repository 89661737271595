import { Fragment } from "react"
import _ from "lodash"
import PropTypes from "prop-types"

import { LineDivider } from "~/components/Divider"
import CarePlanGoal from "~/components/encounters/CarePlanGoal"
import Jumbotron from "~/design/Jumbotron"

const CarePlanGoals = ({ carePlan, goals, editable }) => {
  if (_.isEmpty(goals)) {
    return <Jumbotron message="No active care plan tasks." />
  }
  return (
    <div className="bg-white border rounded p-3 overflow-auto" style={{ maxHeight: "600px" }}>
      {goals.map((goal, index) => (
        <Fragment key={goal.id}>
          <CarePlanGoal carePlan={carePlan} goal={goal} editable={editable} />
          {index < goals.length - 1 ? <LineDivider /> : null}
        </Fragment>
      ))}
    </div>
  )
}

CarePlanGoals.defaultProps = {
  editable: false,
}

CarePlanGoals.propTypes = {
  editable: PropTypes.bool,
}

export default CarePlanGoals
