import { useFormikContext } from "formik"

import { isOutcomeNotApplicable } from "../helpers"

import Section from "./Section"

import Form from "~/design/forms"
import Show from "~/design/Show"

function CompletionSection() {
  const {
    values: { outcomeId },
  } = useFormikContext()

  return (
    <Section id="ces-tool-section-completion" title="Complete">
      <Show when={!isOutcomeNotApplicable(outcomeId)}>
        <p className="mb-3">
          The DOH CES Tool can be completed by either a Case Manager, Supervisor, or Quality Assurance staff. If the
          tool is completed by a Case Manager, supervisory review is required.
        </p>
        <p className="mb-3">
          After completing the CES Tool, a supervisor must provide approval. If you are a supervisor or quality
          assurance staff, you can approve your own CES Tool.
        </p>
      </Show>
      <p>Click "submit" to complete the CES Tool.</p>
      <Form.ActionBar right={<Form.SubmitButton label="Submit" disabled={!outcomeId} />} />
    </Section>
  )
}

export default CompletionSection
