import PropTypes from "prop-types"

const documentType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  documentCategoryId: PropTypes.number.isRequired,
  allowsUserDocumentName: PropTypes.bool.isRequired,
})

export default documentType
