import { connect } from "react-redux"
import pluralize from "pluralize"

import { getFilteredGoalIds } from "~/components/carePlans/carePlanNeedsSelectors"
import { getIsGoalsListOpen, getIsNewGoalFormOpen } from "~/components/carePlans/carePlanNeedsUISelectors"
import { closeGoalsList, openGoalsList } from "~/components/carePlans/carePlanNeedUIActions"
import NeedAddButton from "~/components/carePlans/NeedAddButton"
import DropdownIcon from "~/components/DropdownIcon"

const NeedCardFooter = ({
  needId,
  isGoalsListOpen,
  isNewGoalFormOpen,
  goalIds,
  openGoalsListConnect,
  closeGoalsListConnect,
}) => {
  const numGoals = goalIds.length
  const isDropdownEnabled = numGoals > 0

  let collapsableCssClass = "need-collapsable"
  if (!isDropdownEnabled) {
    collapsableCssClass = ""
  }
  if (isNewGoalFormOpen) {
    collapsableCssClass = "need-collapsable-open"
  }

  const onClick = (e) => {
    e.preventDefault()

    if (!isDropdownEnabled) {
      return
    }

    if (isGoalsListOpen) {
      closeGoalsListConnect(needId, goalIds)
    } else {
      openGoalsListConnect(needId)
    }
  }

  return (
    <div className={`need-card-footer ${collapsableCssClass}`} onClick={onClick}>
      <strong className="text-muted">
        {pluralize("goal", numGoals, true)}
        &nbsp;
        {isDropdownEnabled && <DropdownIcon isOpen={isGoalsListOpen} />}
      </strong>
      <NeedAddButton needId={needId} />
    </div>
  )
}

export default connect(
  (state, props) => ({
    isNewGoalFormOpen: getIsNewGoalFormOpen(state, props),
    isGoalsListOpen: getIsGoalsListOpen(state, props),
    goalIds: getFilteredGoalIds(state, props),
  }),
  {
    openGoalsListConnect: openGoalsList,
    closeGoalsListConnect: closeGoalsList,
  }
)(NeedCardFooter)
