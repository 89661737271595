import PropTypes from "prop-types"

const supervisoryTeamSupervisor = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
})

export default supervisoryTeamSupervisor
