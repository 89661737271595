import { connect } from "react-redux"

import LinkedGapInCareFromFormBanner from "./LinkedGapInCareFromFormBanner"
import TaskFormFields from "./TaskFormFields"

import { carePlanTaskFlashKey, carePlanTaskUpdated } from "~/components/carePlans/carePlanTaskActions"
import { fetchMcoGapsInCare } from "~/components/carePlans/mcoGapInCareActions"
import Form from "~/design/forms"
import Modal from "~/design/Modal"
import { flashMessage } from "~/flashMessages/flashMessageActions"
import api from "~/services/api"

const EditTaskForm = ({
  task,
  goal,
  carePlanTaskUpdatedConnect,
  fetchMcoGapsInCareConnect,
  flashMessageConnect,
  show,
  onClose,
  taskResponsibilities,
  carePlanTaskStatuses,
  gapsForNewTaskLinkages,
}) => {
  async function onSubmit(values, { setErrors, setStatus }) {
    try {
      const updatedTask = await api.carePlans.tasks.patch(task.id, values)
      carePlanTaskUpdatedConnect(updatedTask)

      if (values.mcoGapInCareIds) {
        await fetchMcoGapsInCareConnect(values.mcoGapInCareIds)
      }

      setStatus("success")
      flashMessageConnect(carePlanTaskFlashKey(updatedTask))
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  const defaultTask = {
    description: "",
    taskResponsibilityId: "",
    taskResponsibilityOther: "",
    carePlanTaskStatusId: "",
    targetCompletionDate: "",
    completionDate: "",
    notes: "",
    goalId: goal.id,
    mcoGapInCareIds: [],
  }
  const initialValues = Object.assign(defaultTask, task)

  const gapInCareOptions = Object.values(gapsForNewTaskLinkages).map((gap) => ({
    label: gap.description,
    value: gap.id,
  }))

  return (
    <Modal show={show} onClose={onClose} title="Edit Task" size="lg" dataTestId="edit-task-modal">
      <Form.Provider formId="edit-task-modal-form" initialValues={initialValues} onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Body vertical>
            <LinkedGapInCareFromFormBanner />
            <div>
              <strong className="mr-1">Goal:</strong>
              <span>{goal.description}</span>
            </div>
            <hr />
            <TaskFormFields
              gapInCareOptions={gapInCareOptions}
              taskResponsibilities={taskResponsibilities}
              carePlanTaskStatuses={carePlanTaskStatuses}
              linkedGapInCareFieldInitialVisibility={task.mcoGapInCareIds.length > 0}
            />
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Update" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

export default connect(
  (state) => ({
    gapsForNewTaskLinkages: state.entities.gapsForNewTaskLinkages,
    taskResponsibilities: state.globals.taskResponsibilities,
    carePlanTaskStatuses: state.globals.carePlanTaskStatuses,
  }),
  {
    carePlanTaskUpdatedConnect: carePlanTaskUpdated,
    fetchMcoGapsInCareConnect: fetchMcoGapsInCare,
    flashMessageConnect: flashMessage,
  }
)(EditTaskForm)
