import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import RichTextField from "./RichTextField"

import Form from "~/design/forms"

function SchemaSectionFormFields({ sectionIndex }) {
  return (
    <>
      <Row>
        <Col md={6}>
          <Form.Group name="sectionName">
            <Form.Label>Section Title</Form.Label>
            <Form.TextField placeholder={`Section ${sectionIndex + 1}`} />
          </Form.Group>
        </Col>
      </Row>
      <RichTextField name="description" label="Section Description (optional)" placeholder="Insert description here" />
    </>
  )
}

SchemaSectionFormFields.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
}

export default SchemaSectionFormFields
