import BootstrapAlert from "react-bootstrap/Alert"
import PropTypes from "prop-types"

function Alert({ children, dataTestId, IconComponent, variant }) {
  return (
    <BootstrapAlert data-testid={dataTestId} transition={false} variant={variant}>
      <div className="d-flex gap-2">
        {IconComponent ? <IconComponent /> : null}
        <div className="flex-fill">{children}</div>
      </div>
    </BootstrapAlert>
  )
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  IconComponent: PropTypes.elementType,
  variant: PropTypes.oneOf(["danger", "primary", "success", "warning"]),
}

export default Alert
