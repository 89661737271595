import { ListGroup as ReactBootstrapListGroup } from "react-bootstrap"
import classNames from "classnames"
import PropTypes from "prop-types"

import { getOptionOrFallback } from "~/components/listGroup/getOptionOrFallback"

const SIZE_OPTIONS = {
  sm: "list-group-sm",
  md: null,
}
const DEFAULT_SIZE = "md"

function ListGroup({ size = DEFAULT_SIZE, className, ...otherProps }) {
  return (
    <ReactBootstrapListGroup
      className={classNames(className, getOptionOrFallback(SIZE_OPTIONS, size, DEFAULT_SIZE))}
      {...otherProps}
    />
  )
}

ListGroup.propTypes = {
  size: PropTypes.oneOf(Object.keys(SIZE_OPTIONS)),
  className: PropTypes.string,
}

ListGroup.Item = ReactBootstrapListGroup.Item

export default ListGroup
