import PropTypes from "prop-types"

import barrier from "./barrier"
import riskFactor from "./riskFactor"
import selectOption from "./selectOption"
import strength from "./strength"

const socialBackground = PropTypes.shape({
  barriers: PropTypes.objectOf(barrier),
  riskFactors: PropTypes.objectOf(riskFactor),
  riskFactorCategories: PropTypes.arrayOf(selectOption),
  strengths: PropTypes.objectOf(strength),
})

export default socialBackground
