import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_bulk_strengths_updates_path } from "~/routes"

const bulkStrengthsUpdates = {
  post: function (chartId, bulkStrengthsUpdate) {
    return submitAsyncRequest({
      url: api_v1_bulk_strengths_updates_path(),
      type: "post",
      data: { bulkStrengthsUpdate, chartId },
    })
  },
}

export default bulkStrengthsUpdates
