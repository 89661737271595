import PropTypes from "prop-types"

const cesToolSchema = PropTypes.objectOf(
  PropTypes.shape({
    dependsOnAny: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
      }).isRequired
    ),
    outcomesFromValue: PropTypes.arrayOf(
      PropTypes.shape({
        cesToolOutcomeId: PropTypes.number.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
      }).isRequired
    ),
  }).isRequired
)

export { cesToolSchema }
