import pluralize from "pluralize"
import PropTypes from "prop-types"
import { Dot, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts"

import useSegmentLengthsReport from "./useSegmentSummaryLineChart"

import Jumbotron from "~/design/Jumbotron"
import Show from "~/design/Show"
import { formatCompactNumber, formatNumberWithCommas, formatPercentage } from "~/numberHelpers"
import types from "~/types"
import ChartContainer from "~/views/shared/graphicalCharts/ChartContainer"
import ChartLoadingSpinner from "~/views/shared/graphicalCharts/ChartLoadingSpinner"
import ChartTooltip from "~/views/shared/graphicalCharts/ChartTooltip"

const OUTREACH_ENROLLMENT_COLOR_MAPPINGS = {
  1: "var(--primary)", // Patient::OutreachEnrollmentCode::ENROLLED.id
  2: "var(--secondary)", // Patient::OutreachEnrollmentCode::OUTREACH.id
}

function Report({ healthHomeId, careManagementAgencyId, outreachEnrollmentCodes }) {
  const { data, isLoading } = useSegmentLengthsReport({ healthHomeId, careManagementAgencyId })

  if (isLoading) {
    return <ChartLoadingSpinner />
  }

  if (data.length === 0) {
    return <Jumbotron message="No segments found" />
  }

  return (
    <ChartContainer>
      <LineChart data={data}>
        <XAxis dataKey="month" />
        <YAxis tickFormatter={formatCompactNumber} />
        <Legend formatter={(value) => outreachEnrollmentCodes.find(({ id }) => id === value).description} />
        <Tooltip content={<CustomTooltip outreachEnrollmentCodes={outreachEnrollmentCodes} />} />
        {outreachEnrollmentCodes.map(({ id }) => (
          <Line
            key={id}
            type="monotone"
            dataKey={id}
            stroke={OUTREACH_ENROLLMENT_COLOR_MAPPINGS[id]}
            dot={<CustomDot outreachEnrollmentCodes={outreachEnrollmentCodes} />}
          />
        ))}
      </LineChart>
    </ChartContainer>
  )
}

Report.propTypes = {
  healthHomeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  careManagementAgencyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  outreachEnrollmentCodes: PropTypes.arrayOf(types.outreachEnrollmentCode).isRequired,
}

function CustomDot({ dataKey, payload, outreachEnrollmentCodes, value, ...rest }) {
  const { description } = outreachEnrollmentCodes.find(({ id }) => id === dataKey)
  return <Dot {...rest} aria-label={`${pluralize(`${description} segment`, value, true)} for ${payload.month}`} />
}

CustomDot.propTypes = {
  dataKey: PropTypes.number,
  outreachEnrollmentCodes: PropTypes.arrayOf(types.outreachEnrollmentCode).isRequired,
  payload: PropTypes.shape({
    month: PropTypes.string.isRequired,
  }),
  value: PropTypes.number,
}

function CustomTooltip({ active, payload, label, outreachEnrollmentCodes }) {
  if (active && payload?.length) {
    const total = outreachEnrollmentCodes.reduce((sum, { id }) => sum + payload[0].payload[id], 0)

    return (
      <ChartTooltip title={label}>
        <div>
          {formatNumberWithCommas(total)} {pluralize("active patient", total)}
        </div>
        <Show when={total > 0}>
          {outreachEnrollmentCodes.map(({ id, description }) => {
            const count = payload[0].payload[id]
            return (
              <div key={description}>
                <span
                  className="d-inline-block mr-1"
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: OUTREACH_ENROLLMENT_COLOR_MAPPINGS[id],
                  }}
                />
                <span>
                  {description}: {formatNumberWithCommas(count)} (
                  {formatPercentage(count / total, { maximumFractionDigits: 2 })})
                </span>
              </div>
            )
          })}
        </Show>
      </ChartTooltip>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        1: PropTypes.number,
        2: PropTypes.number,
        month: PropTypes.string,
      }),
    })
  ),
  label: PropTypes.string,
  outreachEnrollmentCodes: PropTypes.arrayOf(types.outreachEnrollmentCode).isRequired,
}

export default Report
