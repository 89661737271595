import { useState } from "react"
import PropTypes from "prop-types"

import CareTeamMembershipsTable from "./components/CareTeamMembershipsTable"

import { redirectTo } from "~/browserHelpers"
import { PrimaryButton, SecondaryButton } from "~/design/buttons"
import { ConfirmationModalProvider } from "~/design/modals/useConfirmationModal"
import Show from "~/design/Show"
import { chart_unite_us_referrals_path } from "~/routes"
import { ToastContainer } from "~/toast"
import types from "~/types"
import AddCareTeamMembershipForm from "~/views/shared/careTeam/AddCareTeamMembershipForm"
import EditCareTeamMembershipForm from "~/views/shared/careTeam/EditCareTeamMembershipForm"
import useCareTeamMembershipModal from "~/views/shared/careTeam/useCareTeamMembershipModal"
import Section from "~/views/shared/patientDetails/Section"

function Index({
  readOnly,
  careTeamMemberships: initialCareTeamMemberships,
  chart,
  providerCredentials,
  providerTitles,
  providerTypes,
  uniteUsEnabled,
}) {
  const [careTeamMemberships, setCareTeamMemberships] = useState(initialCareTeamMemberships)

  const { selectedMembership, isAddModalVisible, isEditModalVisible, showAddModal, showEditModal, hideModal } =
    useCareTeamMembershipModal()

  function handleAdd(membership) {
    setCareTeamMemberships((careTeamMemberships) => [...careTeamMemberships, membership])
  }

  function handleDelete(membershipId) {
    setCareTeamMemberships((careTeamMemberships) =>
      careTeamMemberships.filter((membership) => membership.id !== membershipId)
    )
  }

  function handleUpdate(updatedMembership) {
    setCareTeamMemberships((careTeamMemberships) =>
      careTeamMemberships.map((membership) => (membership.id === updatedMembership.id ? updatedMembership : membership))
    )
  }

  return (
    <ConfirmationModalProvider>
      <Section
        title="Care Team Members"
        actions={
          <>
            <Show when={uniteUsEnabled}>
              <SecondaryButton onClick={() => redirectTo(chart_unite_us_referrals_path(chart.id))}>
                Make Referral
              </SecondaryButton>
            </Show>
            <Show when={!readOnly}>
              <PrimaryButton onClick={() => showAddModal()}>Add Care Team Member</PrimaryButton>
            </Show>
          </>
        }
      >
        <CareTeamMembershipsTable
          readOnly={readOnly}
          careTeamMemberships={careTeamMemberships}
          showEditModal={showEditModal}
          onDelete={handleDelete}
        />
        {chart && (
          <AddCareTeamMembershipForm
            chart={chart}
            providerCredentials={providerCredentials}
            providerTitles={providerTitles}
            providerTypes={providerTypes}
            show={isAddModalVisible}
            onAdd={handleAdd}
            onClose={hideModal}
          />
        )}
        {selectedMembership ? (
          <EditCareTeamMembershipForm
            membership={selectedMembership}
            providerTypes={providerTypes}
            show={isEditModalVisible}
            onClose={hideModal}
            onUpdate={handleUpdate}
          />
        ) : null}
        <ToastContainer />
      </Section>
    </ConfirmationModalProvider>
  )
}

Index.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  careTeamMemberships: PropTypes.arrayOf(types.careTeamMembership).isRequired,
  chart: types.chart,
  providerCredentials: PropTypes.arrayOf(types.selectOption).isRequired,
  providerTitles: PropTypes.arrayOf(types.selectOption).isRequired,
  providerTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  uniteUsEnabled: PropTypes.bool.isRequired,
}

export default Index
