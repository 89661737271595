import _ from "lodash"
import pluralize from "pluralize"
import PropTypes from "prop-types"

import { LineDivider } from "~/components/Divider"
import DividerList from "~/components/DividerList"
import formattedDate from "~/dateHelpers"
import { WarningAlert } from "~/design/alerts"
import Form from "~/design/forms"
import Modal from "~/design/Modal"
import { mapActiveHashToSelectOptions } from "~/reactSelectHelpers"
import api from "~/services/api"
import { toast } from "~/toast"
import ClinicalAlertDetailsList from "~/transitionsOfCare/ClinicalAlertDetailsList"

const ClinicalEventMergeModal = ({ selectedClinicalEvents, clinicalEventStatuses, onSubmitSuccess, show, onClose }) => {
  const selectedClinicalEventIds = selectedClinicalEvents.map((clinicalEvent) => clinicalEvent.id)
  const selectedClinicalAlerts = _.flatten(_.map(selectedClinicalEvents, "clinicalAlerts"))
  const selectedClinicalAlertEncounterIds = _.flatten(_.map(selectedClinicalEvents, "encounterIds"))

  const clinicalEventStatusSelectOptions = mapActiveHashToSelectOptions(clinicalEventStatuses)

  const handleSubmit = async ({ clinicalEventStatusId }, { setErrors, setStatus }) => {
    try {
      const { clinicalEvent } = await api.transitionsOfCare.clinicalEventMerges.post(
        selectedClinicalEventIds,
        clinicalEventStatusId
      )

      setStatus("success")
      toast.success("Clinical events successfully merged")
      onClose()
      onSubmitSuccess(clinicalEvent)
    } catch ({ errors }) {
      setErrors(errors)
      toast.error("Clinical events could not be merged")
    }
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      title="Merge Selected Clinical Events"
      size="xl"
      dataTestId="clinical-event-merge-modal"
    >
      <Form.Provider
        initialValues={{ clinicalEventStatusId: clinicalEventStatusSelectOptions[0].value }}
        onSubmit={handleSubmit}
        formId="clinical-event-merge-modal-form"
      >
        <Modal.Body>
          <Form.Body vertical>
            <p>
              You have selected <strong>{selectedClinicalEvents.length} clinical events</strong> to merge. By confirming
              this action, you understand that all selected clinical events and the existing follow-up encounters will
              be merged together.
            </p>
            <div className="mb-2">
              <strong className="mr-2">Clinical Event</strong>
              <DividerList>
                <span>Created On {formattedDate(_.minBy(selectedClinicalEvents, "createdAt")?.createdAt)}</span>
                <span>{pluralize("alert", selectedClinicalAlerts.length, true)}</span>
                <span>{pluralize("encounter", _.uniq(selectedClinicalAlertEncounterIds).length, true)}</span>
              </DividerList>
            </div>
            <div className="border overflow-auto" style={{ maxHeight: "120px" }}>
              <ClinicalAlertDetailsList clinicalAlerts={selectedClinicalAlerts} />
            </div>
            <LineDivider />
            <Form.Group name="clinicalEventStatusId">
              <Form.Label>Set new status:</Form.Label>
              <Form.SelectBox options={clinicalEventStatusSelectOptions} />
            </Form.Group>
            <WarningAlert>
              <p>
                <strong>Important!</strong>
                <br />
                Once the selected clinical events are merged, <strong>you will not be able to unmerge</strong> or
                separate the existing follow-up encounters associated.
              </p>
            </WarningAlert>
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Yes, Merge Clinical Events" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

ClinicalEventMergeModal.propTypes = {
  selectedClinicalEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      clinicalEventStatusId: PropTypes.number.isRequired,
      clinicalAlerts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          occurredAt: PropTypes.string.isRequired,
          receivedAt: PropTypes.string.isRequired,
          clinicalAlertVisitTypeId: PropTypes.number.isRequired,
          clinicalAlertVisitType: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            color: PropTypes.string,
          }).isRequired,
          facility: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  clinicalEventStatuses: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default ClinicalEventMergeModal
