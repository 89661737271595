import { CARE_PLAN_ENTITIES_DELETED } from "~/components/carePlans/carePlanActions"
import { CARE_PLAN_NEED_CREATED } from "~/components/carePlans/carePlanNeedActions"
import { deleteChildNodes } from "~/components/carePlans/entityTreeTransformer"

export default function carePlanReducer(state = {}, action) {
  switch (action.type) {
    case CARE_PLAN_NEED_CREATED:
      return {
        ...state,
        needIds: [action.need.id, ...state.needIds],
      }
    case CARE_PLAN_ENTITIES_DELETED:
      return deleteChildNodes(state, "needIds", action.needIds)
    default:
      return state
  }
}
