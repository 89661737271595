import { CareManagementAgencyAssessmentsTable } from "~/big3Dashboard/components/AssessmentsTable"
import { CareManagementAgencyCarePlansTable } from "~/big3Dashboard/components/CarePlansTable"
import { CareManagementAgencyConsentsTable } from "~/big3Dashboard/components/ConsentsTable"
import { ASSESSMENTS_TAB_ID, CARE_PLANS_TAB_ID, CONSENTS_TAB_ID } from "~/big3Dashboard/dashboardTabIds"

const tabs = [
  {
    id: CONSENTS_TAB_ID,
    displayName: "Consents",
    tableComponent: CareManagementAgencyConsentsTable,
  },
  {
    id: ASSESSMENTS_TAB_ID,
    displayName: "Assessments",
    tableComponent: CareManagementAgencyAssessmentsTable,
  },
  {
    id: CARE_PLANS_TAB_ID,
    displayName: "Care Plans",
    tableComponent: CareManagementAgencyCarePlansTable,
  },
]

export default tabs
