import PropTypes from "prop-types"

const cesTool = PropTypes.shape({
  id: PropTypes.number,
  memberIsEligibleForHhPlus: PropTypes.bool,
  memberHasQualifyingDiagnosisId: PropTypes.number,
  memberHasActiveMedicaidId: PropTypes.number,
  memberIsExperiencingSignificantRiskFactors: PropTypes.bool,
  significantRiskFactorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  additionalInformationForSignificantRiskFactors: PropTypes.string,
  memberIsExperiencingGeneralRiskFactorsId: PropTypes.number,
  generalRiskFactorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  memberIsExperiencingStabilityRiskFactorsId: PropTypes.number,
  stabilityRiskFactorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  memberIsExperiencingSkillBasedRiskFactorsId: PropTypes.number,
  skillsBasedRiskFactorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  memberHasNonMaintenanceGoalsLeftOnPocId: PropTypes.number,
  memberHasActivelyEngagedInLastSixMonthsId: PropTypes.number,
  memberHasMadeProgressInLastSixMonthsId: PropTypes.number,
  startDate: PropTypes.string,
  outcomeId: PropTypes.number,
  commentsOnItemsAnsweredUnclear: PropTypes.string,
  roleId: PropTypes.number,
})

export default cesTool
