import PropTypes from "prop-types"

function CarePlanFormBody({ title, dataTestId, children }) {
  return (
    <section className="border px-3 pt-3" style={{ backgroundColor: "#FDFAEA" }} data-testid={dataTestId}>
      <h2 className="mb-3" style={{ fontSize: "1rem", lineHeight: 1.25, fontWeight: "bold" }}>
        {title}
      </h2>
      {children}
    </section>
  )
}

CarePlanFormBody.propTypes = {
  title: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default CarePlanFormBody
