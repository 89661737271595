import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { isSelectQuestionType } from "../../responses/helpers/questionHelpers"
import { questionSelectors } from "../redux/questionsSlice"
import { selectOrderedQuestionOptions } from "../redux/sectionsSlice"

import { IconButton } from "~/design/buttons"
import Form from "~/design/forms"
import { CloseIcon } from "~/design/icons"
import Show from "~/design/Show"
import types from "~/types"

function SchemaQuestionCardDependsOnQuestionFields({ dependsOnQuestion, onClose, question, sectionQuestionOptions }) {
  const availableDependsOnQuestionFieldOptions = sectionQuestionOptions.filter((option) => option.value !== question.id)
  let dependsOnOptions = []
  if (dependsOnQuestion) {
    dependsOnOptions = dependsOnQuestion.optionsAttributes.map((dependsOnQuestionOption, optionIndex) => ({
      label: `${optionIndex + 1}: ${dependsOnQuestionOption.optionText}`,
      value: dependsOnQuestionOption.id,
    }))
  }

  return (
    <div className="border rounded bg-light p-3">
      <div className="d-flex justify-content-between mb-3">
        <strong>Make Dependent</strong>
        <IconButton Icon={CloseIcon} label="Cancel" onClick={onClose} />
      </div>
      <Row>
        <Col md={6}>
          <Form.Group name="dependsOnQuestionId" errorKey="dependsOnQuestion">
            <Form.Label>Which question does this depend on?</Form.Label>
            <Form.SelectBox options={availableDependsOnQuestionFieldOptions} isClearable />
          </Form.Group>
        </Col>
        <Show when={Boolean(dependsOnQuestion) && isSelectQuestionType(dependsOnQuestion.questionTypeId)}>
          <Col md={6}>
            <Form.Group name="dependsOnQuestionOptionIds" errorKey="dependsOnQuestionOptions">
              <Form.Label>Which answer options does this depend on?</Form.Label>
              <Form.MultiSelectBox options={dependsOnOptions} isClearable />
            </Form.Group>
          </Col>
        </Show>
      </Row>
    </div>
  )
}

SchemaQuestionCardDependsOnQuestionFields.propTypes = {
  dependsOnQuestion: types.customAssessmentSchemaQuestion,
  onClose: PropTypes.func.isRequired,
  question: types.customAssessmentSchemaQuestion.isRequired,
  sectionQuestionOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default connect((state, { question: { sectionId, dependsOnQuestionId } }) => ({
  dependsOnQuestion: dependsOnQuestionId ? questionSelectors.selectById(state, dependsOnQuestionId) : null,
  sectionQuestionOptions: selectOrderedQuestionOptions(state, sectionId),
}))(SchemaQuestionCardDependsOnQuestionFields)
