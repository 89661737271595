import _ from "lodash"

import { CARE_PLAN_ENTITIES_DELETED } from "~/components/carePlans/carePlanActions"
import { CARE_PLAN_GOAL_CREATED, CARE_PLAN_GOAL_UPDATED } from "~/components/carePlans/carePlanGoalActions"
import { CARE_PLAN_TASK_CREATED } from "~/components/carePlans/carePlanTaskActions"
import { deleteChildNodesAndSiblingNodes } from "~/components/carePlans/entityTreeTransformer"

export default function makeCarePlanGoalsReducer({ taskIdAdder = _.concat }) {
  return (state = {}, action) => {
    switch (action.type) {
      case CARE_PLAN_GOAL_CREATED:
      case CARE_PLAN_GOAL_UPDATED:
        return { ...state, [action.goal.id]: action.goal }
      case CARE_PLAN_TASK_CREATED: {
        const goal = state[action.goalId]
        const updatedTaskIds = taskIdAdder(goal.taskIds, action.task.id)
        return {
          ...state,
          [goal.id]: { ...goal, taskIds: updatedTaskIds },
        }
      }
      case CARE_PLAN_ENTITIES_DELETED:
        return deleteChildNodesAndSiblingNodes(state, "taskIds", action.goalIds, action.taskIds)
      default:
        return state
    }
  }
}
