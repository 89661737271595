import { useEffect } from "react"
import { useFormikContext } from "formik"

function NullEncounterDetailsFields() {
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    setFieldValue("nullEncounterDetailsAttributes", [""])
  }, [])

  return null
}

export default NullEncounterDetailsFields
