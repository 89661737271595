import { FormFile as BootstrapFormFile } from "react-bootstrap"
import { useField } from "formik"
import PropTypes from "prop-types"

import { DEFAULT_CONTROL_WIDTH } from "./constants"
import FieldErrors from "./FieldErrors"
import HelpText from "./HelpText"
import useDisableContext from "./useDisableContext"
import useErrorContext from "./useErrorContext"
import { LayoutWrapper } from "./useLayoutContext"
import useNameContext from "./useNameContext"

/**
 * A `<FileField />` allows users to upload files when submitting forms.
 */
function FileField({ disabled: disabledByProps, helpText, validate, width }) {
  const { id, name } = useNameContext()
  const [, , helpers] = useField({ name, validate })
  const { hasError } = useErrorContext()
  const disabledByContext = useDisableContext()

  const helpTextId = `${id}HelpText`
  const errorMessageId = `${id}Errors`
  const disabled = disabledByContext || disabledByProps

  function handleChange(event) {
    helpers.setValue(event.target.files[0])
  }

  return (
    <LayoutWrapper sm={width}>
      <BootstrapFormFile.Input
        onChange={handleChange}
        aria-describedby={helpText ? helpTextId : null}
        aria-errormessage={errorMessageId}
        aria-invalid={hasError}
        disabled={disabled}
        id={id}
        isInvalid={hasError}
        name={name}
      />
      <HelpText id={helpTextId}>{helpText}</HelpText>
      <FieldErrors id={errorMessageId} />
    </LayoutWrapper>
  )
}

FileField.propTypes = {
  disabled: PropTypes.bool,
  /**
   * Text intended to guide the user toward the correct input.
   */
  helpText: PropTypes.string,
  /**
   * An optional function to run client-side validations:
   * https://formik.org/docs/api/field#validate
   */
  validate: PropTypes.func,
  /**
   * The number of columns the control should span.
   */
  width: PropTypes.number,
}

FileField.defaultProps = {
  disabled: false,
  width: DEFAULT_CONTROL_WIDTH,
}

export default FileField
