import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import usePaymentSummaryReport from "../hooks/usePaymentSummaryReport"

import Form from "~/design/forms"
import IndexFilter from "~/design/IndexFilter"
import useQueryParams from "~/hooks/useQueryParams"
import types from "~/types"

function Filter({
  billingInstanceTypes,
  careManagementAgencies,
  claimStatuses,
  claimTypes,
  healthHomes,
  payers,
  rateCodes,
}) {
  const { isLoading } = usePaymentSummaryReport()
  const [queryParams, setQueryParams] = useQueryParams()

  function handleSubmit(values, { setSubmitting }) {
    setQueryParams(values)
    setSubmitting(false)
  }

  return (
    <Form initialValues={queryParams} onSubmit={handleSubmit}>
      <IndexFilter>
        <Row>
          <Col lg={4}>
            <Form.Group compact name="filter.claim_status_id">
              <Form.Label width={6}>Claim Status</Form.Label>
              <Form.MultiSelectBox width={6} options={claimStatuses} placeholder="" />
            </Form.Group>
            <Form.Group compact name="filter.claim_type_id">
              <Form.Label width={6}>Claim Type</Form.Label>
              <Form.MultiSelectBox width={6} options={claimTypes} placeholder="" />
            </Form.Group>
            <Form.Group compact name="filter.rate_code_id">
              <Form.Label width={6}>Rate Code</Form.Label>
              <Form.MultiSelectBox width={6} options={rateCodes} placeholder="" />
            </Form.Group>
            <Form.Group compact name="filter.billing_instance_type_id">
              <Form.Label width={6}>Billing Instance Type</Form.Label>
              <Form.SelectBox width={6} isClearable options={billingInstanceTypes} placeholder="" />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group compact name="filter.health_home_id">
              <Form.Label width={6}>Health Home</Form.Label>
              <Form.MultiSelectBox width={6} options={healthHomes} placeholder="" />
            </Form.Group>
            <Form.Group compact name="filter.care_management_agency_id">
              <Form.Label width={6}>CMA</Form.Label>
              <Form.SelectBox width={6} isClearable options={careManagementAgencies} placeholder="" />
            </Form.Group>
            <Form.Group compact name="filter.payer_id">
              <Form.Label width={6}>Payer</Form.Label>
              <Form.MultiSelectBox width={6} options={payers} placeholder="" />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group compact name="filter.date_of_service_on_or_after">
              <Form.Label width={6}>DOS (starts)</Form.Label>
              <Form.DatePicker width={6} placeholder="" />
            </Form.Group>
            <Form.Group compact name="filter.date_of_service_on_or_before">
              <Form.Label width={6}>DOS (ends)</Form.Label>
              <Form.DatePicker width={6} placeholder="" />
            </Form.Group>
          </Col>
        </Row>
        <IndexFilter.ActionBar>
          <Form.SubmitButton label="Filter" disabled={isLoading} />
        </IndexFilter.ActionBar>
      </IndexFilter>
    </Form>
  )
}

Filter.propTypes = {
  billingInstanceTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  careManagementAgencies: PropTypes.arrayOf(types.selectOption).isRequired,
  claimStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  claimTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  healthHomes: PropTypes.arrayOf(types.selectOption).isRequired,
  payers: PropTypes.arrayOf(types.selectOption).isRequired,
  rateCodes: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default Filter
