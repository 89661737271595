import { SET_ACTIVE_TAB } from "~/big3Dashboard/actions/activeTabActions"
import { CONSENTS_TAB_ID } from "~/big3Dashboard/dashboardTabIds"

const initialState = CONSENTS_TAB_ID

function activeTabReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return action.activeTabId
    default:
      return state
  }
}

export default activeTabReducer
