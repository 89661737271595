import { createContext, useContext } from "react"
import { useFormikContext } from "formik"
import _ from "lodash"
import PropTypes from "prop-types"

function useErrorContext() {
  const errorContext = useContext(ErrorContext)
  if (!errorContext) {
    throw Error(`useErrorContext can only be called within an <ErrorProvider />.`)
  }
  return errorContext
}

const ErrorContext = createContext()

function ErrorProvider({ errorKey, name, children }) {
  const { touched, errors } = useFormikContext()

  const key = errorKey || name
  const fieldErrors = _.get(errors, key)
  const fieldTouched = _.get(touched, name)

  const hasError = fieldTouched && Boolean(fieldErrors)

  return <ErrorContext.Provider value={{ hasError, errors: fieldErrors }}>{children}</ErrorContext.Provider>
}

ErrorProvider.propTypes = {
  errorKey: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default useErrorContext
export { ErrorProvider }
