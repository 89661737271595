function formatNumberWithCommas(number) {
  return new Intl.NumberFormat("en-US").format(number)
}

function formatCompactNumber(number) {
  return new Intl.NumberFormat("en-US", { notation: "compact" }).format(number)
}

function formatCurrency(number) {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(number)
}

function formatPercentage(number, { maximumFractionDigits = 0 } = {}) {
  return new Intl.NumberFormat("en-US", { style: "percent", maximumFractionDigits }).format(number)
}

export { formatCompactNumber, formatCurrency, formatNumberWithCommas, formatPercentage }
