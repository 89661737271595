import { OverlayTrigger, Popover } from "react-bootstrap"
import { connect } from "react-redux"
import pluralize from "pluralize"

import Badge from "~/components/badge"
import { getSelectedMcoGapsInCare } from "~/components/carePlans/mcoGapsInCareSelectors"

const LinkedGapInCareBadge = ({ mcoGapsInCare }) => {
  if (mcoGapsInCare.length === 0) {
    return null
  }
  const popover = (
    <Popover id="popover-trigger-click-root-close">
      <Popover.Content>
        <ul className="mb-0">
          {mcoGapsInCare.map((mcoGapInCare) => (
            <li key={mcoGapInCare.id}>{mcoGapInCare.description}</li>
          ))}
        </ul>
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger trigger={["hover", "focus"]} rootClose placement="bottom" overlay={popover}>
      {/* role="button" announces the element as interactive and gives it a cursor: pointer */}
      {/* tabIndex="0" makes the element focusable for the 'focus' trigger of the popover */}
      <Badge variant="light-blue" pill={true} tabIndex="0" role="button">
        {pluralize("gap", mcoGapsInCare.length, true)} in care
      </Badge>
    </OverlayTrigger>
  )
}

export default connect((state, props) => ({ mcoGapsInCare: getSelectedMcoGapsInCare(state, props) }))(
  LinkedGapInCareBadge
)
