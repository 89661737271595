import PropTypes from "prop-types"

const chart = PropTypes.shape({
  id: PropTypes.number.isRequired,
  patientId: PropTypes.number.isRequired,
  patientName: PropTypes.string.isRequired,
  patientMedicaidMemberId: PropTypes.string.isRequired,
  healthHomeName: PropTypes.string.isRequired,
  healthHomeShortestName: PropTypes.string.isRequired,
  careManagementAgencyName: PropTypes.string.isRequired,
  careManagementAgencyShortestName: PropTypes.string.isRequired,
})

export default chart
