import classNames from "classnames"
import { useField } from "formik"
import PropTypes from "prop-types"

const SelectableBox = ({ name, value, children }) => {
  const [, { value: selectedValues }, { setValue }] = useField(name)
  const selected = selectedValues.includes(value)
  const toggleSelectValue = () => {
    if (selected) {
      setValue(selectedValues.filter((id) => id !== value))
    } else {
      setValue([...selectedValues, value])
    }
  }

  const handleKeyDown = (e) => {
    // Handle the space key being pressed when the element is focused, so this behaves like a checkbox
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/checkbox_role#description
    if (e.key === " ") {
      e.preventDefault()
      toggleSelectValue()
    }
  }

  // `role="checkbox"` announces this element as a checkbox for screenreaders, and is used in conjunction
  // with `aria-checked` to indicate whether this element is checked or not.
  // `tabIndex={0}` allows this element to take focus when a user is interacting via keyboard.
  return (
    <div
      role="checkbox"
      aria-checked={selected}
      tabIndex={0}
      onClick={toggleSelectValue}
      onKeyDown={handleKeyDown}
      className={classNames("selectable-box", {
        "selectable-box--selected": selected,
      })}
    >
      <div className="selectable-box-icon-container">
        <div
          className={classNames("selectable-box-icon", {
            "selectable-box-icon--checked": selected,
          })}
          aria-label={selected ? "Checked" : "Not Checked"}
        />
      </div>
      <div className="selectable-box-contents">{children}</div>
    </div>
  )
}

SelectableBox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
}

export default SelectableBox
