const ASSESSMENT = "Patient::Assessment"
const BARRIER = "Barrier"
const CARE_PLAN = "Patient::CarePlan"
const CARE_TEAM = "CareTeam::MembershipRecord"
const CUSTOM_ASSESSMENT_RESPONSE = "CustomAssessments::Response"
const DIAGNOSIS = "Patient::Diagnosis"
const MEDICATION = "Patient::Medication"
const RISK_FACTOR = "RiskFactor"
const STRENGTH = "Strength"

export {
  ASSESSMENT,
  BARRIER,
  CARE_PLAN,
  CARE_TEAM,
  CUSTOM_ASSESSMENT_RESPONSE,
  DIAGNOSIS,
  MEDICATION,
  RISK_FACTOR,
  STRENGTH,
}
