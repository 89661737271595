import { useMemo } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import PropTypes from "prop-types"

import DataTable, { useDataTable } from "./DataTable"

import DashboardWidget from "~/views/shared/DashboardWidget"

const columnHelper = createColumnHelper()

function ActivePatientsByMCO({ data }) {
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "MCO",
      }),
      columnHelper.accessor("outreachCount", {
        header: "O",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("enrolledCount", {
        header: "E",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("totalCount", {
        header: "Total",
        enableColumnFilter: false,
      }),
    ],
    []
  )

  const table = useDataTable({ data, columns })

  return (
    <DashboardWidget
      title="Active Patients by MCO"
      info={`This data comes directly from eMedNY, reflecting patients' insurance details as of yesterday.
         Please note that eMedNY tells us the specific insurance plan names (e.g. AMERIGROUP NEW YORK
         LLC ADV PLUS, AMERIGROUP NY LLC MLTC), which results in a long and unwieldy list. Wherever
         possible, we have aggregated the data in this table by MCO -- so that Amerigroup gets only one
         consolidated row. If you see a specific plan here that is not properly grouped, you can fix this
         by going to Manage -> eMedNY Insurance Plans and linking the plan to its parent MCO.`}
    >
      <div className="overflow-auto px-3" style={{ maxHeight: "385px" }}>
        <DataTable table={table} />
      </div>
    </DashboardWidget>
  )
}

ActivePatientsByMCO.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      outreachCount: PropTypes.number.isRequired,
      enrolledCount: PropTypes.number.isRequired,
      totalCount: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default ActivePatientsByMCO
