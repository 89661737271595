import PropTypes from "prop-types"

import Alert from "./Alert"

import CheckIcon from "~/design/icons/CheckIcon"

/**
 * A `<SuccessAlert />` is used for to communicate a successful event e.g.
 * model saved, process finished, etc.
 */
function SuccessAlert({ children, icon }) {
  return (
    <Alert IconComponent={icon ? CheckIcon : null} variant="success">
      {children}
    </Alert>
  )
}

SuccessAlert.propTypes = {
  /**
   * The content of the alert.
   */
  children: PropTypes.node.isRequired,
  /**
   * Adds a lock icon to the start of the alert.
   */
  icon: PropTypes.bool,
}

export default SuccessAlert
