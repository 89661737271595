import PropTypes from "prop-types"

const doh5055Form = PropTypes.shape({
  careManagerId: PropTypes.number,
  chartId: PropTypes.number.isRequired,
  consented: PropTypes.bool,
  healthHomeId: PropTypes.number,
  healthHomeName: PropTypes.string,
  healthHomePhoneNumber: PropTypes.string,
  legalRepresentativeName: PropTypes.string,
  legalRepresentativeRelationshipToPatient: PropTypes.string,
  participatingPartnersPatientSignature: PropTypes.shape({ url: PropTypes.string }),
  participatingPartnersCareManagerSignature: PropTypes.shape({ url: PropTypes.string }),
  patientDateOfBirth: PropTypes.string,
  patientHealthHomeConsentId: PropTypes.number,
  patientName: PropTypes.string,
  rhioName: PropTypes.string,
  rhioPhoneNumber: PropTypes.string,
  signature: PropTypes.shape({ url: PropTypes.string }),
  signedOn: PropTypes.string,
  patientId: PropTypes.number,
  doh5055ParticipatingPartnersAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      isCareManagementAgency: PropTypes.bool,
    })
  ),
})

export default doh5055Form
