import Icon from "./Icon"

/**
 * Renders an icon of a pencil, indicating an edit action.
 */
function EditIcon() {
  return <Icon name="pencil" />
}

export default EditIcon
