import _ from "lodash"

import EntryPoint from "~/redux/EntryPoint"

function wrapper(WrappedComponent, reducers, mapPropsToPreloadedState = _.noop) {
  return (props) => (
    <EntryPoint childProps={props} reducers={reducers} mapPropsToPreloadedState={mapPropsToPreloadedState}>
      <WrappedComponent {...props} />
    </EntryPoint>
  )
}

export default wrapper
