import PropTypes from "prop-types"

const fileField = PropTypes.shape({ url: PropTypes.string })

const carePlanSignature = PropTypes.shape({
  patientCarePlanId: PropTypes.number.isRequired,
  patientSignature: fileField,
  careManagerSignature: fileField,
  caregiverOrLegalRepSignature: fileField,
  caregiverOrLegalRepName: PropTypes.string,
  carePlan: PropTypes.shape({
    patientName: PropTypes.string.isRequired,
    effectiveDate: PropTypes.string.isRequired,
    previewUrl: PropTypes.string.isRequired,
  }).isRequired,
})

export default carePlanSignature
