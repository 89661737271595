import { Dropdown } from "react-bootstrap"
import { connect } from "react-redux"

import {
  ActiveOnlyFilterState,
  filterViewActiveOnly,
  filterViewAll,
} from "~/components/carePlans/carePlanActiveFilterActions"
import { getActiveFilterState } from "~/components/carePlans/carePlanUISelectors"
import { EyeIcon } from "~/design/icons"
import { selectedOption } from "~/reactSelectHelpers"

function CarePlanActiveFilter({ activeFilterState, filterViewActiveOnlyConnect, filterViewAllConnect }) {
  const options = [
    { label: "All", value: ActiveOnlyFilterState.ALL, onClick: filterViewAllConnect },
    { label: "Active only", value: ActiveOnlyFilterState.ACTIVE_ONLY, onClick: filterViewActiveOnlyConnect },
  ]
  const currentlySelectedValue = selectedOption(options, activeFilterState) || ""

  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="care-plan-active-filter">
        <span className="text-dark">
          <EyeIcon />
        </span>
        &nbsp;Status:&nbsp;
        {currentlySelectedValue.label}&nbsp;
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item key={option.value} onClick={() => option.onClick()}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default connect(
  (state) => ({
    activeFilterState: getActiveFilterState(state),
  }),
  {
    filterViewActiveOnlyConnect: filterViewActiveOnly,
    filterViewAllConnect: filterViewAll,
  }
)(CarePlanActiveFilter)
