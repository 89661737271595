import PropTypes from "prop-types"

import * as customAssessmentResponseTypes from "../types"

import CompleteAssessmentSection from "./CompleteAssessmentSection"
import ResponseQuestion from "./ResponseQuestion"
import ResponseSection from "./ResponseSection"

import { PrimaryButton } from "~/design/buttons"
import Form from "~/design/forms"

function PreviewResponseForm({ questionResponseValues: initialQuestionResponseValues, schemaVersion }) {
  return (
    <Form vertical initialValues={{ questionResponseValues: initialQuestionResponseValues }} onSubmit={() => {}}>
      {schemaVersion.sections.map((section) => (
        <ResponseSection key={section.id} section={section}>
          {section.questions.map((question, index) => {
            const dependsOnQuestion = question.dependsOnQuestion
              ? section.questions.find(({ id }) => id === question.dependsOnQuestion.id)
              : null
            return (
              <ResponseQuestion
                key={question.id}
                question={question}
                questionNumber={index + 1}
                dependsOnQuestion={dependsOnQuestion}
              />
            )
          })}
        </ResponseSection>
      ))}
      <CompleteAssessmentSection>
        <div className="d-flex justify-content-between align-items-center border rounded p-3">
          <div>
            <p className="mb-1">By clicking "Complete", the assessment form will be marked as done and finalized.</p>
            <small className="text-muted">This action is disabled when previewing a form.</small>
          </div>
          <PrimaryButton disabled>Complete</PrimaryButton>
        </div>
      </CompleteAssessmentSection>
    </Form>
  )
}

PreviewResponseForm.propTypes = {
  questionResponseValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired
  ),
  schemaVersion: customAssessmentResponseTypes.schemaVersion,
}

export default PreviewResponseForm
