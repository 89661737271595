import PropTypes from "prop-types"

function QuestionCard({ children, questionNumber }) {
  return (
    <div className="d-flex mb-2">
      <div
        className="font-weight-bold flex-shrink-0 border border-right-0 rounded-left text-center py-2"
        style={{ width: "35px", fontSize: "1rem" }}
      >
        {questionNumber}
      </div>
      <div className="flex-grow-1 border rounded-right py-2 px-3">{children}</div>
    </div>
  )
}

QuestionCard.propTypes = {
  children: PropTypes.node.isRequired,
  questionNumber: PropTypes.number.isRequired,
}

export default QuestionCard
