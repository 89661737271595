import { Form as BootstrapForm } from "react-bootstrap"
import PropTypes from "prop-types"

import useErrorContext from "./useErrorContext"

function FieldErrors({ id }) {
  const { hasError, errors } = useErrorContext()

  if (!hasError) return null

  // Formik always returns errors as an array, even if there's only a single
  // error for a field, but we may need to handle single errors, so this just
  // massages everything into the same shape.
  const errorList = Array.isArray(errors) ? errors : [errors]

  return (
    // We have to explicitly make this a block level element since some fields
    // e.g. date pickers aren't covered by the overrides for the .invalid-feedback
    // class due to the way the inputs are heavily nested inside of the form group.
    // Since this component returns null when there are no errors, there's nothing
    // unsafe about just always displaying these as a block.
    <BootstrapForm.Control.Feedback id={id} role="alert" className="d-block" type="invalid">
      <ul className="mb-0">
        {errorList.map((e, idx) => (
          <li key={idx}>{e}</li>
        ))}
      </ul>
    </BootstrapForm.Control.Feedback>
  )
}

FieldErrors.propTypes = {
  id: PropTypes.string.isRequired,
}

export default FieldErrors
