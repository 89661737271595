import PropTypes from "prop-types"

import Alert from "./Alert"

import LockIcon from "~/design/icons/LockIcon"

/**
 * A `<PrimaryAlert />` is used for important informational messages such as
 * notices and explanatory text.
 */
function PrimaryAlert({ children, dataTestId, icon }) {
  return (
    <Alert dataTestId={dataTestId} IconComponent={icon ? LockIcon : null} variant="primary">
      {children}
    </Alert>
  )
}

PrimaryAlert.propTypes = {
  /**
   * The content of the alert.
   */
  children: PropTypes.node.isRequired,
  /**
   * An ID used for selecting the component in tests.
   *
   * This ID is not suitable for production use.
   */
  dataTestId: PropTypes.string,
  /**
   * Adds a lock icon to the start of the alert.
   */
  icon: PropTypes.bool,
}

export default PrimaryAlert
