import EditNeedForm from "~/components/carePlans/EditNeedForm"
import FormShowToggle from "~/components/carePlans/FormShowToggle"
import NeedCardFooter from "~/components/carePlans/NeedCardFooter"
import NeedDetails from "~/components/carePlans/NeedDetails"

const NeedCard = ({ need, status }) => {
  const connectedNeedForm = ({ onToggleEdit }) => <EditNeedForm need={need} onCancelClick={onToggleEdit} />

  const connectedNeedDetails = ({ onToggleEdit }) => <NeedDetails need={need} onEditClick={onToggleEdit} />

  return (
    <div className={`need-card ${status.label.toLowerCase()}`}>
      <FormShowToggle formComponent={connectedNeedForm} showComponent={connectedNeedDetails} />
      <NeedCardFooter needId={need.id} />
    </div>
  )
}

export default NeedCard
