import { connect } from "react-redux"
import PropTypes from "prop-types"

import { selectSchemaVersion } from "../redux/schemaVersionSlice"
import { sectionCreated } from "../redux/sectionsSlice"

import Card from "./Card"
import SchemaSectionFormFields from "./SchemaSectionFormFields"

import { LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import api from "~/services/api"
import { toast } from "~/toast"

function NewSchemaSectionForm({ sectionCreatedConnect, sectionIndex, schemaVersionId, onFormClose }) {
  const defaultSection = {
    description: "",
    sectionName: "",
  }

  async function handleSubmit(sectionFormData, { setErrors, setStatus }) {
    try {
      const createdSection = await api.customAssessments.schemaSections.post(schemaVersionId, sectionFormData)
      sectionCreatedConnect(createdSection)

      toast.success("Section created successfully.")
      setStatus("success")
      onFormClose()
    } catch ({ errors }) {
      toast.error("Section could not be created.")
      setErrors(errors)
    }
  }

  return (
    <Form.Provider initialValues={defaultSection} onSubmit={handleSubmit} formId="schema-section-new-form">
      <Card dataTestid="schema-section-card-new">
        <Card.Header title="New Section">
          <LinkButton color="danger" onClick={onFormClose}>
            Remove
          </LinkButton>
        </Card.Header>
        <Card.Body>
          <Form.Body vertical>
            <SchemaSectionFormFields sectionIndex={sectionIndex} />
          </Form.Body>
        </Card.Body>
        <Card.Footer right={<Form.SubmitButton label="Create" />} />
      </Card>
    </Form.Provider>
  )
}

NewSchemaSectionForm.propTypes = {
  sectionCreatedConnect: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  schemaVersionId: PropTypes.number.isRequired,
  onFormClose: PropTypes.func.isRequired,
}

export default connect((state) => ({ schemaVersionId: selectSchemaVersion(state).id }), {
  sectionCreatedConnect: sectionCreated,
})(NewSchemaSectionForm)
