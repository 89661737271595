import HealthHomeQualityContainer from "~/big3Dashboard/HealthHomeQualityContainer"
import activeTabReducer from "~/big3Dashboard/reducers/activeTabReducer"
import paramsReducer from "~/big3Dashboard/reducers/paramsReducer"
import qualityReportEntitiesReducer from "~/big3Dashboard/reducers/qualityReportEntitiesReducer"
import qualityReportStatusReducer from "~/big3Dashboard/reducers/qualityReportStatusReducer"
import { wrapper } from "~/redux"

const mapPropsToPreloadedState = ({ globals: { healthHomes }, healthHomeId, date, activeTabId }) => ({
  params: {
    reportRowEntityIds: [],
    entityId: healthHomeId,
    date,
  },
  globals: {
    entities: healthHomes,
  },
  activeTabId: parseInt(activeTabId, 10),
})

export default wrapper(
  HealthHomeQualityContainer,
  {
    status: qualityReportStatusReducer,
    entities: qualityReportEntitiesReducer,
    params: paramsReducer,
    activeTabId: activeTabReducer,
  },
  mapPropsToPreloadedState
)
