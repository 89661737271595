import { useEffect } from "react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import Form from "~/design/forms"
import types from "~/types"

export const AdultChildServicesCode = {
  Adult: 1, // Patient::AdultChildServicesCode.ADULT.id
  Child: 2, // Patient::AdultChildServicesCode.CHILD.id
}

function useDefaultServicesProvided(healthHome) {
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (healthHome.servesChildren && healthHome.servesAdults) {
      return
    }

    setFieldValue(
      "adultChildServicesCodeId",
      healthHome.servesAdults ? AdultChildServicesCode.Adult : AdultChildServicesCode.Child
    )
  }, [healthHome, setFieldValue])
}

function ServicesProvided({ codes, healthHome }) {
  useDefaultServicesProvided(healthHome)

  function healthHomeServesChildrenAndAdults() {
    return healthHome.servesChildren && healthHome.servesAdults
  }

  return (
    <Form.Show when={healthHomeServesChildrenAndAdults}>
      <Form.Group clearOnHide={false} name="adultChildServicesCodeId" errorKey="adultChildServicesCode">
        <Form.Label>Services Provided</Form.Label>
        <Form.SelectBox options={codes} />
      </Form.Group>
    </Form.Show>
  )
}

ServicesProvided.propTypes = {
  codes: PropTypes.arrayOf(types.selectOption).isRequired,
  healthHome: types.healthHome.isRequired,
}

export default ServicesProvided
export { useDefaultServicesProvided }
