import { submitAsyncRequest } from "~/asyncHelpers"
import {
  api_v1_chart_clinical_events_path,
  api_v1_clinical_event_merges_path,
  api_v1_clinical_event_path,
} from "~/routes"

const transitionsOfCare = {
  clinicalEventMerges: {
    post: function (clinicalEventIds, clinicalEventStatusId) {
      return submitAsyncRequest({
        url: api_v1_clinical_event_merges_path(),
        type: "post",
        data: { clinicalEventIds, clinicalEventStatusId },
        jsonData: true,
      })
    },
  },
  clinicalEvents: {
    get: function (chartId) {
      return submitAsyncRequest({
        url: api_v1_chart_clinical_events_path(chartId),
        type: "get",
        jsonData: true,
      })
    },
    patch: function (clinicalEventId, clinicalEventData) {
      return submitAsyncRequest({
        url: api_v1_clinical_event_path(clinicalEventId),
        type: "patch",
        data: { clinicalEvent: clinicalEventData },
        jsonData: true,
      })
    },
  },
}

export default transitionsOfCare
