import BarrierSection from "./barriers/BarrierSection"
import RiskFactorSection from "./riskFactors/RiskFactorSection"
import StrengthSection from "./strengths/StrengthSection"

import types from "~/types"

function SocialBackgroundWidget({
  chart,
  socialBackground: { barriers, riskFactors, riskFactorCategories, strengths },
}) {
  return (
    <div className="border bg-light mb-3">
      <StrengthSection chart={chart} strengths={strengths} />
      <BarrierSection barriers={barriers} chart={chart} />
      <RiskFactorSection chart={chart} riskFactors={riskFactors} categories={riskFactorCategories} />
    </div>
  )
}

SocialBackgroundWidget.propTypes = {
  chart: types.chart,
  socialBackground: types.socialBackground,
}

export default SocialBackgroundWidget
