import { useQuery } from "@tanstack/react-query"

import { submitAsyncRequest } from "~/asyncHelpers"
import useQueryParams from "~/hooks/useQueryParams"
import { chart_data_reports_end_date_reason_code_summary_path } from "~/routes"

function useEndDateReasonCodeSummaryReport() {
  const [queryParams] = useQueryParams()
  const reportUrl = chart_data_reports_end_date_reason_code_summary_path(queryParams)

  const { data, isLoading } = useQuery({
    queryFn: () => submitAsyncRequest({ url: reportUrl }),
    queryKey: [reportUrl],
  })

  return { data, isLoading }
}

export default useEndDateReasonCodeSummaryReport
