import { useMemo } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import PropTypes from "prop-types"

import DataTable, { useDataTable } from "./DataTable"

import DashboardWidget from "~/views/shared/DashboardWidget"

const columnHelper = createColumnHelper()

function ActivePatientsByCMA({ data }) {
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "CMA",
      }),
      columnHelper.accessor("outreachCount", {
        header: "O",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("enrolledCount", {
        header: "E",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("totalCount", {
        header: "Total",
        enableColumnFilter: false,
      }),
    ],
    []
  )

  const table = useDataTable({ data, columns })

  return (
    <DashboardWidget title="Active Patients by CMA">
      <div className="overflow-auto px-3" style={{ maxHeight: "385px" }}>
        <DataTable table={table} />
      </div>
    </DashboardWidget>
  )
}

ActivePatientsByCMA.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      outreachCount: PropTypes.number.isRequired,
      enrolledCount: PropTypes.number.isRequired,
      totalCount: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default ActivePatientsByCMA
