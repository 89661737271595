import DOMPurify from "dompurify"

function highlightMatchingStringSection(string, match) {
  const sanitizedString = DOMPurify.sanitize(string)
  const regex = new RegExp(match, "gi")

  return sanitizedString.replace(regex, (str) => `<strong>${str}</strong>`)
}

// Groups records by type for display in tables:
// groupRecordsByType(
//   [
//     { copiedFromId: 1, copiedFromType: "CustomAssessments::Response", copiedFromDescription: "Comp Assessment", ... }
//     { copiedFromId: 1, copiedFromType: "Patient::Diagnosis", copiedFromDescription: "Asthma", ... }
//   ]
// )
// => {
//      "CustomAssessments::Response": [{ copiedFromId: 1, copiedFromDescription: "Comp Assessment", ... }],
//      "Patient::Diagnosis": [{ copiedFromId: 1, copiedFromDescription: "Asthma", ... }]
//    }
function groupRecordsByType(chartDataCopyRecords) {
  return chartDataCopyRecords.reduce((groups, record) => {
    if (!(record.copiedFromType in groups)) {
      groups[record.copiedFromType] = []
    }
    groups[record.copiedFromType].push(record)
    return groups
  }, {})
}

// Map selection state stored in React Table format to shape expected for records_attributes params
// getRecordsAttributes({ Barrier: { 1: true, 2: true }, Diagnosis: { 3: true } })
// => [
//   { copiedFromType: 'Barrier', copiedFromId: 1 },
//   { copiedFromType: 'Barrier', copiedFromId: 2 },
//   { copiedFromType: 'Diagnosis', copiedFromId: 3 },
// ]
function getRecordsAttributes(selectedRecords) {
  return Object.entries(selectedRecords).flatMap(([copiedFromType, selection]) => {
    return Object.keys(selection).map((copiedFromId) => ({
      copiedFromType,
      copiedFromId,
    }))
  })
}

export { getRecordsAttributes, groupRecordsByType, highlightMatchingStringSection }
