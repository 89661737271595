import { HealthHomeAssessmentsTable } from "~/big3Dashboard/components/AssessmentsTable"
import { HealthHomeCarePlansTable } from "~/big3Dashboard/components/CarePlansTable"
import { HealthHomeConsentsTable } from "~/big3Dashboard/components/ConsentsTable"
import { ASSESSMENTS_TAB_ID, CARE_PLANS_TAB_ID, CONSENTS_TAB_ID } from "~/big3Dashboard/dashboardTabIds"

const tabs = [
  {
    id: CONSENTS_TAB_ID,
    displayName: "Consents",
    tableComponent: HealthHomeConsentsTable,
  },
  {
    id: ASSESSMENTS_TAB_ID,
    displayName: "Assessments",
    tableComponent: HealthHomeAssessmentsTable,
  },
  {
    id: CARE_PLANS_TAB_ID,
    displayName: "Care Plans",
    tableComponent: HealthHomeCarePlansTable,
  },
]

export default tabs
