import PropTypes from "prop-types"

const uniteUsAgency = PropTypes.shape({
  id: PropTypes.number.isRequired,
  health_home_id: PropTypes.number,
  agency_id: PropTypes.string,
  roleId: PropTypes.array.isRequired,
  uniteUsEnabledCareManagementAgencies: PropTypes.objectOf(
    PropTypes.shape({
      careManagementAgencyId: PropTypes.number.isRequired,
    })
  ),
})

export default uniteUsAgency
