import { Component } from "react"
import { connect } from "react-redux"

import { FLASH_MESSAGE_SECONDS } from "~/flashMessages/flashMessageActions"

class FlashMessage extends Component {
  get className() {
    return `flash-message ${this.props.className}`
  }

  render() {
    const { active, children } = this.props
    const style = { animation: `flash ${FLASH_MESSAGE_SECONDS}s forwards` }

    return (
      <div className={this.className} style={active && style}>
        {active && children}
      </div>
    )
  }
}

const mapStateToProps = ({ flashMessages }, ownProps) => ({ active: flashMessages && flashMessages[ownProps.flashKey] })

export default connect(mapStateToProps)(FlashMessage)
