import PropTypes from "prop-types"

import supervisoryTeamMember from "./supervisoryTeamMember"
import supervisoryTeamSupervisor from "./supervisoryTeamSupervisor"

const supervisoryTeam = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  careManagementAgencyId: PropTypes.number,
  members: PropTypes.arrayOf(supervisoryTeamMember).isRequired,
  supervisors: PropTypes.arrayOf(supervisoryTeamSupervisor).isRequired,
})

export default supervisoryTeam
