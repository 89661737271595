import { useState } from "react"
import { connect } from "react-redux"

import { getTaskWithOptions } from "~/components/carePlans/carePlanTasksSelectors"
import LinkableCarePlanTaskDetails from "~/components/encounters/LinkableCarePlanTaskDetails"
import EditTaskForm from "~/components/linkedCarePlanTasks/EditTaskForm"
import SelectableBox from "~/views/shared/SelectableBox"

const CarePlanTask = ({ carePlanTaskStatuses, editable, goal, task, taskResponsibilities }) => {
  const [showEditModal, setEditModal] = useState(false)
  const taskId = task.id
  const toggleEditModal = () => setEditModal(!showEditModal)

  return (
    <div data-testid={`care-plan-task-${taskId}`}>
      <SelectableBox name="carePlanTaskIds" value={taskId}>
        <LinkableCarePlanTaskDetails
          task={task}
          editable={editable}
          toggleEditModal={toggleEditModal}
          taskResponsibilities={taskResponsibilities}
          carePlanTaskStatuses={carePlanTaskStatuses}
        />
      </SelectableBox>
      <EditTaskForm show={showEditModal} onClose={() => setEditModal(false)} task={task} goal={goal} />
    </div>
  )
}

export default connect((state, props) => ({ task: getTaskWithOptions(state, props) }))(CarePlanTask)
