import classNames from "classnames"
import PropTypes from "prop-types"

function ResponseQuestionCard({ isAnswerable, children, questionId, questionNumber }) {
  return (
    <div data-testid={`response-question-${questionId}`} className="d-flex mb-2">
      <div
        className={classNames("font-weight-bold flex-shrink-0 border border-right-0 rounded-left text-center py-2", {
          "text-muted": !isAnswerable,
        })}
        style={{ width: "35px", fontSize: "1rem" }}
      >
        {questionNumber}
      </div>
      <div
        className={classNames("flex-grow-1 border rounded-right py-2 px-3", {
          "bg-light text-muted": !isAnswerable,
        })}
      >
        {children}
      </div>
    </div>
  )
}

ResponseQuestionCard.propTypes = {
  isAnswerable: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  questionId: PropTypes.number.isRequired,
  questionNumber: PropTypes.number.isRequired,
}

export default ResponseQuestionCard
