import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import AdditionalRiskFactorsSection from "./components/AdditionalRiskFactorsSection"
import CompletionSection from "./components/CompletionSection"
import EligibilityPrescreenSection from "./components/EligibilityPrescreenSection"
import HealthHomePlusSection from "./components/HealthHomePlusSection"
import InstructionsSection from "./components/InstructionsSection"
import MemberEngagementSection from "./components/MemberEngagementSection"
import ResultsSection from "./components/ResultsSection"
import SideBar from "./components/SideBar"
import SignificantRiskFactorsSection from "./components/SignificantRiskFactorsSection"
import { CesToolSchemaProvider } from "./hooks/useCesToolSchema"
import * as cesToolTypes from "./types"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { ces_tool_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function Edit({
  cesTool,
  cesToolSchema,
  cesToolOutcomes,
  cesToolQuestionResponseOptions,
  cesToolRoleOptions,
  generalRiskFactors,
  significantRiskFactors,
  skillsBasedRiskFactors,
  stabilityRiskFactors,
}) {
  async function handleSubmit(values, { setErrors }) {
    try {
      const {
        significantRiskFactorIds,
        generalRiskFactorIds,
        stabilityRiskFactorIds,
        skillsBasedRiskFactorIds,
        ...otherValues
      } = values
      const riskFactorIds = [
        significantRiskFactorIds,
        generalRiskFactorIds,
        stabilityRiskFactorIds,
        skillsBasedRiskFactorIds,
      ].flat()
      const updatedCesTool = await api.cesTools.patch(cesTool.id, { ...otherValues, riskFactorIds })
      redirectTo(ces_tool_path(updatedCesTool.id))
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form.Provider initialValues={cesTool} onSubmit={handleSubmit}>
      <CesToolSchemaProvider cesToolSchema={cesToolSchema}>
        <Row>
          <Col md={3}>
            <SideBar />
          </Col>
          <Col md={9}>
            <Form.Body vertical>
              <InstructionsSection />
              <HealthHomePlusSection />
              <EligibilityPrescreenSection cesToolQuestionResponseOptions={cesToolQuestionResponseOptions} />
              <SignificantRiskFactorsSection significantRiskFactors={significantRiskFactors} />
              <AdditionalRiskFactorsSection
                cesToolQuestionResponseOptions={cesToolQuestionResponseOptions}
                generalRiskFactors={generalRiskFactors}
                skillsBasedRiskFactors={skillsBasedRiskFactors}
                stabilityRiskFactors={stabilityRiskFactors}
              />
              <MemberEngagementSection cesToolQuestionResponseOptions={cesToolQuestionResponseOptions} />
              <ResultsSection cesToolOutcomes={cesToolOutcomes} cesToolRoleOptions={cesToolRoleOptions} />
              <CompletionSection />
            </Form.Body>
          </Col>
        </Row>
      </CesToolSchemaProvider>
    </Form.Provider>
  )
}

Edit.propTypes = {
  cesTool: types.cesTool.isRequired,
  cesToolSchema: cesToolTypes.cesToolSchema.isRequired,
  cesToolOutcomes: PropTypes.arrayOf(types.cesToolOutcome).isRequired,
  cesToolQuestionResponseOptions: PropTypes.arrayOf(types.selectOption).isRequired,
  cesToolRoleOptions: PropTypes.arrayOf(types.selectOption).isRequired,
  generalRiskFactors: PropTypes.arrayOf(types.cesRiskFactor).isRequired,
  significantRiskFactors: PropTypes.arrayOf(types.cesRiskFactor).isRequired,
  skillsBasedRiskFactors: PropTypes.arrayOf(types.cesRiskFactor).isRequired,
  stabilityRiskFactors: PropTypes.arrayOf(types.cesRiskFactor).isRequired,
}

export default Edit
