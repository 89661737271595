import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import api from "~/services/api"

const workflowDocumentsQueryKey = "childrensHCBSWorkflowDocuments"
const linkableDocumentsQueryKey = "childrensHCBSWorkflowLinkableDocuments"

function useChildrensHCBSWorkflowDocuments({ childrensHCBSWorkflowId, childrensHCBSWorkflowStepId, initialData }) {
  const { isLoading, data: childrensHCBSWorkflowDocuments } = useQuery({
    queryKey: [workflowDocumentsQueryKey],
    queryFn: () =>
      api.childrensHCBSWorkflowDocuments.get(childrensHCBSWorkflowId, {
        childrens_hcbs_workflow_step_id: childrensHCBSWorkflowStepId,
      }),
    initialData,
  })

  return { childrensHCBSWorkflowDocuments, isLoading }
}

function useAddChildrensHCBSWorkflowDocument(childrensHCBSWorkflowId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (workflowDocument) =>
      api.childrensHCBSWorkflowDocuments.post(childrensHCBSWorkflowId, workflowDocument),
    onSuccess: (newWorkflowDocument) => {
      queryClient.setQueryData([workflowDocumentsQueryKey], (workflowDocuments) => [
        ...workflowDocuments,
        newWorkflowDocument,
      ])
    },
  })
}

function useDeleteChildrensHCBSWorkflowDocument(childrensHCBSWorkflowId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (workflowDocumentId) => api.childrensHCBSWorkflowDocuments.delete(workflowDocumentId),
    onSuccess: (_, deletedWorkflowDocumentId) => {
      queryClient.setQueryData([workflowDocumentsQueryKey], (workflowDocuments) =>
        workflowDocuments.filter((workflowDocument) => workflowDocument.id !== deletedWorkflowDocumentId)
      )
      // Since the delete action does not return a document, we need to invalidate the linkable query
      // so that the deleted (unlinked) workflow document is available to be re-linked
      queryClient.invalidateQueries([linkableDocumentsQueryKey, childrensHCBSWorkflowId])
    },
  })
}

function useAddLinkedWorkflowDocument(childrensHCBSWorkflowId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (workflowDocument) =>
      api.childrensHCBSWorkflowLinkedDocuments.post(childrensHCBSWorkflowId, workflowDocument),
    onSuccess: (newWorkflowDocument) => {
      queryClient.setQueryData([workflowDocumentsQueryKey], (workflowDocuments) => [
        ...workflowDocuments,
        newWorkflowDocument,
      ])
      // Remove the added (linked) workflow document from linkable documents since it is no longer available to be re-linked
      queryClient.setQueryData([linkableDocumentsQueryKey, childrensHCBSWorkflowId], (documents) =>
        documents.filter(({ id }) => id !== newWorkflowDocument.document.id)
      )
    },
  })
}

function useLinkableWorkflowDocuments({ childrensHCBSWorkflowId, childrensHCBSWorkflowStepId }) {
  return useQuery({
    queryKey: [linkableDocumentsQueryKey, childrensHCBSWorkflowId],
    queryFn: () => api.childrensHCBSWorkflowLinkableDocuments.get(childrensHCBSWorkflowId, childrensHCBSWorkflowStepId),
  })
}

export {
  useAddChildrensHCBSWorkflowDocument,
  useAddLinkedWorkflowDocument,
  useDeleteChildrensHCBSWorkflowDocument,
  useLinkableWorkflowDocuments,
}
export default useChildrensHCBSWorkflowDocuments
