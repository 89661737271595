import PropTypes from "prop-types"

import Show from "./Show"

function PageHeader({ actions, subtitle, title }) {
  return (
    <div className="d-flex justify-content-between align-items-end border-bottom pb-2 my-3">
      <h1 style={{ fontSize: "1.5rem", lineHeight: "1.25" }}>
        <span>{title}</span>
        <Show when={Boolean(subtitle)}>
          <span className="text-muted" style={{ fontSize: "0.875rem" }}>
            {subtitle}
          </span>
        </Show>
      </h1>
      <div className="d-print-none d-flex align-items-end justify-content-end flex-wrap gap-2">{actions}</div>
    </div>
  )
}

PageHeader.propTypes = {
  /**
   * Top-level page actions. Typically, this should be a button or dropdown.
   */
  actions: PropTypes.node,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default PageHeader
