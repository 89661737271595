import { connect } from "react-redux"

import CarePlanFormBody from "./CarePlanFormBody"
import { carePlanNeedCreated, carePlanNeedFlashKey } from "./carePlanNeedActions"
import { closeNewNeedForm } from "./carePlanNewNeedFormActions"
import NeedFormFields from "./NeedFormFields"

import Form from "~/design/forms"
import { flashMessage } from "~/flashMessages/flashMessageActions"
import api from "~/services/api"
import { toast } from "~/toast"

function NewNeedForm({ carePlanId, carePlanNeedCreatedConnect, closeNewNeedFormConnect, flashMessageConnect }) {
  const defaultNeed = {
    description: "",
    carePlanNeedStatusId: 1, // CarePlanNeedStatus::ACTIVE.id
    notes: "",
    carePlanNeedCategoryId: "",
    startDate: "",
    resolutionDate: "",
  }

  async function handleSubmit(values, { setErrors, setStatus }) {
    try {
      const createdNeed = await api.carePlans.needs.post(carePlanId, values)
      setStatus("success")
      carePlanNeedCreatedConnect(createdNeed)
      flashMessageConnect(carePlanNeedFlashKey(createdNeed))
      toast.success("Need successfully created.")
      closeNewNeedFormConnect(createdNeed)
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={defaultNeed} onSubmit={handleSubmit} vertical>
      <CarePlanFormBody title="Add Need" dataTestId="need-form">
        <NeedFormFields />
        <Form.ActionBar
          right={
            <>
              <Form.CancelButton onClick={closeNewNeedFormConnect} />
              <Form.SubmitButton label="Create" />
            </>
          }
        />
      </CarePlanFormBody>
    </Form>
  )
}

export default connect(({ entities: { carePlan } }) => ({ carePlanId: carePlan.id }), {
  carePlanNeedCreatedConnect: carePlanNeedCreated,
  closeNewNeedFormConnect: closeNewNeedForm,
  flashMessageConnect: flashMessage,
})(NewNeedForm)
