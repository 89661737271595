import PropTypes from "prop-types"

import FormFields from "./FormFields"

import { redirectTo } from "~/browserHelpers"
import { LineDivider } from "~/components/Divider"
import PrimaryAlert from "~/design/alerts/PrimaryAlert"
import Form from "~/design/forms"
import { chart_segments_path, segment_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"
import ChartIndicator from "~/views/shared/ChartIndicator"

function EditForm({
  adultChildServicesCodes,
  appropriatenessCriteriaCodes,
  chart,
  endDateReasonCategories,
  endDateReasonCodes,
  healthHome,
  outreachEnrollmentCodes,
  pendReasons,
  referralCodes,
  segment,
}) {
  async function handleSubmit(values, { setErrors, setStatus }) {
    const { ...segment } = values

    const segmentParams = {
      ...segment,
    }

    try {
      await api.segments.patch(segment.id, segmentParams)
      setStatus("success")
      redirectTo(chart_segments_path(chart.id))
    } catch ({ errors }) {
      setErrors(errors)
      window.scrollTo(0, 0)
    }
  }

  function mappIdentityFieldsChanged({ values, initialValues }) {
    return (
      values.outreachEnrollmentCodeId !== initialValues.outreachEnrollmentCodeId ||
      values.startDate !== initialValues.startDate
    )
  }

  return (
    <Form initialValues={segment} onSubmit={handleSubmit}>
      <Form.Show when={mappIdentityFieldsChanged}>
        <PrimaryAlert dataTestId="mapp-alert">
          <p className="text-justify">
            Editing the Start Date or Outreach/Enrollment Code for a segment here will cause MAPP to delete the current
            segment and create an entirely new segment with the updated information. As a result, all billing support
            questionnaires associated with this segment will be automatically voided by MAPP.
          </p>
          <p className="text-justify mt-2">
            New potential billing support questionnaires will be created for all months included in the newly created
            segment. Note that in order to bill for any months that were voided, the new potential billing support
            questionnaire will need to be filled out. If this new questionnaire is not filled out, any associated claims
            will be voided.
          </p>
          <p className="text-center text-uppercase font-weight-bold mt-3">
            If the edits made on the segment are correct, click "Update Segment" to continue.
          </p>
        </PrimaryAlert>
      </Form.Show>

      <FormFields
        segment={segment}
        healthHome={healthHome}
        endDateReasonCodes={endDateReasonCodes}
        endDateReasonCategories={endDateReasonCategories}
        pendReasons={pendReasons}
        referralCodes={referralCodes}
        outreachEnrollmentCodes={outreachEnrollmentCodes}
        adultChildServicesCodes={adultChildServicesCodes}
        appropriatenessCriteriaCodes={appropriatenessCriteriaCodes}
      />

      <LineDivider />
      <ChartIndicator chart={chart} />

      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(segment_path(segment.id))} />
            <Form.SubmitButton label="Update Segment" submittingLabel="Updating..." />
          </>
        }
      />
    </Form>
  )
}

EditForm.propTypes = {
  adultChildServicesCodes: PropTypes.arrayOf(types.selectOption).isRequired,
  appropriatenessCriteriaCodes: PropTypes.arrayOf(types.appropriatenessCriteriaCode).isRequired,
  chart: types.chart.isRequired,
  endDateReasonCategories: PropTypes.arrayOf(types.selectOption).isRequired,
  endDateReasonCodes: PropTypes.arrayOf(types.endDateReasonCode).isRequired,
  healthHome: types.healthHome.isRequired,
  outreachEnrollmentCodes: PropTypes.arrayOf(types.selectOption).isRequired,
  pendReasons: PropTypes.arrayOf(types.selectOption).isRequired,
  referralCodes: PropTypes.arrayOf(types.selectOption).isRequired,
  segment: types.segment.isRequired,
}

export default EditForm
