import { useQuery } from "@tanstack/react-query"

import { submitAsyncRequest } from "~/asyncHelpers"
import { chart_data_reports_segment_summary_line_chart_path } from "~/routes"

function useSegmentSummaryLineChart({ healthHomeId, careManagementAgencyId }) {
  const reportUrl = chart_data_reports_segment_summary_line_chart_path({
    filter: { health_home_id: healthHomeId, care_management_agency_id: careManagementAgencyId },
  })

  return useQuery({
    queryFn: () => submitAsyncRequest({ url: reportUrl }),
    queryKey: [reportUrl],
  })
}

export default useSegmentSummaryLineChart
