import EditForm from "./components/EditForm"

import { ConfirmationModalProvider } from "~/design/modals/useConfirmationModal"
import { ToastContainer } from "~/toast"

function Edit(pageProps) {
  return (
    <ConfirmationModalProvider>
      <EditForm {...pageProps} />
      <ToastContainer />
    </ConfirmationModalProvider>
  )
}

export default Edit
