import PropTypes from "prop-types"

const careManagementAgency = PropTypes.shape({
  id: PropTypes.number.isRequired,
  actServiceProvisionIndicatorId: PropTypes.number,
  address: PropTypes.string,
  clinicalEventNotificationEmail: PropTypes.string,
  mmisId: PropTypes.string,
  name: PropTypes.string,
  npi: PropTypes.string,
  preDecember2016DirectBiller: PropTypes.bool,
  requireConsentedBy: PropTypes.bool,
  servesAdults: PropTypes.bool,
  servesChildren: PropTypes.bool,
  shortName: PropTypes.string,
  statusId: PropTypes.number,
  upstate: PropTypes.bool,
})

export default careManagementAgency
