import { useMemo } from "react"
import spinner from "images/spinner.svg"
import _ from "lodash"
import PropTypes from "prop-types"

import { QUALITY_REPORT_ERROR, QUALITY_REPORT_LOADING } from "~/big3Dashboard/actions/qualityReportStatusActions"
import Table from "~/big3Dashboard/components/Table"

function Big3Table({ status, columns, report, pageSize }) {
  const loading = status === QUALITY_REPORT_LOADING
  const displayLoadingSpinner = () => (
    <div className="big3-table-loading">
      <img className="big3-table-ajax-spinner" src={spinner} alt="Loading..." />
      <h2 className="big3-table-ajax-spinner-title"> Please wait while we load your data...</h2>
    </div>
  )

  const error = status === QUALITY_REPORT_ERROR
  const displayError = () => <div className="big3-table-error" />

  const initialState = {
    pagination: { pageSize },
    sorting: [{ id: _.first(columns).id, desc: false }],
  }

  const tableColumns = useMemo(() => columns, [columns])

  return (
    <div className="big3-table">
      <Table
        loading={loading}
        displayLoadingSpinner={displayLoadingSpinner}
        error={error}
        displayError={displayError}
        columns={tableColumns}
        data={report}
        initialState={initialState}
        border
      />
    </div>
  )
}

Big3Table.defaultProps = {
  pageSize: 50,
}

Big3Table.propTypes = {
  status: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  report: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageSize: PropTypes.number,
}

export default Big3Table
