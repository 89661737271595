export const SET_ACTIVE_FILTER_STATE = "SET_ACTIVE_FILTER_STATE"
export const ActiveOnlyFilterState = Object.freeze({
  ACTIVE_ONLY: "ACTIVE_ONLY",
  ALL: "ALL",
})
export function filterViewActiveOnly() {
  return { type: SET_ACTIVE_FILTER_STATE, activeFilterState: ActiveOnlyFilterState.ACTIVE_ONLY }
}

export function filterViewAll() {
  return { type: SET_ACTIVE_FILTER_STATE, activeFilterState: ActiveOnlyFilterState.ALL }
}
