import { connect } from "react-redux"

import { getCarePlan } from "~/components/carePlans/carePlanSelectors"
import { carePlanTaskFlashKey } from "~/components/carePlans/carePlanTaskActions"
import DateDetail from "~/components/carePlans/DateDetail"
import EditButton from "~/components/carePlans/EditButton"
import { getTaskResponsibilities } from "~/components/carePlans/globalsSelectors"
import LinkedGapInCareBadge from "~/components/carePlans/LinkedGapInCareBadge"
import Notes from "~/components/carePlans/Notes"
import DividerList from "~/components/DividerList"
import EncountersLink from "~/components/EncountersLink"
import FlashMessage from "~/components/FlashMessage"
import Show from "~/design/Show"

const TaskDetails = ({ isAccessibleExternal, task, chartId, onEditClick }) => {
  const {
    id,
    description,
    targetCompletionDate,
    completionDate,
    responsibilityName,
    status,
    notes,
    mcoGapInCareIds,
    numEncounters,
  } = task

  return (
    <div className={`task ${status.label.toLowerCase()}`}>
      <div className="row">
        <strong className="col-md-1 task-title">Task</strong>
        <div className="col-md-7">
          <div className="task-description">{description}</div>
        </div>
        <div className="col-md-2">
          <Show when={!isAccessibleExternal}>
            <EncountersLink
              filter={{ care_plan_tasks: { id: [id] } }}
              numEncounters={numEncounters}
              chartId={chartId}
            />
          </Show>
        </div>
        <div className="col-md-2 text-right task-status">
          <strong className="status text-muted">{status.label}</strong>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-7">
          <div className="task-subtitle">
            <DividerList>
              {responsibilityName && <span className="task-responsibility text-muted">{responsibilityName}</span>}
              {targetCompletionDate && (
                <DateDetail date={targetCompletionDate} className="task-target-completion-date" label="Target" />
              )}
              {completionDate && (
                <DateDetail date={completionDate} className="task-completion-date" label="Completed" />
              )}
              {notes && <Notes entityId={task.id} entityName="task" notes={notes} />}
            </DividerList>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-7">
              <Show when={!isAccessibleExternal}>
                <LinkedGapInCareBadge mcoGapInCareIds={mcoGapInCareIds} />
              </Show>
            </div>
            <div className="col-md-5 text-right">
              <FlashMessage flashKey={carePlanTaskFlashKey(task)} className="task-flash-message">
                Saved <i className="fa fa-check" />
              </FlashMessage>
              <EditButton onClick={onEditClick} label="Edit task" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect((state) => ({
  isAccessibleExternal: state.globals.isAccessibleExternal,
  taskResponsibilities: getTaskResponsibilities(state),
  chartId: getCarePlan(state).chartId,
}))(TaskDetails)
