import { QUALITY_REPORT_ERROR } from "~/big3Dashboard/actions/qualityReportStatusActions"
import Header from "~/big3Dashboard/components/Header"
import QualityDashboardStatusAlert from "~/big3Dashboard/components/QualityDashboardStatusAlert"
import Tabs from "~/big3Dashboard/components/Tabs"
import careManagementAgencyColumn from "~/big3Dashboard/HealthHomeQualityDashboard/careManagementAgencyColumn"
import Filters from "~/big3Dashboard/HealthHomeQualityDashboard/Filters"
import { getHealthHomeQualityDashboardExportRoute, getSelectedEntity } from "~/big3Dashboard/selectors/globalsSelector"
import { api_v1_health_home_health_home_quality_dashboard_exports_path } from "~/routes"

function HealthHomeQualityDashboard({ activeTab, tabs, status, report, healthHomeId, date }) {
  const activeTabId = activeTab.id
  const TableComponent = activeTab.tableComponent
  const entityColumn = careManagementAgencyColumn({ activeTabId, healthHomeId })
  return (
    <div>
      <QualityDashboardStatusAlert error={status === QUALITY_REPORT_ERROR} />
      <Header
        entity="Health Home"
        entityNameSelector={getSelectedEntity}
        exportRouteFunction={api_v1_health_home_health_home_quality_dashboard_exports_path}
        exportRouteSelector={getHealthHomeQualityDashboardExportRoute}
      />
      <Filters />
      <Tabs tabs={tabs} />
      <TableComponent
        status={status}
        report={report}
        entityColumn={entityColumn}
        healthHomeId={healthHomeId}
        date={date}
      />
    </div>
  )
}

export default HealthHomeQualityDashboard
