import PropTypes from "prop-types"

import EndDateReasonCodes from "./EndDateReasonCodes"
import InitialAppropriateness from "./InitialAppropriateness"
import NewMedicaidMemberId from "./NewMedicaidMemberId"
import ServicesProvided from "./ServicesProvided"

import Form from "~/design/forms"
import types from "~/types"

function FormFields({
  segment,
  healthHome,
  endDateReasonCodes,
  endDateReasonCategories,
  pendReasons,
  referralCodes,
  outreachEnrollmentCodes,
  adultChildServicesCodes,
  appropriatenessCriteriaCodes,
}) {
  return (
    <>
      <Form.Fieldset title="Segment Details">
        <Form.Group name="startDate">
          <Form.Label>Start Date</Form.Label>
          <Form.DatePicker disabled={segment.mappIdentityFieldsLocked} />
        </Form.Group>

        <Form.Group name="outreachEnrollmentCodeId" errorKey="outreachEnrollmentCode">
          <Form.Label>Outreach/Enrollment Code</Form.Label>
          <Form.SelectBox options={outreachEnrollmentCodes} disabled={segment.mappIdentityFieldsLocked} />
        </Form.Group>

        <ServicesProvided codes={adultChildServicesCodes} healthHome={healthHome} />
      </Form.Fieldset>

      <Form.Fieldset title="End Segment" disabled={segment.isParentOfPended}>
        <Form.Group name="endDate">
          <Form.Label>End Date</Form.Label>
          <Form.DatePicker />
        </Form.Group>

        <Form.Group name="endDateReasonCategoryId">
          <Form.Label>End Date Reason Category</Form.Label>
          <Form.SelectBox options={[{ label: "All", value: null }, ...endDateReasonCategories]} />
        </Form.Group>

        <EndDateReasonCodes endDateReasonCodes={endDateReasonCodes} />

        {/* Patient::EndDateReasonCode::MEMBER_HAS_NEW_CIN */}
        <Form.Show when={({ values }) => values.endDateReasonCodeId === 5}>
          <Form.Group name="endDateReasonNewMedicaidMemberId">
            <Form.Label>New CIN</Form.Label>
            <NewMedicaidMemberId />
          </Form.Group>
        </Form.Show>
      </Form.Fieldset>

      <Form.Show when={() => segment.pended}>
        <Form.Fieldset title="Pend Info">
          <Form.Group name="segmentPendReasonId" errorKey="segmentPendReason">
            <Form.Label>Pend Reason</Form.Label>
            <Form.SelectBox options={pendReasons} />
          </Form.Group>
        </Form.Fieldset>
      </Form.Show>

      <Form.Fieldset title="Referral Info">
        <Form.Group name="referralCodeId" errorKey="referralCode">
          <Form.Label>Referral Code</Form.Label>
          <Form.SelectBox options={referralCodes} placeholder={""} isClearable />
        </Form.Group>
      </Form.Fieldset>

      <InitialAppropriateness appropriatenessCriteriaCodes={appropriatenessCriteriaCodes} />
    </>
  )
}

FormFields.propTypes = {
  adultChildServicesCodes: PropTypes.arrayOf(types.selectOption).isRequired,
  appropriatenessCriteriaCodes: PropTypes.arrayOf(types.appropriatenessCriteriaCode).isRequired,
  endDateReasonCategories: PropTypes.arrayOf(types.selectOption).isRequired,
  endDateReasonCodes: PropTypes.arrayOf(types.endDateReasonCode).isRequired,
  healthHome: types.healthHome.isRequired,
  outreachEnrollmentCodes: PropTypes.arrayOf(types.selectOption).isRequired,
  pendReasons: PropTypes.arrayOf(types.selectOption).isRequired,
  referralCodes: PropTypes.arrayOf(types.selectOption).isRequired,
  segment: types.segment.isRequired,
}

export default FormFields
