import { Fragment } from "react"
import { connect } from "react-redux"
import pluralize from "pluralize"

import { getFilteredTaskIds } from "~/components/carePlans/carePlanGoalsSelectors"
import { getIsTasksListOpen } from "~/components/carePlans/carePlanGoalsUISelectors"
import { closeTasksList, openTasksList } from "~/components/carePlans/carePlanGoalUIActions"
import TaskAddButton from "~/components/carePlans/TaskAddButton"
import DropdownIcon from "~/components/DropdownIcon"

const GoalCardFooter = ({ goalId, isTasksListOpen, numTasks, openTasksListConnect, closeTasksListConnect }) => {
  const isDropdownEnabled = numTasks > 0
  const collapsableCssClass = isDropdownEnabled && "goal-collapsable"

  const onClick = (e) => {
    e.preventDefault()

    if (!isDropdownEnabled) {
      return
    }

    if (isTasksListOpen) {
      closeTasksListConnect(goalId)
    } else {
      openTasksListConnect(goalId)
    }
  }

  return (
    <div className={`goal-card-footer ${collapsableCssClass}`} onClick={onClick}>
      <strong className="text-muted">
        {pluralize("task", numTasks, true)}
        &nbsp;
        {isDropdownEnabled && <DropdownIcon isOpen={isTasksListOpen} />}
      </strong>
      {numTasks === 0 && (
        <Fragment>
          <TaskAddButton goalId={goalId} />
          <div />
        </Fragment>
      )}
    </div>
  )
}

export default connect(
  (state, props) => ({
    isTasksListOpen: getIsTasksListOpen(state, props),
    numTasks: getFilteredTaskIds(state, props).length,
  }),
  {
    openTasksListConnect: openTasksList,
    closeTasksListConnect: closeTasksList,
  }
)(GoalCardFooter)
