import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_patient_enrollment_records_path, api_v1_patient_segments_path } from "~/routes"

const accessibleViaPatients = {
  segments: {
    get: function (patientId) {
      return submitAsyncRequest({
        url: api_v1_patient_segments_path(patientId),
        type: "get",
      })
    },
  },
  enrollmentRecords: {
    get: function (patientId) {
      return submitAsyncRequest({
        url: api_v1_patient_enrollment_records_path(patientId),
        type: "get",
      })
    },
  },
}

export default accessibleViaPatients
