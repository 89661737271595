import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import useEndDateReasonCodeSummaryReport from "../hooks/useEndDateReasonCodeSummaryReport"

import Form from "~/design/forms"
import IndexFilter from "~/design/IndexFilter"
import useQueryParams from "~/hooks/useQueryParams"
import types from "~/types"

function Filter({ careManagementAgencies, healthHomes, outreachEnrollmentCodes }) {
  const { isLoading } = useEndDateReasonCodeSummaryReport()
  const [queryParams, setQueryParams] = useQueryParams()

  function handleSubmit(values, { setSubmitting }) {
    setQueryParams(values)
    setSubmitting(false)
  }

  return (
    <Form initialValues={queryParams} onSubmit={handleSubmit}>
      <IndexFilter>
        <Row>
          <Col lg={4}>
            <Form.Group name="filter.health_home_id">
              <Form.Label width={6}>Health Home</Form.Label>
              <Form.SelectBox width={6} isClearable options={healthHomes} placeholder="" />
            </Form.Group>
            <Form.Group name="filter.care_management_agency_id">
              <Form.Label width={6}>Care Management Agency</Form.Label>
              <Form.SelectBox width={6} isClearable options={careManagementAgencies} placeholder="" />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group name="filter.outreach_enrollment_code_id">
              <Form.Label width={6}>Segment Type</Form.Label>
              <Form.SelectBox width={6} isClearable options={outreachEnrollmentCodes} placeholder="" />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group name="filter.end_date_before">
              <Form.Label width={6}>End date between</Form.Label>
              <Form.DatePicker width={6} placeholder="" />
            </Form.Group>
            <Form.Group name="filter.end_date_after">
              <Form.Label width={6}>and</Form.Label>
              <Form.DatePicker width={6} placeholder="" />
            </Form.Group>
          </Col>
        </Row>
        <IndexFilter.ActionBar>
          <Form.SubmitButton label="Filter" disabled={isLoading} />
        </IndexFilter.ActionBar>
      </IndexFilter>
    </Form>
  )
}

Filter.propTypes = {
  careManagementAgencies: PropTypes.arrayOf(types.selectOption).isRequired,
  healthHomes: PropTypes.arrayOf(types.selectOption).isRequired,
  outreachEnrollmentCodes: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default Filter
