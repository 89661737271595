import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import createCachedSelector from "re-reselect"

import { questionCreated, questionDestroyed, questionSelectors } from "./questionsSlice"

const sectionsAdapter = createEntityAdapter()

const sectionsSlice = createSlice({
  name: "sections",
  initialState: sectionsAdapter.getInitialState(),
  reducers: {
    sectionCreated: sectionsAdapter.addOne,
    sectionUpdated: sectionsAdapter.upsertOne,
    sectionDestroyed: sectionsAdapter.removeOne,
    questionsReordered: (state, action) => {
      state.entities[action.payload.sectionId].questionIds = action.payload.questionIds
    },
  },
  extraReducers: {
    [questionCreated]: (state, action) => {
      state.entities[action.payload.sectionId].questionIds.push(action.payload.id)
    },
    [questionDestroyed]: (state, action) => {
      state.entities[action.payload.sectionId].questionIds = state.entities[
        action.payload.sectionId
      ].questionIds.filter((questionId) => questionId !== action.payload.id)
    },
  },
})

export const getPreloadedSectionsState = (sections) =>
  sectionsAdapter.upsertMany(sectionsAdapter.getInitialState(), sections)

export const { sectionCreated, sectionUpdated, sectionDestroyed, questionsReordered } = sectionsSlice.actions
export const sectionSelectors = sectionsAdapter.getSelectors((state) => state.sections)

// createCachedSelector allows memoization to work when this selector is used across components with different props.
// The absence of proper memoization causes unnecessary re-renders, leading the page to hang.
// See https://github.com/toomuchdesign/re-reselect#why--example
export const selectSectionQuestionEntities = createCachedSelector(
  [sectionSelectors.selectById, questionSelectors.selectEntities],
  (section, questionEntities) => _.pick(questionEntities, section.questionIds)
)((_state, sectionId) => sectionId)

export const selectOrderedQuestionOptions = createCachedSelector(
  [sectionSelectors.selectById, questionSelectors.selectEntities],
  (section, questionEntities) =>
    section.questionIds.map((questionId, questionIndex) => {
      const question = questionEntities[questionId]
      return {
        label: `${questionIndex + 1}: ${question.questionText}`,
        value: question.id,
      }
    })
)((_state, sectionId) => sectionId)

export default sectionsSlice.reducer
