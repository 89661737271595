import _ from "lodash"
import PropTypes from "prop-types"

import PopoverButton from "~/components/PopoverButton"
import Show from "~/design/Show"

function DashboardWidget({ title, info, children }) {
  return (
    <div className="border rounded" data-testid={`${_.kebabCase(title)}-widget`}>
      <div
        className="d-flex justify-content-center align-items-center gap-2 p-1"
        style={{ backgroundColor: "#efefef" }}
      >
        <h2 className="font-weight-bold" style={{ fontSize: "16px" }}>
          {title}
        </h2>
        <Show when={info?.length > 0}>
          <PopoverButton label="?">{info}</PopoverButton>
        </Show>
      </div>
      {children}
    </div>
  )
}

DashboardWidget.propTypes = {
  info: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default DashboardWidget

function DashboardWidgetBigNumber({ number, description }) {
  return (
    <div className="text-center p-3">
      <div style={{ fontSize: "36px" }}>{number}</div>
      <p>{description}</p>
    </div>
  )
}

DashboardWidgetBigNumber.propTypes = {
  number: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

DashboardWidget.BigNumber = DashboardWidgetBigNumber

function DashboardWidgetError({ text }) {
  return <div className="bg-light text-center p-5 m-3">{text}</div>
}

DashboardWidgetError.propTypes = {
  text: PropTypes.string.isRequired,
}

DashboardWidget.Error = DashboardWidgetError

function DashboardWidgetSuccess({ text }) {
  return (
    <div className="bg-light text-center p-3 m-3">
      <div className="mb-2">
        {/* #d6e9c6 = $light-green, #468847 = $green */}
        <i className="fa fa-check fa-3x rounded-circle p-1" style={{ color: "#468847", backgroundColor: "#d6e9c6" }} />
      </div>
      <em>{text}</em>
    </div>
  )
}

DashboardWidgetSuccess.propTypes = {
  text: PropTypes.string.isRequired,
}

DashboardWidget.Success = DashboardWidgetSuccess
