import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import PropTypes from "prop-types"

import { ToastContainer } from "~/toast"
import types from "~/types"
import WorkflowDocumentsTable from "~/views/shared/childrensHCBS/WorkflowDocumentsTable"

const queryClient = new QueryClient()

function Edit({
  chart,
  childrensHCBSWorkflowId,
  childrensHCBSWorkflowStepId,
  childrensHCBSWorkflowDocuments,
  documentTypes,
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <WorkflowDocumentsTable
        chart={chart}
        childrensHCBSWorkflowId={childrensHCBSWorkflowId}
        childrensHCBSWorkflowStepId={childrensHCBSWorkflowStepId}
        childrensHCBSWorkflowDocuments={childrensHCBSWorkflowDocuments}
        documentTypes={documentTypes}
      />
      <ToastContainer />
    </QueryClientProvider>
  )
}

Edit.propTypes = {
  chart: types.chart.isRequired,
  childrensHCBSWorkflowId: PropTypes.number.isRequired,
  childrensHCBSWorkflowStepId: PropTypes.number.isRequired,
  childrensHCBSWorkflowDocuments: PropTypes.arrayOf(types.childrensHCBSWorkflowDocument).isRequired,
  documentTypes: PropTypes.arrayOf(types.documentType).isRequired,
}

export default Edit
