import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import classNames from "classnames"
import _ from "lodash"
import PropTypes from "prop-types"

import Pagination from "~/components/pagination"
import PopoverIcon from "~/components/PopoverIcon"
import SortIcon, { SORTED_ASC, SORTED_DESC, UNSORTED } from "~/components/SortIcon"
import TablePageInfo from "~/components/TablePageInfo"

const Table = ({ loading, displayLoadingSpinner, error, displayError, columns, data, initialState, border }) => {
  const table = useReactTable({
    data,
    columns,
    initialState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const {
    pagination: { pageIndex, pageSize },
  } = table.getState()

  const sortIconHelper = (column) => {
    let sortState = UNSORTED
    if (column.getIsSorted()) {
      sortState = column.getIsSorted() === "desc" ? SORTED_DESC : SORTED_ASC
    }
    return <SortIcon sortState={sortState} />
  }

  const tableClassNameHelper = () => {
    let className = "table"
    if (border) {
      className += " table-bordered"
    }
    return className
  }

  if (loading) {
    return displayLoadingSpinner()
  }

  if (error) {
    return displayError()
  }

  return (
    <>
      <TablePageInfo pageIndex={pageIndex} pageSize={pageSize} total={data.length} />
      <table className={tableClassNameHelper()}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  role={header.column.getCanSort() ? "button" : undefined}
                  className={classNames({
                    "text-right": header.column.id !== "entity",
                    "inactive-header": !header.column.getIsSorted(),
                  })}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.column.columnDef.popoverContent ? (
                    <span>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <div>
                        {sortIconHelper(header.column)}
                        <PopoverIcon
                          placement={header.id === _.last(columns).id ? "left" : "right"}
                          title={flexRender(header.column.columnDef.header, header.getContext())}
                          content={header.column.columnDef.popoverContent}
                        />
                      </div>
                    </span>
                  ) : (
                    <span>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {sortIconHelper(header.column)}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={cell.column.id !== "entity" ? "text-right" : undefined}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination gotoPage={table.setPageIndex} pageCount={table.getPageCount()} pageIndex={pageIndex} />
    </>
  )
}

Table.defaultProps = {
  border: false,
}

Table.propTypes = {
  loading: PropTypes.bool.isRequired,
  displayLoadingSpinner: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  displayError: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialState: PropTypes.shape({
    pageSize: PropTypes.number,
    sortBy: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  border: PropTypes.bool,
}

export default Table
