import { FieldArray, useFormikContext } from "formik"
import PropTypes from "prop-types"

import { IconButton, LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import { DeleteIcon } from "~/design/icons"
import types from "~/types"

function SupervisorFormFields({ supervisorOptions }) {
  const {
    values: { supervisorUserIds },
  } = useFormikContext()

  return (
    <FieldArray
      name="supervisorUserIds"
      render={({ push, remove }) => (
        <>
          <p>Select</p>
          {supervisorUserIds.map((supervisorUserId, i) => (
            <div className="d-flex border-top pt-3" key={`${supervisorUserId}-${i}`}>
              <div style={{ width: "85%" }}>
                <Form.Group clearOnHide={false} name={`supervisorUserIds.${i}`}>
                  <Form.Label hidden>Supervisor {i + 1}</Form.Label>
                  <Form.SelectBox options={supervisorOptions} isSearchable={true} />
                </Form.Group>
              </div>
              <div className="flex-grow-1 d-flex justify-content-center mb-3">
                <IconButton Icon={DeleteIcon} onClick={() => remove(i)} label={`Remove supervisor ${i + 1}`} />
              </div>
            </div>
          ))}
          <div className="d-flex border-top pt-3">
            <div style={{ width: "85%" }}>
              <Form.Group clearOnHide={false} name={`supervisorUserIds.${supervisorUserIds.length}`}>
                <Form.Label hidden>New Supervisor</Form.Label>
                <Form.SelectBox options={supervisorOptions} isSearchable={true} />
              </Form.Group>
            </div>
          </div>
          <LinkButton onClick={() => push("")}>Add another supervisor</LinkButton>
        </>
      )}
    />
  )
}

SupervisorFormFields.propTypes = {
  supervisorOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default SupervisorFormFields
