import Icon from "./Icon"

/**
 * Renders an icon of a down arrow, indicating an expanded section.
 */
function ExpandIcon() {
  return <Icon name="chevron-down" />
}

export default ExpandIcon
