import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_bulk_barriers_updates_path } from "~/routes"

const bulkBarriersUpdates = {
  post: function (chartId, bulkBarriersUpdate) {
    return submitAsyncRequest({
      url: api_v1_bulk_barriers_updates_path(),
      type: "post",
      data: { bulkBarriersUpdate, chartId },
    })
  },
}

export default bulkBarriersUpdates
