import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import CareTeamProviderDetails from "./CareTeamProviderDetails"
import CareTeamProviderSelector from "./CareTeamProviderSelector"

import { LineDivider } from "~/components/Divider"
import { LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import types from "~/types"

function CareTeamProviderFormFields({ providerCredentials, providerTitles }) {
  const { setFieldValue } = useFormikContext()
  const [showProviderSelector, setShowProviderSelector] = useState(true)
  const [selectedProvider, setSelectedProvider] = useState(null)

  // When creating a membership with an existing provider,
  // providerAttributes must be an empty object and providerId must have a value.
  // When creating a membership with a new provider,
  // providerAttributes must be populated and providerId must be null.

  function clearSelectedProvider() {
    setFieldValue("providerId", null)
    setFieldValue("providerAttributes", {})
    setSelectedProvider(null)
    setShowProviderSelector(true)
  }

  function showSelectedProvider({ providerId, provider }) {
    setFieldValue("providerId", providerId)
    setFieldValue("providerAttributes", {})
    setSelectedProvider(provider)
    setShowProviderSelector(false)
  }

  function showNewProvider() {
    setFieldValue("providerId", null)
    setFieldValue("providerAttributes", {
      titleId: null,
      credentialId: null,
      firstName: "",
      lastName: "",
      specialty: "",
      facility: "",
      phone: "",
      address: "",
      email: "",
    })
    setSelectedProvider(null)
    setShowProviderSelector(false)
  }

  function handleSelectProvider({ providerId, provider }) {
    if (provider) {
      showSelectedProvider({ providerId, provider })
    } else {
      showNewProvider()
    }
  }

  if (showProviderSelector) {
    return (
      <Form.Group clearOnHide={false} name="providerId">
        <Form.Label>Care Team Member</Form.Label>
        <CareTeamProviderSelector handleSelectProvider={handleSelectProvider} />
      </Form.Group>
    )
  }

  if (selectedProvider) {
    return (
      <>
        <span className="text-muted">Provider</span>
        <CareTeamProviderDetails provider={selectedProvider} />
        <LinkButton onClick={clearSelectedProvider}>Change Provider</LinkButton>
      </>
    )
  }

  return (
    <>
      <Row>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="providerAttributes.titleId" errorKey="provider.titleId">
            <Form.Label>Title</Form.Label>
            <Form.SelectBox options={providerTitles} isClearable />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="providerAttributes.credentialId" errorKey="provider.credentialId">
            <Form.Label>Credentials</Form.Label>
            <Form.SelectBox options={providerCredentials} isClearable />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="providerAttributes.firstName" errorKey="provider.firstName">
            <Form.Label>First Name</Form.Label>
            <Form.TextField />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="providerAttributes.lastName" errorKey="provider.lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.TextField />
          </Form.Group>
        </Col>
      </Row>
      <LineDivider />
      <Row>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="providerAttributes.specialty" errorKey="provider.specialty">
            <Form.Label>Specialty</Form.Label>
            <Form.TextField placeholder="Example: Podiatry" />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="providerAttributes.facility" errorKey="provider.facility">
            <Form.Label>Facility Name</Form.Label>
            <Form.TextField placeholder="Example: St. Judes Hospital" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="providerAttributes.phone" errorKey="provider.phone">
            <Form.Label>Phone</Form.Label>
            <Form.TextField placeholder="Include country/area code" />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="providerAttributes.address" errorKey="provider.address">
            <Form.Label>Address</Form.Label>
            <Form.TextField placeholder="Include street, city, state, and zip" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group clearOnHide={false} name="providerAttributes.email" errorKey="provider.email">
            <Form.Label>Email</Form.Label>
            <Form.TextField placeholder="Example: jacob@example.com" />
          </Form.Group>
        </Col>
      </Row>
      <LinkButton onClick={clearSelectedProvider}>Choose from existing providers</LinkButton>
    </>
  )
}

CareTeamProviderFormFields.propTypes = {
  providerCredentials: PropTypes.arrayOf(types.selectOption).isRequired,
  providerTitles: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default CareTeamProviderFormFields
