import PopoverButton from "~/components/PopoverButton"
import Form from "~/design/forms"

function ActiveReferralNote() {
  function showActiveProviderNote({ values: { active, providerTypeId } }) {
    // CareTeam::ProviderType::HCBS_PROVIDER.id || CareTeam::ProviderType::CORE_PROVIDER.id
    return active && (providerTypeId === 27 || providerTypeId === 28)
  }

  return (
    <Form.Show when={showActiveProviderNote}>
      <p className="mt-3">
        Will be an <strong>Active Referral</strong>{" "}
        <PopoverButton title="Active Referral" label="?">
          Details for this provider will appear as a referral on member's CORE & HCBS tab.
        </PopoverButton>
      </p>
    </Form.Show>
  )
}

export default ActiveReferralNote
