import { Col, Row } from "react-bootstrap"

import LinkedGapInCareBadge from "~/components/carePlans/LinkedGapInCareBadge"
import formattedDate from "~/dateHelpers"
import { selectedOption } from "~/reactSelectHelpers"
import { globalsStateWrapper } from "~/redux"

const CarePlanTaskDetails = ({ task, taskResponsibilities, carePlanTaskStatuses }) => {
  const { mcoGapInCareIds } = task

  const taskResponsibilityName = () => {
    const selectedResponsibility = selectedOption(taskResponsibilities, task.taskResponsibilityId)

    if (task.taskResponsibilityOther) {
      return task.taskResponsibilityOther
    }
    return selectedResponsibility && selectedResponsibility.label
  }

  const selectedStatus = selectedOption(carePlanTaskStatuses, task.carePlanTaskStatusId)
  const statusName = selectedStatus && selectedStatus.label

  return (
    <div>
      <Row noGutters={true}>
        <Col sm={1}>
          <div className="selectable-box-icon selectable-box-icon--checked" style={{ width: "20px", height: "20px" }} />
        </Col>
        <Col sm={11}>
          <div className="mb-2">
            {task.taskResponsibilityId && <div className="text-uppercase">{taskResponsibilityName()}</div>}
            {task.description}
          </div>
        </Col>
      </Row>
      <Row noGutters={true}>
        <Col sm={{ offset: 1, span: 5 }} lg={12} xl={{ offset: 1, span: 5 }}>
          <span className="text-muted">Target</span> {formattedDate(task.targetCompletionDate)}
        </Col>
        <Col sm={3} lg={6} xl={3}>
          {statusName !== "Active" && <span className="float-sm-right">{statusName}</span>}
        </Col>
        <Col sm={3} lg={6} xl={3} className="text-right">
          <LinkedGapInCareBadge mcoGapInCareIds={mcoGapInCareIds} />
        </Col>
      </Row>
    </div>
  )
}

export default globalsStateWrapper(({ taskResponsibilities, carePlanTaskStatuses }) => ({
  taskResponsibilities,
  carePlanTaskStatuses,
}))(CarePlanTaskDetails)
