import { createContext, useContext } from "react"
import PropTypes from "prop-types"

const FeatureFlagsContext = createContext({})

const useFeatureFlagsContext = () => useContext(FeatureFlagsContext)

const useIsFeatureEnabled = (flag) => {
  const featureFlags = useFeatureFlagsContext()
  const featureFlagEnabled = featureFlags[flag]

  if (featureFlagEnabled === undefined) {
    throw Error(`Feature flag '${flag}' is not defined.`)
  }

  return featureFlagEnabled
}

const FeatureFlagsProvider = ({ userEnabledFeatures = {}, children }) => {
  return <FeatureFlagsContext.Provider value={userEnabledFeatures}>{children}</FeatureFlagsContext.Provider>
}

FeatureFlagsProvider.propTypes = {
  /**
   * An object with keys representing the names of feature flags on this screen and
   * values representing whether the feature is enabled for the user.
   */
  userEnabledFeatures: PropTypes.objectOf(PropTypes.bool),
  children: PropTypes.node.isRequired,
}

export default useFeatureFlagsContext
export { FeatureFlagsProvider, useIsFeatureEnabled }
