import _ from "lodash"
import PropTypes from "prop-types"

import SupervisorFormFields from "./SupervisorFormFields"

import Form from "~/design/forms"
import Modal from "~/design/Modal"
import types from "~/types"

function AddSupervisorModal({ onSubmit, onClose, potentialSupervisorUsers, show, supervisors }) {
  const supervisorUserIds = supervisors.map(({ userId }) => userId)
  const potentialSupervisorOptions = Object.values(potentialSupervisorUsers).map(({ id, name }) => ({
    value: id,
    label: name,
  }))
  const currentSupervisorsOptions = supervisors.map(({ userId, name }) => ({
    value: userId,
    label: name,
  }))
  const supervisorOptions = _.unionBy(currentSupervisorsOptions, potentialSupervisorOptions, "value")

  return (
    <Modal onClose={onClose} title="Add Supervisor" show={show}>
      <Form.Provider formId="add-supervisor-form" initialValues={{ supervisorUserIds }} onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Body vertical>
            <SupervisorFormFields supervisorOptions={supervisorOptions} />
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Add to Team" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

AddSupervisorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  potentialSupervisorUsers: PropTypes.objectOf(types.user).isRequired,
  show: PropTypes.bool.isRequired,
  supervisors: PropTypes.arrayOf(types.supervisoryTeamSupervisor).isRequired,
}

export default AddSupervisorModal
