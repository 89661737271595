// Shim to resolve JS error: https://rollbar.com/relevant/HealthHomes/items/24/
// See: https://github.com/Pomax/react-onclickoutside#ie-does-not-support-classlist-for-svg-elements
if (!("classList" in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, "classList", {
    get() {
      return {
        contains: (className) => this.className.baseVal.split(" ").indexOf(className) !== -1,
      }
    },
  })
}
