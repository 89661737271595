import EncounterDetailsFields from "~/components/encounters/EncounterDetailsFields"
import EncounterTargetField from "~/components/encounters/EncounterTargetField"

function FaxEncounterDetailsFields() {
  return (
    <EncounterDetailsFields
      attributesName="faxEncounterDetailsAttributes"
      defaultValues={{
        targetId: "",
      }}
    >
      <EncounterTargetField name="targetId" label="Who did you send it to?" />
    </EncounterDetailsFields>
  )
}

export default FaxEncounterDetailsFields
