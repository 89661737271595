import { Fragment } from "react"
import { connect } from "react-redux"

import { getFilteredTaskIds } from "~/components/carePlans/carePlanGoalsSelectors"
import { getIsNewTaskFormOpen, getIsTasksListOpen } from "~/components/carePlans/carePlanGoalsUISelectors"
import NewTaskForm from "~/components/carePlans/NewTaskForm"
import TaskAddButton from "~/components/carePlans/TaskAddButton"
import TaskContainer from "~/components/carePlans/TaskContainer"

const TasksList = ({ taskIds, isTasksListOpen, goalId, isNewTaskFormOpen }) => (
  <div className="tasks-list">
    {isTasksListOpen && (
      <Fragment>
        {taskIds.map((taskId) => (
          <TaskContainer key={taskId} taskId={taskId} />
        ))}
        {isNewTaskFormOpen && <NewTaskForm goalId={goalId} />}
      </Fragment>
    )}
    {isTasksListOpen && taskIds.length > 0 && (
      <div className="task-add-button-container text-center">
        <TaskAddButton goalId={goalId} />
      </div>
    )}
  </div>
)

export default connect((state, props) => ({
  taskIds: getFilteredTaskIds(state, props),
  isNewTaskFormOpen: getIsNewTaskFormOpen(state, props),
  isTasksListOpen: getIsTasksListOpen(state, props),
}))(TasksList)
