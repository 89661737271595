import { connect } from "react-redux"

import CarePlanFormBody from "./CarePlanFormBody"
import { carePlanGoalFlashKey, carePlanGoalUpdated, deleteCarePlanGoal } from "./carePlanGoalActions"
import GoalFormFields from "./GoalFormFields"

import { DangerButton } from "~/design/buttons"
import Form from "~/design/forms"
import useConfirmationModal from "~/design/modals/useConfirmationModal"
import { flashMessage } from "~/flashMessages/flashMessageActions"
import api from "~/services/api"
import { toast } from "~/toast"

function EditGoalForm({
  carePlanGoalUpdatedConnect,
  deleteCarePlanGoalConnect,
  flashMessageConnect,
  goal,
  onCancelClick,
}) {
  const confirm = useConfirmationModal()

  async function handleSubmit(values, { setErrors, setStatus }) {
    try {
      const updatedGoal = await api.carePlans.goals.patch(goal.id, values)
      setStatus("success")
      carePlanGoalUpdatedConnect(updatedGoal)
      flashMessageConnect(carePlanGoalFlashKey(updatedGoal))
      toast.success("Goal successfully updated.")
      onCancelClick()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  async function handleDelete() {
    const confirmed = await confirm.danger("This goal and associated tasks will all be permanently deleted.", {
      title: "Delete Goal",
      confirmButtonLabel: "Delete",
    })

    if (confirmed) {
      try {
        await deleteCarePlanGoalConnect(goal.id)
        toast.success("Goal successfully deleted.")
      } catch (error) {
        toast.error(
          <>
            Goal could not be deleted.
            <br />
            Please refresh the page.
          </>
        )
      }
    }
  }

  return (
    <Form initialValues={goal} onSubmit={handleSubmit} vertical>
      <CarePlanFormBody title="Edit Goal" dataTestId="goal-form">
        <GoalFormFields />
        <Form.ActionBar
          left={<DangerButton onClick={handleDelete}>Delete</DangerButton>}
          right={
            <>
              <Form.CancelButton onClick={onCancelClick} />
              <Form.SubmitButton label="Update" />
            </>
          }
        />
      </CarePlanFormBody>
    </Form>
  )
}

export default connect(null, {
  carePlanGoalUpdatedConnect: carePlanGoalUpdated,
  deleteCarePlanGoalConnect: deleteCarePlanGoal,
  flashMessageConnect: flashMessage,
})(EditGoalForm)
