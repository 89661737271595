import { carePlanEntitiesDeleted } from "~/components/carePlans/carePlanActions"
import { getTaskIds } from "~/components/carePlans/carePlanGoalsSelectors"
import api from "~/services/api"

export function carePlanGoalFlashKey(goal) {
  return `CARE_PLAN_GOAL_${goal.id}_FLASH_KEY`
}

export const CARE_PLAN_GOAL_CREATED = "CARE_PLAN_GOAL_CREATED"
export function carePlanGoalCreated(goal, needId) {
  return {
    type: CARE_PLAN_GOAL_CREATED,
    needId,
    goal,
  }
}

export function editCarePlanGoalFormKey(goalId) {
  return `EDIT_GOAL_FORM_KEY_${goalId}`
}

export const CARE_PLAN_GOAL_UPDATED = "CARE_PLAN_GOAL_UPDATED"
export function carePlanGoalUpdated(goal) {
  return {
    type: CARE_PLAN_GOAL_UPDATED,
    goal,
  }
}

export function deleteCarePlanGoal(goalId) {
  return async (dispatch, getState) => {
    const state = getState()
    const taskIds = getTaskIds(state, { goalId })

    await api.carePlans.goals.delete(goalId)
    dispatch(carePlanEntitiesDeleted([], [goalId], taskIds))
  }
}
