import spinner from "images/spinner.svg"

function LoadingSpinner() {
  return (
    <div className="d-flex align-items-center gap-3 py-5">
      <img width={50} src={spinner} alt="Loading..." />
      <span style={{ fontSize: "1.5rem", fontWeight: 500, lineHeight: 1.2 }}>Loading...</span>
    </div>
  )
}

export default LoadingSpinner
