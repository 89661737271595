import PropTypes from "prop-types"

const appropriatenessCriteriaCode = PropTypes.shape({
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  adultChildServicesCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired
  ),
  commentsRequired: PropTypes.bool.isRequired,
  commentsDescription: PropTypes.string,
})

export default appropriatenessCriteriaCode
