import { Col, Row } from "react-bootstrap"
import _ from "lodash"
import PropTypes from "prop-types"

import Badge from "~/components/badge"
import formattedDate from "~/dateHelpers"

const ClinicalAlertDetailsList = ({ clinicalAlerts }) => {
  return (
    <>
      {_.orderBy(clinicalAlerts, "occurredAt", "desc").map((clinicalAlert) => (
        <div key={clinicalAlert.id} className="clinical-alert-details">
          <Row className="m-2">
            <Col md="3">
              <dd>
                <Badge variant={clinicalAlert.clinicalAlertVisitType.color}>
                  {clinicalAlert.clinicalAlertVisitType.name}
                </Badge>
              </dd>
            </Col>
            <Col md="3">
              <dt>Occurred On</dt>
              <dd>{formattedDate(clinicalAlert.occurredAt)}</dd>
            </Col>
            <Col md="3">
              <dt>Notified Date</dt>
              <dd>{formattedDate(clinicalAlert.receivedAt)}</dd>
            </Col>
            <Col md="3">
              <dt>Facility</dt>
              <dd>{_.truncate(clinicalAlert.facility, { length: 18 })}</dd>
            </Col>
          </Row>
        </div>
      ))}
    </>
  )
}

ClinicalAlertDetailsList.propTypes = {
  clinicalAlerts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      occurredAt: PropTypes.string.isRequired,
      receivedAt: PropTypes.string.isRequired,
      clinicalAlertVisitTypeId: PropTypes.number.isRequired,
      clinicalAlertVisitType: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
      }).isRequired,
      facility: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default ClinicalAlertDetailsList
