import { useField } from "formik"

import Form from "~/design/forms"
import { globalsStateWrapper } from "~/redux"

function EncounterLocationField({ encounterLocations = [], errorKey, label, name }) {
  const [locationId] = useField(name)
  const visibleEncounterLocations = encounterLocations.filter(
    (location) => location.active || location.value === locationId
  )

  return (
    <Form.Group name={name} errorKey={errorKey}>
      <Form.Label>{label}</Form.Label>
      <Form.SelectBox options={visibleEncounterLocations} isClearable />
    </Form.Group>
  )
}

export default globalsStateWrapper(({ encounterLocations }) => ({ encounterLocations }))(EncounterLocationField)
