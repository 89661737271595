import PropTypes from "prop-types"

const healthHomeOptOut = PropTypes.shape({
  id: PropTypes.number,
  chartId: PropTypes.number.isRequired,
  uploadedFile: PropTypes.shape({ url: PropTypes.string }),
  dateOfOptOut: PropTypes.string,
})

export default healthHomeOptOut
