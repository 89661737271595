import PropTypes from "prop-types"

import careManagementAgency from "./careManagementAgency"
import healthHome from "./healthHome"

const segment = PropTypes.shape({
  id: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  healthHome: healthHome,
  careManagementAgency: careManagementAgency,
  endDateReasonCodeId: PropTypes.number,
  endDateReasonCode: PropTypes.string,
  endDateReasonCodeName: PropTypes.string,
  endDateReasonCategoryId: PropTypes.number,
  referralCodeId: PropTypes.number,
  referralCode: PropTypes.string,
  outreachEnrollmentCodeId: PropTypes.number,
  outreachEnrollmentCodeName: PropTypes.string,
  adultChildServicesCodeId: PropTypes.number,
  pended: PropTypes.bool.isRequired,
  segmentPendReasonId: PropTypes.number,
  isParentOfPended: PropTypes.bool.isRequired,
  patientId: PropTypes.number,
  mappIdentityFieldsLocked: PropTypes.bool.isRequired,
  appropriatenessCriteriaCodeId: PropTypes.number,
  appropriatenessComments: PropTypes.string,
  reportingStatus: PropTypes.string,
})

export default segment
