import Icon from "./Icon"

/**
 * Renders an icon of an exclamation mark inside a triangle.
 */
function CautionIcon() {
  return <Icon name="exclamation-triangle" />
}

export default CautionIcon
