import { useState } from "react"
import { Table } from "react-bootstrap"
import classNames from "classnames"
import PropTypes from "prop-types"

import { SecondaryButton } from "~/design/buttons"
import Jumbotron from "~/design/Jumbotron"
import types from "~/types"
import MedicationsForm from "~/views/shared/medications/MedicationsForm"
import Section from "~/views/shared/patientDetails/Section"

function MedicationsSection({ chart, medications: initialMedications }) {
  const [medications, setMedications] = useState(initialMedications)
  const [showModal, setShowModal] = useState(false)

  const displayMedications = Object.keys(medications)
    .map((medicationId) => medications[medicationId])
    .sort((medicationA, medicationB) => medicationB.active - medicationA.active)

  return (
    <Section
      title="Medications"
      actions={<SecondaryButton onClick={() => setShowModal(true)}>Edit Medications</SecondaryButton>}
    >
      {displayMedications.length > 0 ? (
        <Table striped bordered size="sm" className="table-light-header">
          <thead>
            <tr>
              <th>Medication</th>
              <th>Amount</th>
              <th>Reason</th>
              <th>Start</th>
              <th>End</th>
            </tr>
          </thead>
          <tbody>
            {displayMedications.map((medication) => (
              <tr key={medication.id} className={classNames({ "text-muted": !medication.active })}>
                <td>
                  {medication.name}
                  {medication.active ? "" : " [INACTIVE]"}
                </td>
                <td>{medication.dosage}</td>
                <td>{medication.reason}</td>
                <td>{medication.startDateDescription}</td>
                <td>{medication.endDateDescription}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Jumbotron message="This patient does not have any medications for the selected agencies." />
      )}
      <MedicationsForm
        show={showModal}
        onClose={() => setShowModal(false)}
        medications={medications}
        chart={chart}
        onUpdate={setMedications}
      />
    </Section>
  )
}

MedicationsSection.propTypes = {
  chart: types.chart.isRequired,
  medications: PropTypes.objectOf(types.medication).isRequired,
}

export default MedicationsSection
