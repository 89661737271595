import PropTypes from "prop-types"

import Show from "~/design/Show"

function Section({ actions, title, children }) {
  return (
    <section className="mb-5">
      <div className="d-flex justify-content-between align-items-end border-bottom pb-1 mb-3">
        <h2 style={{ fontSize: "1.25rem", lineHeight: "1.2" }}>{title}</h2>
        <Show when={Boolean(actions)}>
          <div className="d-flex gap-1">{actions}</div>
        </Show>
      </div>
      {children}
    </section>
  )
}

Section.propTypes = {
  actions: PropTypes.node,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Section
