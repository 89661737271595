import pluralize from "pluralize"
import PropTypes from "prop-types"

import { chart_encounters_path } from "~/routes"

function EncountersLink({ filter = {}, numEncounters, chartId }) {
  const text = pluralize("encounter", numEncounters, true)

  if (numEncounters === 0) {
    return <span className="text-muted">{text}</span>
  }

  return (
    <a href={chart_encounters_path(chartId, { filter })} target="_blank" rel="noopener noreferrer">
      {text}&nbsp;
      <i className="fa fa-external-link" />
    </a>
  )
}

EncountersLink.propTypes = {
  filter: PropTypes.object,
  numEncounters: PropTypes.number.isRequired,
  chartId: PropTypes.number.isRequired,
}

export default EncountersLink
