import { useState } from "react"
import { Collapse } from "react-bootstrap"
import classNames from "classnames"
import PropTypes from "prop-types"

import IconButton from "~/design/buttons/IconButton"
import { CollapseIcon, ExpandIcon } from "~/design/icons"

function SideBarToggle({ ActionIcon, HeaderIcon, actionLabel, title, children, onActionIconClick }) {
  const [isCollapsed, setIsCollapsed] = useState(false)

  function toggleCollapsed() {
    setIsCollapsed((isCollapsed) => !isCollapsed)
  }

  const ArrowIcon = isCollapsed ? CollapseIcon : ExpandIcon

  const hasActionIcon = Boolean(ActionIcon)

  return (
    <div className={classNames("p-3", { "bg-white": isCollapsed })}>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="font-weight-bold">
          {HeaderIcon ? <HeaderIcon /> : null} {title}
        </h3>
        <span>
          {hasActionIcon ? <IconButton Icon={ActionIcon} label={actionLabel} onClick={onActionIconClick} /> : null}
          <IconButton Icon={ArrowIcon} label="Toggle section collapsed" onClick={toggleCollapsed} />
        </span>
      </div>
      <Collapse in={!isCollapsed}>
        {/* This extra wrapper div ensures smooth transition animations: https://react-bootstrap-v4.netlify.app/utilities/transitions/#collapse */}
        <div>
          <div className="pt-3 px-2">{children}</div>
        </div>
      </Collapse>
    </div>
  )
}

SideBarToggle.propTypes = {
  ActionIcon: PropTypes.elementType,
  HeaderIcon: PropTypes.elementType,
  actionLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onActionIconClick: PropTypes.func,
}

export default SideBarToggle
