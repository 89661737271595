import { useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { SIDEBAR_TOP_OFFSET } from "../constants"
import { selectOrderedSectionIds } from "../redux/schemaVersionSlice"

import EditSchemaSectionForm from "./EditSchemaSectionForm"
import NewSchemaSectionForm from "./NewSchemaSectionForm"
import SchemaQuestions from "./SchemaQuestions"

import { LinkButton } from "~/design/buttons"
import Show from "~/design/Show"

function SchemaSections({ sectionIds }) {
  const [isNewSectionOpen, setIsNewSectionOpen] = useState(false)

  return (
    <>
      {sectionIds.map((sectionId, sectionIndex) => (
        <div key={sectionId} id={`schema-section-${sectionId}`} style={{ scrollMarginTop: `${SIDEBAR_TOP_OFFSET}px` }}>
          <EditSchemaSectionForm sectionId={sectionId} sectionIndex={sectionIndex} />
          <SchemaQuestions sectionId={sectionId} />
        </div>
      ))}
      <Show when={isNewSectionOpen}>
        <NewSchemaSectionForm sectionIndex={sectionIds.length} onFormClose={() => setIsNewSectionOpen(false)} />
      </Show>
      <LinkButton onClick={() => setIsNewSectionOpen(true)} disabled={isNewSectionOpen}>
        Add Section
      </LinkButton>
    </>
  )
}

SchemaSections.propTypes = {
  sectionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default connect((state) => ({ sectionIds: selectOrderedSectionIds(state) }))(SchemaSections)
