import PropTypes from "prop-types"

import Show from "~/design/Show"

/**
 * A `<Section />` renders a section of content with a heading.
 */
function Section({ actions, title, children }) {
  return (
    <section className="mt-3 mb-4">
      <div className="d-flex justify-content-between align-items-center mb-2 gap-3">
        <h3 style={{ fontSize: "1.25rem", fontWeight: "500", lineHeight: "1.2" }}>{title}</h3>
        <Show when={Boolean(actions)}>
          <div className="d-flex flex-wrap gap-2 d-print-none">{actions}</div>
        </Show>
      </div>
      {children}
    </section>
  )
}

Section.propTypes = {
  /**
   * Optional buttons for key actions related to this section of the page.
   */
  actions: PropTypes.node,
  /**
   * A title for the heading of this section of the page.
   */
  title: PropTypes.string.isRequired,
  /**
   * The contents for this section of the page.
   */
  children: PropTypes.node.isRequired,
}

export default Section
