import { transformChartData } from "./index"

describe("transformChartData", () => {
  const data = {
    20240201: {
      claimSummaries: {
        denied: { sumCharges: "50.0" },
      },
    },
    20240101: {
      claimSummaries: {
        outstanding: { sumCharges: "100.0" },
        paid: { sumCharges: "200.0", sumPayments: "50.5" },
      },
    },
  }

  it("returns a sorted array of claim summary data", () => {
    expect(transformChartData(data)).toEqual([
      { month: new Date(2024, 0, 1), Outstanding: 100.0, Paid: 50.5, Adjusted: 149.5 },
      { month: new Date(2024, 1, 1), Denied: 50.0 },
    ])
  })
})
