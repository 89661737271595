import { useEffect } from "react"
import { isBefore, isValid, parseISO } from "date-fns"
import { useFormikContext } from "formik"

import PopoverButton from "~/components/PopoverButton"
import Form from "~/design/forms"
import Show from "~/design/Show"

// Verbal consents are only valid for consents prior to April 1, 2023
const VERBAL_CONSENT_NO_LONGER_ALLOWED_DATE = new Date(2023, 3, 1) // months are 0-indexed

function VerbalConsentField() {
  const {
    values: { dateOfConsent },
    setFieldValue,
  } = useFormikContext()

  const parsedDateOfConsent = parseISO(dateOfConsent)
  const showVerbalConsentField =
    isValid(parsedDateOfConsent) && isBefore(parsedDateOfConsent, VERBAL_CONSENT_NO_LONGER_ALLOWED_DATE)

  useEffect(() => {
    if (!showVerbalConsentField) {
      setFieldValue("verbal", false)
    }
  }, [showVerbalConsentField, setFieldValue])

  return (
    <Show when={showVerbalConsentField}>
      <Form.Group name="verbal">
        <Form.Label>
          Is this a verbal consent or a wet/electronic signature?{" "}
          <PopoverButton title="Is this a verbal consent or a wet/electronic signature?" label="?">
            Health Home consents obtained verbally do not include consent to Healthix.
          </PopoverButton>
        </Form.Label>
        <Form.SelectBox
          isClearable
          options={[
            { label: "Verbal Consent", value: true },
            { label: "Wet/Electronic Signature", value: false },
          ]}
        />
      </Form.Group>
    </Show>
  )
}

export default VerbalConsentField
