import PropTypes from "prop-types"

import Form from "~/design/forms"
import types from "~/types"

const TeamFormFields = ({ careManagementAgencies }) => (
  <>
    <Form.Group name="name">
      <Form.Label>Team Name</Form.Label>
      <Form.TextField component="input" type="text" className="form-control" />
    </Form.Group>
    <Form.Group name="careManagementAgencyId" errorKey="careManagementAgency">
      <Form.Label>Care Management Agency</Form.Label>
      <Form.SelectBox options={careManagementAgencies} />
    </Form.Group>
  </>
)

TeamFormFields.propTypes = {
  careManagementAgencies: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default TeamFormFields
