import PropTypes from "prop-types"

import Alert from "./Alert"

import CautionIcon from "~/design/icons/CautionIcon"

/**
 * A `<WarningAlert />` is used for messages indicating a potentially critical
 * circumstance or potentially failed operation.
 */
function WarningAlert({ children, icon }) {
  return (
    <Alert IconComponent={icon ? CautionIcon : null} variant="warning">
      {children}
    </Alert>
  )
}

WarningAlert.propTypes = {
  /**
   * The content of the alert.
   */
  children: PropTypes.node.isRequired,
  /**
   * Adds a lock icon to the start of the alert.
   */
  icon: PropTypes.bool,
}

export default WarningAlert
