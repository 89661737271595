import PropTypes from "prop-types"

import careManager from "./careManager"
import careTeamMembership from "./careTeamMembership"
import diagnosis from "./diagnosis"
import medication from "./medication"
import selectOption from "./selectOption"

const medicalBackground = PropTypes.shape({
  diagnoses: PropTypes.objectOf(diagnosis),
  diagnosisValues: PropTypes.arrayOf(selectOption),
  medications: PropTypes.objectOf(medication),
  careTeamMemberships: PropTypes.arrayOf(careTeamMembership),
  providerCredentials: PropTypes.arrayOf(selectOption),
  providerTitles: PropTypes.arrayOf(selectOption),
  providerTypes: PropTypes.arrayOf(selectOption),
  careManager: careManager,
})

export default medicalBackground
