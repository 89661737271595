import { useQuery } from "@tanstack/react-query"

import { submitAsyncRequest } from "~/asyncHelpers"

function useWorkCheck(url, pollingInterval = 3000) {
  return useQuery({
    queryKey: [url],
    queryFn: () => submitAsyncRequest({ url }),
    // Poll while `data.working` is true and the query has not returned an error
    refetchInterval: (data, query) => {
      return data.working && query.state.status !== "error" ? pollingInterval : undefined
    },
    // Don't retry if the query returns an error
    retry: false,
    // Ensure that `data.working` always returns a value, even before we've made a request
    initialData: { working: true },
  })
}

export default useWorkCheck
