import { createContext, useContext } from "react"
import PropTypes from "prop-types"

const DisableContext = createContext()

function useDisableContext() {
  const disableContext = useContext(DisableContext)
  if (disableContext === undefined) {
    // Form controls don't -have- to be inside of a disabled context since they
    // can be disabled individually, so if we don't have a context we just
    // assume they aren't.
    return false
  }
  return disableContext
}

function DisableProvider({ disabled, children }) {
  return <DisableContext.Provider value={disabled}>{children}</DisableContext.Provider>
}

DisableProvider.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

export default useDisableContext
export { DisableProvider }
