import { submitAsyncRequest } from "~/asyncHelpers"
import {
  api_v1_childrens_hcbs_workflow_childrens_hcbs_workflow_documents_path,
  api_v1_childrens_hcbs_workflow_document_path,
} from "~/routes"

const childrensHCBSWorkflowDocuments = {
  get: function (childrensHCBSWorkflowId, params) {
    return submitAsyncRequest({
      url: api_v1_childrens_hcbs_workflow_childrens_hcbs_workflow_documents_path(childrensHCBSWorkflowId, params),
      type: "GET",
    })
  },
  post: function (childrensHCBSWorkflowId, workflowDocument) {
    return submitAsyncRequest({
      url: api_v1_childrens_hcbs_workflow_childrens_hcbs_workflow_documents_path(childrensHCBSWorkflowId),
      type: "POST",
      data: { workflowDocument },
    })
  },
  delete: function (workflowDocumentId) {
    return submitAsyncRequest({
      url: api_v1_childrens_hcbs_workflow_document_path(workflowDocumentId),
      type: "delete",
    })
  },
}

export default childrensHCBSWorkflowDocuments
