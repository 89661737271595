import { Col, Row } from "react-bootstrap"
import classNames from "classnames"
import { useFormikContext } from "formik"
import _ from "lodash"
import PropTypes from "prop-types"
import uniqid from "uniqid"

import DividerList from "~/components/DividerList"
import { LinkButton } from "~/design/buttons"
import IconButton from "~/design/buttons/IconButton"
import Form from "~/design/forms"
import CloseIcon from "~/design/icons/CloseIcon"
import Show from "~/design/Show"
import types from "~/types"

function DiagnosesFormFields({ diagnosisValues }) {
  const {
    values: { diagnoses },
    setFieldValue,
  } = useFormikContext()

  function removeUnpersistedDiagnosis(id) {
    setFieldValue("diagnoses", _.omit(diagnoses, id))
  }

  function addDiagnosis() {
    const newDiagnosisKey = _.camelCase(uniqid())
    setFieldValue("diagnoses", {
      ...diagnoses,
      [newDiagnosisKey]: {
        diagnosisValueId: null,
        diagnosisValueOther: "",
        isPrimary: false,
      },
    })
  }

  function shouldShowDiagnosisOtherField(id) {
    const showDiagnosisOtherField = diagnoses[id]?.diagnosisValueId === 1 // Patient::DiagnosisValue::OTHER.id
    if (!showDiagnosisOtherField && diagnoses[id] && diagnoses[id].diagnosisValueOther !== "") {
      setFieldValue(`diagnoses.${id}.diagnosisValueOther`, "")
    }
    return showDiagnosisOtherField
  }

  const diagnosisIds = Object.keys(diagnoses)
  const [persistedDiagnosisIds, unpersistedDiagnosisIds] = _.partition(diagnosisIds, (id) => diagnoses[id].id)

  return (
    <>
      <Show when={persistedDiagnosisIds.length > 0}>
        <div className="d-none d-md-block mb-3">Diagnosis</div>
        <DividerList Divider={() => <hr className="mt-0 mb-3" />}>
          {persistedDiagnosisIds.map((id) => {
            const disabled = diagnoses[id].wasDeleted

            return (
              <Row
                className={classNames("align-items-center", { "bg-light": disabled })}
                data-testid={`diagnosis-form-fields-${_.kebabCase(id)}`}
                key={id}
              >
                <Col md={4}>
                  <Form.Group name={`diagnoses.${id}.diagnosisValueId`}>
                    <Form.Label hidden="md">Diagnosis</Form.Label>
                    <Form.SelectBox options={diagnosisValues} isClearable disabled={disabled} />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Show when={shouldShowDiagnosisOtherField(id)}>
                    <Form.Group clearOnHide={false} name={`diagnoses.${id}.diagnosisValueOther`}>
                      <Form.Label hidden="md">Description</Form.Label>
                      <Form.TextField placeholder="Type diagnosis here" disabled={disabled} />
                    </Form.Group>
                  </Show>
                </Col>
                <Col md={3}>
                  <Form.Group name={`diagnoses.${id}.isPrimary`}>
                    <Form.CheckBox disabled={disabled} label="Primary" />
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group name={`diagnoses.${id}.wasDeleted`}>
                    <Form.MarkForDelete />
                  </Form.Group>
                </Col>
              </Row>
            )
          })}
        </DividerList>
      </Show>
      <h2 className="my-3">Add Diagnoses</h2>
      <Show when={unpersistedDiagnosisIds.length > 0}>
        <div className="d-none d-md-block mb-3">Diagnosis</div>
        <DividerList Divider={() => <hr className="mt-0 mb-3" />}>
          {unpersistedDiagnosisIds.map((id) => {
            return (
              <Row className="align-items-center" data-testid={`diagnosis-form-fields-${_.kebabCase(id)}`} key={id}>
                <Col md={4}>
                  <Form.Group clearOnHide={false} name={`diagnoses.${id}.diagnosisValueId`}>
                    <Form.Label hidden="md">Diagnosis</Form.Label>
                    <Form.SelectBox options={diagnosisValues} isClearable />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Show when={shouldShowDiagnosisOtherField(id)}>
                    <Form.Group clearOnHide={false} name={`diagnoses.${id}.diagnosisValueOther`}>
                      <Form.Label hidden="md">Description</Form.Label>
                      <Form.TextField placeholder="Type diagnosis here" />
                    </Form.Group>
                  </Show>
                </Col>
                <Col md={3}>
                  <Form.Group clearOnHide={false} name={`diagnoses.${id}.isPrimary`}>
                    <Form.CheckBox label="Primary" />
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <div className="mb-3">
                    <IconButton
                      Icon={CloseIcon}
                      label="Remove diagnosis row"
                      onClick={() => removeUnpersistedDiagnosis(id)}
                    />
                  </div>
                </Col>
              </Row>
            )
          })}
        </DividerList>
      </Show>
      <div className="mt-3">
        <LinkButton onClick={addDiagnosis}>Add {diagnosisIds.length > 0 ? "another " : ""}diagnosis</LinkButton>
      </div>
    </>
  )
}

DiagnosesFormFields.propTypes = {
  diagnosisValues: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default DiagnosesFormFields
