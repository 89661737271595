import { useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { questionSelectors, questionUpdated } from "../redux/questionsSlice"

import Card from "./Card"
import DeleteQuestionButton from "./DeleteQuestionButton"
import ReorderQuestionsModal from "./ReorderQuestionsModal"
import SchemaQuestionFields from "./SchemaQuestionFields"

import { BulletDivider } from "~/components/Divider"
import { LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import { useModal } from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function EditSchemaQuestionForm({ initialQuestion, questionIndex, questionTypes, questionUpdatedConnect }) {
  const initialIsDependsOnQuestionCardOpen = Number.isInteger(initialQuestion.dependsOnQuestionId)
  const [isDependsOnQuestionCardOpen, setIsDependsOnQuestionCardOpen] = useState(initialIsDependsOnQuestionCardOpen)
  const [isReorderQuestionsModalOpen, { open, close }] = useModal(false)

  async function handleSubmit(questionFormData, { setErrors, setStatus, resetForm }) {
    try {
      const updatedQuestion = await api.customAssessments.questions.patch(initialQuestion.id, questionFormData)
      questionUpdatedConnect(updatedQuestion)

      toast.success("Question updated successfully.")
      setStatus("success")

      // Must resetForm to update form with updated options
      // (i.e. newly created option ids, deletion of `_destroy`ed options)
      resetForm({ values: updatedQuestion })
    } catch ({ errors }) {
      toast.error("Question could not be updated.")
      setErrors(errors)
    }
  }

  return (
    <Form.Provider
      initialValues={initialQuestion}
      onSubmit={handleSubmit}
      formId={`schema-question-${initialQuestion.id}`}
    >
      <Card dataTestid={`schema-question-card-${initialQuestion.id}`}>
        <Card.Header title={`${questionIndex + 1}`}>
          <LinkButton disabled={isReorderQuestionsModalOpen} onClick={open}>
            Reorder
          </LinkButton>
          <BulletDivider />
          <LinkButton disabled={isDependsOnQuestionCardOpen} onClick={() => setIsDependsOnQuestionCardOpen(true)}>
            Make Dependent
          </LinkButton>
          <BulletDivider />
          <DeleteQuestionButton question={initialQuestion} />
        </Card.Header>
        <Card.Body>
          <Form.Body vertical>
            <SchemaQuestionFields
              isDependsOnQuestionCardOpen={isDependsOnQuestionCardOpen}
              questionIndex={questionIndex}
              questionTypes={questionTypes}
              onCloseDependsOnQuestionCard={() => setIsDependsOnQuestionCardOpen(false)}
            />
          </Form.Body>
        </Card.Body>
        <Card.Footer right={<Form.SubmitButton label="Update" />} />
      </Card>
      {isReorderQuestionsModalOpen ? (
        <ReorderQuestionsModal
          onClose={close}
          sectionId={initialQuestion.sectionId}
          show={isReorderQuestionsModalOpen}
        />
      ) : null}
    </Form.Provider>
  )
}

EditSchemaQuestionForm.propTypes = {
  initialQuestion: types.customAssessmentSchemaQuestion,
  questionIndex: PropTypes.number.isRequired,
  questionTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  questionUpdatedConnect: PropTypes.func.isRequired,
}

export default connect(
  (state, { questionId }) => ({
    initialQuestion: questionSelectors.selectById(state, questionId),
    questionTypes: state.questionTypes,
  }),
  {
    questionUpdatedConnect: questionUpdated,
  }
)(EditSchemaQuestionForm)
