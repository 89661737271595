import _ from "lodash"

export const DATE_QUESTION_TYPE_ID = 1 // CustomAssessments::SchemaQuestionType::DATE.id
export const SINGLE_SELECT_QUESTION_TYPE_ID = 2 // CustomAssessments::SchemaQuestionType::SINGLE_SELECT.id
export const MULTI_SELECT_QUESTION_TYPE_ID = 3 // CustomAssessments::SchemaQuestionType::MULTI_SELECT.id
export const SHORT_TEXT_QUESTION_TYPE_ID = 4 // CustomAssessments::SchemaQuestionType::SHORT_TEXT.id
export const LONG_TEXT_QUESTION_TYPE_ID = 5 // CustomAssessments::SchemaQuestionType::LONG_TEXT.id

export const isSelectQuestionType = (questionTypeId) =>
  [SINGLE_SELECT_QUESTION_TYPE_ID, MULTI_SELECT_QUESTION_TYPE_ID].includes(questionTypeId)

export const getDefaultResponseValue = (questionTypeId) => {
  const DEFAULT_RESPONSE_VALUES_BY_QUESTION_TYPE_ID = {
    [DATE_QUESTION_TYPE_ID]: "",
    [SINGLE_SELECT_QUESTION_TYPE_ID]: "",
    [MULTI_SELECT_QUESTION_TYPE_ID]: [],
    [SHORT_TEXT_QUESTION_TYPE_ID]: "",
    [LONG_TEXT_QUESTION_TYPE_ID]: "",
  }

  const defaultResponseValue = DEFAULT_RESPONSE_VALUES_BY_QUESTION_TYPE_ID[questionTypeId]

  if (_.isNil(defaultResponseValue)) {
    throw new Error("Invalid question type")
  }

  return defaultResponseValue
}

export const isAnsweredQuestionResponseValue = (questionResponseValue) =>
  !(questionResponseValue === "" || questionResponseValue === [] || questionResponseValue === null)

const getOptionIds = (question, questionResponseValue) => {
  if (question.questionTypeId === SINGLE_SELECT_QUESTION_TYPE_ID) {
    return [questionResponseValue]
  }

  if (question.questionTypeId === MULTI_SELECT_QUESTION_TYPE_ID) {
    return questionResponseValue
  }

  return null
}

export const isAnswerableQuestion = (question, dependsOnQuestion, dependsOnQuestionResponseValue) => {
  if (question.dependsOnQuestion) {
    const dependsOnQuestionIsAnswered = isAnsweredQuestionResponseValue(dependsOnQuestionResponseValue)
    const questionDependsOnQuestionOptions = question.dependsOnQuestion.optionIds.length > 0

    if (dependsOnQuestionIsAnswered && questionDependsOnQuestionOptions) {
      return question.dependsOnQuestion.optionIds.some((optionId) =>
        getOptionIds(dependsOnQuestion, dependsOnQuestionResponseValue).includes(optionId)
      )
    }

    return dependsOnQuestionIsAnswered
  }

  return true
}

export function getChangedQuestionResponseValues(previousValues, newValues) {
  return Object.keys(newValues).reduce((diff, key) => {
    if (_.isEqual(newValues[key], previousValues[key])) return diff

    return {
      ...diff,
      [key]: newValues[key],
    }
  }, {})
}
