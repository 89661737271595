import PropTypes from "prop-types"

const endDateReasonCode = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  categoryCode: PropTypes.number,
  validSegmentOutreachEnrollmentCodes: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired
  ),
})

export default endDateReasonCode
