import { connect } from "react-redux"

import { getTaskWithOptions } from "~/components/carePlans/carePlanTasksSelectors"
import TaskCard from "~/components/carePlans/TaskCard"

const TaskContainer = ({ task }) => (
  <div className="task-container">
    <TaskCard task={task} />
  </div>
)

export default connect((state, props) => ({
  task: getTaskWithOptions(state, props),
}))(TaskContainer)
