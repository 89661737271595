import PropTypes from "prop-types"

const childrensHCBSWorkflow = PropTypes.shape({
  id: PropTypes.number.isRequired,
  disenrollmentStatusId: PropTypes.number,
  noticeSentToHealthHome: PropTypes.string,
  careTeamMembersNotified: PropTypes.string,
  disenrollmentReasonId: PropTypes.number,
  disenrollmentReasonOther: PropTypes.string,
  disenrolledOn: PropTypes.string,
})

export default childrensHCBSWorkflow
