import { CARE_PLAN_ENTITIES_DELETED } from "~/components/carePlans/carePlanActions"
import { CARE_PLAN_GOAL_CREATED } from "~/components/carePlans/carePlanGoalActions"
import { CARE_PLAN_NEED_CREATED, CARE_PLAN_NEED_UPDATED } from "~/components/carePlans/carePlanNeedActions"
import { deleteChildNodesAndSiblingNodes } from "~/components/carePlans/entityTreeTransformer"

export default function carePlanNeedsReducer(state = {}, action) {
  switch (action.type) {
    case CARE_PLAN_GOAL_CREATED: {
      const need = state[action.needId]

      return {
        ...state,
        [need.id]: { ...need, goalIds: [action.goal.id, ...need.goalIds] },
      }
    }
    case CARE_PLAN_NEED_CREATED:
    case CARE_PLAN_NEED_UPDATED:
      return { ...state, [action.need.id]: action.need }
    case CARE_PLAN_ENTITIES_DELETED:
      return deleteChildNodesAndSiblingNodes(state, "goalIds", action.needIds, action.goalIds)
    default:
      return state
  }
}
