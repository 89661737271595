import VerbalConsentField from "./components/VerbalConsentField"

import { redirectTo } from "~/browserHelpers"
import PopoverButton from "~/components/PopoverButton"
import Form from "~/design/forms"
import { chart_documents_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function Edit({ healthHomeConsent, careManagers }) {
  async function handleSubmit(values, { setStatus, setErrors }) {
    try {
      await api.healthHomeConsents.patch(healthHomeConsent.id, values)
      setStatus("success")
      redirectTo(chart_documents_path(healthHomeConsent.chartId))
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={healthHomeConsent} onSubmit={handleSubmit}>
      <Form.Group name="dateOfConsent">
        <Form.Label>Date of Consent</Form.Label>
        <Form.DatePicker />
      </Form.Group>

      <VerbalConsentField />

      <Form.Group name="consentedByCareManagerId" errorKey="consentedByCareManager">
        <Form.Label>
          Consented By{" "}
          <PopoverButton title="Consented By" label="?">
            Please indicate the person who facilitated the patient's signing of the Health Home consent form.
          </PopoverButton>
        </Form.Label>
        <Form.SelectBox options={careManagers} isClearable />
      </Form.Group>
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_documents_path(healthHomeConsent.chartId))} />
            <Form.SubmitButton label="Update Health Home Consent" />
          </>
        }
      />
    </Form>
  )
}

Edit.propTypes = {
  healthHomeConsent: types.healthHomeConsent.isRequired,
  careManagers: types.selectOption.isRequired,
}

export default Edit
