import _ from "lodash"
import PropTypes from "prop-types"

import DocumentFields from "./components/DocumentFields"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { chart_documents_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function Edit({ document, documentTypes, documentCategories }) {
  async function handleSubmit(values, { setStatus, setErrors }) {
    const valuesToSubmit = _.omit(values, "categoryId")
    try {
      await api.documents.patch(document.id, valuesToSubmit)
      setStatus("success")
      redirectTo(chart_documents_path(document.chartId))
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={document} onSubmit={handleSubmit}>
      <DocumentFields documentTypes={documentTypes} documentCategories={documentCategories} />
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_documents_path(document.chartId))} />
            <Form.SubmitButton label={"Update Document"} />
          </>
        }
      />
    </Form>
  )
}

Edit.propTypes = {
  document: types.document.isRequired,
  documentTypes: PropTypes.arrayOf(types.documentType).isRequired,
  documentCategories: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default Edit
