import { useQuery } from "@tanstack/react-query"
import spinner from "images/spinner.svg"
import PropTypes from "prop-types"

import * as modelNames from "../constants/modelNames"
import { groupRecordsByType } from "../helpers"

import RecordTable from "./RecordTable"

import Jumbotron from "~/design/Jumbotron"
import api from "~/services/api"

function RecordTables({ copiedFromChartId }) {
  const { data: chartedDataCopy, isLoading } = useChartDataRecords(copiedFromChartId)

  if (isLoading) {
    return (
      <div className="d-flex align-items-center gap-3 p-5 border rounded">
        <img src={spinner} width={50} height={50} alt="Loading data..." />
        <span style={{ fontSize: "1.5rem" }}>Loading data...</span>
      </div>
    )
  }

  const chartedDataCopyRecords = chartedDataCopy?.records

  if (chartedDataCopyRecords.length === 0) {
    return <Jumbotron message="No copyable data found for this chart" />
  }

  const chartedDataCopyRecordTables = groupRecordsByType(chartedDataCopyRecords)

  return (
    <>
      <section className="mb-5">
        <h2 className="pb-1 mb-3 border-bottom" style={{ fontSize: "1.25rem" }}>
          Assessments
        </h2>
        <RecordTable
          caption="Biopsychosocial Assessments"
          recordType={modelNames.ASSESSMENT}
          chartedDataCopyRecords={chartedDataCopyRecordTables[modelNames.ASSESSMENT]}
        />
        <RecordTable
          alert="Because custom assessment schemas are health home-specific, custom assessments can only be copied to charts within the same health home."
          caption="Custom Assessment Responses"
          recordType={modelNames.CUSTOM_ASSESSMENT_RESPONSE}
          chartedDataCopyRecords={chartedDataCopyRecordTables[modelNames.CUSTOM_ASSESSMENT_RESPONSE]}
        />
      </section>
      <section className="mb-5">
        <h2 className="pb-1 mb-3 border-bottom" style={{ fontSize: "1.25rem" }}>
          Background
        </h2>
        <RecordTable
          caption="Diagnoses"
          recordType={modelNames.DIAGNOSIS}
          chartedDataCopyRecords={chartedDataCopyRecordTables[modelNames.DIAGNOSIS]}
        />
        <RecordTable
          caption="Medications"
          recordType={modelNames.MEDICATION}
          chartedDataCopyRecords={chartedDataCopyRecordTables[modelNames.MEDICATION]}
        />
        <RecordTable
          caption="Strengths"
          recordType={modelNames.STRENGTH}
          chartedDataCopyRecords={chartedDataCopyRecordTables[modelNames.STRENGTH]}
        />
        <RecordTable
          caption="Barriers"
          recordType={modelNames.BARRIER}
          chartedDataCopyRecords={chartedDataCopyRecordTables[modelNames.BARRIER]}
        />
        <RecordTable
          caption="Risk Factors"
          recordType={modelNames.RISK_FACTOR}
          chartedDataCopyRecords={chartedDataCopyRecordTables[modelNames.RISK_FACTOR]}
        />
      </section>
      <section className="mb-5">
        <h2 className="pb-1 mb-3 border-bottom" style={{ fontSize: "1.25rem" }}>
          Care Plan
        </h2>
        <RecordTable
          alert="The copied care plan includes all goals, needs, tasks, and the pdf file for the latest signed care plan. Tasks on the new copy will not be linked to any encounters or gaps in care."
          caption="Care Plans"
          recordType={modelNames.CARE_PLAN}
          chartedDataCopyRecords={chartedDataCopyRecordTables[modelNames.CARE_PLAN]}
        />
      </section>
      <section className="mb-5">
        <h2 className="pb-1 mb-3 border-bottom" style={{ fontSize: "1.25rem" }}>
          Care Team
        </h2>
        <RecordTable
          caption="Care Team"
          recordType={modelNames.CARE_TEAM}
          chartedDataCopyRecords={chartedDataCopyRecordTables[modelNames.CARE_TEAM]}
        />
      </section>
    </>
  )
}

RecordTables.propTypes = {
  copiedFromChartId: PropTypes.number.isRequired,
}

function useChartDataRecords(copiedFromChartId) {
  return useQuery({
    queryKey: [copiedFromChartId],
    queryFn: () => api.chartedDataCopies.new.get(copiedFromChartId),
  })
}

export default RecordTables
