import { Dropdown } from "react-bootstrap"
import { connect } from "react-redux"
import _ from "lodash"

import { filterByNeedCategory } from "~/components/carePlans/carePlanNeedCategoryFilterActions"
import { getAvailableNeedCategories } from "~/components/carePlans/carePlanSelectors"
import { getFilteredNeedCategoryId } from "~/components/carePlans/carePlanUISelectors"
import { TagIcon } from "~/design/icons"
import { selectedOption } from "~/reactSelectHelpers"

function NeedCategoryFilter({ filteredNeedCategoryId, filterByNeedCategoryConnect, needCategories }) {
  const options = _.concat([{ label: "All", value: null }], needCategories)
  const currentlySelectedValue = selectedOption(options, filteredNeedCategoryId) || ""

  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="care-plan-need-category-filter">
        <span className="text-dark">
          <TagIcon />
        </span>
        &nbsp;Category:&nbsp;
        {currentlySelectedValue.label}&nbsp;
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item key={option.value} onClick={() => filterByNeedCategoryConnect(option.value)}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default connect(
  (state) => ({
    needCategories: getAvailableNeedCategories(state),
    filteredNeedCategoryId: getFilteredNeedCategoryId(state),
  }),
  { filterByNeedCategoryConnect: filterByNeedCategory }
)(NeedCategoryFilter)
