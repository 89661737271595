import types from "~/types"

function ColorOption({ option }) {
  return (
    <div className="d-flex align-items-center gap-2">
      <span style={{ backgroundColor: option.value, width: 16, height: 16, borderRadius: 4 }} />
      <span>{option.label}</span>
    </div>
  )
}

ColorOption.propTypes = {
  option: types.selectOption.isRequired,
}

export default ColorOption
