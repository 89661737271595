import PropTypes from "prop-types"

const careManager = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  workPhone: PropTypes.string,
  cellPhone: PropTypes.string,
})

export default careManager
