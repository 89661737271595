import api from "~/services/api"

class SessionKeeper {
  constructor() {
    this.userActions = 0
    this.previousUserActions = 0
  }

  incrementUserActions = () => {
    this.userActions = this.userActions + 1
  }

  notifyServer = () => {
    if (this.userActions > this.previousUserActions) {
      api.sessions.keepAlive()
      this.previousUserActions = this.userActions
    }
  }

  startTracking(millisecondDelay) {
    $(document).on("input", this.incrementUserActions)
    $(document).on("change", "select", this.incrementUserActions)

    this.trackingInterval = setInterval(this.notifyServer, millisecondDelay)
  }

  stopTracking() {
    clearInterval(this.trackingInterval)
  }
}

export default SessionKeeper
