import { Table as ReactBootstrapTable } from "react-bootstrap"
import spinner from "images/spinner.svg"
import PropTypes from "prop-types"

import AddWorkflowDocumentModal from "./AddWorkflowDocumentModal"
import LinkWorkflowDocumentModal from "./LinkWorkflowDocumentModal"
import Section from "./Section"
import useChildrensHCBSWorkflowDocuments, {
  useDeleteChildrensHCBSWorkflowDocument,
} from "./useChildrensHCBSWorkflowDocuments"

import DividerList from "~/components/DividerList"
import PopoverButton from "~/components/PopoverButton"
import formattedDate from "~/dateHelpers"
import { SecondaryButton } from "~/design/buttons"
import { LinkButton } from "~/design/buttons"
import { LinkIcon, PlusIcon } from "~/design/icons"
import Jumbotron from "~/design/Jumbotron"
import { useModal } from "~/design/Modal"
import { childrens_hcbs_workflow_path, document_path, uploads_document_path } from "~/routes"
import { toast } from "~/toast"
import types from "~/types"
import DownloadModalSync from "~/views/shared/DownloadModalSync"

function WorkflowDocumentsTable({
  chart,
  childrensHCBSWorkflowId,
  childrensHCBSWorkflowStepId,
  childrensHCBSWorkflowDocuments: initialChildrensHCBSWorkflowDocuments,
  documentTypes,
}) {
  const [isAddWorkflowDocumentModalOpen, { open: openAddWorkflowDocumentModal, close: closeAddWorkflowDocumentModal }] =
    useModal(false)

  const [
    isLinkWorkflowDocumentModalOpen,
    { open: openLinkWorkflowDocumentModal, close: closeLinkWorkflowDocumentModal },
  ] = useModal(false)

  const { childrensHCBSWorkflowDocuments, isLoading } = useChildrensHCBSWorkflowDocuments({
    childrensHCBSWorkflowId,
    childrensHCBSWorkflowStepId,
    initialData: initialChildrensHCBSWorkflowDocuments,
  })

  if (isLoading) {
    return (
      <Section title="Documents">
        <div className="text-center p-3">
          <img width="50px" height="50px" src={spinner} alt="Loading..." />
        </div>
      </Section>
    )
  }

  return (
    <Section
      title="Documents"
      actions={
        <>
          <SecondaryButton size="sm" onClick={() => openLinkWorkflowDocumentModal()}>
            <LinkIcon />
            Link Existing Document
          </SecondaryButton>
          <SecondaryButton size="sm" onClick={() => openAddWorkflowDocumentModal()}>
            <PlusIcon />
            Add Document
          </SecondaryButton>
        </>
      }
    >
      <div className="mb-3">
        <a href={childrens_hcbs_workflow_path(childrensHCBSWorkflowId)}>Back to Workflow Home</a>
      </div>
      {childrensHCBSWorkflowDocuments.length > 0 ? (
        <ReactBootstrapTable size="sm" striped bordered className="table-internally-unbordered">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Description</th>
              <th>
                <div className="d-flex align-items-center justify-content-end">
                  Actions&nbsp;
                  <PopoverButton label="?" placement="top">
                    Unlinking the document will not delete the original document. If you want to delete it, you must
                    navigate to the original document in the Documents tab.
                  </PopoverButton>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {childrensHCBSWorkflowDocuments.map((childrensHCBSWorkflowDocument) => (
              <TableRow
                key={childrensHCBSWorkflowDocument.id}
                childrensHCBSWorkflowId={childrensHCBSWorkflowId}
                childrensHCBSWorkflowDocument={childrensHCBSWorkflowDocument}
              />
            ))}
          </tbody>
        </ReactBootstrapTable>
      ) : (
        <Jumbotron message="There are no documents linked to this workflow step." />
      )}
      <LinkWorkflowDocumentModal
        childrensHCBSWorkflowId={childrensHCBSWorkflowId}
        childrensHCBSWorkflowStepId={childrensHCBSWorkflowStepId}
        show={isLinkWorkflowDocumentModalOpen}
        onClose={closeLinkWorkflowDocumentModal}
      />
      <AddWorkflowDocumentModal
        chart={chart}
        childrensHCBSWorkflowId={childrensHCBSWorkflowId}
        childrensHCBSWorkflowStepId={childrensHCBSWorkflowStepId}
        show={isAddWorkflowDocumentModalOpen}
        onClose={closeAddWorkflowDocumentModal}
        documentTypes={documentTypes}
      />
    </Section>
  )
}

WorkflowDocumentsTable.propTypes = {
  chart: types.chart.isRequired,
  childrensHCBSWorkflowId: PropTypes.number.isRequired,
  childrensHCBSWorkflowStepId: PropTypes.number.isRequired,
  childrensHCBSWorkflowDocuments: PropTypes.arrayOf(types.childrensHCBSWorkflowDocument).isRequired,
  documentTypes: PropTypes.arrayOf(types.documentType).isRequired,
}

function TableRow({ childrensHCBSWorkflowId, childrensHCBSWorkflowDocument }) {
  const [isDownloadModalOpen, { open: openDownloadModal, close: closeDownloadModal }] = useModal(false)
  const { document, id } = childrensHCBSWorkflowDocument

  const { mutateAsync, isLoading } = useDeleteChildrensHCBSWorkflowDocument(childrensHCBSWorkflowId)

  async function unlinkWorkflowDocument() {
    try {
      await mutateAsync(id)
    } catch {
      toast.error("Workflow document could not be unlinked")
    }
  }

  return (
    <>
      <tr>
        <td>{formattedDate(document.date)}</td>
        <td>{document.documentTypeName}</td>
        <td>{document.description}</td>
        <td>
          <div className="d-flex align-items-center justify-content-end gap-1">
            <DividerList Divider={() => <span>|</span>}>
              <LinkButton onClick={openDownloadModal} size="sm">
                download
              </LinkButton>
              <a href={document_path(document.id)}>details</a>
              <LinkButton isLoading={isLoading} loadingText="unlinking..." onClick={unlinkWorkflowDocument} size="sm">
                unlink
              </LinkButton>
            </DividerList>
          </div>
        </td>
      </tr>
      {isDownloadModalOpen ? (
        <DownloadModalSync
          downloadPath={uploads_document_path(document.id, document.filename)}
          show={isDownloadModalOpen}
          onClose={closeDownloadModal}
        />
      ) : null}
    </>
  )
}

TableRow.propTypes = {
  childrensHCBSWorkflowId: PropTypes.number.isRequired,
  childrensHCBSWorkflowDocument: types.childrensHCBSWorkflowDocument.isRequired,
}

export default WorkflowDocumentsTable
