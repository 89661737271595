import { SET_NEW_NEED_FORM_STATE } from "~/components/carePlans/carePlanNewNeedFormActions"

export default function carePlanNewNeedFormReducer(state = false, action) {
  switch (action.type) {
    case SET_NEW_NEED_FORM_STATE:
      return action.isOpen
    default:
      return state
  }
}
