import PropTypes from "prop-types"

const healthHomeConsent = PropTypes.shape({
  id: PropTypes.number,
  chartId: PropTypes.number.isRequired,
  uploadedFile: PropTypes.shape({ url: PropTypes.string }),
  dateOfConsent: PropTypes.string,
  verbal: PropTypes.bool,
  consentedByCareManagerId: PropTypes.number,
})

export default healthHomeConsent
