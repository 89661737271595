import { connect } from "react-redux"

import { carePlanGoalFlashKey } from "~/components/carePlans/carePlanGoalActions"
import DateDetail from "~/components/carePlans/DateDetail"
import EditButton from "~/components/carePlans/EditButton"
import { getCarePlanPriorities } from "~/components/carePlans/globalsSelectors"
import Notes from "~/components/carePlans/Notes"
import DividerList from "~/components/DividerList"
import FlashMessage from "~/components/FlashMessage"
import { selectedOption } from "~/reactSelectHelpers"

const GoalDetails = ({ goal, carePlanPriorities, onEditClick }) => {
  const { completionDate, description, id, notes, patientCarePlanPriorityId, startDate, targetCompletionDate, status } =
    goal
  const priority = selectedOption(carePlanPriorities, patientCarePlanPriorityId)

  return (
    <div className="goal">
      <div className="row">
        <strong className="col-md-1 goal-title">Goal</strong>
        <div className="col-md-8">
          <div className="goal-description">
            {description}&nbsp;
            {status.label === "Active" && priority.label === "High" && (
              <div className="badge badge-danger">High Priority</div>
            )}
          </div>
        </div>
        <div className="col-md-3 text-right goal-status">
          {status.label !== "Active" && <strong className="status">{status.label}</strong>}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-8 goal-subtitle goal-dates">
          <DividerList>
            <DateDetail date={startDate} className="goal-start-date" label="Active" />
            {targetCompletionDate && (
              <DateDetail date={targetCompletionDate} className="goal-target-completion-date" label="Target" />
            )}
            {completionDate && <DateDetail date={completionDate} className="goal-completion-date" label="Completed" />}
            {notes && <Notes entityId={id} entityName="goal" notes={notes} />}
          </DividerList>
        </div>
        <div className="col-md-3 text-right">
          <FlashMessage flashKey={carePlanGoalFlashKey(goal)} className="goal-flash-message">
            Saved <i className="fa fa-check" />
          </FlashMessage>
          <EditButton onClick={onEditClick} label="Edit goal" />
        </div>
      </div>
    </div>
  )
}

export default connect((state) => ({
  carePlanPriorities: getCarePlanPriorities(state),
}))(GoalDetails)
