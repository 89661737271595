import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import {
  DATE_QUESTION_TYPE_ID,
  getDefaultResponseValue,
  isAnswerableQuestion,
  LONG_TEXT_QUESTION_TYPE_ID,
  MULTI_SELECT_QUESTION_TYPE_ID,
  SHORT_TEXT_QUESTION_TYPE_ID,
  SINGLE_SELECT_QUESTION_TYPE_ID,
} from "../helpers/questionHelpers"
import * as customAssessmentResponseTypes from "../types"

import ResponseQuestionCard from "./ResponseQuestionCard"

import Form from "~/design/forms"
import Show from "~/design/Show"

function ResponseQuestion({ dependsOnQuestion, question, questionNumber }) {
  const {
    values: { questionResponseValues },
  } = useFormikContext()

  let formControl

  switch (question.questionTypeId) {
    case DATE_QUESTION_TYPE_ID:
      formControl = <Form.DatePicker />

      break
    case SINGLE_SELECT_QUESTION_TYPE_ID: {
      formControl = (
        <Form.SelectBox
          options={question.options.map(({ id, optionText }) => ({ value: id, label: optionText }))}
          isClearable
        />
      )

      break
    }
    case MULTI_SELECT_QUESTION_TYPE_ID: {
      formControl = (
        <Form.MultiSelectBox
          options={question.options.map(({ id, optionText }) => ({ value: id, label: optionText }))}
          isClearable
        />
      )

      break
    }
    case SHORT_TEXT_QUESTION_TYPE_ID:
      formControl = <Form.TextField debounce />

      break
    case LONG_TEXT_QUESTION_TYPE_ID:
      formControl = <Form.TextArea debounce />

      break
    default:
      throw new Error("Invalid question type")
  }

  const dependsOnQuestionResponseValue = dependsOnQuestion
    ? questionResponseValues[dependsOnQuestion.questionResponseId]
    : null
  const isAnswerable = isAnswerableQuestion(question, dependsOnQuestion, dependsOnQuestionResponseValue)

  const name = `questionResponseValues.${question.questionResponseId}`
  const defaultValue = getDefaultResponseValue(question.questionTypeId)

  return (
    <ResponseQuestionCard isAnswerable={isAnswerable} questionId={question.id} questionNumber={questionNumber}>
      {isAnswerable ? (
        <Form.Group compact clearedValue={defaultValue} name={name}>
          <Form.Label>
            {question.questionText}
            <Show when={question.isRequired}>
              <span className="text-danger">
                &nbsp;*<span className="sr-only">Required</span>
              </span>
            </Show>
          </Form.Label>
          {formControl}
        </Form.Group>
      ) : (
        question.questionText
      )}
    </ResponseQuestionCard>
  )
}

ResponseQuestion.propTypes = {
  dependsOnQuestion: customAssessmentResponseTypes.schemaQuestion,
  question: customAssessmentResponseTypes.schemaQuestion.isRequired,
  questionNumber: PropTypes.number.isRequired,
}

export default ResponseQuestion
