import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import { PrimaryButton } from "~/design/buttons"
import useConfirmationModal from "~/design/modals/useConfirmationModal"

function SubmitButton({ isCompleting, setIsCompleting }) {
  const { isSubmitting, submitForm } = useFormikContext()
  const confirm = useConfirmationModal()

  async function handleComplete() {
    const confirmed = await confirm.primary(
      <>
        By completing this assessment, <strong>it will no longer be editable</strong>. Are you sure you want to proceed?
      </>,
      { confirmButtonLabel: "Complete" }
    )

    if (confirmed) {
      setIsCompleting(true)
      submitForm()
    }
  }

  return (
    <PrimaryButton disabled={isSubmitting} onClick={handleComplete}>
      {isCompleting ? "Completing..." : isSubmitting ? "Saving..." : "Complete"}
    </PrimaryButton>
  )
}

SubmitButton.propTypes = {
  isCompleting: PropTypes.bool.isRequired,
  setIsCompleting: PropTypes.func.isRequired,
}

export default SubmitButton
