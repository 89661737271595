import _ from "lodash"

import { FLASH_MESSAGE_END, FLASH_MESSAGE_START } from "~/flashMessages/flashMessageActions"

export default function flashMessageReducer(state = {}, action) {
  switch (action.type) {
    case FLASH_MESSAGE_START:
      return { ...state, [action.key]: true }
    case FLASH_MESSAGE_END:
      return _.omit(state, action.key)
    default:
      return state
  }
}
