import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_childrens_hcbs_workflow_disenrollment_path } from "~/routes"

const childrensHCBSWorkflowDisenrollments = {
  patch: function (childrensHCBSWorkflowId, childrensHCBSWorkflow) {
    return submitAsyncRequest({
      url: api_v1_childrens_hcbs_workflow_disenrollment_path(childrensHCBSWorkflowId),
      type: "PATCH",
      data: { childrensHCBSWorkflow },
    })
  },
}

export default childrensHCBSWorkflowDisenrollments
