import Icon from "./Icon"

/**
 * Renders an icon of a check mark.
 */
function CheckIcon() {
  return <Icon name="check" />
}

export default CheckIcon
