import classNames from "classnames"
import PropTypes from "prop-types"

import Show from "~/design/Show"

function Section({ border, children, title }) {
  return (
    <section className={classNames("mb-4", { "pb-2 border-bottom": border })}>
      <Show when={Boolean(title)}>
        <h3 className="mb-4" style={{ fontSize: "1.25rem", fontWeight: "500", lineHeight: "1.2" }}>
          {title}
        </h3>
      </Show>
      {children}
    </section>
  )
}

Section.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

Section.defaultProps = {
  border: true,
}

export default Section
