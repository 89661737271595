import { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { useFormikContext } from "formik"

import ChartField from "./ChartField"

import updatePath from "~/browserHelpers"
import { new_charted_data_copy_path } from "~/routes"

function ChartSelection() {
  const { values } = useFormikContext()
  const copiedFromChartId = values.copiedFromChart?.id
  const copiedToChartId = values.copiedToChart?.id

  useEffect(() => {
    updatePath(
      new_charted_data_copy_path({ copied_from_chart_id: copiedFromChartId, copied_to_chart_id: copiedToChartId })
    )
  }, [copiedFromChartId, copiedToChartId])

  return (
    <Row className="mb-3">
      <Col lg={6}>
        <section className="bg-light h-100 p-3" data-testid="copied-from-chart-section">
          <h2 className="mb-3" style={{ fontSize: "1.25rem" }}>
            Copy From
          </h2>
          <ChartField name="copiedFromChart" />
        </section>
      </Col>
      <Col lg={6}>
        <section className="bg-light h-100 p-3" data-testid="copied-to-chart-section">
          <h2 className="mb-3" style={{ fontSize: "1.25rem" }}>
            Copy To
          </h2>
          <ChartField name="copiedToChart" />
        </section>
      </Col>
    </Row>
  )
}

export default ChartSelection
