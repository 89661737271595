import { connect } from "react-redux"

import CarePlanFormBody from "./CarePlanFormBody"
import { carePlanGoalCreated, carePlanGoalFlashKey } from "./carePlanGoalActions"
import { closeNewGoalForm } from "./carePlanNeedUIActions"
import { getNormalCarePlanPriority } from "./globalsSelectors"
import GoalFormFields from "./GoalFormFields"

import Form from "~/design/forms"
import { flashMessage } from "~/flashMessages/flashMessageActions"
import api from "~/services/api"
import { toast } from "~/toast"

function NewGoalForm({
  carePlanGoalCreatedConnect,
  closeNewGoalFormConnect,
  flashMessageConnect,
  needId,
  normalPriority,
}) {
  const defaultGoal = {
    description: "",
    carePlanGoalStatusId: 2, // CarePlanGoalStatus::ACTIVE.id
    notes: "",
    patientCarePlanPriorityId: normalPriority?.value,
    startDate: "",
    targetCompletionDate: "",
    completionDate: "",
  }

  async function handleSubmit(values, { setErrors, setStatus }) {
    try {
      const createdGoal = await api.carePlans.goals.post(needId, values)
      setStatus("success")
      carePlanGoalCreatedConnect(createdGoal, needId)
      flashMessageConnect(carePlanGoalFlashKey(createdGoal))
      toast.success("Goal successfully created.")
      closeNewGoalFormConnect(needId)
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={defaultGoal} onSubmit={handleSubmit} vertical>
      <CarePlanFormBody title="Add Goal" dataTestId="goal-form">
        <GoalFormFields />
        <Form.ActionBar
          right={
            <>
              <Form.CancelButton onClick={() => closeNewGoalFormConnect(needId)} />
              <Form.SubmitButton label="Create" />
            </>
          }
        />
      </CarePlanFormBody>
    </Form>
  )
}

export default connect(
  (state) => ({
    normalPriority: getNormalCarePlanPriority(state),
  }),
  {
    carePlanGoalCreatedConnect: carePlanGoalCreated,
    closeNewGoalFormConnect: closeNewGoalForm,
    flashMessageConnect: flashMessage,
  }
)(NewGoalForm)
