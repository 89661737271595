import PropTypes from "prop-types"

function ChartTooltip({ children, title }) {
  return (
    <div className="bg-white border p-2 text-center">
      <div style={{ fontSize: "1rem" }}>{title}</div>
      {children}
    </div>
  )
}

ChartTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default ChartTooltip
