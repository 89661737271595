/*
  Used to generate class names for react-select, which have rules defined in `react_select.scss`
  We use the `aria-invalid` prop to dynamically add class names when the field is invalid.
  See: https://react-select.com/styles#the-classnameprefix-prop
*/
const classNames = {
  control: ({ selectProps }) => (selectProps["aria-invalid"] ? "react-select__control--is-invalid" : undefined),
  singleValue: ({ selectProps }) =>
    selectProps["aria-invalid"] ? "react-select__single-value--is-invalid" : undefined,
}

export { classNames }
