import { Form as BootstrapForm } from "react-bootstrap"
import PropTypes from "prop-types"

function HelpText({ id, children }) {
  if (!children) {
    return null
  }

  return (
    <BootstrapForm.Text id={id} muted>
      {children}
    </BootstrapForm.Text>
  )
}

HelpText.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.string,
}

export default HelpText
