import PropTypes from "prop-types"

function Card({ HeaderIcon, actions, title, children }) {
  return (
    <section className="border h-100 p-3">
      <div className="d-flex align-items-center gap-3 mb-4">
        <h4 style={{ fontSize: "1rem", fontWeight: "500", lineHeight: "1.25" }}>
          {HeaderIcon ? <HeaderIcon /> : null} {title}
        </h4>
        {actions}
      </div>
      {children}
    </section>
  )
}

Card.propTypes = {
  actions: PropTypes.node,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  HeaderIcon: PropTypes.elementType,
}

export default Card
