import PropTypes from "prop-types"

const document = PropTypes.shape({
  id: PropTypes.number,
  categoryId: PropTypes.number,
  chartId: PropTypes.number.isRequired,
  uploadedFile: PropTypes.shape({ url: PropTypes.string }),
  description: PropTypes.string,
  documentTypeId: PropTypes.number,
  otherDocumentTypeName: PropTypes.string,
  date: PropTypes.string,
  filename: PropTypes.string,
  documentTypeName: PropTypes.string,
})

export default document
