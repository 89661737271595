export const SET_GOAL_FORM_STATE = "SET_GOAL_FORM_STATE"
export function openNewGoalForm(id) {
  return { type: SET_GOAL_FORM_STATE, id, isOpen: true }
}

export function closeNewGoalForm(id) {
  return { type: SET_GOAL_FORM_STATE, id, isOpen: false }
}

export const SET_GOALS_LIST_OPEN_STATE = "SET_GOALS_LIST_OPEN_STATE"
export function openGoalsList(id) {
  return { type: SET_GOALS_LIST_OPEN_STATE, id, isOpen: true }
}

export function closeGoalsList(id, goalIds) {
  return { type: SET_GOALS_LIST_OPEN_STATE, id, isOpen: false, goalIds }
}
