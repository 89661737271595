import EditGoalForm from "~/components/carePlans/EditGoalForm"
import FormShowToggle from "~/components/carePlans/FormShowToggle"
import GoalCardFooter from "~/components/carePlans/GoalCardFooter"
import GoalDetails from "~/components/carePlans/GoalDetails"

const GoalCard = ({ goal }) => {
  const connectedGoalForm = ({ onToggleEdit }) => <EditGoalForm goal={goal} onCancelClick={onToggleEdit} />

  const connectedGoalDetails = ({ onToggleEdit }) => <GoalDetails goal={goal} onEditClick={onToggleEdit} />

  return (
    <div className={`goal-card ${goal.status.label.toLowerCase()}`}>
      <FormShowToggle formComponent={connectedGoalForm} showComponent={connectedGoalDetails} />
      <GoalCardFooter goalId={goal.id} />
    </div>
  )
}

export default GoalCard
