import { useEffect } from "react"
import { useFormikContext } from "formik"
import _ from "lodash"

import EncounterDetailsFields from "~/components/encounters/EncounterDetailsFields"
import EncounterMeetingTypeField from "~/components/encounters/EncounterMeetingTypeField"
import EncounterTargetsField from "~/components/encounters/EncounterTargetsField"
import EncounterYesNoField from "~/components/encounters/EncounterYesNoField"

function PhoneEncounterDetailsFields() {
  const {
    values: { phoneEncounterDetailsAttributes = {} },
    setFieldValue,
  } = useFormikContext()
  const { contacted } = phoneEncounterDetailsAttributes
  const defaultValues = { contacted: "" }

  useEffect(() => {
    if (!_.isBoolean(contacted)) {
      setFieldValue("phoneEncounterDetailsAttributes", defaultValues)
    } else if (contacted === true) {
      setFieldValue("phoneEncounterDetailsAttributes", {
        targetIds: [],
        meetingTypeId: "",
        ...phoneEncounterDetailsAttributes,
        voicemail: false,
      })
    } else if (contacted === false) {
      setFieldValue("phoneEncounterDetailsAttributes", {
        targetIds: [],
        voicemail: "",
        ...phoneEncounterDetailsAttributes,
        meetingTypeId: 3, // EncounterMeetingType::NONE
      })
    }
  }, [contacted])

  return (
    <EncounterDetailsFields attributesName="phoneEncounterDetailsAttributes" defaultValues={defaultValues}>
      <EncounterYesNoField name="contacted" label="Did you reach anyone?" />
      {contacted === false && <EncounterYesNoField name="voicemail" label="Did you leave a voicemail?" />}
      {_.isBoolean(contacted) && (
        <EncounterTargetsField
          name="targetIds"
          label={contacted ? "Who did you talk to?" : "Who did you reach out to?"}
        />
      )}
      <EncounterMeetingTypeField enabled={contacted} name="meetingTypeId" />
    </EncounterDetailsFields>
  )
}

export default PhoneEncounterDetailsFields
