import { lazy, Suspense } from "react"
import PropTypes from "prop-types"

import LoadingSpinner from "./components/LoadingSpinner"

import types from "~/types"

// This component imports the openpgp library, which is quite large.
// We lazy load it so that it's not included in the main JS bundle.
const EncryptPayerFileForm = lazy(() => import("./components/EncryptPayerFileForm.lazy"))

function New({ payers }) {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <EncryptPayerFileForm payers={payers} />
    </Suspense>
  )
}

New.propTypes = {
  payers: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default New
