import _ from "lodash"

import { CARE_PLAN_ENTITIES_DELETED } from "~/components/carePlans/carePlanActions"
import { CARE_PLAN_GOAL_CREATED } from "~/components/carePlans/carePlanGoalActions"
import { SET_TASK_FORM_STATE, SET_TASKS_LIST_OPEN_STATE } from "~/components/carePlans/carePlanGoalUIActions"
import { SET_GOALS_LIST_OPEN_STATE } from "~/components/carePlans/carePlanNeedUIActions"
import { COLLAPSE_ALL, EXPAND_ALL } from "~/components/carePlans/carePlanUIActions"
import { deleteSiblingNodes } from "~/components/carePlans/entityTreeTransformer"

export default function carePlanGoalsUiReducer(state = {}, action) {
  switch (action.type) {
    case CARE_PLAN_GOAL_CREATED:
      return { ...state, [action.goal.id]: { isTasksListOpen: false } }
    case SET_GOALS_LIST_OPEN_STATE:
      if (!action.isOpen) {
        return _.mapValues(state, (goal) =>
          action.goalIds.includes(goal.id)
            ? {
                ...goal,
                isTasksListOpen: false,
              }
            : goal
        )
      }
      return state
    case SET_TASK_FORM_STATE: {
      const goal = state[action.id]

      return {
        ...state,
        [action.id]: { ...goal, isNewTaskFormOpen: action.isOpen },
      }
    }
    case SET_TASKS_LIST_OPEN_STATE: {
      const goal = state[action.id]

      return {
        ...state,
        [action.id]: {
          ...goal,
          isTasksListOpen: action.isOpen,
        },
      }
    }
    case EXPAND_ALL:
      return _.mapValues(state, (goal) => ({
        ...goal,
        isTasksListOpen: true,
      }))
    case COLLAPSE_ALL:
      return _.mapValues(state, (goal) => ({
        ...goal,
        isTasksListOpen: false,
        isNewTaskFormOpen: false,
      }))
    case CARE_PLAN_ENTITIES_DELETED:
      return deleteSiblingNodes(state, action.goalIds)
    default:
      return state
  }
}
