import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

const questionsAdapter = createEntityAdapter()

const questionsSlice = createSlice({
  name: "questions",
  initialState: questionsAdapter.getInitialState(),
  reducers: {
    questionCreated: questionsAdapter.addOne,
    questionUpdated: questionsAdapter.upsertOne,
    questionDestroyed: (state, action) => {
      questionsAdapter.removeOne(state, action.payload.id)
    },
  },
})

export const getPreloadedQuestionsState = (questions) =>
  questionsAdapter.upsertMany(questionsAdapter.getInitialState(), questions)

export const { questionCreated, questionUpdated, questionDestroyed } = questionsSlice.actions
export const questionSelectors = questionsAdapter.getSelectors((state) => state.questions)

export default questionsSlice.reducer
