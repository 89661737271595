import PropTypes from "prop-types"

const supervisoryTeamMember = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  careManagerId: PropTypes.number.isRequired,
})

export default supervisoryTeamMember
