import { createContext, useContext } from "react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import * as cesToolTypes from "../types"

const CesToolSchemaContext = createContext()

function useCesToolSchema() {
  const context = useContext(CesToolSchemaContext)
  if (!context) {
    throw Error(`useCesToolSchema can only be called within a <CesToolSchemaProvider />.`)
  }
  return context
}

function CesToolSchemaProvider({ children, cesToolSchema }) {
  const { values } = useFormikContext()

  const calculatedOutcomeId = calculateCesToolOutcome(cesToolSchema, values)

  return (
    <CesToolSchemaContext.Provider
      value={{
        calculatedOutcomeId,
        isFieldAnswerable: (answerableField) => isFieldAnswerable(cesToolSchema, answerableField, values),
      }}
    >
      {children}
    </CesToolSchemaContext.Provider>
  )
}

CesToolSchemaProvider.propTypes = {
  children: PropTypes.node.isRequired,
  cesToolSchema: cesToolTypes.cesToolSchema.isRequired,
}

function calculateCesToolOutcome(schema, values) {
  for (const field in schema) {
    if ("outcomesFromValue" in schema[field]) {
      for (const { value, cesToolOutcomeId } of schema[field].outcomesFromValue) {
        if (values[field] === value) return cesToolOutcomeId
      }
    }
  }
  return null
}

function isFieldAnswerable(schema, answerableField, values) {
  if (!(answerableField in schema)) {
    throw new Error(`Unknown CES Tool field ${answerableField}`)
  }

  if (!("dependsOnAny" in schema[answerableField])) {
    return true
  }

  for (const { field, value } of schema[answerableField].dependsOnAny) {
    if (values[field] === value) return true
  }

  return false
}

export default useCesToolSchema
export { CesToolSchemaProvider }
