import Icon from "./Icon"

/**
 * Renders an icon of a backwards arrow, indicating an undo action.
 */
function UndoIcon() {
  return <Icon name="undo" />
}

export default UndoIcon
