import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import PropTypes from "prop-types"

import Filter from "./components/Filter"
import Report from "./components/Report"

import { QueryParamsProvider } from "~/hooks/useQueryParams"
import types from "~/types"

const client = new QueryClient()

function Show({
  billingInstanceTypes,
  careManagementAgencies,
  claimStatuses,
  claimTypes,
  healthHomes,
  payers,
  rateCodes,
}) {
  return (
    <QueryClientProvider client={client}>
      <QueryParamsProvider>
        <Filter
          billingInstanceTypes={billingInstanceTypes}
          careManagementAgencies={careManagementAgencies}
          claimStatuses={claimStatuses}
          claimTypes={claimTypes}
          healthHomes={healthHomes}
          payers={payers}
          rateCodes={rateCodes}
        />
        <Report claimStatuses={claimStatuses} />
      </QueryParamsProvider>
    </QueryClientProvider>
  )
}

Show.propTypes = {
  billingInstanceTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  careManagementAgencies: PropTypes.arrayOf(types.selectOption).isRequired,
  claimStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  claimTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  healthHomes: PropTypes.arrayOf(types.selectOption).isRequired,
  payers: PropTypes.arrayOf(types.selectOption).isRequired,
  rateCodes: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default Show
