import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_chart_charted_data_copies_path, new_api_v1_chart_charted_data_copy_path } from "~/routes"

const chartDataCopies = {
  post: function (copiedFromChartId, chartedDataCopy) {
    return submitAsyncRequest({
      url: api_v1_chart_charted_data_copies_path(copiedFromChartId),
      type: "POST",
      data: { chartedDataCopy },
    })
  },
  new: {
    get: function (copiedFromChartId) {
      return submitAsyncRequest({
        url: new_api_v1_chart_charted_data_copy_path(copiedFromChartId),
        type: "GET",
      })
    },
  },
}

export default chartDataCopies
