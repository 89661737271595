import { createColumnHelper } from "@tanstack/react-table"

import Big3Table from "~/big3Dashboard/components/Big3Table"
import {
  createBig3CareManagementAgencyPatientsDrillthruUrl,
  createBig3HealthHomesPatientsDrillthruUrl,
} from "~/big3Dashboard/helpers/createBig3HealthHomesPatientsDrillthruUrl"

export function CareManagementAgencyConsentsTable({
  entityColumn,
  status,
  report,
  careManagementAgencyId,
  healthHomeIds,
  date,
  adultChildServicesCodeId,
}) {
  const noncompliantDrillThruCreator = (careManagerId) =>
    createBig3CareManagementAgencyPatientsDrillthruUrl({
      careManagerId,
      careManagementAgencyId,
      healthHomeIds,
      date,
      adultChildServicesCodeId,
      statusValue: "health_home_consent_noncompliant",
    })

  return ConsentsTable({ entityColumn, status, report, noncompliantDrillThruCreator })
}

export function HealthHomeConsentsTable({ entityColumn, status, report, healthHomeId, date }) {
  const noncompliantDrillThruCreator = (careManagementAgencyId) =>
    createBig3HealthHomesPatientsDrillthruUrl({
      healthHomeId,
      careManagementAgencyId,
      date,
      statusValue: "health_home_consent_noncompliant",
    })

  return ConsentsTable({ entityColumn, status, report, noncompliantDrillThruCreator })
}

function ConsentsTable({ entityColumn, status, report, noncompliantDrillThruCreator }) {
  const columnHelper = createColumnHelper()

  const columns = [
    entityColumn,
    columnHelper.accessor("enrolled", {
      header: "Enrolled",
      id: "enrolled",
      popoverContent: "Number of patients that are Enrolled (Not-Pended).",
    }),
    columnHelper.accessor("missing", {
      header: "Missing Consents",
      id: "missing",
      popoverContent:
        "Number of patients among Enrolled (Not-Pended) that do not have a consent with their health home and care management agency.",
      cell: ({ row, getValue }) => {
        const { entity } = row.original
        return (
          <a data-testid="missing-consents-drillthru" href={noncompliantDrillThruCreator(entity.id)}>
            {getValue()}
          </a>
        )
      },
    }),
    columnHelper.accessor("valid", {
      header: "Valid Consents",
      id: "valid",
      popoverContent:
        "Total number of patients among Enrolled (Not-Pended) that have a consent with their health home and care management agency.",
    }),
    columnHelper.accessor("completionPercentage", {
      header: "Completion (%)",
      id: "completion_percentage",
      popoverContent:
        "Percentage of patients among Enrolled (Not-Pended) that have a consent with their health home and care management agency.",
    }),
  ]

  return <Big3Table status={status} columns={columns} report={report} />
}
