import { useState } from "react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import { LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import types from "~/types"

function LinkedGapsInCareField({ gapOptions }) {
  const {
    values: { mcoGapInCareIds },
  } = useFormikContext()

  const isInitiallyVisible = mcoGapInCareIds.length > 0
  const [isVisible, setIsVisible] = useState(isInitiallyVisible)

  if (!isVisible) {
    return <LinkButton onClick={() => setIsVisible(true)}>Link to Gaps in Care</LinkButton>
  }

  if (gapOptions.length === 0) {
    return <p className="text-muted">This patient has no gaps in care.</p>
  }

  return (
    <Form.Group name="mcoGapInCareIds">
      <Form.Label>Select from active gaps in care:</Form.Label>
      <Form.MultiSelectBox isClearable options={gapOptions} />
    </Form.Group>
  )
}

LinkedGapsInCareField.propTypes = {
  gapOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default LinkedGapsInCareField
