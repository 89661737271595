import PropTypes from "prop-types"

import { SIDEBAR_TOP_OFFSET } from "../constants"

import Form from "~/design/forms"

function Section({ children, id, title }) {
  return (
    <div id={id} className="mb-4" style={{ scrollMarginTop: `${SIDEBAR_TOP_OFFSET}px` }}>
      <Form.Fieldset title={title}>{children}</Form.Fieldset>
    </div>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Section
