import { Col, Row } from "react-bootstrap"
import _ from "lodash"
import PropTypes from "prop-types"

import { carePlanTaskFlashKey } from "~/components/carePlans/carePlanTaskActions"
import LinkedGapInCareBadge from "~/components/carePlans/LinkedGapInCareBadge"
import FlashMessage from "~/components/FlashMessage"
import formattedDate from "~/dateHelpers"
import { SecondaryButton } from "~/design/buttons"
import { selectedOption } from "~/reactSelectHelpers"
import { globalsStateWrapper } from "~/redux"

const LinkableCarePlanTaskDetails = ({
  task,
  editable,
  toggleEditModal,
  taskResponsibilities,
  carePlanTaskStatuses,
}) => {
  const handleEditClick = (event) => {
    event.stopPropagation()
    toggleEditModal()
  }

  const { mcoGapInCareIds } = task

  const taskResponsibilityName = () => {
    const selectedResponsibility = selectedOption(taskResponsibilities, task.taskResponsibilityId)

    if (task.taskResponsibilityOther) {
      return task.taskResponsibilityOther
    }
    return selectedResponsibility && selectedResponsibility.label
  }

  const selectedStatus = selectedOption(carePlanTaskStatuses, task.carePlanTaskStatusId)
  const statusName = selectedStatus && selectedStatus.label

  return (
    <>
      <Row noGutters={true}>
        <Col sm={8}>
          <div className="mb-3">
            {task.taskResponsibilityId && <div className="text-uppercase">{taskResponsibilityName()}</div>}
            {task.description}
          </div>
        </Col>
        <Col sm={4}>
          <div className="d-sm-flex flex-column align-items-end">
            {editable && (
              <>
                <div className="selectable-box-visible-when-active">
                  <SecondaryButton onClick={handleEditClick}>Edit</SecondaryButton>
                </div>
                <FlashMessage flashKey={carePlanTaskFlashKey(task)}>
                  Saved <i className="fa fa-check" />
                </FlashMessage>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row noGutters={true}>
        <Col sm={6} lg={12} xl={6}>
          <span className="text-muted">Target</span> {formattedDate(task.targetCompletionDate)}
        </Col>
        <Col sm={3} lg={6} xl={3}>
          {statusName !== "Active" && <span className="float-sm-right">{statusName}</span>}
        </Col>
        <Col sm={3} lg={6} xl={3} className="text-right">
          <LinkedGapInCareBadge mcoGapInCareIds={mcoGapInCareIds} />
        </Col>
      </Row>
    </>
  )
}

LinkableCarePlanTaskDetails.defaultProps = {
  toggleEditModal: _.noop,
}

LinkableCarePlanTaskDetails.propTypes = {
  toggleEditModal: PropTypes.func,
}

export default globalsStateWrapper(({ taskResponsibilities, carePlanTaskStatuses }) => ({
  taskResponsibilities,
  carePlanTaskStatuses,
}))(LinkableCarePlanTaskDetails)
