import { useState } from "react"
import { Table } from "react-bootstrap"
import _ from "lodash"
import PropTypes from "prop-types"

import AddMemberModal from "./components/AddMemberModal"
import AddSupervisorModal from "./components/AddSupervisorModal"
import useModal from "./hooks/useModal"

import { LinkButton, SecondaryButton } from "~/design/buttons"
import Jumbotron from "~/design/Jumbotron"
import { care_manager_path, user_path } from "~/routes"
import api from "~/services/api"
import { toast, ToastContainer } from "~/toast"
import types from "~/types"

function Show({ team, potentialSupervisorUsers, potentialMemberCareManagers }) {
  const [supervisors, setSupervisors] = useState(team.supervisors)
  const [members, setMembers] = useState(team.members)

  const { isMemberModalVisible, isSupervisorModalVisible, showMemberModal, showSupervisorModal, hideModal } = useModal()

  const handleRemoveSupervisor = async (supervisorIdToRemove) => {
    try {
      await api.supervisoryTeams.supervisors.delete(team.id, supervisorIdToRemove)
      setSupervisors(supervisors.filter(({ id }) => id !== supervisorIdToRemove))
      toast.success("Supervisor successfully removed from team")
    } catch {
      toast.error("Supervisor could not be removed from team")
    }
  }

  const handleSubmitSupervisors = async ({ supervisorUserIds }, { setErrors, setStatus }) => {
    const formValues = {
      supervisorUserIds: _.without(supervisorUserIds, ""),
    }
    try {
      const response = await api.supervisoryTeams.supervisors.post(team.id, formValues)
      setSupervisors(response.supervisors)
      setStatus("success")
      hideModal()
      toast.success("Supervisors updated successfully")
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  const handleRemoveMember = async (memberIdToRemove) => {
    try {
      await api.supervisoryTeams.members.delete(team.id, memberIdToRemove)
      setMembers(members.filter(({ id }) => id !== memberIdToRemove))
      toast.success("Care manager successfully removed from team")
    } catch {
      toast.error("Care manager could not be removed from team")
    }
  }

  const handleSubmitMembers = async ({ memberCareManagerIds }, { setErrors, setStatus }) => {
    const formValues = {
      memberCareManagerIds: _.without(memberCareManagerIds, ""),
    }
    try {
      const response = await api.supervisoryTeams.members.post(team.id, formValues)
      setMembers(response.members)
      setStatus("success")
      toast.success("Care managers updated successfully")
      hideModal()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <>
      <div className="mb-5">
        <div className="d-flex justify-content-end mb-3">
          <SecondaryButton onClick={showSupervisorModal}>Add Supervisor</SecondaryButton>
        </div>
        {supervisors.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: "40%" }}>Supervisor</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {supervisors.map((supervisor) => (
                <tr key={supervisor.id}>
                  <td>
                    <a href={user_path(supervisor.userId)}>{supervisor.name}</a>
                  </td>
                  <td>{supervisor.email}</td>
                  <td className="text-right">
                    <LinkButton color="danger" onClick={() => handleRemoveSupervisor(supervisor.id)}>
                      Remove
                    </LinkButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Jumbotron message="No supervisors are assigned to this team" />
        )}
      </div>
      <div className="mb-5">
        <div className="d-flex justify-content-end mb-3">
          <SecondaryButton onClick={showMemberModal}>Add Care Manager</SecondaryButton>
        </div>
        {members.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: "40%" }}>Care Manager</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {members.map((member) => (
                <tr key={member.id}>
                  <td>
                    <a href={care_manager_path(member.careManagerId)}>{member.name}</a>
                  </td>
                  <td>{member.email}</td>
                  <td className="text-right">
                    <LinkButton color="danger" onClick={() => handleRemoveMember(member.id)}>
                      Remove
                    </LinkButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Jumbotron message="No care managers are assigned to this team" />
        )}
      </div>
      <AddSupervisorModal
        onClose={hideModal}
        onSubmit={handleSubmitSupervisors}
        potentialSupervisorUsers={potentialSupervisorUsers}
        show={isSupervisorModalVisible}
        supervisors={supervisors}
      />
      <AddMemberModal
        members={members}
        onSubmit={handleSubmitMembers}
        onClose={hideModal}
        potentialMemberCareManagers={potentialMemberCareManagers}
        show={isMemberModalVisible}
      />
      <ToastContainer />
    </>
  )
}

Show.propTypes = {
  team: types.supervisoryTeam.isRequired,
  potentialSupervisorUsers: PropTypes.objectOf(types.user).isRequired,
  potentialMemberCareManagers: PropTypes.objectOf(types.careManager).isRequired,
}

export default Show
