import PropTypes from "prop-types"

import document from "./document"

const childrensHCBSWorkflowDocument = PropTypes.shape({
  id: PropTypes.number.isRequired,
  document: document.isRequired,
})

export default childrensHCBSWorkflowDocument
