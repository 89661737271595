import { parseISO } from "date-fns"

export const filterAsOfDate = (riskFactors, startDate) => {
  const parsedStartDate = parseISO(startDate)

  return riskFactors.filter((riskFactor) => {
    if (riskFactor.enabledOn) {
      return parsedStartDate >= parseISO(riskFactor.enabledOn)
    }

    if (riskFactor.disabledOn) {
      return parsedStartDate < parseISO(riskFactor.disabledOn)
    }

    return true
  })
}
