import Select from "react-select"
import { useField } from "formik"
import PropTypes from "prop-types"

import { DEFAULT_CONTROL_WIDTH } from "./constants"
import FieldErrors from "./FieldErrors"
import HelpText from "./HelpText"
import { classNames } from "./selectBoxConfiguration"
import useDisableContext from "./useDisableContext"
import useErrorContext from "./useErrorContext"
import { LayoutWrapper } from "./useLayoutContext"
import useNameContext from "./useNameContext"

import types from "~/types"

/**
 * A `<SelectBox />` represents a dropdown control from which a user
 * can select an option from a pre-determined list.
 */
function SelectBox({
  disabled: disabledByProps,
  filterOption,
  formatOptionLabel,
  helpText,
  isClearable,
  options,
  placeholder,
  validate,
  width,
}) {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name, validate })
  const { hasError } = useErrorContext()
  const disabledByContext = useDisableContext()

  const helpTextId = `${id}HelpText`
  const errorMessageId = `${id}Errors`
  const disabled = disabledByContext || disabledByProps
  const value = field.value === undefined ? null : options?.find((opt) => opt.value === field.value)

  function noOptionsMessage({ inputValue }) {
    if (!inputValue) {
      return "Oops! There are no options here."
    }
    return `Oops! There are no options that contain "${inputValue}".`
  }

  return (
    <LayoutWrapper sm={width}>
      <Select
        {...field}
        aria-describedby={helpText ? helpTextId : null}
        aria-errormessage={errorMessageId}
        aria-invalid={hasError}
        aria-label={`${name} search box`}
        aria-labelledby={`${id}Label`}
        className="react-select"
        classNamePrefix="react-select"
        classNames={classNames}
        filterOption={filterOption}
        formatOptionLabel={formatOptionLabel}
        id={`${id}SelectBox`}
        inputId={id}
        isClearable={isClearable}
        isDisabled={disabled}
        name={name}
        noOptionsMessage={noOptionsMessage}
        onChange={(evt) => helpers.setValue(evt?.value === undefined ? null : evt.value)}
        options={options}
        placeholder={placeholder}
        value={value}
      />
      <HelpText id={helpTextId}>{helpText}</HelpText>
      <FieldErrors id={errorMessageId} />
    </LayoutWrapper>
  )
}

SelectBox.propTypes = {
  /**
   * Whether the selectbox can receive input.
   */
  disabled: PropTypes.bool,
  /**
   * Custom method to filter whether an option should be displayed in the menu
   */
  filterOption: PropTypes.func,
  /**
   * Formats option labels in the menu and control as React components:
   * `(option) => <MyCustomOption label={option.label} />`
   */
  formatOptionLabel: PropTypes.func,
  /**
   * Text intended to guide the user toward the correct input.
   */
  helpText: PropTypes.string,
  /**
   * When true, adds a button to clear the current value of the field.
   */
  isClearable: PropTypes.bool,
  /**
   * The options to display within the select box.
   */
  options: PropTypes.arrayOf(types.selectOption).isRequired,
  /**
   * The text to display before an option has been chosen.
   */
  placeholder: PropTypes.string,
  /**
   * An optional function to run client-side validations:
   * https://formik.org/docs/api/field#validate
   */
  validate: PropTypes.func,
  /**
   * The number of columns the control should span.
   */
  width: PropTypes.number,
}

SelectBox.defaultProps = {
  disabled: false,
  isClearable: false,
  placeholder: "Please select a value...",
  width: DEFAULT_CONTROL_WIDTH,
}

export default SelectBox
