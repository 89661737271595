import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import _ from "lodash"

import { setFilterParams } from "~/big3Dashboard/actions/paramsActions"
import { QUALITY_REPORT_LOADING } from "~/big3Dashboard/actions/qualityReportStatusActions"
import { getDate, getEntityId, getFilteredReportRowEntityIds } from "~/big3Dashboard/selectors/filtersSelector"
import { getEntities } from "~/big3Dashboard/selectors/globalsSelector"
import { getReportRowEntities, getStatus } from "~/big3Dashboard/selectors/qualityReportStatusSelector"
import Form from "~/design/forms"
import IndexFilter from "~/design/IndexFilter"

function Filters({
  allCareManagementAgencies,
  healthHomes,
  initialCareManagementAgencyIds,
  initialDate,
  initialHealthHomeId,
  setFilterParamsConnect,
  status,
}) {
  const isLoading = status === QUALITY_REPORT_LOADING

  function handleSubmit(values, { setSubmitting }) {
    setFilterParamsConnect(values)
    setSubmitting(false)
  }

  return (
    <Form
      initialValues={{
        date: initialDate,
        entityId: initialHealthHomeId,
        reportRowEntityIds: initialCareManagementAgencyIds,
      }}
      onSubmit={handleSubmit}
    >
      <IndexFilter>
        <Row>
          <Col md={4}>
            <Form.Group name="entityId" compact>
              <Form.Label width={5}>Health Home</Form.Label>
              <Form.SelectBox
                width={7}
                placeholder=""
                options={_.sortBy(healthHomes, "short_name").map((option) => ({
                  label: option.short_name,
                  value: option.id,
                }))}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group name="reportRowEntityIds" compact>
              <Form.Label width={5}>CMA</Form.Label>
              <Form.MultiSelectBox
                width={7}
                placeholder=""
                options={_.sortBy(allCareManagementAgencies, ["name"]).map((cma) => ({
                  label: cma.name,
                  value: cma.id,
                }))}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group name="date" compact>
              <Form.Label width={5}>Date</Form.Label>
              <Form.DatePicker width={7} />
            </Form.Group>
          </Col>
        </Row>
        <IndexFilter.ActionBar>
          <Form.SubmitButton label="Filter" disabled={isLoading} />
        </IndexFilter.ActionBar>
      </IndexFilter>
    </Form>
  )
}

export default connect(
  (state) => ({
    allCareManagementAgencies: getReportRowEntities(state),
    healthHomes: getEntities(state),
    initialCareManagementAgencyIds: getFilteredReportRowEntityIds(state),
    initialDate: getDate(state),
    initialHealthHomeId: getEntityId(state),
    status: getStatus(state),
  }),
  {
    setFilterParamsConnect: setFilterParams,
  }
)(Filters)
