import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_childrens_hcbs_workflow_linkable_documents_path } from "~/routes"

const childrensHCBSWorkflowLinkableDocuments = {
  get: function (childrensHCBSWorkflowId, childrensHCBSWorkflowStepId) {
    return submitAsyncRequest({
      url: api_v1_childrens_hcbs_workflow_linkable_documents_path(childrensHCBSWorkflowId, {
        childrens_hcbs_workflow_step_id: childrensHCBSWorkflowStepId,
      }),
      method: "GET",
    })
  },
}

export default childrensHCBSWorkflowLinkableDocuments
