import _ from "lodash"

import { CARE_PLAN_ENTITIES_DELETED } from "~/components/carePlans/carePlanActions"
import { CARE_PLAN_TASK_CREATED, CARE_PLAN_TASK_UPDATED } from "~/components/carePlans/carePlanTaskActions"
import { deleteSiblingNodes } from "~/components/carePlans/entityTreeTransformer"
import { MCO_GAP_IN_CARE_UPDATED } from "~/components/carePlans/mcoGapInCareActions"

const updateGapIdsReducer = (mcoGapInCare) => (updatedTasks, task, taskId) => {
  let mcoGapInCareIds
  if (_.includes(mcoGapInCare.carePlanTaskIds, _.parseInt(taskId))) {
    mcoGapInCareIds = _.union(task.mcoGapInCareIds, [mcoGapInCare.id])
  } else {
    mcoGapInCareIds = _.without(task.mcoGapInCareIds, mcoGapInCare.id)
  }

  return {
    ...updatedTasks,
    [taskId]: { ...task, mcoGapInCareIds },
  }
}

export default function carePlanTasksReducer(state = {}, action) {
  switch (action.type) {
    case CARE_PLAN_TASK_CREATED:
    case CARE_PLAN_TASK_UPDATED:
      return { ...state, [action.task.id]: action.task }
    case CARE_PLAN_ENTITIES_DELETED:
      return deleteSiblingNodes(state, action.taskIds)
    case MCO_GAP_IN_CARE_UPDATED:
      return _.reduce(state, updateGapIdsReducer(action.mcoGapInCare), {})
    default:
      return state
  }
}
