import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_health_home_quality_dashboard_path } from "~/routes"

const healthHomeQualities = {
  get: function (healthHomeId, category, date) {
    return submitAsyncRequest({
      url: api_v1_health_home_quality_dashboard_path(healthHomeId),
      type: "get",
      data: { category, date },
    }).then((res) => res.map(columnValues))
  },
}

function columnValues(row) {
  return {
    entity: row.entity,
    enrolled: row.enrolledCount,
    pastGracePeriod: row.pastGracePeriodCount,
    missing: row.missingCount,
    expired: row.expiredCount,
    valid: row.compliantCount,
    completionPercentage: row.completionPercentage,
  }
}

export default healthHomeQualities
