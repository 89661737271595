import { Table } from "react-bootstrap"
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import spinner from "images/spinner.svg"
import PropTypes from "prop-types"

import SegmentSyncStatus from "./SegmentSyncStatus"

import PopoverButton from "~/components/PopoverButton"
import formattedDate from "~/dateHelpers"
import Jumbotron from "~/design/Jumbotron"
import api from "~/services/api"

function SegmentsTable({ patientId }) {
  const { isLoading, data: segments } = useQuery({
    queryKey: ["segments", patientId],
    queryFn: () => api.accessibleViaPatients.segments.get(patientId),
  })

  if (isLoading) {
    return (
      <div className="text-center p-3">
        <img width="50px" height="50px" src={spinner} alt="Loading..." />
      </div>
    )
  } else if (segments.length === 0) {
    return <Jumbotron message="This patient has no segments." />
  } else {
    return (
      <Table
        striped
        bordered
        className="table-light-header table-internally-unbordered"
        data-testid="segment-history-table"
      >
        <thead>
          <tr>
            <th>Reporting Status</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Segment Type</th>
            <th>Health Home</th>
            <th>Care Management Agency</th>
            <th>Referral Code</th>
            <th>End Date Reason Code</th>
          </tr>
        </thead>
        <tbody>
          {segments.map((segment) => {
            return (
              <tr key={segment.id}>
                <td>
                  <SegmentSyncStatus reportingStatus={segment.reportingStatus} />
                </td>
                <td>{formattedDate(segment.startDate)}</td>
                <td>{formattedDate(segment.endDate)}</td>
                <td>
                  {segment.outreachEnrollmentCodeName} {segment.pended && " (Pended)"}
                </td>
                <td>{segment.healthHome.name}</td>
                <td>{segment.careManagementAgency.name}</td>
                <td>{segment.referralCode}</td>
                <td>
                  <PopoverButton label={segment.endDateReasonCode}>{segment.endDateReasonCodeName}</PopoverButton>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}

SegmentsTable.propTypes = {
  patientId: PropTypes.number.isRequired,
}

const queryClient = new QueryClient()

function Provider(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <SegmentsTable {...props} />
    </QueryClientProvider>
  )
}

export default Provider
