import { connect } from "react-redux"

import { carePlanNeedFlashKey } from "~/components/carePlans/carePlanNeedActions"
import DateDetail from "~/components/carePlans/DateDetail"
import EditButton from "~/components/carePlans/EditButton"
import { getCarePlanNeedStatuses, getNeedCategories } from "~/components/carePlans/globalsSelectors"
import Notes from "~/components/carePlans/Notes"
import DividerList from "~/components/DividerList"
import FlashMessage from "~/components/FlashMessage"
import { selectedOption } from "~/reactSelectHelpers"

function NeedDetails({ need, needCategories, carePlanNeedStatuses, onEditClick }) {
  const { description, notes, startDate, resolutionDate, carePlanNeedStatusId } = need

  const status = selectedOption(carePlanNeedStatuses, carePlanNeedStatusId)
  const { carePlanNeedCategoryId } = need
  const category = selectedOption(needCategories, carePlanNeedCategoryId)

  return (
    <div className="need">
      <div className="row">
        <div className="col-md-9">
          <div className="row">
            <strong className="col-md-2 need-title">Need</strong>
            <div className="col-md-10">
              <div className="need-description">{description}</div>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-right need-category">
          <span className="badge badge-secondary">{category ? category.label : "Uncategorized"}</span>
          <div className="need-status">
            {status.label !== "Active" && <strong className="status">{status.label}</strong>}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 need-dates">
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-10 need-subtitle">
              <DividerList>
                <DateDetail date={startDate} className="need-start-date" label="Active" />
                {resolutionDate && (
                  <DateDetail date={resolutionDate} className="need-resolution-date" label="Resolved" />
                )}
                {notes && <Notes entityId={need.id} entityName="need" notes={notes} />}
              </DividerList>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-right">
          <FlashMessage flashKey={carePlanNeedFlashKey(need)} className="need-flash-message">
            Saved <i className="fa fa-check" />
          </FlashMessage>
          <EditButton onClick={onEditClick} label="Edit need" />
        </div>
      </div>
    </div>
  )
}

export default connect((state) => ({
  needCategories: getNeedCategories(state),
  carePlanNeedStatuses: getCarePlanNeedStatuses(state),
}))(NeedDetails)
