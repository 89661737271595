import PropTypes from "prop-types"

import { redirectTo } from "~/browserHelpers"
import { open_past_due_tasks_path } from "~/routes"
import types from "~/types"
import AutoSubmitForm from "~/views/shared/autoSubmitForm/AutoSubmitForm"

function Filter({ currentCareManagerId, careManagers }) {
  function handleSubmit({ careManagerId }) {
    redirectTo(open_past_due_tasks_path({ filter: { care_manager: { id: careManagerId } } }))
  }

  return (
    <AutoSubmitForm initialValues={{ careManagerId: currentCareManagerId }} onSubmit={handleSubmit}>
      <AutoSubmitForm.SelectBox label="View Tasks for Patients of:" name="careManagerId" options={careManagers} />
    </AutoSubmitForm>
  )
}

Filter.propTypes = {
  currentCareManagerId: PropTypes.number,
  careManagers: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default Filter
