import { Fragment, useState } from "react"
import { connect } from "react-redux"
import pluralize from "pluralize"

import { getMcoGapsInCare } from "~/components/carePlans/mcoGapsInCareSelectors"
import LinkActiveTasksForm from "~/components/linkedCarePlanTasks/LinkActiveTasksForm"
import NewTaskForm from "~/components/linkedCarePlanTasks/NewTaskForm"
import { LinkButton } from "~/design/buttons"

const LinkedTaskDetails = ({ carePlan, mcoGapInCare }) => {
  const { carePlanTaskIds, allowNewTaskLinkage } = mcoGapInCare
  const linkedTaskCount = carePlanTaskIds.length
  const NEW_TASK_MODAL = "NEW_TASK_MODAL"
  const LINK_TASK_MODAL = "LINK_TASK_MODAL"
  const [showModal, setModalVisibility] = useState(false)
  const closeModal = () => setModalVisibility(false)

  return (
    <Fragment>
      <p className={linkedTaskCount === 0 ? "text-muted" : ""}>
        {`${pluralize("task", linkedTaskCount, true)} linked`}
      </p>
      {allowNewTaskLinkage && (
        <Fragment>
          <span>
            <LinkButton size="sm" onClick={() => setModalVisibility(LINK_TASK_MODAL)}>
              Link Tasks
            </LinkButton>
            {" · "}
            <LinkButton size="sm" onClick={() => setModalVisibility(NEW_TASK_MODAL)}>
              Add New
            </LinkButton>
          </span>
          <LinkActiveTasksForm
            show={showModal === LINK_TASK_MODAL}
            onClose={closeModal}
            carePlan={carePlan}
            mcoGapInCare={mcoGapInCare}
          />
          <NewTaskForm
            show={showModal === NEW_TASK_MODAL}
            onClose={closeModal}
            carePlan={carePlan}
            defaultSelectedGapInCare={mcoGapInCare}
            linkedGapInCareFieldInitialVisibility={true}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default connect((state, ownProps) => ({ mcoGapInCare: getMcoGapsInCare(state)[ownProps.nodeGapId] }))(
  LinkedTaskDetails
)
