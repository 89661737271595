import { useState } from "react"
import { Modal as BootstrapModal } from "react-bootstrap"
import PropTypes from "prop-types"

/**
 * A `<Modal />` is typically used when the user has to perform an action, but maintain their location in the existing workflow.
 *
 * There are two main types of modals that are used on the platform: form modals and confirmation dialog modals.
 */
function Modal({ show, onClose, title, size, dataTestId, children, scrollable }) {
  return (
    <BootstrapModal show={show} onHide={onClose} size={size} data-testid={dataTestId} scrollable={scrollable}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title as="h2">{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      {children}
    </BootstrapModal>
  )
}

Modal.propTypes = {
  /**
   * The contents of the modal.
   *
   * Visible elements within the modal should be constrained to components scoped to the Modal
   * component (i.e. only a Modal.Body and optionally a Modal.Footer)
   */
  children: PropTypes.node.isRequired,
  /**
   * A callback fired when the close button or static backdrop is clicked.
   * Should be used to set whatever is setting the `show` state of the modal to false.
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Text to display in the modal's header.
   */
  title: PropTypes.string.isRequired,
  /**
   * Makes the body of the modal scrollable instead of the entire modal when content overflows.
   */
  scrollable: PropTypes.bool,
  /**
   * Controls the visibility of the modal. Typically handled via state outside in a parent
   * component of the modal.
   */
  show: PropTypes.bool,
  /**
   * Set the maximum size of the modal.
   */
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  /**
   * Optionally adds a data-testid attribute to the modal HTML element.
   */
  dataTestId: PropTypes.string,
}

Modal.defaultProps = {
  scrollable: false,
  show: false,
}

function ModalBody({ children }) {
  return <BootstrapModal.Body>{children}</BootstrapModal.Body>
}

ModalBody.propTypes = {
  /**
   * The contents of the modal body.
   */
  children: PropTypes.node.isRequired,
}

function ModalFooter({ children }) {
  return <BootstrapModal.Footer>{children}</BootstrapModal.Footer>
}

ModalFooter.propTypes = {
  /**
   * The contents of the modal footer. This should only include action buttons
   * such as a PrimaryButton to submit a form, or a SecondaryButton to cancel/close
   * the modal.
   */
  children: PropTypes.node.isRequired,
}

Modal.Body = ModalBody
Modal.Footer = ModalFooter

const OPEN = "OPEN"
const CLOSED = "CLOSED"

function useModal(initialState) {
  const [value, setValue] = useState(initialState)
  const state = value ? OPEN : CLOSED

  function open() {
    setValue(true)
  }

  function close() {
    setValue(false)
  }

  function toggle() {
    setValue((curr) => !curr)
  }

  return [value, { open, close, toggle, state }]
}

export default Modal
export { useModal }
