import { OverlayTrigger, Tooltip } from "react-bootstrap"
import PropTypes from "prop-types"

import { ListIcon } from "~/design/icons"

function Notes({ entityId, entityName, notes }) {
  const tooltipId = `${entityName}-notes-${entityId}`
  const tooltip = <Tooltip id={tooltipId}>{notes}</Tooltip>

  return (
    <OverlayTrigger placement="bottom" overlay={tooltip}>
      <span className={`${entityName}-notes`} data-testid={tooltipId}>
        <ListIcon />
      </span>
    </OverlayTrigger>
  )
}

Notes.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityName: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
}

export default Notes
