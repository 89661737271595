import { redirectTo } from "~/browserHelpers"
import { WarningAlert } from "~/design/alerts"
import Form from "~/design/forms"
import { care_plan_path, chart_care_plans_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function New({ carePlan, chart }) {
  async function handleSubmit(values, { setStatus, setErrors }) {
    try {
      const { id } = await api.carePlans.post(chart.id, values)
      setStatus("success")
      redirectTo(care_plan_path(id))
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={carePlan} onSubmit={handleSubmit}>
      <Form.Group name="effectiveDate">
        <Form.Label>Start Date</Form.Label>
        <Form.DatePicker helpText="The date at which this care plan was first put into effect." />
      </Form.Group>
      <WarningAlert>
        <p>
          Creating a new care plan will result in all other care plans for this member associated with{" "}
          <strong>{chart.healthHomeName}</strong> and <strong>{chart.careManagementAgencyName}</strong> being marked as{" "}
          <strong>inactive</strong>.
        </p>
      </WarningAlert>
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_care_plans_path(chart.id))} />
            <Form.SubmitButton label="Create Care Plan" />
          </>
        }
      />
    </Form>
  )
}

New.propTypes = {
  carePlan: types.carePlan.isRequired,
  chart: types.chart.isRequired,
}

export default New
