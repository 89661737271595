import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_care_team_membership_path, api_v1_care_team_memberships_path } from "~/routes"

const careTeamMemberships = {
  post: function (chartId, params) {
    return submitAsyncRequest({
      url: api_v1_care_team_memberships_path(),
      type: "post",
      data: { chartId, membership: params },
    })
  },
  patch: function (membershipId, params) {
    return submitAsyncRequest({
      url: api_v1_care_team_membership_path(membershipId),
      type: "patch",
      data: { membership: params },
    })
  },
  delete: function (membershipId) {
    return submitAsyncRequest({
      url: api_v1_care_team_membership_path(membershipId),
      type: "delete",
    })
  },
}

export default careTeamMemberships
