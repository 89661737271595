import createCachedSelector from "re-reselect"

import { getCarePlanTaskStatuses, getTaskResponsibilities } from "~/components/carePlans/globalsSelectors"
import { getMcoGapsInCare } from "~/components/carePlans/mcoGapsInCareSelectors"
import { selectedOption } from "~/reactSelectHelpers"

export const getTasks = ({ entities: { tasks } }) => tasks
export const getTask = ({ entities: { tasks } }, { taskId }) => tasks[taskId]

const taskWithMappedOptions = (task, carePlanTaskStatuses, taskResponsibilities, mcoGapsInCare) => {
  const responsibility = selectedOption(taskResponsibilities, task.taskResponsibilityId)

  return {
    ...task,
    status: selectedOption(carePlanTaskStatuses, task.carePlanTaskStatusId),
    responsibility,
    responsibilityName:
      responsibility && (responsibility.isOther ? task.taskResponsibilityOther : responsibility.label),
    mcoGapInCare: mcoGapsInCare && mcoGapsInCare[task.mcoGapInCareId],
  }
}

export const getTaskWithOptions = createCachedSelector(
  [getTask, getCarePlanTaskStatuses, getTaskResponsibilities, getMcoGapsInCare],
  taskWithMappedOptions
)((state, { taskId }) => taskId)

export const getFormTask = ({ forms }, { formKey }) => forms[formKey]

export const getFormTaskWithOptions = createCachedSelector(
  [getFormTask, getCarePlanTaskStatuses, getTaskResponsibilities],
  taskWithMappedOptions
)((state, { formKey }) => formKey)
