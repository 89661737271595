import PropTypes from "prop-types"

const customAssessmentResponse = PropTypes.shape({
  id: PropTypes.number,
  completedByName: PropTypes.string,
  completedAt: PropTypes.string,
  responseQuestions: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    })
  ),
})

export default customAssessmentResponse
