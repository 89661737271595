import { submitAsyncRequest } from "~/asyncHelpers"
import {
  api_v1_supervisory_team_ces_tools_requiring_approval_path,
  api_v1_supervisory_team_member_path,
  api_v1_supervisory_team_members_path,
  api_v1_supervisory_team_path,
  api_v1_supervisory_team_pending_care_plan_review_requests_path,
  api_v1_supervisory_team_supervisor_path,
  api_v1_supervisory_team_supervisors_path,
  api_v1_supervisory_teams_path,
} from "~/routes"

const supervisoryTeams = {
  members: {
    post: function (teamId, values) {
      return submitAsyncRequest({
        url: api_v1_supervisory_team_members_path(teamId),
        type: "post",
        data: values,
        jsonData: true,
      })
    },
    delete: function (teamId, memberId) {
      return submitAsyncRequest({
        url: api_v1_supervisory_team_member_path(teamId, memberId),
        type: "delete",
      })
    },
  },
  supervisors: {
    post: function (teamId, values) {
      return submitAsyncRequest({
        url: api_v1_supervisory_team_supervisors_path(teamId),
        type: "post",
        data: values,
        jsonData: true,
      })
    },
    delete: function (teamId, supervisorId) {
      return submitAsyncRequest({
        url: api_v1_supervisory_team_supervisor_path(teamId, supervisorId),
        type: "delete",
      })
    },
  },
  teams: {
    post: function (team) {
      return submitAsyncRequest({
        url: api_v1_supervisory_teams_path(),
        type: "post",
        data: { team },
        jsonData: true,
      })
    },
    patch: function (id, team) {
      return submitAsyncRequest({
        url: api_v1_supervisory_team_path(id),
        type: "patch",
        data: { team },
        jsonData: true,
      })
    },
    delete: function (id) {
      return submitAsyncRequest({
        url: api_v1_supervisory_team_path(id),
        type: "delete",
      })
    },
  },
  cesToolsRequiringApproval: {
    get: function (teamId) {
      return submitAsyncRequest({
        url: api_v1_supervisory_team_ces_tools_requiring_approval_path(teamId),
        type: "get",
      })
    },
  },
  pendingCarePlanReviewRequests: {
    get: function (teamId) {
      return submitAsyncRequest({
        url: api_v1_supervisory_team_pending_care_plan_review_requests_path(teamId),
        type: "get",
      })
    },
  },
}

export default supervisoryTeams
