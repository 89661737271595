import { Component } from "react"
import { Provider } from "react-redux"
import _ from "lodash"
import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import thunk from "redux-thunk"

class EntryPoint extends Component {
  static get defaultReducers() {
    return {
      globals: (state = {}) => state,
      entities: (state = {}) => state,
      ui: (state = {}) => state,
    }
  }

  get reducers() {
    return combineReducers(_.assign(EntryPoint.defaultReducers, this.props.reducers))
  }

  get defaultPreloadedState() {
    return {
      entities: this.props.childProps.entities,
      globals: this.props.childProps.globals,
    }
  }

  get mappedPreloadedState() {
    return this.props.mapPropsToPreloadedState(this.props.childProps)
  }

  get preloadedState() {
    return _.merge(this.defaultPreloadedState, this.mappedPreloadedState)
  }

  get store() {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    if (!this._store) {
      this._store = createStore(this.reducers, this.preloadedState, composeEnhancers(applyMiddleware(thunk)))
    }
    return this._store
  }

  render() {
    return <Provider store={this.store}>{this.props.children}</Provider>
  }
}

export default EntryPoint
