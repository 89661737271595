import PropTypes from "prop-types"

import {
  DATE_QUESTION_TYPE_ID,
  isAnswerableQuestion,
  LONG_TEXT_QUESTION_TYPE_ID,
  MULTI_SELECT_QUESTION_TYPE_ID,
  SHORT_TEXT_QUESTION_TYPE_ID,
  SINGLE_SELECT_QUESTION_TYPE_ID,
} from "../helpers/questionHelpers"
import * as customAssessmentResponseTypes from "../types"

import ResponseQuestionCard from "./ResponseQuestionCard"

import formattedDate from "~/dateHelpers"
import Show from "~/design/Show"

function questionValues(question, questionResponseValue) {
  switch (question.questionTypeId) {
    case DATE_QUESTION_TYPE_ID:
      return [formattedDate(questionResponseValue)]
    case SINGLE_SELECT_QUESTION_TYPE_ID:
      return [question.options.find(({ id }) => id === questionResponseValue)?.optionText]
    case MULTI_SELECT_QUESTION_TYPE_ID:
      return question.options.filter(({ id }) => questionResponseValue.includes(id)).map((option) => option.optionText)
    case SHORT_TEXT_QUESTION_TYPE_ID:
    case LONG_TEXT_QUESTION_TYPE_ID:
      return [questionResponseValue]
    default:
      throw new Error("Invalid question type")
  }
}

function ReadOnlyResponseQuestion({ dependsOnQuestion, question, questionNumber, questionResponseValues }) {
  const questionResponseValue = questionResponseValues[question.questionResponseId]

  const dependsOnQuestionResponseValue = dependsOnQuestion
    ? questionResponseValues[dependsOnQuestion.questionResponseId]
    : null
  const isAnswerable = isAnswerableQuestion(question, dependsOnQuestion, dependsOnQuestionResponseValue)

  return (
    <ResponseQuestionCard questionId={question.id} questionNumber={questionNumber} isAnswerable={isAnswerable}>
      {question.questionText}
      <Show when={isAnswerable && question.isRequired}>
        <span className="text-danger">
          &nbsp;*<span className="sr-only">Required</span>
        </span>
      </Show>
      <Show when={isAnswerable && Boolean(questionResponseValue)}>
        <ul className="mb-0">
          {questionValues(question, questionResponseValue).map((value) => (
            <li key={value}>{value}</li>
          ))}
        </ul>
      </Show>
    </ResponseQuestionCard>
  )
}

ReadOnlyResponseQuestion.propTypes = {
  dependsOnQuestion: customAssessmentResponseTypes.schemaQuestion,
  question: customAssessmentResponseTypes.schemaQuestion.isRequired,
  questionNumber: PropTypes.number.isRequired,
  questionResponseValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired
  ),
}

export default ReadOnlyResponseQuestion
