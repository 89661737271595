import { MCO_GAP_IN_CARE_UPDATED, MCO_GAPS_IN_CARE_FETCHED } from "~/components/carePlans/mcoGapInCareActions"

export default function mcoGapsInCareReducer(state = {}, action) {
  switch (action.type) {
    case MCO_GAPS_IN_CARE_FETCHED:
      return {
        ...state,
        ...action.mcoGapsInCare,
      }
    case MCO_GAP_IN_CARE_UPDATED:
      return {
        ...state,
        [action.mcoGapInCare.id]: action.mcoGapInCare,
      }
    default:
      return state
  }
}
