import { Table } from "react-bootstrap"
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import spinner from "images/spinner.svg"
import PropTypes from "prop-types"

import formattedDate from "~/dateHelpers"
import Jumbotron from "~/design/Jumbotron"
import api from "~/services/api"

function EnrollmentRecordsTable({ patientId }) {
  const { isLoading, data: enrollmentRecords } = useQuery({
    queryKey: ["enrollmentRecords", patientId],
    queryFn: () => api.accessibleViaPatients.enrollmentRecords.get(patientId),
  })

  if (isLoading) {
    return (
      <div className="text-center p-3">
        <img width="50px" height="50px" src={spinner} alt="Loading..." />
      </div>
    )
  } else if (enrollmentRecords.length === 0) {
    return <Jumbotron message="This patient has no enrollment records." />
  } else {
    return (
      <Table
        striped
        bordered
        className="table-light-header table-internally-unbordered"
        data-testid="enrollment-record-history-table"
      >
        <thead>
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Segment Type</th>
            <th>Status</th>
            <th>Health Home</th>
            <th>Care Management Agency</th>
            <th>Referral Code</th>
            <th>End Date Reason Code</th>
          </tr>
        </thead>
        <tbody>
          {enrollmentRecords.map((enrollmentRecord) => {
            return (
              <tr key={enrollmentRecord.id}>
                <td>{formattedDate(enrollmentRecord.beginDate)}</td>
                <td>{formattedDate(enrollmentRecord.endDate)}</td>
                <td>{enrollmentRecord.outreachEnrollmentCode}</td>
                <td>{enrollmentRecord.status}</td>
                <td>{enrollmentRecord.healthHome ? enrollmentRecord.healthHome.name : ""}</td>
                <td>{enrollmentRecord.careManagementAgency ? enrollmentRecord.careManagementAgency.name : ""}</td>
                <td>{enrollmentRecord.referralCode}</td>
                <td>{enrollmentRecord.disenrollmentReasonCode}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}

EnrollmentRecordsTable.propTypes = {
  patientId: PropTypes.number.isRequired,
}

const queryClient = new QueryClient()

function Provider(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <EnrollmentRecordsTable {...props} />
    </QueryClientProvider>
  )
}

export default Provider
