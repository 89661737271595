import PropTypes from "prop-types"

import useCesToolSchema from "../hooks/useCesToolSchema"

import QuestionCard from "./QuestionCard"
import Section from "./Section"

import Form from "~/design/forms"
import Show from "~/design/Show"
import types from "~/types"

function MemberEngagementSection({ cesToolQuestionResponseOptions }) {
  const { isFieldAnswerable } = useCesToolSchema()

  return (
    <Show when={isFieldAnswerable("memberHasNonMaintenanceGoalsLeftOnPocId")}>
      <Section id="ces-tool-section-member-engagement" title="Member Engagement">
        <p className="mb-3">
          <strong>
            A "Yes" answer for ALL THREE questions ends the screening and generates a RECOMMEND CONTINUED SERVICES
            result.
          </strong>
        </p>
        <p className="mb-3">
          <strong>Any "No" answer ends the screening and generates a RECOMMEND DISENROLLMENT result.</strong>
        </p>
        <p className="mb-3">
          <strong>Any "Unclear" answer ends the screening and generates a MORE INFORMATION NEEDED result.</strong>
        </p>
        <p className="mb-3">
          Members with legal guardians may not be able to engage actively with the CM, and progress on HH POC goals may
          look different than for other members. For example: A member with severe dementia may not be able to talk
          directly to the HHCM, and the HHCM's primary engagement may be with the member's adult child. The "Member
          Engagement" questions may be considered as "Caregiver Engagement" questions in these cases.
        </p>
        <QuestionCard questionNumber={1}>
          <Form.Group name="memberHasNonMaintenanceGoalsLeftOnPocId">
            <Form.Label>Does the member have non-maintenance goals left to accomplish on the POC?</Form.Label>
            <Form.SelectBox
              isClearable
              options={cesToolQuestionResponseOptions}
              helpText="Maintenance goals are goals that the member has technically met, but they are ongoing and do not require any active assistance from the HHCM. Example: 'Member will continue to fill their medication monthly.'"
            />
          </Form.Group>
        </QuestionCard>
        <Show when={isFieldAnswerable("memberHasActivelyEngagedInLastSixMonthsId")}>
          <QuestionCard questionNumber={2}>
            <Form.Group name="memberHasActivelyEngagedInLastSixMonthsId">
              <Form.Label>
                Has the member been actively engaged and working with the CM on their HH POC Goals and Tasks in the last
                six months?
              </Form.Label>
              <Form.SelectBox
                isClearable
                options={cesToolQuestionResponseOptions}
                helpText="A member is not actively engaged if they are only in touch with the HHCM to say they are busy, or will call them back, or if Core Services are regularly provided to the Care Team because the member was unavailable. A member is not working with the HHCM on their HH POC Goals and Tasks if during their contacts they are solely updating the HHCM on their life or addressing something not on the POC."
              />
            </Form.Group>
          </QuestionCard>
        </Show>
        <Show when={isFieldAnswerable("memberHasMadeProgressInLastSixMonthsId")}>
          <QuestionCard questionNumber={3}>
            <Form.Group name="memberHasMadeProgressInLastSixMonthsId">
              <Form.Label>
                Have the member and Care Manager been making progress on their HH POC Goals and Tasks in the last six
                months?
              </Form.Label>
              <Form.SelectBox
                isClearable
                options={cesToolQuestionResponseOptions}
                helpText="A member is and HHCM are not making progress on the HH POC Goals and Tasks if the same task is being attempted month after month and nothing changes or moves forward."
              />
            </Form.Group>
          </QuestionCard>
        </Show>
      </Section>
    </Show>
  )
}

MemberEngagementSection.propTypes = {
  cesToolQuestionResponseOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default MemberEngagementSection
