import { Col, Row } from "react-bootstrap"
import classNames from "classnames"
import { useFormikContext } from "formik"
import _ from "lodash"
import uniqid from "uniqid"

import DividerList from "~/components/DividerList"
import { LinkButton } from "~/design/buttons"
import IconButton from "~/design/buttons/IconButton"
import Form from "~/design/forms"
import CloseIcon from "~/design/icons/CloseIcon"
import Show from "~/design/Show"

function StrengthsFormFields() {
  const {
    values: { strengths },
    setFieldValue,
  } = useFormikContext()

  function removeUnpersistedStrength(id) {
    setFieldValue("strengths", _.omit(strengths, id))
  }

  function addStrength() {
    const newStrengthKey = _.camelCase(uniqid())
    setFieldValue("strengths", {
      ...strengths,
      [newStrengthKey]: {
        description: "",
        active: false,
      },
    })
  }

  const [persistedStrengthIds, unpersistedStrengthIds] = _.partition(Object.keys(strengths), (id) => strengths[id].id)

  return (
    <>
      <Show when={persistedStrengthIds.length > 0}>
        <div className="d-none d-md-block mb-3">Strength</div>
        <DividerList Divider={() => <hr className="mt-0 mb-3" />}>
          {persistedStrengthIds.map((id) => {
            const disabled = strengths[id].wasDeleted
            return (
              <Row
                className={classNames("align-items-center", { "bg-light": disabled })}
                data-testid={`strengths-form-fields-${_.kebabCase(id)}`}
                key={id}
              >
                <Col md={8}>
                  <Form.Group name={`strengths.${id}.description`}>
                    <Form.Label hidden="md">Strength</Form.Label>
                    <Form.TextArea disabled={disabled} placeholder="Enter strength description" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group name={`strengths.${id}.active`}>
                    <Form.CheckBox disabled={disabled} label="Active" />
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group name={`strengths.${id}.wasDeleted`}>
                    <Form.MarkForDelete />
                  </Form.Group>
                </Col>
              </Row>
            )
          })}
        </DividerList>
      </Show>
      <h2 className="my-3">Add Strengths</h2>
      <Show when={unpersistedStrengthIds.length > 0}>
        <div className="d-none d-md-block mb-3">Strength</div>
        <DividerList Divider={() => <hr className="mt-0 mb-3" />}>
          {unpersistedStrengthIds.map((id) => (
            <Row className="align-items-center" data-testid={`strengths-form-fields-${_.kebabCase(id)}`} key={id}>
              <Col md={8}>
                <Form.Group clearOnHide={false} name={`strengths.${id}.description`}>
                  <Form.Label hidden="md">Strength</Form.Label>
                  <Form.TextArea placeholder="Enter strength description" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group clearOnHide={false} name={`strengths.${id}.active`}>
                  <Form.CheckBox label="Active" />
                </Form.Group>
              </Col>
              <Col md={1}>
                <div className="mb-3">
                  <IconButton
                    Icon={CloseIcon}
                    label="Remove strength row"
                    onClick={() => removeUnpersistedStrength(id)}
                  />
                </div>
              </Col>
            </Row>
          ))}
        </DividerList>
      </Show>
      <div className="mt-3">
        <LinkButton onClick={addStrength}>Add {strengths.length > 0 ? "another " : ""}strength</LinkButton>
      </div>
    </>
  )
}

export default StrengthsFormFields
