import _ from "lodash"
import PropTypes from "prop-types"

import TeamFormFields from "./TeamFormFields"

import { redirectTo } from "~/browserHelpers"
import DangerButton from "~/design/buttons/DangerButton"
import Form from "~/design/forms"
import useConfirmationModal from "~/design/modals/useConfirmationModal"
import { supervisory_team_path, supervisory_teams_path } from "~/routes"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function EditForm({ team, careManagementAgencies, deleteableByCurrentUser }) {
  const confirm = useConfirmationModal()

  async function handleSubmit(formValues, { setErrors, setStatus }) {
    try {
      const response = await api.supervisoryTeams.teams.patch(team.id, formValues)
      redirectTo(supervisory_team_path(response.id))
      setStatus("success")
    } catch ({ errors }) {
      // A supervisorTeam would be invalid if the user is attempting to change the CMA on a team with
      // existing supervisors who do not have access to the new CMA
      if (errors.supervisorTeams || errors.memberships) {
        errors.base = [
          ...(errors.base || []),
          "All supervisors and members must have access to the team's CMA. Try removing the team members first.",
        ]
      }
      const e = _.pick(errors, ["base", "name", "careManagementAgency"])
      setErrors(e)
    }
  }

  async function handleDelete() {
    const confirmed = await confirm.danger("The team will be permanently deleted.", {
      title: "Delete Team",
      confirmButtonLabel: "Delete",
    })

    if (confirmed) {
      try {
        await api.supervisoryTeams.teams.delete(team.id)
        redirectTo(supervisory_teams_path())
      } catch (e) {
        toast.error(`Could not delete this team: ${e.message}`)
      }
    }
  }

  return (
    <Form initialValues={team} onSubmit={handleSubmit}>
      <TeamFormFields careManagementAgencies={careManagementAgencies} />
      <Form.ActionBar
        left={
          <Form.Show when={() => deleteableByCurrentUser}>
            <DangerButton onClick={handleDelete}>Delete</DangerButton>
          </Form.Show>
        }
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(supervisory_team_path(team.id))} />
            <Form.SubmitButton label="Update Team" submittingLabel="Saving..." />
          </>
        }
      />
    </Form>
  )
}

EditForm.propTypes = {
  team: types.supervisoryTeam,
  careManagementAgencies: PropTypes.arrayOf(types.selectOption).isRequired,
  deleteableByCurrentUser: PropTypes.bool,
}

export default EditForm
