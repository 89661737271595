import AsyncSelect from "react-select/async"
import debounce from "debounce-promise"
import { useField } from "formik"
import PropTypes from "prop-types"

import CareTeamProviderDetails from "./CareTeamProviderDetails"

import FieldErrors from "~/design/forms/FieldErrors"
import { classNames } from "~/design/forms/selectBoxConfiguration"
import useErrorContext from "~/design/forms/useErrorContext"
import useNameContext from "~/design/forms/useNameContext"
import api from "~/services/api"

function CareTeamProviderSelector({ handleSelectProvider }) {
  const { id, name } = useNameContext()
  const [field] = useField({ name })
  const { hasError } = useErrorContext()

  const errorMessageId = `${id}Errors`

  const newProviderOption = {
    value: null,
    provider: null,
  }

  async function fetchResults(searchString) {
    if (!searchString) return

    const response = await api.careTeamProviders.get(searchString)
    return Object.values(response)
      .map((provider) => ({
        value: provider.id,
        provider,
      }))
      .concat(newProviderOption)
  }

  return (
    <>
      <AsyncSelect
        aria-errormessage={errorMessageId}
        aria-invalid={hasError}
        cacheOptions
        className="react-select"
        classNamePrefix="react-select"
        classNames={classNames}
        defaultOptions={[newProviderOption]}
        formatOptionLabel={({ provider }) =>
          provider ? (
            <CareTeamProviderDetails provider={provider} />
          ) : (
            <div data-testid="new-provider-option">
              <div>
                <strong>Provider does not exist.</strong>
              </div>
              <em>Create new provider</em>
            </div>
          )
        }
        id={`${id}SearchBox`}
        inputId={id}
        loadOptions={debounce(fetchResults, 400)}
        name={name}
        onChange={({ value, provider }) => handleSelectProvider({ providerId: value, provider })}
        placeholder="Start typing a provider name"
        value={field.value}
      />
      <FieldErrors id={errorMessageId} />
    </>
  )
}

CareTeamProviderSelector.propTypes = {
  handleSelectProvider: PropTypes.func.isRequired,
}

export default CareTeamProviderSelector
