import { createContext, useCallback, useContext, useRef, useState } from "react"
import PropTypes from "prop-types"

import { DangerButton, PrimaryButton, SecondaryButton } from "~/design/buttons"
import Modal from "~/design/Modal"

const ConfirmationModalContext = createContext()

const DEFAULT_STATE = {
  title: "",
  description: "",
  confirmButtonLabel: "",
  Button: PrimaryButton,
  isOpen: false,
}

function ConfirmationModalProvider({ children }) {
  const [{ title, description, Button, confirmButtonLabel, isOpen }, setModalState] = useState(DEFAULT_STATE)
  const resolveConfirmationFn = useRef()

  const generateConfirmationPromiseFn = useCallback((Button) => {
    return (description, { title = "Are you sure?", confirmButtonLabel = "Confirm" } = {}) => {
      return new Promise((resolve) => {
        setModalState({ isOpen: true, title, description, confirmButtonLabel, Button })

        resolveConfirmationFn.current = (confirmed) => {
          resolve(confirmed)
          setModalState((state) => ({ ...state, isOpen: false }))
        }
      })
    }
  }, [])

  return (
    <ConfirmationModalContext.Provider
      value={{
        primary: generateConfirmationPromiseFn(PrimaryButton),
        danger: generateConfirmationPromiseFn(DangerButton),
      }}
    >
      {children}
      <Modal
        title={title}
        show={isOpen}
        onClose={() => resolveConfirmationFn.current(false)}
        onExited={() => setModalState(DEFAULT_STATE)}
      >
        <Modal.Body>
          <p>{description}</p>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton onClick={() => resolveConfirmationFn.current(false)}>Cancel</SecondaryButton>
          <Button onClick={() => resolveConfirmationFn.current(true)}>{confirmButtonLabel}</Button>
        </Modal.Footer>
      </Modal>
    </ConfirmationModalContext.Provider>
  )
}

ConfirmationModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

function useConfirmationModal() {
  const confirmationModalContext = useContext(ConfirmationModalContext)
  if (!confirmationModalContext) {
    throw Error(`useConfirmationModal can only be called within an <ConfirmationModalProvider />.`)
  }
  return confirmationModalContext
}

export default useConfirmationModal
export { ConfirmationModalProvider }
