import { connect } from "react-redux"
import pluralize from "pluralize"

import { getSelectedMcoGapsInCare } from "~/components/carePlans/mcoGapsInCareSelectors"
import { PrimaryAlert } from "~/design/alerts"
import Show from "~/design/Show"

const LinkedGapInCareBanner = ({ mcoGapsInCare }) => {
  return (
    <Show when={mcoGapsInCare.length > 0}>
      <PrimaryAlert>
        Linked {pluralize("Gap", mcoGapsInCare.length, false)} in Care:
        <ul>
          {mcoGapsInCare.map((mcoGapInCare) => (
            <li key={mcoGapInCare.id}>
              <strong>{mcoGapInCare.description}</strong>
            </li>
          ))}
        </ul>
      </PrimaryAlert>
    </Show>
  )
}

export default connect((state, props) => ({
  mcoGapsInCare: getSelectedMcoGapsInCare(state, props),
}))(LinkedGapInCareBanner)
