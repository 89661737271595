import Icon from "./Icon"

/**
 * Renders an icon of a file.
 */
function FileIcon() {
  return <Icon name="file" />
}

export default FileIcon
