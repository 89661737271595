import PropTypes from "prop-types"

import { PrimaryButtonLink, SecondaryButton } from "~/design/buttons"
import Modal from "~/design/Modal"

function DownloadModalSync({ downloadPath, show, onClose }) {
  return (
    <Modal title="PHI Download Disclaimer" show={show} onClose={onClose}>
      <Modal.Body>
        <div className="small text-center">
          {/* NOTE: this text should be kept in sync with the PHI message in app/views/shared/_download_modal.html.haml */}
          The file you are about to download may contain privileged and confidential data, and/or Protected Health
          Information (PHI). It is intended solely for your use, and is subject to protection under the law as defined
          by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and its successor legislation,
          and/or by other relevant policies and regulations. By clicking OK you agree that you are aware of all relevant
          guidelines and restrictions on use of the information being downloaded, and that you have taken/will take all
          appropriate measures to protect and handle that information, including but not limited to ensuring that the
          device onto which the file is being downloaded has been encrypted, as appropriate. Any questions about the
          status of this data or the requirements related to its handling should be directed to your HIPAA
          Privacy/Security Officer, supervisor, or other appropriate staff at your agency. If you do not agree to these
          conditions, click Cancel.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButtonLink href={downloadPath}>OK, I Agree</PrimaryButtonLink>
      </Modal.Footer>
    </Modal>
  )
}

DownloadModalSync.propTypes = {
  downloadPath: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default DownloadModalSync
