import Icon from "./Icon"

/**
 * Renders an icon of a trash can, indicating a delete action.
 */
function DeleteIcon() {
  return <Icon name="trash" />
}

export default DeleteIcon
