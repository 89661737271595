function isAnswerYes(answer) {
  return answer === 1 // CESToolQuestionResponse::YES.id
}

function isAnswerNo(answer) {
  return answer === 2 // CESToolQuestionResponse::NO.id
}

function isAnswerUnclear(answer) {
  return answer === 3 // CESToolQuestionResponse::UNCLEAR.id
}

function isOutcomeRecommendContinuedServices(outcomeId) {
  return outcomeId === 1 // CESToolOutcome::RECOMMEND_CONTINUED_SERVICES.id
}

function isOutcomeRecommendDisenrollment(outcomeId) {
  return outcomeId === 2 // CESToolOutcome::RECOMMEND_DISENROLLMENT.id
}

function isOutcomeMoreInfoNeeded(outcomeId) {
  return outcomeId === 3 // CESToolOutcome::MORE_INFORMATION_NEEDED.id
}

function isOutcomeNotApplicable(outcomeId) {
  return outcomeId === 4 // CESToolOutcome::NOT_APPLICABLE.id
}

export {
  isAnswerNo,
  isAnswerUnclear,
  isAnswerYes,
  isOutcomeMoreInfoNeeded,
  isOutcomeNotApplicable,
  isOutcomeRecommendContinuedServices,
  isOutcomeRecommendDisenrollment,
}
