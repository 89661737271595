import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_ces_tool_path, api_v1_chart_ces_tools_path } from "~/routes"

const cesTools = {
  patch: function (cesToolId, cesTool) {
    return submitAsyncRequest({
      url: api_v1_ces_tool_path(cesToolId),
      type: "patch",
      data: { cesTool },
      jsonData: true,
    })
  },
  post: function (chartId, cesTool) {
    return submitAsyncRequest({
      url: api_v1_chart_ces_tools_path(chartId),
      type: "post",
      data: { cesTool },
      jsonData: true,
    })
  },
}

export default cesTools
