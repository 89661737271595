import { QUALITY_REPORT_RECEIVED } from "~/big3Dashboard/actions/qualityReportStatusActions"

function qualityReportEntitiesReducer(state = [], action) {
  switch (action.type) {
    case QUALITY_REPORT_RECEIVED:
      return action.report
    default:
      return state
  }
}

export default qualityReportEntitiesReducer
