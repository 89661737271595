import PropTypes from "prop-types"

import Show from "../Show"

import { iconStyle } from "./Icon"

function SortIcon({ sortDirection }) {
  const inactiveSortColor = "#d4d4d4" // $medium-gray

  return (
    <span style={iconStyle} className="fa-stack fa-stack-sm fa-fw">
      <i className="fa fa-stack-1x fa-sort" style={{ color: inactiveSortColor }} />
      <Show when={sortDirection === "asc"}>
        <i className="fa fa-sort-asc fa-stack-1x" />
      </Show>
      <Show when={sortDirection === "desc"}>
        <i className="fa fa-sort-desc fa-stack-1x" />
      </Show>
    </span>
  )
}

SortIcon.propTypes = {
  sortDirection: PropTypes.oneOf([false, "asc", "desc"]),
}

export default SortIcon
