import { createContext, Fragment, useContext } from "react"
import { Col as BootstrapCol } from "react-bootstrap"
import PropTypes from "prop-types"

const LayoutContext = createContext()

function useLayoutContext() {
  const layoutContext = useContext(LayoutContext)
  if (!layoutContext) {
    throw Error(
      `useLayoutContext can only be called within a <LayoutProvider />. ` +
        `Try rendering your component inside of a <Form />. `
    )
  }
  return layoutContext
}

function LayoutProvider({ vertical, children }) {
  return <LayoutContext.Provider value={{ vertical }}>{children}</LayoutContext.Provider>
}

LayoutProvider.propTypes = {
  vertical: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

LayoutProvider.defaultProps = {
  vertical: false,
}

function LayoutWrapper({ children, ...rest }) {
  const { vertical } = useLayoutContext()
  if (vertical) {
    return <Fragment children={children} />
  }
  return <BootstrapCol children={children} {...rest} />
}

LayoutWrapper.propTypes = {
  children: PropTypes.node,
}

export default useLayoutContext

export { LayoutProvider, LayoutWrapper }
