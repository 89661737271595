import _ from "lodash"
import { createSelector } from "reselect"

export const getCarePlanPriorities = ({ globals: { carePlanPriorities } }) => carePlanPriorities
export const getNormalCarePlanPriority = createSelector(getCarePlanPriorities, (priorities) =>
  _.find(priorities, { isNormal: true })
)

export const getCarePlanNeedStatuses = ({ globals: { carePlanNeedStatuses } }) => carePlanNeedStatuses
export const getCarePlanGoalStatuses = ({ globals: { carePlanGoalStatuses } }) => carePlanGoalStatuses
export const getCarePlanTaskStatuses = ({ globals: { carePlanTaskStatuses } }) => carePlanTaskStatuses

export const getPatientId = ({ globals: { patientId } }) => patientId
export const getNeedCategories = ({ globals: { needCategories } }) => needCategories
export const getTaskResponsibilities = ({ globals: { taskResponsibilities } }) => taskResponsibilities
export const getReadOnly = ({ globals: { readOnly } }) => readOnly
export const getDiagnosisValues = ({ globals: { diagnosisValues } }) => diagnosisValues
