import { Table } from "react-bootstrap"
import spinner from "images/spinner.svg"
import PropTypes from "prop-types"

import { useAddLinkedWorkflowDocument, useLinkableWorkflowDocuments } from "./useChildrensHCBSWorkflowDocuments"

import DividerList from "~/components/DividerList"
import PopoverButton from "~/components/PopoverButton"
import formattedDate from "~/dateHelpers"
import { PrimaryAlert } from "~/design/alerts"
import { LinkButton, SecondaryButton } from "~/design/buttons"
import Jumbotron from "~/design/Jumbotron"
import Modal from "~/design/Modal"
import { document_path } from "~/routes"
import { toast } from "~/toast"
import types from "~/types"

function LinkWorkflowDocumentModal({ childrensHCBSWorkflowId, childrensHCBSWorkflowStepId, onClose, show }) {
  const { data: documents, isLoading } = useLinkableWorkflowDocuments({
    childrensHCBSWorkflowId,
    childrensHCBSWorkflowStepId,
  })

  return (
    <Modal show={show} onClose={onClose} title="Link Existing Document" size="lg">
      <Modal.Body>
        <PrimaryAlert>
          Displaying all documents for this member's chart with a document type that matches this Children's HCBS
          workflow step that are not already linked to this Children's HCBS workflow.
        </PrimaryAlert>
        {isLoading ? (
          <div className="my-5 d-flex gap-1 justify-content-center align-items-center">
            <img width={50} height={50} src={spinner} alt="Loading..." />
            <span className="font-weight-bold">Loading...</span>
          </div>
        ) : (
          <DocumentsTable
            childrensHCBSWorkflowId={childrensHCBSWorkflowId}
            childrensHCBSWorkflowStepId={childrensHCBSWorkflowStepId}
            documents={documents}
            isLoading={isLoading}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton onClick={onClose}>Close</SecondaryButton>
      </Modal.Footer>
    </Modal>
  )
}

LinkWorkflowDocumentModal.propTypes = {
  childrensHCBSWorkflowId: PropTypes.number.isRequired,
  childrensHCBSWorkflowStepId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

function DocumentsTable({ childrensHCBSWorkflowId, childrensHCBSWorkflowStepId, documents }) {
  if (documents.length === 0) {
    return <Jumbotron message="No documents found that can be linked to this workflow step" />
  }

  return (
    <Table size="sm" bordered striped className="table-internally-unbordered">
      <thead>
        <tr>
          <th>Date</th>
          <th>Type</th>
          <th>Description</th>
          <th>
            <div className="d-flex align-items-center justify-content-end">
              Actions&nbsp;
              <PopoverButton label="?">
                Click "link" to link an existing document with this Children's HCBS Workflow step.
              </PopoverButton>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {documents.map((document) => (
          <DocumentRow
            key={document.id}
            childrensHCBSWorkflowId={childrensHCBSWorkflowId}
            childrensHCBSWorkflowStepId={childrensHCBSWorkflowStepId}
            document={document}
          />
        ))}
      </tbody>
    </Table>
  )
}

DocumentsTable.propTypes = {
  childrensHCBSWorkflowId: PropTypes.number.isRequired,
  childrensHCBSWorkflowStepId: PropTypes.number.isRequired,
  documents: PropTypes.arrayOf(types.document),
}

function DocumentRow({ childrensHCBSWorkflowId, childrensHCBSWorkflowStepId, document }) {
  const { mutateAsync, isLoading } = useAddLinkedWorkflowDocument(childrensHCBSWorkflowId)

  async function handleLinkToWorkflow() {
    try {
      const workflowDocumentParams = {
        childrensHCBSWorkflowStepId,
        documentId: document.id,
      }
      await mutateAsync(workflowDocumentParams)
      toast.success("Workflow document added successfully")
    } catch ({ errors }) {
      toast.error(
        <>
          <p>Error linking document:</p>
          <ul>
            {Object.values(errors).flatMap((errorMessages) =>
              errorMessages.map((message) => <li key={message}>{message}</li>)
            )}
          </ul>
        </>
      )
    }
  }

  return (
    <tr key={document.id}>
      <td>{formattedDate(document.date)}</td>
      <td>{document.documentTypeName}</td>
      <td>{document.description}</td>
      <td>
        <div className="d-flex align-items-center justify-content-end gap-1">
          <DividerList Divider={() => <span>|</span>}>
            <LinkButton isLoading={isLoading} loadingText="linking..." size="sm" onClick={handleLinkToWorkflow}>
              link
            </LinkButton>
            <a href={document_path(document.id)}>details</a>
          </DividerList>
        </div>
      </td>
    </tr>
  )
}

DocumentRow.propTypes = {
  childrensHCBSWorkflowId: PropTypes.number.isRequired,
  childrensHCBSWorkflowStepId: PropTypes.number.isRequired,
  document: types.document.isRequired,
}

export default LinkWorkflowDocumentModal
