import PropTypes from "prop-types"

import EnrollmentRecordsTable from "./components/EnrollmentRecordsTable"
import SegmentsTable from "./components/SegmentsTable"

import { LinkButton } from "~/design/buttons"
import { PrimaryButton } from "~/design/buttons"
import Modal from "~/design/Modal"
import { useModal } from "~/design/Modal"

function SegmentHistoryModal({ patientId }) {
  const [show, { close, open }] = useModal()

  return (
    <>
      <LinkButton onClick={open}>See Full Segment History</LinkButton>
      <Modal title="Segment History" show={show} onClose={close} size={"xl"}>
        <Modal.Body>
          <h2 className="pb-2">Segments</h2>
          <SegmentsTable patientId={patientId} />

          <br />

          <h2 className="pb-2">MAPP Enrollment Records</h2>
          <EnrollmentRecordsTable patientId={patientId} />
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton onClick={close}>Close</PrimaryButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

SegmentHistoryModal.propTypes = {
  patientId: PropTypes.number.isRequired,
}

export default SegmentHistoryModal
