import PropTypes from "prop-types"

// This value was calculated by taking the value of line-height(1.45) and font-size(.875)
// from the body times the default font size(16px).
const DEFAULT_LINE_HEIGHT = 20.3

// Unfortunately, because the .fa CSS class sets a new font-size, if you use ems
// exclusively it's based off of the FA 14px size, but the line height is still
// using the top-level size. The funky CSS here is just ensuring that up to 16px,
// our line height still matches normal text which centers the icon vertically.
// Above that, the line height will scale since we're no longer concerned with
// aligning next to text.
const iconStyle = {
  fontSize: `1em`,
  height: `1em`,
  width: `1em`,
  minHeight: `max(1em, ${DEFAULT_LINE_HEIGHT}px)`,
  minWidth: `max(1em, ${DEFAULT_LINE_HEIGHT}px)`,
  lineHeight: `max(1em, ${DEFAULT_LINE_HEIGHT}px)`,
}

function Icon({ name }) {
  return <i style={iconStyle} className={`fa fa-${name} text-center`} />
}

Icon.propTypes = {
  name: PropTypes.oneOf([
    "check",
    "chevron-down",
    "chevron-up",
    "child",
    "close",
    "exclamation-triangle",
    "expand",
    "external-link",
    "eye",
    "file",
    "folder-open",
    "heartbeat",
    "list",
    "link",
    "lock",
    "medkit",
    "minus",
    "minus-circle",
    "pencil",
    "plus",
    "plus-circle",
    "shield",
    "search",
    "tag",
    "trash",
    "exclamation-triangle",
    "undo",
    "user",
    "users",
    "user-md",
  ]),
}

export { iconStyle }
export default Icon
