import PropTypes from "prop-types"

import Form from "~/design/forms"
import types from "~/types"

function LinkedCarePlanGoalField({ goalOptions }) {
  function validateRequiredField(value) {
    if (!value) {
      return ["Required"]
    }
  }

  if (goalOptions.length === 0) {
    return <strong>This care plan has no goals.</strong>
  }

  return (
    <Form.Group name="goalId">
      <Form.Label>Select an active goal on the patient's current care plan:</Form.Label>
      <Form.SelectBox options={goalOptions} validate={validateRequiredField} />
    </Form.Group>
  )
}

LinkedCarePlanGoalField.propTypes = {
  goalOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default LinkedCarePlanGoalField
