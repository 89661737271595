/* eslint-disable react/no-danger */

import DOMPurify from "dompurify"

export function FormattedEncounterNotes({ notes, searchTerm }) {
  const sanitizedNotes = DOMPurify.sanitize(notes)

  return (
    <span
      className="patient-encounters-index-notes-teaser"
      dangerouslySetInnerHTML={{ __html: highlightSearchTerm(sanitizedNotes, searchTerm) }}
    />
  )
}

export function TruncatedFormattedEncounterNotes({ notes, searchTerm }) {
  const sanitizedNotes = DOMPurify.sanitize(notes)

  return (
    <span
      className="patient-encounters-index-notes-teaser"
      dangerouslySetInnerHTML={{ __html: notesTeaser(sanitizedNotes, searchTerm) }}
    />
  )
}

export const TEASER_LENGTH = 402

export function notesTeaser(notes, searchTerm) {
  if (notes.length === 0) {
    return ""
  }

  const upperCaseNotes = notes.toUpperCase()
  const upperCaseSearchTerm = searchTerm && searchTerm.toUpperCase()
  const searchTermFirstLocation = upperCaseNotes.indexOf(upperCaseSearchTerm)

  if (searchTermFirstLocation > -1) {
    return teaserWithHighlightedTerm(notes, searchTerm, searchTermFirstLocation)
  }

  return teaserWithoutHighlightedSearchTerm(notes)
}

export function teaserWithHighlightedTerm(notes, searchTerm, searchTermFirstLocation) {
  if (searchTermFirstLocation < TEASER_LENGTH) {
    const teaser = notes.slice(0, TEASER_LENGTH)
    return `${highlightSearchTerm(teaser, searchTerm)} ...`
  }

  const start = searchTermFirstLocation - TEASER_LENGTH / 2
  const end = searchTermFirstLocation + (TEASER_LENGTH / 2 - 1)
  const teaser = notes.slice(start, end)

  return `... ${highlightSearchTerm(teaser, searchTerm)} ...`
}

export function teaserWithoutHighlightedSearchTerm(notes) {
  if (notes.length > TEASER_LENGTH) {
    return `${notes.slice(0, TEASER_LENGTH)} ...`
  }

  return notes
}

export function highlightSearchTerm(notes, searchTerm) {
  const regex = new RegExp(searchTerm, "gi")

  return notes.replace(regex, (str) => `<strong>${str}</strong>`)
}
