import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_chart_pdf_assessments_path } from "~/routes"

const pdfAssessments = {
  post: function (chartId, assessment) {
    return submitAsyncRequest({
      url: api_v1_chart_pdf_assessments_path(chartId),
      type: "post",
      data: { assessment },
    })
  },
}

export default pdfAssessments
