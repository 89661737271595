import { format, lastDayOfMonth } from "date-fns"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import { Bar, BarChart, Cell, Legend, Tooltip, XAxis, YAxis } from "recharts"

import { transformChartData } from "../helpers"
import usePaymentSummaryReport from "../hooks/usePaymentSummaryReport"

import Jumbotron from "~/design/Jumbotron"
import Show from "~/design/Show"
import useQueryParams from "~/hooks/useQueryParams"
import { formatCompactNumber, formatCurrency, formatNumberWithCommas, formatPercentage } from "~/numberHelpers"
import { billing_claims_path } from "~/routes"
import types from "~/types"
import ChartContainer from "~/views/shared/graphicalCharts/ChartContainer"
import ChartLoadingSpinner from "~/views/shared/graphicalCharts/ChartLoadingSpinner"
import ChartTooltip from "~/views/shared/graphicalCharts/ChartTooltip"

const CLAIM_STATUS_COLOR_MAPPINGS = {
  2: "var(--warning)", // ClaimStatus::OUTSTANDING.id
  4: "var(--success)", // ClaimStatus::PAID.id
  5: "var(--danger)", // ClaimStatus::DENIED.id
}

function Report({ claimStatuses }) {
  const [queryParams] = useQueryParams()
  const { data, isLoading } = usePaymentSummaryReport()

  if (isLoading) {
    return <ChartLoadingSpinner />
  }

  if (Object.keys(data).length === 0) {
    return <Jumbotron message="No claims found" />
  }

  function goToDrillthru(claimStatusId, month) {
    const firstOfMonth = format(month, "yyyy-MM-01")
    const lastOfMonth = format(lastDayOfMonth(month), "yyyy-MM-dd")

    window.open(
      billing_claims_path({
        filter: {
          ...(queryParams?.filter || {}),
          date_of_service_on_or_after: firstOfMonth,
          date_of_service_on_or_before: lastOfMonth,
          claim_status_id: [claimStatusId],
        },
      }),
      "_blank"
    )
  }

  const barChartData = transformChartData(data)

  return (
    <ChartContainer>
      <BarChart data={barChartData}>
        <XAxis dataKey="month" tickFormatter={(month) => format(month, "M/yyyy")} />
        <YAxis tickFormatter={formatCompactNumber} />
        <Legend />
        <Tooltip shared={false} content={<CustomTooltip data={data} />} />
        {claimStatuses.map(({ label, value }) => (
          <Bar
            key={value}
            dataKey={label}
            fill={CLAIM_STATUS_COLOR_MAPPINGS[value]}
            stackId="a"
            onClick={({ month }) => goToDrillthru(value, month)}
            style={{ cursor: "pointer" }}
          >
            {barChartData.map(({ month }, index) => (
              <Cell key={index} aria-label={`${label} claims for ${format(month, "LLL yyyy")}`} />
            ))}
          </Bar>
        ))}
        <Bar dataKey="Adjusted" fill="var(--secondary)" stackId="a">
          {barChartData.map(({ month }, index) => (
            <Cell key={index} aria-label={`Adjusted claims for ${format(month, "LLL yyyy")}`} />
          ))}
        </Bar>
      </BarChart>
    </ChartContainer>
  )
}

Report.propTypes = {
  claimStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
}

function CustomTooltip({ active, data, payload }) {
  if (active && payload && payload.length) {
    const status = payload[0].dataKey
    const month = format(payload[0].payload.month, "yyyyMMdd")

    const numClaims = data[month].claimSummaries[status.toLowerCase()]?.numClaims || 0
    const amount = payload[0].payload[status]

    const totalCharges = parseFloat(data[month].totalCharges) || 0

    return (
      <ChartTooltip title={`${payload[0].dataKey} - ${format(payload[0].payload.month, "LLL yyyy")}`}>
        <Show when={status !== "Adjusted"}>
          <div>
            {formatNumberWithCommas(numClaims)} {pluralize("claim", numClaims)}
          </div>
        </Show>
        <div>{formatCurrency(amount)}</div>
        <em>
          {formatPercentage(amount / totalCharges || 0)} of {formatCurrency(totalCharges)} total charges for month
        </em>
      </ChartTooltip>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  data: PropTypes.objectOf(
    PropTypes.shape({
      totalCharges: PropTypes.string,
      claimSummaries: PropTypes.objectOf(
        PropTypes.shape({
          sumCharges: PropTypes.string,
          sumPayments: PropTypes.string,
          numClaims: PropTypes.number,
        })
      ),
    })
  ),
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string,
      payload: PropTypes.shape({
        month: PropTypes.instanceOf(Date),
      }),
    })
  ),
}

export default Report
