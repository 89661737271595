import PropTypes from "prop-types"
import { ResponsiveContainer } from "recharts"

function ChartContainer({ children }) {
  return (
    <div style={{ height: "400px" }} className="border p-2">
      <ResponsiveContainer width="100%" height="100%">
        {children}
      </ResponsiveContainer>
    </div>
  )
}

ChartContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ChartContainer
