import { useMemo } from "react"
import { Button, Table } from "react-bootstrap"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import PropTypes from "prop-types"

import {
  FormattedEncounterNotes,
  TEASER_LENGTH,
  TruncatedFormattedEncounterNotes,
} from "~/components/encounters/FormattedEncounterNotes"
import PatientEncountersActionBar from "~/components/encounters/PatientEncountersActionBar"
import PopoverButton from "~/components/PopoverButton"
import formattedDate from "~/dateHelpers"
import { accessible_external_encounter_path, encounter_path } from "~/routes"

const columnHelper = createColumnHelper()

function PatientEncountersIndex({
  entities: {
    coreServiceTypes,
    searchTerm,
    viewOnly,
    encounters: { encounters, encounterIds },
  },
}) {
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => {
          if (row.original.notes.length <= TEASER_LENGTH) return null

          return (
            <Button
              variant="link"
              onClick={() => row.toggleExpanded()}
              aria-controls={`target-id-${row.original.id}`}
              aria-expanded={row.getIsExpanded()}
              className={`fa patient-encounters-index-arrow toggle-icon ${
                row.getIsExpanded() ? "fa-chevron-down expanded" : "fa-chevron-up closed"
              }`}
            />
          )
        },
      }),
      columnHelper.display({
        id: "locked",
        cell: ({ row }) => (row.original.locked ? <i className="fa fa-lock" /> : null),
      }),
      columnHelper.display({
        id: "flags",
        cell: ({ row }) => {
          const { coreServiceId, isAboutAdmissionOrDischarge } = row.original
          const coreService = coreServiceTypes.find((coreServiceType) => coreServiceType.id === coreServiceId)
          return (
            <div className="d-flex gap-1 align-items-start flex-wrap">
              {coreService && (
                <PopoverButton label="CS" placement="top" style={{ maxWidth: "400px" }}>
                  {coreService.name}
                </PopoverButton>
              )}
              {isAboutAdmissionOrDischarge && (
                <PopoverButton label="AD" placement="top" variant="warning" style={{ maxWidth: "400px" }}>
                  About Admission or Discharge
                </PopoverButton>
              )}
            </div>
          )
        },
      }),
      columnHelper.accessor("date", {
        header: "Date",
        cell: ({ getValue }) => formattedDate(getValue()),
      }),
      columnHelper.accessor("description", {
        header: "Description",
        cell: ({ getValue }) => <div className="patient-encounters-index-table-description-column">{getValue()}</div>,
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
        cell: ({ row, getValue }) =>
          row.getIsExpanded() ? (
            <FormattedEncounterNotes notes={getValue()} searchTerm={searchTerm} />
          ) : (
            <TruncatedFormattedEncounterNotes notes={getValue()} searchTerm={searchTerm} />
          ),
      }),
      columnHelper.display({
        id: "details",
        cell: ({ row }) => {
          const { id } = row.original
          const detailsRoute = viewOnly ? accessible_external_encounter_path(id) : encounter_path(id)
          return <a href={detailsRoute}>Details</a>
        },
      }),
    ],
    [coreServiceTypes, searchTerm, viewOnly]
  )

  const table = useReactTable({
    data: encounterIds.map((id) => encounters[id]),
    columns,
    enableGlobalFilter: true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  return (
    <>
      <PatientEncountersActionBar
        collapseAll={() => table.toggleAllRowsExpanded(false)}
        expandAll={() => table.toggleAllRowsExpanded(true)}
        areAllCollapsed={!table.getIsAllRowsExpanded()}
        areAllExpanded={table.getIsAllRowsExpanded()}
      />
      <Table
        striped
        bordered
        size="sm"
        className="table-internally-unbordered table-light-header patient-encounters-index-table"
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

PatientEncountersIndex.propTypes = {
  entities: PropTypes.shape({
    coreServiceTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    searchTerm: PropTypes.string,
    viewOnly: PropTypes.bool,
    encounters: PropTypes.shape({
      encounterIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      encounters: PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          notes: PropTypes.string.isRequired,
          locked: PropTypes.bool,
          coreServiceId: PropTypes.number,
          isAboutAdmissionOrDischarge: PropTypes.bool,
          date: PropTypes.string,
          description: PropTypes.string,
        })
      ).isRequired,
    }).isRequired,
  }),
}

export default PatientEncountersIndex
