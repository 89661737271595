import { createContext, useContext } from "react"
import PropTypes from "prop-types"

const FormIdContext = createContext()

function useFormIdContext() {
  return useContext(FormIdContext)
}

function FormIdProvider({ id, children }) {
  return <FormIdContext.Provider value={id}>{children}</FormIdContext.Provider>
}

FormIdProvider.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
}

export default useFormIdContext

export { FormIdProvider }
