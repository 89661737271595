import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_chart_searches_path } from "~/routes"

const chartSearches = {
  get: function (q) {
    return submitAsyncRequest({
      url: api_v1_chart_searches_path({ q }),
      type: "GET",
    })
  },
}

export default chartSearches
