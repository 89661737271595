import BootstrapPagination from "react-bootstrap/Pagination"
import PropTypes from "prop-types"

const Pagination = ({ gotoPage, pageIndex, pageCount, maxPageItemsAway = 4 }) => {
  const canNextPage = pageIndex !== pageCount - 1
  const canPreviousPage = pageIndex !== 0

  const pageItemHead = pageIndex - maxPageItemsAway < 0 ? 0 : pageIndex - maxPageItemsAway
  const pageItemTail = pageIndex + maxPageItemsAway + 1 > pageCount ? pageCount : pageIndex + maxPageItemsAway + 1

  if (pageCount <= 1) return null

  const pageNumbers = [...Array(pageCount)].map((_, i) => i + 1)

  return (
    <div>
      <BootstrapPagination size="sm">
        {[
          canPreviousPage && (
            <BootstrapPagination.First key="First" onClick={() => gotoPage(0)}>
              {"« First"}
            </BootstrapPagination.First>
          ),
          canPreviousPage && (
            <BootstrapPagination.Prev key="Prev" onClick={() => gotoPage(pageIndex - 1)}>
              {"‹ Previous"}
            </BootstrapPagination.Prev>
          ),
          pageItemHead > 0 && <BootstrapPagination.Ellipsis key="HeadEllipsis" disabled />,
          ...pageNumbers.slice(pageItemHead, pageItemTail).map((pageNumber) => (
            <BootstrapPagination.Item
              key={pageNumber}
              active={pageNumber - 1 === pageIndex}
              onClick={() => gotoPage(pageNumber - 1)}
            >
              {pageNumber}
            </BootstrapPagination.Item>
          )),
          pageItemTail < pageCount && <BootstrapPagination.Ellipsis key="TailEllipsis" disabled />,
          canNextPage && (
            <BootstrapPagination.Next key="Next" onClick={() => gotoPage(pageIndex + 1)}>
              {"Next ›"}
            </BootstrapPagination.Next>
          ),
          canNextPage && (
            <BootstrapPagination.Last key="Last" onClick={() => gotoPage(pageCount - 1)}>
              {"Last »"}
            </BootstrapPagination.Last>
          ),
        ]}
      </BootstrapPagination>
    </div>
  )
}

Pagination.propTypes = {
  /**
   * The callback function to call when a page is clicked
   */
  gotoPage: PropTypes.func.isRequired,
  /**
   * The currently active page index
   */
  pageIndex: PropTypes.number.isRequired,
  /**
   * The total number of pages to display
   */
  pageCount: PropTypes.number.isRequired,
  /**
   * The total number buttons adjacent to the current page to display before showing an ellipsis
   */
  maxPageItemsAway: PropTypes.number,
}

export default Pagination
