import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_patient_flag_path, api_v1_patient_flags_path } from "~/routes"

const patientFlags = {
  post: function (patientFlag) {
    return submitAsyncRequest({
      url: api_v1_patient_flags_path(),
      type: "post",
      data: { patientFlag },
    })
  },
  patch: function (patientFlagId, patientFlag) {
    return submitAsyncRequest({
      url: api_v1_patient_flag_path(patientFlagId),
      type: "patch",
      data: { patientFlag },
    })
  },
}

export default patientFlags
