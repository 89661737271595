import PropTypes from "prop-types"

export const UNSORTED = "UNSORTED"
export const SORTED_ASC = "SORTED_ASC"
export const SORTED_DESC = "SORTED_DESC"

const SortIcon = ({ sortState }) => {
  const StackedIconWrapper = ({ children }) => <span className="fa-stack fa-stack-sm fa-fw">{children}</span>
  const StackedSortIcon = () => <i className="fa fa-sort inactive-sort fa-stack-1x" />
  const AscStackedSortIcon = () => <i className="fa fa-sort-asc fa-stack-1x" />
  const DescStackedSortIcon = () => <i className="fa fa-sort-desc fa-stack-1x" />

  switch (sortState) {
    case UNSORTED:
      return (
        <StackedIconWrapper>
          <StackedSortIcon />
        </StackedIconWrapper>
      )
    case SORTED_ASC:
      return (
        <StackedIconWrapper>
          <StackedSortIcon />
          <AscStackedSortIcon />
        </StackedIconWrapper>
      )
    case SORTED_DESC:
      return (
        <StackedIconWrapper>
          <StackedSortIcon />
          <DescStackedSortIcon />
        </StackedIconWrapper>
      )
    default:
      throw new Error(`Unsupported Sort State: ${sortState}`)
  }
}

SortIcon.propTypes = {
  sortState: PropTypes.oneOf([UNSORTED, SORTED_ASC, SORTED_DESC]),
}

export default SortIcon
