import { submitAsyncRequest } from "~/asyncHelpers"
import { keepalive_sessions_path, session_test_sessions_path } from "~/routes"

const sessions = {
  check: function () {
    return submitAsyncRequest({
      url: session_test_sessions_path(),
      type: "GET",
      async: false,
      cache: false,
      timeout: 30000,
    })
  },
  keepAlive: function () {
    return submitAsyncRequest({
      url: keepalive_sessions_path(),
      type: "get",
    })
  },
  test: function () {
    return submitAsyncRequest({ url: "/sessions/session_test" })
  },
}

export default sessions
