import { Col, Row } from "react-bootstrap"
import { Provider } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import PropTypes from "prop-types"

import SchemaSections from "./components/SchemaSections"
import SideBar from "./components/SideBar"
import questionsReducer, { getPreloadedQuestionsState } from "./redux/questionsSlice"
import schemaVersionReducer from "./redux/schemaVersionSlice"
import sectionsReducer, { getPreloadedSectionsState } from "./redux/sectionsSlice"

import { ToastContainer } from "~/toast"
import types from "~/types"

function Show({ schemaVersion, schemaSections, schemaQuestions, questionTypes }) {
  const store = configureStore({
    reducer: {
      schemaVersion: schemaVersionReducer,
      sections: sectionsReducer,
      questions: questionsReducer,
      questionTypes: (state = {}) => state,
    },
    preloadedState: {
      schemaVersion,
      sections: getPreloadedSectionsState(schemaSections),
      questions: getPreloadedQuestionsState(schemaQuestions),
      questionTypes,
    },
  })

  return (
    <Provider store={store}>
      <Row>
        <Col lg={3}>
          <SideBar
            medicalBackgroundWidgetEnabled={schemaVersion.medicalBackgroundWidgetEnabled}
            socialBackgroundWidgetEnabled={schemaVersion.socialBackgroundWidgetEnabled}
          />
        </Col>
        <Col lg={9}>
          <SchemaSections />
        </Col>
        <ToastContainer />
      </Row>
    </Provider>
  )
}

Show.propTypes = {
  schemaVersion: types.customAssessmentSchemaVersion,
  schemaSections: PropTypes.arrayOf(types.customAssessmentSchemaSection).isRequired,
  schemaQuestions: PropTypes.arrayOf(types.customAssessmentSchemaQuestion).isRequired,
  questionTypes: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default Show
