import _ from "lodash"

import { SET_FILTER_PARAMS } from "~/big3Dashboard/actions/paramsActions"
import { QUALITY_REPORT_RECEIVED } from "~/big3Dashboard/actions/qualityReportStatusActions"

const initialState = {
  reportRowEntityIds: [],
}

function paramsReducer(state = initialState, action) {
  switch (action.type) {
    case QUALITY_REPORT_RECEIVED:
      return {
        ...state,
        reportRowEntityIds: _.intersection(
          action.report.map(({ entity: { id } }) => id),
          state.reportRowEntityIds
        ),
      }
    case SET_FILTER_PARAMS:
      return action.params
    default:
      return state
  }
}

export default paramsReducer
