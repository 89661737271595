import { useFormikContext } from "formik"
import pluralize from "pluralize"

function LinkActiveTasksCarePlanHeader() {
  const {
    values: { carePlanTaskIds },
  } = useFormikContext()

  return (
    <div className="mb-2">
      <p>
        Select all tasks to link with this gap in care:&nbsp;
        <strong>{pluralize("task", carePlanTaskIds.length, true)} selected</strong>
      </p>
    </div>
  )
}

export default LinkActiveTasksCarePlanHeader
