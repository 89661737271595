export const COLLAPSE_ALL = "COLLAPSE_ALL"
export function collapseAll() {
  return { type: COLLAPSE_ALL }
}

export const EXPAND_GOALS = "EXPAND_GOALS"
export function expandGoals() {
  return { type: EXPAND_GOALS }
}

export const EXPAND_ALL = "EXPAND_ALL"
export function expandAll() {
  return { type: EXPAND_ALL }
}
