import { Form as BootstrapForm } from "react-bootstrap"
import { useField } from "formik"
import PropTypes from "prop-types"

import { DEFAULT_CONTROL_WIDTH, DEFAULT_LABEL_WIDTH } from "./constants"
import FieldErrors from "./FieldErrors"
import HelpText from "./HelpText"
import useDisableContext from "./useDisableContext"
import useErrorContext from "./useErrorContext"
import { LayoutWrapper } from "./useLayoutContext"
import useNameContext from "./useNameContext"

function CheckBox({ disabled: disabledByProps, helpText, label, offset, validate, width }) {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name, validate })
  const { hasError } = useErrorContext()
  const disabledByContext = useDisableContext()

  const helpTextId = `${id}HelpText`
  const errorMessageId = `${id}Errors`
  const disabled = disabledByContext || disabledByProps

  return (
    <LayoutWrapper sm={{ span: width, offset }}>
      <BootstrapForm.Check
        checked={field.value}
        custom
        aria-describedby={helpText ? helpTextId : null}
        aria-errormessage={errorMessageId}
        aria-invalid={hasError}
        disabled={disabled}
        id={id}
        isInvalid={hasError}
        label={label}
        onChange={(e) => helpers.setValue(e.target.checked)}
        name={name}
        style={{ zIndex: "auto" }}
      />
      <HelpText id={helpTextId}>{helpText}</HelpText>
      <FieldErrors id={errorMessageId} />
    </LayoutWrapper>
  )
}

CheckBox.propTypes = {
  disabled: PropTypes.bool,
  /**
   * Text intended to guide the user toward the correct input.
   */
  helpText: PropTypes.string,
  /**
   * Text for the checkbox's `<label />` element.
   */
  label: PropTypes.node.isRequired,
  /**
   * The number of columns to the control so that it aligns correctly in horizontal forms.
   */
  offset: PropTypes.number,
  /**
   * An optional function to run client-side validations:
   * https://formik.org/docs/api/field#validate
   */
  validate: PropTypes.func,
  /**
   * The number of columns the control should span.
   */
  width: PropTypes.number,
}

CheckBox.defaultProps = {
  disabled: false,
  offset: DEFAULT_LABEL_WIDTH,
  width: DEFAULT_CONTROL_WIDTH,
}

export default CheckBox
