import { connect } from "react-redux"
import pluralize from "pluralize"

import { getTasks } from "~/components/carePlans/carePlanTasksSelectors"
import CarePlanMessageWrapper from "~/components/encounters/CarePlanMessageWrapper"
import CarePlanTasks from "~/components/encounters/CarePlanTasks"
import { wrapper } from "~/redux"
import { care_plan_path } from "~/routes"

const LinkedCarePlanTasksDetails = ({ carePlan, encounter, tasks }) => (
  <div className="border bg-light p-3">
    <div className="mb-3 d-flex justify-content-between">
      <div>
        <strong>{pluralize("Task", tasks.length, true)}</strong> Mentioned in Encounter
      </div>
      {carePlan && (
        <a href={care_plan_path(carePlan.id)} target="_blank">
          View Care Plan&nbsp;
          <i className="fa fa-external-link" />
        </a>
      )}
    </div>
    <CarePlanMessageWrapper carePlan={carePlan} chartId={encounter.chartId}>
      <CarePlanTasks tasks={tasks} />
    </CarePlanMessageWrapper>
  </div>
)

const mapStateToProps = (state) => ({
  tasks: Object.values(getTasks(state)),
})

export default wrapper(connect(mapStateToProps)(LinkedCarePlanTasksDetails))
