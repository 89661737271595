import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_chart_segments_path, api_v1_segment_path } from "~/routes"

const segments = {
  patch: function (id, segment) {
    return submitAsyncRequest({
      url: api_v1_segment_path(id),
      type: "patch",
      data: { segment: segment },
    })
  },
  post: function (chartId, segment) {
    return submitAsyncRequest({
      url: api_v1_chart_segments_path(chartId),
      type: "post",
      data: { segment: segment },
    })
  },
}

export default segments
