import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_chart_health_home_opt_outs_path } from "~/routes"

const healthHomeOptOuts = {
  post: function (chartId, healthHomeOptOut) {
    return submitAsyncRequest({
      url: api_v1_chart_health_home_opt_outs_path(chartId),
      type: "post",
      data: { healthHomeOptOut },
    })
  },
}

export default healthHomeOptOuts
