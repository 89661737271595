import { createPortal } from "react-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import spinner from "images/spinner.svg"
import PropTypes from "prop-types"

import useWorkCheck from "./useWorkCheck"

import { DangerAlert } from "~/design/alerts"

function SmallSpinner({ reloadWhenDone = false, redirectUrl, workCheckUrl }) {
  const {
    data: { working },
    error,
  } = useWorkCheck(workCheckUrl)

  if (error) {
    return createPortal(
      <DangerAlert icon>
        <p className="font-weight-bold mb-3">Error</p>
        <p>{error.message}</p>
      </DangerAlert>,
      document.querySelector("[data-placeholder=flash]")
    )
  }

  if (working) {
    return <img src={spinner} width={15} height={15} alt="Processing..." />
  }

  if (redirectUrl) {
    window.location.replace(redirectUrl)
  }

  if (reloadWhenDone) {
    window.location.reload(true)
  }

  return null
}

SmallSpinner.propTypes = {
  reloadWhenDone: PropTypes.bool,
  redirectUrl: PropTypes.string,
  workCheckUrl: PropTypes.string.isRequired,
}

const queryClient = new QueryClient()

function Provider(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <SmallSpinner {...props} />
    </QueryClientProvider>
  )
}

export { SmallSpinner }
export default Provider
