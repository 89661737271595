import { mapValuesDeep } from "deepdash-es/standalone"

import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_chart_encounters_path, api_v1_encounter_path } from "~/routes"

// AJAX removes keys with empty array values from request data.
// As a workaround, send [""] instead. Rails interprets [""] as an empty array.
const transformEmptyArrayLeaves = (values) =>
  mapValuesDeep(values, (v) => (Array.isArray(v) && v.length === 0 ? [""] : v), { leavesOnly: true })

const encounters = {
  post: function (chartId, encounter) {
    return submitAsyncRequest({
      url: api_v1_chart_encounters_path(chartId),
      type: "post",
      data: { encounter: transformEmptyArrayLeaves(encounter) },
    })
  },
  patch: function (encounterId, encounter) {
    return submitAsyncRequest({
      url: api_v1_encounter_path(encounterId),
      type: "patch",
      data: { encounter: transformEmptyArrayLeaves(encounter) },
    })
  },
}

export default encounters
