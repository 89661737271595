import PropTypes from "prop-types"

import ExternalLinkIcon from "./icons/ExternalLinkIcon"

function ExternalLink({ href, text }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <span className="mr-1">{text}</span>
      <ExternalLinkIcon />
    </a>
  )
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default ExternalLink
