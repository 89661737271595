/* eslint-disable react/no-array-index-key */
import { Children, Fragment } from "react"
import _ from "lodash"

import { BulletDivider } from "~/components/Divider"

function DividerList({ children, Divider = BulletDivider }) {
  return (
    <Fragment>
      {_.compact(Children.toArray(children)).map((child, idx) => (
        <Fragment key={idx}>
          {idx > 0 && <Divider />}
          {child}
        </Fragment>
      ))}
    </Fragment>
  )
}

export default DividerList
