import { Component } from "react"
import ReactDOM from "react-dom"
import { combineReducers } from "redux"

import makeCarePlanGoalsReducer from "~/components/carePlans/carePlanGoalsReducer"
import carePlanTasksReducer from "~/components/carePlans/carePlanTasksReducer"
import mcoGapsInCareReducer from "~/components/carePlans/mcoGapsInCareReducer"
import LinkedTaskDetails from "~/components/linkedCarePlanTasks/LinkedTaskDetails"
import { wrapper } from "~/redux"

class McoGapInCareCarePlanTaskContainer extends Component {
  constructor() {
    super()
    this.childNodeEntryPoints = document.getElementsByClassName("react-portal-child-mount-point")
  }

  render() {
    return [...this.childNodeEntryPoints].map((node) => {
      const nodeGapId = parseInt(node.id.replace("mco-gap-in-care-", ""), 10)

      return ReactDOM.createPortal(<LinkedTaskDetails carePlan={this.props.carePlan} nodeGapId={nodeGapId} />, node)
    })
  }
}

const mapPropsToPreloadedState = (props) => ({ entities: { mcoGapsInCare: props.entities.mcoGapsInCare } })

export default wrapper(
  McoGapInCareCarePlanTaskContainer,
  {
    entities: combineReducers({
      mcoGapsInCare: mcoGapsInCareReducer,
      gapsForNewTaskLinkages: (state = {}) => state,
      goals: makeCarePlanGoalsReducer({}),
      tasks: carePlanTasksReducer,
    }),
  },
  mapPropsToPreloadedState
)
