import { connect } from "react-redux"

import CarePlanActiveFilter from "~/components/carePlans/CarePlanActiveFilter"
import { openNewNeedForm } from "~/components/carePlans/carePlanNewNeedFormActions"
import { getIsNewNeedFormOpen } from "~/components/carePlans/carePlanUISelectors"
import CollapseSelector from "~/components/carePlans/CollapseSelector"
import { getReadOnly } from "~/components/carePlans/globalsSelectors"
import NeedCategoryFilter from "~/components/carePlans/NeedCategoryFilter"
import { PrimaryButton } from "~/design/buttons"
import Show from "~/design/Show"

function CarePlanActionBar({ isNewNeedFormOpen, openNewNeedFormConnect, readOnly }) {
  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="d-flex gap-3">
        <NeedCategoryFilter />
        <CarePlanActiveFilter />
        <CollapseSelector />
      </div>
      <Show when={!readOnly}>
        <PrimaryButton onClick={openNewNeedFormConnect} disabled={isNewNeedFormOpen}>
          Add Need
        </PrimaryButton>
      </Show>
    </div>
  )
}

export default connect(
  (state) => ({
    readOnly: getReadOnly(state),
    isNewNeedFormOpen: getIsNewNeedFormOpen(state),
  }),
  { openNewNeedFormConnect: openNewNeedForm }
)(CarePlanActionBar)
