import { useEffect, useMemo, useState } from "react"
import { useFormikContext } from "formik"
import _ from "lodash"
import PropTypes from "prop-types"

function Autosave({ debounceMs }) {
  const { setSubmitting, submitForm, values } = useFormikContext()
  const [previousValues, setPreviousValues] = useState(values)
  const hasNewValues = !_.isEqual(values, previousValues)

  const debouncedSubmit = useMemo(() => _.debounce(submitForm, debounceMs), [debounceMs, submitForm])

  useEffect(() => {
    if (hasNewValues) {
      setSubmitting(true)
      setPreviousValues(values)
      debouncedSubmit()
    }
  }, [debouncedSubmit, hasNewValues, setSubmitting, values])

  useEffect(() => debouncedSubmit.cancel(), [debouncedSubmit])

  return null
}

Autosave.propTypes = {
  debounceMs: PropTypes.number,
}

Autosave.defaultProps = {
  debounceMs: 500,
}

export default Autosave
