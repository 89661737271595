import { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import _ from "lodash"
import pluralize from "pluralize"
import PropTypes from "prop-types"

import Badge from "~/components/badge"
import { LineDivider } from "~/components/Divider"
import formattedDate from "~/dateHelpers"
import Jumbotron from "~/design/Jumbotron"
import { clinical_event_path } from "~/routes"

const EmptyClinicalEvents = () => <Jumbotron message="There are no clinical events linked to this encounter." />

const NonEmptyClinicalEvents = ({ clinicalEvents }) => (
  <div className="bg-white border rounded px-3 pt-2 overflow-auto" style={{ maxHeight: "136px" }}>
    {clinicalEvents.map((event, index) => (
      <Fragment key={event.id}>
        <div data-testid={`linked-clinical-event-${event.id}`}>
          <Row noGutters className="py-1">
            <Col sm={3}>Clinical Event</Col>
            <Col sm={3}>{pluralize("alert", event.clinicalAlerts.length, true)}</Col>
            <Col sm={6}>
              <div className="d-sm-flex justify-content-end">
                <div>
                  <span className="text-muted">Created On </span> {formattedDate(event.initialAlertReceivedAt)}
                </div>
              </div>
            </Col>
          </Row>
          <Row noGutters className="pt-2">
            <Col>
              <ul>
                {event.clinicalAlerts.map((clinicalAlert, i) => (
                  <li key={clinicalAlert.id} className="mb-1">
                    {formattedDate(clinicalAlert.occurredAt)}
                    {" - "}
                    <Badge variant={clinicalAlert.visitType.color}>{clinicalAlert.visitType.name}</Badge>
                    {i === event.clinicalAlerts.length - 1 && (
                      <a href={clinical_event_path(event.id)} target="_blank" className="float-right" rel="noreferrer">
                        View&nbsp;
                        <i className="fa fa-external-link" />
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
        {index < clinicalEvents.length - 1 ? <LineDivider className="mt-1 mb-2" /> : null}
      </Fragment>
    ))}
  </div>
)

const LinkedClinicalEvents = ({ clinicalEvents }) => {
  return (
    <div className="border bg-light p-3 mb-3">
      <div className="mb-3 d-flex justify-content-between">
        <div>
          <strong>{pluralize("Clinical Event", clinicalEvents.length, true)}</strong> Mentioned in Encounter
        </div>
      </div>
      <div>
        {_.isEmpty(clinicalEvents) ? (
          <EmptyClinicalEvents />
        ) : (
          <NonEmptyClinicalEvents clinicalEvents={clinicalEvents} />
        )}
      </div>
    </div>
  )
}

LinkedClinicalEvents.propTypes = {
  clinicalEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      chartId: PropTypes.number.isRequired,
      initialAlertReceivedAt: PropTypes.string.isRequired,
      clinicalAlerts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          occurredAt: PropTypes.string.isRequired,
          visitType: PropTypes.shape({
            color: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        })
      ),
    })
  ),
}

export default LinkedClinicalEvents
