import { Children } from "react"
import PropTypes from "prop-types"

function SideBarList({ bulleted, emptyListMessage, children }) {
  if (Children.count(children) === 0) {
    return <p className="font-weight-light">{emptyListMessage}</p>
  }

  return <ul className={bulleted ? "ml-3 mb-0" : "m-0 list-unstyled"}>{children}</ul>
}

SideBarList.propTypes = {
  bulleted: PropTypes.bool,
  emptyListMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default SideBarList
