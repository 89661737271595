import { Nav, Tab } from "react-bootstrap"
import { useFormikContext } from "formik"

import CarePlanDetails from "./CarePlanDetails"
import ClinicalEventDetails from "./ClinicalEventDetails"

function EncounterFormTabs({ carePlan, clinicalEvents }) {
  const { values } = useFormikContext()

  return (
    <Tab.Container defaultActiveKey="activeCarePlanTasks" transition={false} id="encounter-tabs">
      <Nav variant="tabs">
        <Nav.Link eventKey="activeCarePlanTasks">Active Care Plan Tasks ({values.carePlanTaskIds.length})</Nav.Link>
        <Nav.Link eventKey="clinicalEvents">Clinical Events ({values.clinicalEventIds.length})</Nav.Link>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="activeCarePlanTasks">
          <div className="border border-top-0 bg-light p-3">
            <CarePlanDetails carePlan={carePlan} />
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="clinicalEvents">
          <div className="border border-top-0 bg-light p-3">
            <ClinicalEventDetails
              clinicalEvents={clinicalEvents}
              clinicalEventIds={values.clinicalEventIds}
              chartId={values.chartId}
            />
          </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  )
}

export default EncounterFormTabs
