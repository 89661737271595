import { useState } from "react"
import { connect } from "react-redux"
import pluralize from "pluralize"
import PropTypes from "prop-types"

import { questionsReordered, sectionSelectors, selectSectionQuestionEntities } from "../redux/sectionsSlice"

import ListGroup from "~/components/listGroup"
import { IconButton, PrimaryButton, SecondaryButton } from "~/design/buttons"
import { CollapseIcon, ExpandIcon } from "~/design/icons"
import Modal from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function ReorderQuestionsModal({ onClose, questionEntities, questionsReorderedConnect, section, show }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [questionIds, setQuestionIds] = useState(section.questionIds)

  function moveQuestionId(fromIndex, toIndex) {
    const questionIdsCopy = [...questionIds]
    questionIdsCopy[toIndex] = questionIds[fromIndex]
    questionIdsCopy[fromIndex] = questionIds[toIndex]
    setQuestionIds(questionIdsCopy)
  }

  async function saveReorderedQuestions() {
    try {
      setIsSubmitting(true)
      await api.customAssessments.schemaSections.patch(section.id, { questionIds })
      questionsReorderedConnect({ sectionId: section.id, questionIds })
      toast.success("Questions reordered successfully.")
      setIsSubmitting(false)
      onClose()
    } catch {
      toast.error("Questions could not be reordered.")
      setIsSubmitting(false)
    }
  }

  return (
    <Modal size="lg" title="Reorder Questions" onClose={onClose} show={show} scrollable>
      <Modal.Body>
        <strong>{pluralize("question", section.questionIds.length, true)}</strong>
        <ListGroup size="sm">
          {questionIds.map((questionId, questionIndex) => (
            <ListGroup.Item key={questionId}>
              <div className="d-flex justify-content-between align-items-center gap-2">
                <div>
                  {questionIndex + 1}. {questionEntities[questionId].questionText}
                </div>
                <div className="d-flex align-items-center gap-2">
                  <IconButton
                    Icon={CollapseIcon}
                    disabled={questionIndex === 0}
                    onClick={() => moveQuestionId(questionIndex, questionIndex - 1)}
                    label={`Move ${questionEntities[questionId].questionText} up`}
                  />
                  <IconButton
                    Icon={ExpandIcon}
                    disabled={questionIndex === section.questionIds.length - 1}
                    onClick={() => moveQuestionId(questionIndex, questionIndex + 1)}
                    label={`Move ${questionEntities[questionId].questionText} down`}
                  />
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton disabled={isSubmitting} onClick={saveReorderedQuestions}>
          {isSubmitting ? "Loading..." : "Save"}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  )
}

ReorderQuestionsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  questionEntities: PropTypes.objectOf(types.customAssessmentSchemaQuestion).isRequired,
  questionsReorderedConnect: PropTypes.func.isRequired,
  section: types.customAssessmentSchemaSection,
  show: PropTypes.bool.isRequired,
}

export default connect(
  (state, { sectionId }) => ({
    section: sectionSelectors.selectById(state, sectionId),
    questionEntities: selectSectionQuestionEntities(state, sectionId),
  }),
  {
    questionsReorderedConnect: questionsReordered,
  }
)(ReorderQuestionsModal)
