import PropTypes from "prop-types"

const riskFactor = PropTypes.shape({
  id: PropTypes.number.isRequired,
  categoryId: PropTypes.number,
  categoryName: PropTypes.string,
  description: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
})

export default riskFactor
