import { connect } from "react-redux"

import CarePlanFormBody from "./CarePlanFormBody"
import { carePlanNeedFlashKey, carePlanNeedUpdated, deleteCarePlanNeed } from "./carePlanNeedActions"
import { closeNewNeedForm } from "./carePlanNewNeedFormActions"
import NeedFormFields from "./NeedFormFields"

import { DangerButton } from "~/design/buttons"
import Form from "~/design/forms"
import useConfirmationModal from "~/design/modals/useConfirmationModal"
import { flashMessage } from "~/flashMessages/flashMessageActions"
import api from "~/services/api"
import { toast } from "~/toast"

function EditNeedForm({
  carePlanNeedUpdatedConnect,
  deleteCarePlanNeedConnect,
  flashMessageConnect,
  need,
  onCancelClick,
}) {
  const confirm = useConfirmationModal()

  async function handleSubmit(values, { setErrors, setStatus }) {
    try {
      const updatedNeed = await api.carePlans.needs.patch(need.id, values)
      setStatus("success")
      carePlanNeedUpdatedConnect(updatedNeed)
      flashMessageConnect(carePlanNeedFlashKey(updatedNeed))
      toast.success("Need successfully updated.")
      onCancelClick()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  async function handleDelete() {
    const confirmed = await confirm.danger(
      "This need, associated goals, and associated tasks will all be permanently deleted.",
      { title: "Delete Need", confirmButtonLabel: "Delete" }
    )

    if (confirmed) {
      try {
        await deleteCarePlanNeedConnect(need.id)
        toast.success("Need successfully deleted.")
      } catch (error) {
        toast.error(
          <>
            Need could not be deleted.
            <br />
            Please refresh the page.
          </>
        )
      }
    }
  }

  return (
    <Form initialValues={need} onSubmit={handleSubmit} vertical>
      <CarePlanFormBody title="Edit Need" dataTestId="need-form">
        <NeedFormFields />
        <Form.ActionBar
          left={<DangerButton onClick={handleDelete}>Delete</DangerButton>}
          right={
            <>
              <Form.CancelButton onClick={onCancelClick} />
              <Form.SubmitButton label="Update" />
            </>
          }
        />
      </CarePlanFormBody>
    </Form>
  )
}

export default connect(({ entities: { carePlan } }) => ({ carePlanId: carePlan.id }), {
  carePlanNeedUpdatedConnect: carePlanNeedUpdated,
  closeNewNeedFormConnect: closeNewNeedForm,
  deleteCarePlanNeedConnect: deleteCarePlanNeed,
  flashMessageConnect: flashMessage,
})(EditNeedForm)
