import { care_management_agency_quality_dashboard_path, health_home_quality_dashboard_path } from "~/routes"

export const createNewHealthHomeBig3Url = ({ healthHomeId, activeTabId, date }) =>
  health_home_quality_dashboard_path(healthHomeId, { category_id: activeTabId, date })

export const createNewCareManagementAgencyBig3Url = ({
  careManagementAgencyId,
  activeTabId,
  healthHomeIds,
  supervisorIds,
  supervisoryTeamIds,
  adultChildServicesCodeId,
}) =>
  care_management_agency_quality_dashboard_path(careManagementAgencyId, {
    category_id: activeTabId,
    health_home_ids: healthHomeIds,
    supervisor_ids: supervisorIds,
    supervisory_team_ids: supervisoryTeamIds,
    adult_child_services_code_id: adultChildServicesCodeId,
  })
