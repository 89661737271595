import PropTypes from "prop-types"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { childrens_hcbs_workflow_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function EditForm({
  childrensHCBSDisenrollmentReasons,
  childrensHCBSDisenrollmentStatuses,
  childrensHCBSWorkflowStatuses,
  childrensHCBSWorkflow,
}) {
  async function handleSubmit(values, { setStatus, setErrors }) {
    try {
      await api.childrensHCBSWorkflowDisenrollments.patch(childrensHCBSWorkflow.id, values)
      setStatus("success")
      redirectTo(childrens_hcbs_workflow_path(childrensHCBSWorkflow.id))
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  // When a user is disenrolling, we want to set the status to "Disenrolled" - but if a user
  // is using this form to clear disenrollment details from a workflow, we need to let them
  // set the workflow status to something else. So we default the status to "Disenrolled"
  // but include a field to let them change it as needed.
  const initialValues = {
    ...childrensHCBSWorkflow,
    statusId: 6, // ChildrensHCBSWorkflowStatus::DISENROLLED.id
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <Form.Fieldset title="HCBS Disenrollment">
        <Form.Group name="disenrolledOn">
          <Form.Label>Disenrolled On</Form.Label>
          <Form.DatePicker />
        </Form.Group>
        <Form.Group name="disenrollmentStatusId">
          <Form.Label>Health Home/HCBS Enrollment Status</Form.Label>
          <Form.SelectBox
            isClearable
            options={childrensHCBSDisenrollmentStatuses}
            helpText="Note: If disenrolling from both HH and HCBS use End Reason Code 56. If disenrolling from HH and staying in the waiver, use End Reason Code 57."
          />
        </Form.Group>
        {/* ChildrensHCBSDisenrollmentStatus::DISENROLLING_FROM_HCBS */}
        <Form.Show when={({ values: { disenrollmentStatusId } }) => disenrollmentStatusId === 1}>
          <Form.Group name="noticeSentToHealthHome">
            <Form.Label>Notice Sent to Health Home</Form.Label>
            <Form.DatePicker />
          </Form.Group>
        </Form.Show>
        <Form.Group name="careTeamMembersNotified">
          <Form.Label>Care Team Members Notified</Form.Label>
          <Form.DatePicker />
        </Form.Group>
      </Form.Fieldset>
      <Form.Fieldset title="Reason for Disenrollment">
        <Form.Group name="disenrollmentReasonId">
          <Form.Label>Reason For Disenrollment</Form.Label>
          <Form.SelectBox isClearable options={childrensHCBSDisenrollmentReasons} />
        </Form.Group>
        {/* ChildrensHCBSDisenrollmentReason::OTHER */}
        <Form.Show when={({ values: { disenrollmentReasonId } }) => disenrollmentReasonId === 6}>
          <Form.Group name="disenrollmentReasonOther">
            <Form.Label>Other Reason Notes</Form.Label>
            <Form.TextField />
          </Form.Group>
        </Form.Show>
      </Form.Fieldset>
      <Form.Fieldset title="Workflow Status">
        <Form.Group name="statusId">
          <Form.Label>Status</Form.Label>
          <Form.SelectBox options={childrensHCBSWorkflowStatuses} />
        </Form.Group>
      </Form.Fieldset>
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(childrens_hcbs_workflow_path(childrensHCBSWorkflow.id))} />
            <Form.SubmitButton label="Update Disenrollment" />
          </>
        }
      />
    </Form>
  )
}

EditForm.propTypes = {
  childrensHCBSDisenrollmentReasons: PropTypes.arrayOf(types.selectOption).isRequired,
  childrensHCBSDisenrollmentStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  childrensHCBSWorkflow: types.childrensHCBSWorkflow.isRequired,
  childrensHCBSWorkflowStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default EditForm
