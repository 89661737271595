import _ from "lodash"

import { carePlanEntitiesDeleted } from "~/components/carePlans/carePlanActions"
import { getTaskIds } from "~/components/carePlans/carePlanGoalsSelectors"
import { getGoalIds } from "~/components/carePlans/carePlanNeedsSelectors"
import api from "~/services/api"

export function carePlanNeedFlashKey(need) {
  return `CARE_PLAN_NEED_${need.id}_FLASH_KEY`
}

export const CARE_PLAN_NEED_CREATED = "CARE_PLAN_NEED_CREATED"
export function carePlanNeedCreated(need, carePlanId) {
  return {
    type: CARE_PLAN_NEED_CREATED,
    carePlanId,
    need,
  }
}

export const CARE_PLAN_NEED_UPDATED = "CARE_PLAN_NEED_UPDATED"
export function carePlanNeedUpdated(need) {
  return {
    type: CARE_PLAN_NEED_UPDATED,
    need,
  }
}

export function deleteCarePlanNeed(needId) {
  return async (dispatch, getState) => {
    const state = getState()
    const goalIds = getGoalIds(state, { needId })
    const taskIds = _.flatMap(goalIds, (goalId) => getTaskIds(state, { goalId }))

    await api.carePlans.needs.delete(needId)
    dispatch(carePlanEntitiesDeleted([needId], goalIds, taskIds))
  }
}
