import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import { YES_NO_OPTIONS } from "../constants"
import { filterAsOfDate } from "../helpers/filterAsOfDate"
import useCesToolSchema from "../hooks/useCesToolSchema"

import QuestionCard from "./QuestionCard"
import RiskFactorsAccordion from "./RiskFactorsAccordion"
import Section from "./Section"

import Form from "~/design/forms"
import Show from "~/design/Show"
import types from "~/types"

function SignificantRiskFactorsSection({ significantRiskFactors }) {
  const { isFieldAnswerable } = useCesToolSchema()
  const {
    values: { startDate },
  } = useFormikContext()

  const significantRiskFactorOptions = filterAsOfDate(significantRiskFactors, startDate)
    .map(({ id, description }) => ({
      value: id,
      label: description,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  function showAdditionalInformationField({ values }) {
    return values.significantRiskFactorIds.some(
      (id) =>
        significantRiskFactors.find((option) => id === option.id)?.requiresAdditionalSignificantRiskFactorInformation
    )
  }

  return (
    <Show when={isFieldAnswerable("memberIsExperiencingSignificantRiskFactors")}>
      <Section id="ces-tool-section-significant-risk-factors" title="Significant Risk Factors">
        <p className="mb-3">
          <strong>Selection of at least 1 Significant Risk Factor skips straight to Member Engagement.</strong>
        </p>
        <p className="mb-3">
          <strong>
            Selection of "No Concrete Example of a Significant Risk Factor in record" skips straight to Additional Risk
            Factors.
          </strong>
        </p>
        <QuestionCard questionNumber={1}>
          <Form.Group name="memberIsExperiencingSignificantRiskFactors">
            <Form.Label>
              Does the member currently have a Concrete Example of a Significant Risk Factor from the list below?
            </Form.Label>
            <Form.SelectBox isClearable options={YES_NO_OPTIONS} />
          </Form.Group>
          <RiskFactorsAccordion
            title="Click here to view a list of significant risk factors"
            riskFactorDescriptions={significantRiskFactorOptions.map(({ label }) => label)}
          />
        </QuestionCard>
        <Show when={isFieldAnswerable("significantRiskFactorIds")}>
          <QuestionCard questionNumber={2}>
            <Form.Group clearedValue={[]} name="significantRiskFactorIds">
              <Form.Label>Select at least one Concrete Example of a Significant Risk Factor</Form.Label>
              <Form.MultiSelectBox options={significantRiskFactorOptions} />
            </Form.Group>
          </QuestionCard>
        </Show>
        <Form.Show when={showAdditionalInformationField}>
          <QuestionCard questionNumber={3}>
            <Form.Group name="additionalInformationForSignificantRiskFactors">
              <Form.Label>
                Description/Specifics if you selected a Significant Risk Factor that says "must specify"
              </Form.Label>
              <Form.TextArea />
            </Form.Group>
          </QuestionCard>
        </Form.Show>
      </Section>
    </Show>
  )
}

SignificantRiskFactorsSection.propTypes = {
  significantRiskFactors: PropTypes.arrayOf(types.cesRiskFactor).isRequired,
}

export default SignificantRiskFactorsSection
