import { useEffect } from "react"
import { useFormikContext } from "formik"
import _ from "lodash"

import EncounterDetailsFields from "~/components/encounters/EncounterDetailsFields"
import EncounterLocationField from "~/components/encounters/EncounterLocationField"
import EncounterMeetingTypeField from "~/components/encounters/EncounterMeetingTypeField"
import EncounterTargetsField from "~/components/encounters/EncounterTargetsField"
import EncounterYesNoField from "~/components/encounters/EncounterYesNoField"

function InPersonEncounterDetailsFields() {
  const {
    values: { inPersonEncounterDetailsAttributes = {} },
    setFieldValue,
  } = useFormikContext()
  const { contacted } = inPersonEncounterDetailsAttributes
  const defaultValues = { contacted: "" }

  useEffect(() => {
    if (!_.isBoolean(contacted)) {
      setFieldValue("inPersonEncounterDetailsAttributes", defaultValues)
    } else if (contacted === true) {
      setFieldValue("inPersonEncounterDetailsAttributes", {
        targetIds: [],
        locationId: "",
        meetingTypeId: "",
        ...inPersonEncounterDetailsAttributes,
      })
    } else if (contacted === false) {
      setFieldValue("inPersonEncounterDetailsAttributes", {
        targetIds: [],
        locationId: "",
        ...inPersonEncounterDetailsAttributes,
        meetingTypeId: 3, // EncounterMeetingType::NONE
      })
    }
  }, [contacted])

  return (
    <EncounterDetailsFields attributesName="inPersonEncounterDetailsAttributes" defaultValues={defaultValues}>
      <EncounterYesNoField name="contacted" label="Did you meet with anyone?" />
      {_.isBoolean(contacted) && (
        <EncounterTargetsField
          name="targetIds"
          label={contacted ? "Who did you meet with?" : "Who did you try to meet with?"}
        />
      )}
      <EncounterMeetingTypeField enabled={contacted} name="meetingTypeId" />
      {_.isBoolean(contacted) && (
        <EncounterLocationField
          label={contacted ? "Where did it take place?" : "Where did you attempt the meeting?"}
          name="locationId"
        />
      )}
    </EncounterDetailsFields>
  )
}

export default InPersonEncounterDetailsFields
