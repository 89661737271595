import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import Form from "~/design/forms"
import IndexFilter from "~/design/IndexFilter"
import useQueryParams from "~/hooks/useQueryParams"
import types from "~/types"
import useSegmentSummaryLineChart from "~/views/shared/reports/segmentSummaryLineChart/useSegmentSummaryLineChart"

function Filter({ careManagementAgencies, healthHomes }) {
  const [queryParams, setQueryParams] = useQueryParams()
  const { isLoading } = useSegmentSummaryLineChart({
    careManagementAgencyId: queryParams?.filter?.care_management_agency_id,
    healthHomeId: queryParams?.filter?.health_home_id,
  })

  function handleSubmit(values, { setSubmitting }) {
    setQueryParams(values)
    setSubmitting(false)
  }

  return (
    <Form initialValues={queryParams} onSubmit={handleSubmit}>
      <IndexFilter>
        <Row>
          <Col lg={4}>
            <Form.Group name="filter.health_home_id">
              <Form.Label width={6}>Health Home</Form.Label>
              <Form.SelectBox width={6} isClearable options={healthHomes} placeholder="" />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group name="filter.care_management_agency_id">
              <Form.Label width={6}>Care Management Agency</Form.Label>
              <Form.SelectBox width={6} isClearable options={careManagementAgencies} placeholder="" />
            </Form.Group>
          </Col>
        </Row>
        <IndexFilter.ActionBar>
          <Form.SubmitButton label="Filter" disabled={isLoading} />
        </IndexFilter.ActionBar>
      </IndexFilter>
    </Form>
  )
}

Filter.propTypes = {
  careManagementAgencies: PropTypes.arrayOf(types.selectOption).isRequired,
  healthHomes: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default Filter
