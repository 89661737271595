import { Form as BootstrapForm } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import classNames from "classnames"
import { format, isValid, parseISO } from "date-fns"
import { useField } from "formik"
import PropTypes from "prop-types"

// We should be able to remove the import from packs/application.js after all
// of the forms are converted, which centralizes loading the CSS to pages that
// actually include a date picker element. This also ensures that the CSS is
// loaded outside of the application context e.g. in a Storybook story.
import "react-datepicker/dist/react-datepicker.css"

import { DEFAULT_CONTROL_WIDTH } from "./constants"
import FieldErrors from "./FieldErrors"
import HelpText from "./HelpText"
import useDisableContext from "./useDisableContext"
import useErrorContext from "./useErrorContext"
import { LayoutWrapper } from "./useLayoutContext"
import useNameContext from "./useNameContext"

/**
 * A `<DatePicker />` is used to allow a user to select a date from a graphical
 * calendar.
 */
function DatePicker({ disabled: disabledByProps, helpText, minDate, maxDate, placeholder, validate, width }) {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name, validate })
  const { hasError } = useErrorContext()
  const disabledByContext = useDisableContext()

  const helpTextId = `${id}HelpText`
  const errorMessageId = `${id}Errors`
  const disabled = disabledByContext || disabledByProps
  const parsedDate = parseISO(field.value)
  const date = isValid(parsedDate) ? parsedDate : null
  const arrowStyles = {
    name: "arrow",
    options: { padding: ({ popper }) => ({ right: popper.width - 32 }) },
  }

  function handleChange(date) {
    helpers.setValue(date ? format(date, "yyyy-MM-dd") : null)
  }

  return (
    <LayoutWrapper sm={width}>
      <BootstrapForm.Control
        ariaDescribedBy={classNames({ [helpTextId]: helpText, [errorMessageId]: hasError })}
        ariaInvalid={hasError}
        as={ReactDatePicker}
        autoComplete="off"
        disabled={disabled}
        id={id}
        isInvalid={hasError}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        onChange={handleChange}
        placeholderText={placeholder}
        popperModifiers={[arrowStyles]}
        selected={date}
        type="text"
      />
      <HelpText id={helpTextId}>{helpText}</HelpText>
      <FieldErrors id={errorMessageId} />
    </LayoutWrapper>
  )
}

DatePicker.propTypes = {
  /**
   * Whether the date picker can receive input.
   */
  disabled: PropTypes.bool,
  /**
   * Text intended to guide the user toward the correct input.
   */
  helpText: PropTypes.string,
  /**
   * Specify the earliest date a user can select in the datepicker.
   */
  minDate: PropTypes.instanceOf(Date),
  /**
   * Specify the latest date a user can select in the datepicker.
   */
  maxDate: PropTypes.instanceOf(Date),
  /**
   * The text to display before a date has been chosen.
   */
  placeholder: PropTypes.string,
  /**
   * An optional function to run client-side validations:
   * https://formik.org/docs/api/field#validate
   */
  validate: PropTypes.func,
  /**
   * The number of columns the control should span.
   */
  width: PropTypes.number,
}

DatePicker.defaultProps = {
  disabled: false,
  placeholder: "Please select a date",
  width: DEFAULT_CONTROL_WIDTH,
}

export default DatePicker
