import PropTypes from "prop-types"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { chart_assessments_path, pdf_assessment_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"
import ChartIndicator from "~/views/shared/ChartIndicator"

function New({ chart, pdfAssessment, occasions }) {
  async function handleSubmit(values, { setStatus, setErrors }) {
    try {
      const { id } = await api.pdfAssessments.post(chart.id, values)
      redirectTo(pdf_assessment_path(id))
      setStatus("success")
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={pdfAssessment} onSubmit={handleSubmit}>
      <Form.Group name="completionDate">
        <Form.Label>Completion Date</Form.Label>
        <Form.DatePicker />
      </Form.Group>
      <Form.Group name="patientAssessmentOccasionId" errorKey="occasion">
        <Form.Label>Assessment Occasion</Form.Label>
        <Form.SelectBox options={occasions} />
      </Form.Group>
      <Form.Group name="uploadedFile">
        <Form.Label>Assessment PDF</Form.Label>
        <Form.FileField />
      </Form.Group>
      <ChartIndicator chart={chart} />
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_assessments_path(chart.id))} />
            <Form.SubmitButton label="Create Assessment" />
          </>
        }
      />
    </Form>
  )
}

New.propTypes = {
  chart: types.chart.isRequired,
  pdfAssessment: types.pdfAssessment.isRequired,
  occasions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default New
