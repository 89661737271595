import PropTypes from "prop-types"

import IconButton from "~/design/buttons/IconButton"
import { DoctorIcon, HeartbeatIcon, MedkitIcon, UserIcon } from "~/design/icons"
import { ExpandIcon } from "~/design/icons"

function PreviewMedicalBackgroundWidget() {
  return (
    <div className="border bg-light mb-3 p-3">
      <EmptySideBarToggle title="Diagnoses" HeaderIcon={MedkitIcon} />
      <EmptySideBarToggle title="Medications" HeaderIcon={HeartbeatIcon} />
      <EmptySideBarToggle title="Care Manager" HeaderIcon={UserIcon} />
      <EmptySideBarToggle title="Care Team" HeaderIcon={DoctorIcon} />
    </div>
  )
}

function EmptySideBarToggle({ HeaderIcon, title }) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <h3 className="font-weight-bold mb-3">
        {HeaderIcon ? <HeaderIcon /> : null} {title}
      </h3>
      <span className="mb-3">
        <IconButton Icon={ExpandIcon} disabled={true} />
      </span>
    </div>
  )
}

EmptySideBarToggle.propTypes = {
  HeaderIcon: PropTypes.elementType,
  title: PropTypes.string.isRequired,
}

export default PreviewMedicalBackgroundWidget
