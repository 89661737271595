import { useFormikContext } from "formik"
import _ from "lodash"

import EncounterDetailsFields from "~/components/encounters/EncounterDetailsFields"
import EncounterTargetsField from "~/components/encounters/EncounterTargetsField"
import EncounterYesNoField from "~/components/encounters/EncounterYesNoField"

function EmailEncounterDetailsFields() {
  const {
    values: { emailEncounterDetailsAttributes = {} },
  } = useFormikContext()
  const { targetIds } = emailEncounterDetailsAttributes

  let subject = "they"
  if (!_.isNil(targetIds) && targetIds.length > 1) {
    subject = "someone"
  }

  return (
    <EncounterDetailsFields
      attributesName="emailEncounterDetailsAttributes"
      defaultValues={{
        respondedTo: "",
      }}
    >
      <EncounterTargetsField name="targetIds" label="Who did you send it to?" />
      <EncounterYesNoField name="respondedTo" label={`Did ${subject} respond?`} />
    </EncounterDetailsFields>
  )
}

export default EmailEncounterDetailsFields
