import { connect } from "react-redux"

import { collapseAll, expandAll, expandGoals } from "~/components/carePlans/carePlanUIActions"
import DividerList from "~/components/DividerList"
import { LinkButton } from "~/design/buttons"
import { ExpandAllIcon } from "~/design/icons"

function CollapseSelector({ collapseAllConnect, expandGoalsConnect, expandAllConnect }) {
  const performActionOnClick = (action) => (e) => {
    e.preventDefault()
    action()
  }

  return (
    <div>
      <DividerList>
        <LinkButton onClick={performActionOnClick(expandGoalsConnect)}>
          <span className="text-dark">
            <ExpandAllIcon />
          </span>
          &nbsp; Show goals
        </LinkButton>
        <LinkButton onClick={performActionOnClick(expandAllConnect)}>Show all</LinkButton>
        <LinkButton onClick={performActionOnClick(collapseAllConnect)}>Collapse all</LinkButton>
      </DividerList>
    </div>
  )
}

export default connect(null, {
  collapseAllConnect: collapseAll,
  expandGoalsConnect: expandGoals,
  expandAllConnect: expandAll,
})(CollapseSelector)
