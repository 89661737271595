import { FieldArray, useFormikContext } from "formik"
import PropTypes from "prop-types"

import { IconButton, LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import { DeleteIcon } from "~/design/icons"
import types from "~/types"

function MemberFormFields({ memberOptions }) {
  const {
    values: { memberCareManagerIds },
  } = useFormikContext()

  return (
    <FieldArray
      name="memberCareManagerIds"
      render={({ push, remove }) => (
        <>
          <p>Select</p>
          {memberCareManagerIds.map((memberCareManagerId, i) => (
            <div className="d-flex border-top pt-3" key={`${memberCareManagerId}-${i}`}>
              <div style={{ width: "85%" }}>
                <Form.Group clearOnHide={false} name={`memberCareManagerIds.${i}`}>
                  <Form.Label hidden>Member {i + 1}</Form.Label>
                  <Form.SelectBox options={memberOptions} isSearchable={true} />
                </Form.Group>
              </div>
              <div className="flex-grow-1 d-flex justify-content-center mb-3">
                <IconButton Icon={DeleteIcon} onClick={() => remove(i)} label={`Remove member ${i + 1}`} />
              </div>
            </div>
          ))}
          <div className="d-flex border-top pt-3">
            <div style={{ width: "85%" }}>
              <Form.Group clearOnHide={false} name={`memberCareManagerIds.${memberCareManagerIds.length}`}>
                <Form.Label hidden>New Member</Form.Label>
                <Form.SelectBox options={memberOptions} isSearchable={true} />
              </Form.Group>
            </div>
          </div>
          <LinkButton onClick={() => push("")}>Add another care manager</LinkButton>
        </>
      )}
    />
  )
}

MemberFormFields.propTypes = {
  memberOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default MemberFormFields
