import { useEffect } from "react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

import {
  isOutcomeMoreInfoNeeded,
  isOutcomeNotApplicable,
  isOutcomeRecommendContinuedServices,
  isOutcomeRecommendDisenrollment,
} from "../helpers"
import useCesToolSchema from "../hooks/useCesToolSchema"

import QuestionCard from "./QuestionCard"
import Section from "./Section"

import { PrimaryAlert, WarningAlert } from "~/design/alerts"
import Form from "~/design/forms"
import types from "~/types"

function ResultsSection({ cesToolOutcomes, cesToolRoleOptions }) {
  const { calculatedOutcomeId } = useCesToolSchema()
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    setFieldValue("outcomeId", calculatedOutcomeId)
  }, [calculatedOutcomeId, setFieldValue])

  const selectedCesToolOutcome = cesToolOutcomes.find(({ id }) => id === calculatedOutcomeId)

  if (!selectedCesToolOutcome) {
    return (
      <Section id="ces-tool-section-results" title="Results">
        <WarningAlert>
          <p>
            No result has been determined. Please answer all the questions on the screening tool to determine a result.
          </p>
        </WarningAlert>
      </Section>
    )
  }

  return (
    <Section id="ces-tool-section-results" title="Results">
      <PrimaryAlert>
        <h3 className="mb-3">
          CES Tool Screening Result: <strong>{selectedCesToolOutcome.description}</strong>
        </h3>
        <CestDescription outcomeId={selectedCesToolOutcome.id} />
      </PrimaryAlert>
      <QuestionCard questionNumber={1}>
        <Form.Group clearOnHide={false} name="startDate">
          <Form.Label>CES Tool Date</Form.Label>
          <Form.DatePicker helpText="This date will be reported to MAPP along with the CES Tool Screening Result in order to determine when the next CES Tool Screening is required." />
        </Form.Group>
      </QuestionCard>
      <QuestionCard questionNumber={2}>
        <Form.Group clearOnHide={false} name="roleId">
          <Form.Label>Type of staff completing the CES Tool</Form.Label>
          <Form.SelectBox options={cesToolRoleOptions} isClearable />
        </Form.Group>
      </QuestionCard>
      <Form.Show when={({ values }) => isOutcomeMoreInfoNeeded(values.outcomeId)}>
        <QuestionCard questionNumber={3}>
          <Form.Group clearOnHide={false} name="commentsOnItemsAnsweredUnclear">
            <Form.Label>Comments on items answered "UNCLEAR"</Form.Label>
            <Form.TextArea />
          </Form.Group>
        </QuestionCard>
      </Form.Show>
    </Section>
  )
}

ResultsSection.propTypes = {
  cesToolOutcomes: PropTypes.arrayOf(types.cesToolOutcome).isRequired,
  cesToolRoleOptions: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default ResultsSection

function CestDescription({ outcomeId }) {
  if (isOutcomeRecommendContinuedServices(outcomeId)) {
    return (
      <p className="mb-3">
        The member should remain in the Health Home program and have this tool re-completed in 6 months.
      </p>
    )
  } else if (isOutcomeRecommendDisenrollment(outcomeId)) {
    return (
      <p className="mb-3">
        Work with the member and their Care Team to implement this recommendation within the next 60 days. Depending on
        the member's needs, this could be a graduation, step-up, step-down, withdrawal of consent, etc. All standard
        disenrollment requirements must be followed.
      </p>
    )
  } else if (isOutcomeMoreInfoNeeded(outcomeId)) {
    return (
      <>
        <p className="mb-3">
          Talk to whomever you need to in order to clarify the 'UNCLEAR' items so that the tool generates a
          recommendation of either CONTINUE SERVICES or DISENROLLMENT. You must re-complete the DOH CES Tool within 60
          days, and follow the recommendation.
        </p>
        <p className="mb-3">
          If you are unable to get clarity to generate a recommendation of either CONTINUE SERVICES or DISENROLLMENT
          within 60 days, contact your lead Health Home for guidance. A second recommendation of MORE INFORMATION NEEDED
          is not acceptable.
        </p>
      </>
    )
  } else if (isOutcomeNotApplicable(outcomeId)) {
    return (
      <p>
        Due to being HH+ eligible or enrolled, this member is exempt from the DOH CES Tool requirements. No further
        action is needed after submitting this form.
      </p>
    )
  }

  return null
}

CestDescription.propTypes = {
  outcomeId: PropTypes.number,
}
