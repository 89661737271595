import Form from "~/design/forms"
import { globalsStateWrapper } from "~/redux"

function EncounterTargetField({ name, encounterTargets, label }) {
  return (
    <Form.Group name={name} errorKey="details.target">
      <Form.Label>{label}</Form.Label>
      <Form.SelectBox options={encounterTargets} isClearable />
    </Form.Group>
  )
}

export default globalsStateWrapper(({ encounterTargets }) => ({ encounterTargets }))(EncounterTargetField)
