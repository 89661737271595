import EditTaskForm from "~/components/carePlans/EditTaskForm"
import FormShowToggle from "~/components/carePlans/FormShowToggle"
import TaskDetails from "~/components/carePlans/TaskDetails"

const TaskCard = ({ task }) => {
  const connectedTaskForm = ({ onToggleEdit }) => <EditTaskForm task={task} onCancelClick={onToggleEdit} />

  const connectedTaskDetails = ({ onToggleEdit }) => <TaskDetails task={task} onEditClick={onToggleEdit} />

  return (
    <div className="task-card">
      <FormShowToggle formComponent={connectedTaskForm} showComponent={connectedTaskDetails} />
    </div>
  )
}

export default TaskCard
