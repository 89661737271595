import { useState } from "react"
import PropTypes from "prop-types"

import SideBarList from "../SideBarList"
import SideBarToggle from "../SideBarToggle"

import StrengthsForm from "./StrengthsForm"

import { ChildIcon, EditIcon } from "~/design/icons"
import { useModal } from "~/design/Modal"
import types from "~/types"

function StrengthSection({ chart, strengths: initialStrengths }) {
  const [show, { close, open }] = useModal()
  const [strengths, setStrengths] = useState(initialStrengths)

  const activeStrengthIds = Object.keys(strengths).filter((strengthId) => strengths[strengthId].active)

  return (
    <SideBarToggle
      title="Strengths"
      actionLabel="Edit strengths"
      HeaderIcon={ChildIcon}
      ActionIcon={EditIcon}
      onActionIconClick={open}
    >
      <SideBarList bulleted emptyListMessage="This patient does not have any strengths.">
        {activeStrengthIds.map((strengthId) => (
          <li key={strengthId}>{strengths[strengthId].description}</li>
        ))}
      </SideBarList>
      <StrengthsForm show={show} onClose={close} strengths={strengths} chart={chart} onUpdate={setStrengths} />
      <p className="font-weight-light mt-1">*Required by MAPP as part of the sync process</p>
    </SideBarToggle>
  )
}

StrengthSection.propTypes = {
  chart: types.chart.isRequired,
  strengths: PropTypes.objectOf(types.strength).isRequired,
}

export default StrengthSection
