import { submitAsyncRequest } from "~/asyncHelpers"
import { api_v1_health_home_unite_us_configurations_path } from "~/routes"

const uniteUsConfigurationUpdate = {
  put: function (healthHome, activate, agencyId = null, selectedCmas = []) {
    return submitAsyncRequest({
      url: api_v1_health_home_unite_us_configurations_path(healthHome),
      type: "put",
      data: {
        activate: activate,
        agencyId: agencyId,
        selectedCmas: selectedCmas,
      },
    })
  },
}

export default uniteUsConfigurationUpdate
