import { createSelector, createSlice } from "@reduxjs/toolkit"

import { sectionCreated, sectionDestroyed, sectionSelectors } from "./sectionsSlice"

const schemaVersionSlice = createSlice({
  name: "schemaVersion",
  initialState: {
    sectionIds: [],
  },
  reducers: {
    sectionsReordered: (state, action) => {
      state.sectionIds = action.payload
    },
  },
  extraReducers: {
    [sectionCreated]: (state, action) => {
      state.sectionIds.push(action.payload.id)
    },
    [sectionDestroyed]: (state, action) => {
      state.sectionIds = state.sectionIds.filter((sectionId) => sectionId !== action.payload)
    },
  },
})

export const { sectionsReordered } = schemaVersionSlice.actions

export const selectSchemaVersion = (state) => state.schemaVersion

export const selectOrderedSections = createSelector(
  [selectSchemaVersion, sectionSelectors.selectEntities],
  (schemaVersion, sectionEntities) => schemaVersion.sectionIds.map((sectionId) => sectionEntities[sectionId])
)

export const selectOrderedSectionIds = createSelector(
  [selectSchemaVersion],
  (schemaVersion) => schemaVersion.sectionIds
)

export default schemaVersionSlice.reducer
