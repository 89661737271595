import PropTypes from "prop-types"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { assessment_path, chart_assessments_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"
import ChartIndicator from "~/views/shared/ChartIndicator"

function New({ assessment, occasions, chart }) {
  async function handleSubmit(values, { setStatus, setErrors }) {
    try {
      const { id } = await api.assessments.post(chart.id, values)
      redirectTo(assessment_path(id))
      setStatus("success")
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Form initialValues={assessment} onSubmit={handleSubmit}>
      <Form.Group name="patientAssessmentOccasionId" errorKey="occasion">
        <Form.Label>Assessment Occasion</Form.Label>
        <Form.SelectBox options={occasions} placeholder="Select an occasion" />
      </Form.Group>
      <ChartIndicator chart={chart} />
      <Form.ActionBar
        right={
          <>
            <Form.CancelButton onClick={() => redirectTo(chart_assessments_path(chart))} />
            <Form.SubmitButton label="Create Assessment" />
          </>
        }
      />
    </Form>
  )
}

New.propTypes = {
  assessment: types.assessment.isRequired,
  occasions: PropTypes.arrayOf(types.selectOption).isRequired,
  chart: types.chart.isRequired,
}

export default New
