/* eslint-disable react/no-danger */

import DOMPurify from "dompurify"
import pluralize from "pluralize"
import PropTypes from "prop-types"

import { SIDEBAR_TOP_OFFSET } from "../constants"
import * as customAssessmentResponseTypes from "../types"

import Show from "~/design/Show"

function ResponseSection({ children, section }) {
  return (
    <div id={`response-section-${section.id}`} style={{ scrollMarginTop: `${SIDEBAR_TOP_OFFSET}px` }}>
      <h2 className="mb-3">{section.sectionName}</h2>
      <Show when={Boolean(section.description)}>
        <p className="rich-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.description) }} />
      </Show>
      <Show when={section.questions.length > 0}>
        <p>
          <strong>{pluralize("question", section.questions.length, true)}</strong>
        </p>
      </Show>
      {children}
      <hr className="border-top" />
    </div>
  )
}

ResponseSection.propTypes = {
  children: PropTypes.node.isRequired,
  section: customAssessmentResponseTypes.schemaSection,
}

export default ResponseSection
