import { Component } from "react"

class FormShowToggle extends Component {
  state = {
    edit: false,
  }

  toggleEdit = () => {
    this.setState((prevState) => ({ edit: !prevState.edit }))
  }

  render() {
    const { formComponent: FormComponent, showComponent: ShowComponent } = this.props
    if (this.state.edit) {
      return <FormComponent onToggleEdit={this.toggleEdit} />
    }

    return <ShowComponent onToggleEdit={this.toggleEdit} />
  }
}

export default FormShowToggle
