import { format, isValid, parseISO } from "date-fns"

export default function formattedDate(date) {
  const parsedDate = parseISO(date)

  if (isValid(parsedDate)) {
    return format(parsedDate, "M/d/yyyy")
  } else {
    return date
  }
}

// mimics Time::DATE_FORMATS[:app_default] in ./config/initializers/time_formats.rb
export function toAppDefaultDateTime(dateTime) {
  const parsedDate = parseISO(dateTime)

  if (isValid(parsedDate)) {
    return format(parsedDate, "M/d/yyyy h:mm a")
  } else {
    return dateTime
  }
}
