import { useFormikContext } from "formik"

import RecordTables from "./RecordTables"

import Jumbotron from "~/design/Jumbotron"

function RecordSelection() {
  const {
    values: { copiedFromChart },
  } = useFormikContext()

  if (!copiedFromChart) {
    return <Jumbotron message="Select a chart to copy data from" />
  }

  return <RecordTables copiedFromChartId={copiedFromChart.id} />
}

export default RecordSelection
