import { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import SideBarList from "../SideBarList"
import SideBarToggle from "../SideBarToggle"

import AddCareTeamMembershipForm from "./AddCareTeamMembershipForm"
import EditCareTeamMembershipForm from "./EditCareTeamMembershipForm"
import useCareTeamMembershipModal from "./useCareTeamMembershipModal"

import { IconButton } from "~/design/buttons"
import { AddIcon, DeleteIcon, DoctorIcon, EditIcon } from "~/design/icons"
import useConfirmationModal from "~/design/modals/useConfirmationModal"
import Show from "~/design/Show"
import { chart_memberships_path, chart_unite_us_referrals_path } from "~/routes"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function CareTeamMembershipSection({
  careTeamMemberships: initialCareTeamMemberships,
  chart,
  providerCredentials,
  providerTitles,
  providerTypes,
  uniteUsEnabled,
}) {
  const [careTeamMemberships, setCareTeamMemberships] = useState(initialCareTeamMemberships)

  const { selectedMembership, isAddModalVisible, isEditModalVisible, showAddModal, showEditModal, hideModal } =
    useCareTeamMembershipModal()
  const confirm = useConfirmationModal()

  function handleAdd(membership) {
    setCareTeamMemberships((careTeamMemberships) => [...careTeamMemberships, membership])
  }

  async function handleDelete(membershipId) {
    const confirmed = await confirm.danger(
      `Remove this provider from the care team? If the provider was a member of the care team, but is no longer active,
      please don't remove them — mark the provider 'Inactive' instead by clicking 'Edit.' If the provider was
      mistakenly added to the care team, click 'Remove' to remove them.`,
      { confirmButtonLabel: "Remove" }
    )

    if (confirmed) {
      try {
        await api.careTeamMemberships.delete(membershipId)
        toast.success("Care team member successfully removed.")
        setCareTeamMemberships((careTeamMemberships) =>
          careTeamMemberships.filter((membership) => membership.id !== membershipId)
        )
      } catch {
        toast.error(
          <p>
            Care team member could not be removed.
            <br />
            Please refresh the page.
          </p>
        )
      }
    }
  }

  function handleUpdate(updatedMembership) {
    setCareTeamMemberships((careTeamMemberships) =>
      careTeamMemberships.map((membership) => (membership.id === updatedMembership.id ? updatedMembership : membership))
    )
  }

  const activeCareTeamMemberships = careTeamMemberships.filter((membership) => membership.active)

  return (
    <>
      <SideBarToggle
        title="Care Team"
        HeaderIcon={DoctorIcon}
        ActionIcon={AddIcon}
        actionLabel="Add care team member"
        onActionIconClick={showAddModal}
      >
        <SideBarList emptyListMessage="This patient does not have any active care team members.">
          {activeCareTeamMemberships.map((membership, index) => (
            <li
              key={membership.id}
              className={classNames("py-2", { "border-bottom": index < activeCareTeamMemberships.length - 1 })}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <span className="small text-uppercase">{membership.providerTypeName}</span>
                  <span>
                    <IconButton
                      Icon={EditIcon}
                      label="Edit care team member"
                      size="sm"
                      onClick={() => showEditModal(membership)}
                    />
                    <IconButton
                      Icon={DeleteIcon}
                      label="Delete care team member"
                      size="sm"
                      onClick={() => handleDelete(membership.id)}
                    />
                  </span>
                </div>
                <h4 className="font-weight-bold mb-2">{membership.provider.name}</h4>
                <p className="small m-0">Specialty: {membership.provider.specialty}</p>
                <p className="small m-0">{membership.provider.facility}</p>
                <p className="small m-0">{membership.provider.phone}</p>
                <p className="small m-0">{membership.provider.address}</p>
              </div>
            </li>
          ))}
        </SideBarList>
        <a href={chart_memberships_path(chart.id)}>View inactive care team members</a>
        <Show when={uniteUsEnabled}>
          <hr />
          <a href={chart_unite_us_referrals_path(chart.id)}>Make Referral</a>
        </Show>
      </SideBarToggle>
      {selectedMembership ? (
        <EditCareTeamMembershipForm
          membership={selectedMembership}
          providerTypes={providerTypes}
          show={isEditModalVisible}
          onClose={hideModal}
          onUpdate={handleUpdate}
        />
      ) : null}
      <AddCareTeamMembershipForm
        chart={chart}
        providerCredentials={providerCredentials}
        providerTitles={providerTitles}
        providerTypes={providerTypes}
        show={isAddModalVisible}
        onAdd={handleAdd}
        onClose={hideModal}
      />
    </>
  )
}

CareTeamMembershipSection.propTypes = {
  careTeamMemberships: PropTypes.arrayOf(types.careTeamMembership).isRequired,
  chart: types.chart.isRequired,
  providerCredentials: PropTypes.arrayOf(types.selectOption).isRequired,
  providerTitles: PropTypes.arrayOf(types.selectOption).isRequired,
  providerTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  uniteUsEnabled: PropTypes.bool.isRequired,
}

export default CareTeamMembershipSection
