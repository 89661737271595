import { Card as BootstrapCard } from "react-bootstrap"
import PropTypes from "prop-types"

function Card({ children, dataTestid }) {
  return (
    <BootstrapCard className="mb-3" data-testid={dataTestid}>
      {children}
    </BootstrapCard>
  )
}

function CardHeader({ children, title }) {
  return (
    <BootstrapCard.Header className="d-flex justify-content-between align-items-center">
      <h3 className="font-weight-bold">{title}</h3>
      <span>{children}</span>
    </BootstrapCard.Header>
  )
}

function CardBody({ children }) {
  return <BootstrapCard.Body>{children}</BootstrapCard.Body>
}

function CardFooter({ left, right }) {
  return (
    <BootstrapCard.Footer className="d-flex justify-content-between bg-white">
      <span className="d-flex align-items-center gap-2">{left}</span>
      <span className="d-flex align-items-center gap-2">{right}</span>
    </BootstrapCard.Footer>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestid: PropTypes.string,
}

CardHeader.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

CardBody.propTypes = {
  children: PropTypes.node.isRequired,
}

CardFooter.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node.isRequired,
}

Card.Header = CardHeader
Card.Body = CardBody
Card.Footer = CardFooter

export default Card
